<template>
  <div
    class="items-list__item items-list__item--topx"
    :class="{ 'items-list__item--digital': isDigitalMagazinesCats || isDigitalMagazine }"
    itemprop="itemListElement"
    itemscope
    itemtype="http://schema.org/ListItem"
  >
    <client-only>
      <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="false"></loading>
    </client-only>

    <TheMagazineImage :item="item" :show-save-overlay="showSaveOverlay" :show-free-gift-overlay="false" />

    <div class="product__information">
      <a :href="linkURL(item)" class="product__text" @click.prevent="handleViewOffer(item)">
        <h3 :title="item.title" class="product__magazine" itemprop="name">
          {{ item.title }}
        </h3>
        <meta :content="index" itemprop="position" />
        <meta :content="`${$config.baseURL}${item.url}`" itemprop="url" />
        <div v-if="!isDigitalMagazinesCats" class="product__offer">
          <p class="special-offer">{{ item.offer.name }}</p>
          <span v-if="!isDigitalMagazine && item.offer.save > 0" class="savings">Saving {{ item.offer.save }}%</span>
        </div>
      </a>
      <div v-if="isDigitalMagazinesCats" class="actions--digital">
        <a :href="linkURL(item)" class="view-btn--digital" title="view product" @click.prevent="handleViewOffer(item)">View Offers</a>
      </div>
      <div v-else-if="isDigitalMagazine" class="actions actions--center">
        <a :href="linkURL(item)" class="view-btn view-btn--center" title="view product" @click.prevent="handleViewOffer(item)">View Offers</a>
      </div>
      <div v-else class="actions">
        <a :href="linkURL(item)" class="view-btn" title="view product" @click.prevent="handleViewOffer(item)">View Offers</a>
        <!-- <span class="btn-divider">|</span> -->
        <div class="a add-to-basket add-to-basket-btn add-to-basket-clp" title="add to basket" @click.prevent="handleClickAction(item, 'add')">Add to Basket</div>
      </div>
    </div>
  </div>
</template>

<script>
import basketMixin from '~/mixins/basket.vue'
import gtmProductPageMixin from '@/mixins/gtm/product-page'

export default {
  components: {
    TheMagazineImage: () => import('~/components/common/TheMagazineImage')
  },
  mixins: [gtmProductPageMixin, basketMixin],
  props: {
    item: {
      type: Object,
      required: true,
      default() {
        return {}
      }
    },
    index: {
      type: Number,
      required: true,
      default: 0
    },
    showSaveOverlay: {
      type: Boolean,
      required: false,
      default() {
        return false
      }
    },
    isDigitalMagazinesCats: {
      type: Boolean,
      required: false,
      default() {
        return false
      }
    },
    imgixQueryString: {
      type: String,
      required: false,
      default() {
        return '?fm=png&auto=format&fit=fill&fill=solid&bg=0FFF&w=178'
      }
    }
  },
  data() {
    return {
      isLoading: false,
      imgixQueryStringValue: this.isFromMobile ? '?fm=png&auto=format&fit=fill&fill=solid&bg=0FFF&w=178' : '?fm=png&auto=format&fit=fill&fill=solid&bg=0FFF&w=204'
    }
  },
  computed: {
    isDigitalMagazine() {
      return !this.item.offer || this.item.offer.productId === 0
    }
  },
  methods: {
    linkURL(item) {
      const url = item?.url
      if (!url) {
        return this.$config.baseURL
      }
      return url.replace(/magazine-(\d{4,5})$/g, 'magazine')
    },
    getProductUrl(item) {
      if (this.categoryUrl) {
        const productPath = item.url.replace(/^\/magazines/, '')
        return `${this.categoryUrl}${productPath}`
      }
      return item.url.replace(/magazine-(\d{4,5})$/g, 'magazine')
    },
    async handleClickAction(item, action) {
      console.log('common/topx/Product.vue handleClickAction item=', item)
      console.log('common/topx/Product.vue handleClickAction action=', action)

      // Update the BE Basket data
      const ret = await this.updateBasket(item, action)

      // GTM & FB push events integration
      !ret.error && this.pushGTMAndFBAfterButtonClick({ action, item })

      !ret.error && this.$nuxt.$emit('SHOW_BASKET', true)
    },
    handleViewOffer(item) {
      // Trigger GTM event
      if (item && item.url) {
        this.$gtm.push({ viewspecialofferurl: `${this.$config.baseURL}${item.url}` })
        this.$gtm.push({ event: 'viewspecialoffer' })
        this.$store.commit('s/addtorecentlyviewed', this.item)
        // Redirct to the offer page
        window.location.href = this.getProductUrl(item)
      }
    }
  }
}
</script>

<style lang="scss">
h3 {
  margin: 0 !important;
  color: $bodyTextColor;
  font-size: 12px;
  font-weight: 700 !important;
}
</style>
