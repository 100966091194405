<template>
  <div class="gc-print">
    <div class="gc-print__header">
      <center>
        <img
          src="https://magazine.imgix.net/wysiwyg/logo_home_desktop.png?fm=png&auto=format&w=287&dpr=2&q=30"
          alt="magazine.co.uk"
          border="0"
          title="magazine.co.uk"
          width="286"
        />
        <p>{{ fromName }} {{ havePresentTense }} bought you a gift subscription!</p>
      </center>
    </div>
    <div class="gc-print__wrapper gc-print--border">
      <client-only>
        <ECardTemplate
          :image="image"
          :message="message"
          :magazine-cover="magazineCover"
          :magazine-name="magazineName"
          :from-name="fromName"
          :from-email="fromEmail"
          :to-name="toName"
          :to-email="toEmail"
          :to-date="new Date(toDate)"
          :is-print="true"
        />
      </client-only>
      <p class="gc-print__button">
        <a target="_blank" href="#" onclick="window.print();return false;">
          Print eCard
        </a>
      </p>
      <p class="gc-print__section">
        <b>You don't have to do anything.</b><br />
        You don't have to do anything. You will receive the first new issue released after your order has been processed by the publisher. Depending on a magazine's publishing
        schedule and frequency (i.e. when the next issue will be released) this will typically mean your first issue will arrive in 2 to 6 weeks.
      </p>
      <p class="gc-print__section">
        If you have any questions about your Gift Subscription, please contact us at enquiries@magazine.co.uk
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ECardPrint',
  components: {
    ECardTemplate: () => import('~/components/ecard/ECardTemplate.vue')
  },
  serverCacheKey() {
    return false
  },
  props: {
    isPreview: {
      type: Boolean,
      required: false,
      default() {
        return false
      }
    },
    image: {
      type: String,
      required: false,
      default() {
        return 'https://magazine.imgix.net/ecard/tropical2.png?auto=format&w=540&dpr=3&rect=left,top,1506,375'
      }
    },
    message: {
      type: String,
      required: false,
      default() {
        return "You've been bought a magazine gift subscription from magazine.co.uk"
      }
    },
    magazineCover: {
      type: String,
      required: true,
      default() {
        return 'https://magazine.imgix.net/placeholder.jpg?auto=format&fit=fill&fill=solid&bg=0FFF&w=200&dpr=3'
      }
    },
    magazineName: {
      type: String,
      required: false,
      default() {
        return ''
      }
    },
    fromName: {
      type: String,
      required: false,
      default() {
        return ''
      }
    },
    fromEmail: {
      type: String,
      required: false,
      default() {
        return ''
      }
    },
    toName: {
      type: String,
      required: false,
      default() {
        return ''
      }
    },
    toEmail: {
      type: String,
      required: false,
      default() {
        return ''
      }
    },
    toDate: {
      type: Date,
      required: false,
      default() {
        return new Date()
      }
    },
    disabledAnchors: {
      type: Boolean,
      required: false,
      default() {
        return false
      }
    }
  },
  data() {
    return {
      designPreviewQueryString: '?auto=format&w=540&dpr=3&rect=left,top,1506,375'
    }
  },
  computed: {
    havePresentTense() {
      // Add have/has preent tense depens on the first_name value
      let value = 'has'
      const regexInsensitive = /( and | or |&|\+)/i
      if (regexInsensitive.test(this.fromName)) {
        value = 'have'
      }
      return value
    }
  },
  mounted() {
    // Trigger a print on the current browser window
    // setTimeout(() => {
    //   window.print()
    // }, 1000)
  },
  methods: {
    createImgixURL(image, qs) {
      return `${this.$config.imgixURL}${image}${qs}`
    }
  }
}
</script>

<style lang="scss" scoped>
.gc-print {
  display: flex;
  flex-flow: column;
  align-items: center;
  text-align: center;
  font-size: 14px;
  margin: 30px;
  &__wrapper {
    background-color: rgb(242, 242, 242);
    display: flex;
    flex-flow: column;
    align-items: center;
    text-align: center;
    padding: 30px 20px 20px 20px;
  }
  &__section {
    max-width: 660px;
    margin-top: 60px;
    color: rgb(53, 53, 53s);
    line-height: 22px;
    margin: 0% 10% 5% 10%;
  }
  &--border {
    // border: 1px solid #d9d9d9;
    border-top: 2px solid rgb(154, 154, 154);
  }
  &__header {
    img {
      margin: 20px 20px 5px 20px;
    }
    padding: 0 10px;
    width: 100%;
    border-top: 3px solid rgb(230, 0, 4);
    color: rgb(91, 90, 90);
  }
  &__button {
    background-color: rgb(230, 0, 4);
    word-wrap: break-word;
    word-break: break-word;
    border: 0px solid black;
    padding: 8px 15px;
    color: white;
    font-family: Arial, sans-serif;
    font-size: 16px;
    text-align: center;
    text-decoration: none;
    display: block;
    border-radius: 20px;
    font-weight: bold;
    height: 100%;
    font-style: normal;
    line-height: inherit;
    padding-top: 9px;
    padding-right: 30px;
    padding-bottom: 9px;
    padding-left: 30px;
    a {
      color: white;
      text-decoration: none;
    }
    margin: 5%;
  }
}
.gc-live-preview-inner .gc-design-img-preview {
  border-radius: 0px !important;
}

#gc-live-preview-container {
  box-shadow: unset !important;
}
@media (max-width: $screen-xs-max) {
  .gc-print {
    padding: unset;
    margin: 30px 5px;
    &--border {
      padding: 10px 0px;
    }
  }
}
@media print {
  .gc-print {
    margin: 50px auto;
    &--border {
      border: 1px solid #d9d9d9;
    }
    &__button {
      display: none !important;
    }
  }
}
</style>
