import { render, staticRenderFns } from "./pocketmagsplus.vue?vue&type=template&id=2bfdba6c"
import script from "./pocketmagsplus.vue?vue&type=script&lang=js"
export * from "./pocketmagsplus.vue?vue&type=script&lang=js"
import style0 from "./pocketmagsplus.vue?vue&type=style&index=0&id=2bfdba6c&prod&lang=scss"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports