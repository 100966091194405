<template>
  <div class="content__main">
    <Carousel3Images :id="`homepage::${dataUI.expiresAt}`" :banners="dataUI.banners || []" location-id="homepage_hero_banner" :is-homepage="true" :timestamp="timestamp" />
    <!-- <LazyHydrate > -->
    <TheSearch />
    <!-- </LazyHydrate> -->
    <div class="content--grid" style="max-width: 100vw;">
      <HomepageNav :timestamp="timestamp" />
      <LazyHydrate never>
        <FilterAZ />
      </LazyHydrate>
      <div
        v-for="(gridCategory, index) in dataUI.grid.slice(0, 4)"
        :id="`homepagegrid-${gridCategory.key}`"
        :key="`${gridCategory.key}-${index}`"
        class="grid-container"
        style="content-visibility: auto;"
      >
        <!-- <LazyHydrate when-visible> -->
        <HomepageGirdCategory :grid-category="gridCategory" />
        <!-- </LazyHydrate> -->
      </div>
      <LazyHydrate when-visible>
        <HomepageTopx id="top-x" :values="dataUI.topx" :show-save-overlay="true" />
      </LazyHydrate>
    </div>

    <LazyHydrate never>
      <PromotionsSitewideMobile v-if="sitewidemobile && sitewidemobile.enabled === true" creative-slot="mobile" location-id="sitewide_offer_promotion" :sitewide="sitewidemobile" />
    </LazyHydrate>
    <div class="content--description">
      <!-- eslint-disable-next-line vue/no-v-html -->
      <div v-if="dataUI.content" class="content--description__text" v-html="textToShow"></div>
      <button class="content--description__showreadmore" @click="showText = !showText">{{ showText ? 'Read less' : 'Read more' }}</button>
    </div>
  </div>
</template>

<script>
import LazyHydrate from 'vue-lazy-hydration'
import xss from 'xss'
import { sendSSAPIPageView, createUniqueFBEventId } from '~/lib/util/fb'
import { getGTMPageViewDetailsPayload } from '~/lib/util/gtmUAEE'
import { activeBannersImages } from '~/lib/util/page'

import { getStatusCodeFromPath, callAWSFrontendAPI } from '~/lib/util/aws'

export default {
  filters: {
    sanitize(value) {
      if (!value) {
        return ''
      }
      return xss(value)
    }
  },
  components: {
    LazyHydrate,
    HomepageNav: () => import('~/components/Homepage/HomepageNav.vue'),
    FilterAZ: () => import('~/components/common/FilterAZ.vue'),
    HomepageGirdCategory: () => import('~/components/Homepage/HomepageGirdCategory.vue'),
    HomepageTopx: () => import('~/components/Homepage/HomepageTopx.vue'),
    Carousel3Images: () => import('~/components/common/Carousel3Images.vue'),
    TheSearch: () => import('~/components/common/TheSearch.vue'),
    PromotionsSitewideMobile: () => import('~/components/common/promotions/PromotionsSitewideMobile.vue')
  },

  layout: 'home',

  async fetch() {
    const status = getStatusCodeFromPath({ context: this })
    if (status !== 200) {
      if (process.server) {
        this.$nuxt.context.res.statusCode = status
      }
      this.$store.commit('UI/statusCode', status, { root: true })
      return
    }

    // Look for an available Query String and retrieve the URL path
    const path = 'home'
    const query = this.$nuxt.context.query
    const useCache = this.$store.getters['UI/useCache']
    console.log('page=Homepage')
    console.log('path=', path)
    console.log('query=', query)
    console.log('QS useCache=', useCache)

    this.dataUI = {
      ...(await callAWSFrontendAPI({ context: this, path, query, useCache, type: 'CATEGORYPAGE' }))
    }

    // Fill the Banners data with the up to date banner's images
    const activeBanners = activeBannersImages({ banners: this.dataUI.banners, date: this.$route.query.date || undefined, addExtraImage: true })
    this.dataUI.banners = [...activeBanners]
    this.$store.commit('UI/banners', activeBanners, { root: true })
    this.$store.commit('UI/expiresAt', this.dataUI.expiresAt, { root: true })
  },

  fetchOnServer: true,
  // Doc https://nuxtjs.org/announcements/understanding-how-fetch-works-in-nuxt-2-12/
  // Doc https://nuxtjs.org/docs/components-glossary/fetch/
  // multiple components can return the same `fetchKey` and Nuxt will track them both separately
  // fetchKey: 'home',
  // alternatively, for more control, a function can be passed with access to the component instance
  // It will be called in `created` and must not depend on fetched data
  // fetchKey(getCounter) {
  //   // getCounter is a method that can be called to get the next number in a sequence
  //   // as part of generating a unique fetchKey.
  //   return `home::${this.dataUI.expiresAt}${getCounter('home')}`
  // },
  // Set the minimum executing time in milliseconds (to avoid quick flashes)
  // fetchDelay: 5000,

  // activated() {
  //   // Call fetch again if last fetch more than a minute ago
  //   if (this.$fetchState.timestamp <= Date.now() - 1000) {
  //     this.$fetch()
  //   }
  // },

  data() {
    return {
      isHomePage: true,
      imgixBaseQueryString: '?auto=format',
      queryString: '&w=467&h=138',
      dataUI: {
        grid: [
          {
            label: 'Magazines For Women',
            items: [
              {
                offer: {
                  rrp: '23.94',
                  productId: 1470,
                  cpa: '9.00',
                  price: '15.00',
                  name: '6 issues for £15.00',
                  save: 37,
                  numOfIssues: 6,
                  id: 'VOC1',
                  type: 'PRINT',
                  sku: '4064',
                  paymentType: 'DD'
                },
                subCategory: 'Celebrity Gossip',
                productId: 2605,
                imageUrl: '/catalog/product/v/o/vogue-magazine_4_1.jpg?fm=png&auto=format&fit=fill&fill=solid&bg=0FFF&w=180&h=250',
                id: 'VOC1',
                title: 'Vogue',
                sku: 'Vogue',
                category: 'Fashion and Beauty',
                url: '/magazines/vogue-magazine'
              },
              {
                offer: {
                  rrp: '48.96',
                  productId: 1169,
                  cpa: '13.50',
                  price: '34.99',
                  name: '12 issues for £34.99',
                  save: 29,
                  numOfIssues: 12,
                  id: 'IHI1',
                  type: 'PRINT',
                  sku: '3003',
                  paymentType: 'DD'
                },
                subCategory: 'House and Home',
                productId: 2636,
                imageUrl: '/products/ideal-home-magazine-cover.jpg?fm=png&auto=format&fit=fill&fill=solid&bg=0FFF&w=180&h=250',
                id: 'IHI1',
                title: 'Ideal Home',
                sku: 'Ideal Home',
                category: 'Home and Garden',
                url: '/magazines/ideal-home-magazine'
              },
              {
                offer: {
                  rrp: '28.50',
                  productId: 133,
                  cpa: '11.00',
                  price: '9.99',
                  name: '6 issues for £9.99',
                  save: 65,
                  numOfIssues: 6,
                  id: 'NGHK',
                  type: 'PRINT',
                  sku: '5163',
                  paymentType: 'DD'
                },
                subCategory: 'House and Home',
                productId: 8423,
                imageUrl: '/products/good-housekeeping-magazine-cover.jpg?fm=png&auto=format&fit=fill&fill=solid&bg=0FFF&w=180&h=250',
                id: 'NGHK',
                title: 'Good Housekeeping',
                sku: 'Good Housekeeping',
                category: 'Home and Garden',
                url: '/magazines/good-housekeeping-magazine'
              },
              {
                offer: {
                  rrp: '45.48',
                  productId: 219,
                  cpa: '12.00',
                  price: '21.99',
                  name: '12 issues for £21.99',
                  save: 52,
                  numOfIssues: 12,
                  id: 'READ',
                  type: 'PRINT',
                  sku: '1679',
                  paymentType: 'DD'
                },
                subCategory: 'House and Home',
                productId: 2821,
                imageUrl: '/products/readers-digest-magazine-cover.jpg?fm=png&auto=format&fit=fill&fill=solid&bg=0FFF&w=180&h=250',
                id: 'READ',
                title: 'Readers Digest',
                sku: 'Readers Digest',
                category: 'Womens Lifestyle',
                url: '/magazines/readers-digest-magazine'
              },
              {
                offer: {
                  rrp: '13.80',
                  productId: 11636,
                  cpa: '15.12',
                  price: '3.00',
                  name: '3 issues for £3.00',
                  save: 78,
                  numOfIssues: 3,
                  id: 'ANGT',
                  type: 'PRINT',
                  sku: '9786',
                  paymentType: 'DD'
                },
                subCategory: 'Nature and Wildlife',
                productId: 2924,
                imageUrl: '/products/national-geographic-traveller-magazine-cover.jpg?fm=png&auto=format&fit=fill&fill=solid&bg=0FFF&w=180&h=250',
                id: 'ANGT',
                title: 'National Geographic Traveller',
                sku: 'National Geographic Traveller',
                category: 'Travel',
                url: '/magazines/national-geographic-traveller-magazine'
              }
            ],
            key: 'for-women',
            url: '/magazine-subscriptions-for-women'
          },
          {
            label: 'Magazines For Men',
            items: [
              {
                offer: {
                  rrp: '22.80',
                  productId: 325,
                  cpa: '11.50',
                  price: '6.00',
                  name: '6 issues for £6.00',
                  save: 74,
                  numOfIssues: 6,
                  id: 'TWD1',
                  type: 'PRINT',
                  sku: '1364',
                  paymentType: 'DD'
                },
                subCategory: 'Money and Finance',
                productId: 2704,
                imageUrl: '/products/the-week-magazine-cover.jpg?fm=png&auto=format&fit=fill&fill=solid&bg=0FFF&w=180&h=250',
                id: 'TWD1',
                title: 'The Week',
                sku: 'The Week',
                category: 'Business and Finance',
                url: '/magazines/the-week-magazine'
              },
              {
                offer: {
                  rrp: '22.80',
                  productId: 3384,
                  cpa: '14.50',
                  price: '6.00',
                  name: '6 issues for £6.00',
                  save: 74,
                  numOfIssues: 6,
                  id: 'HAUM',
                  type: 'PRINT',
                  sku: '5314',
                  paymentType: 'DD'
                },
                subCategory: 'Cars',
                productId: 3004,
                imageUrl: '/products/autocar-magazine-cover.jpg?fm=png&auto=format&fit=fill&fill=solid&bg=0FFF&w=180&h=250',
                id: 'HAUM',
                title: 'Autocar',
                sku: 'Autocar',
                category: 'Motoring and Transport',
                url: '/magazines/autocar-magazine'
              },
              {
                offer: {
                  rrp: '54.00',
                  productId: 10340,
                  cpa: '12.00',
                  price: '12.00',
                  name: '12 issues for £12.00',
                  save: 78,
                  numOfIssues: 12,
                  id: 'STA1',
                  type: 'PRINT',
                  sku: '8442',
                  paymentType: 'DD'
                },
                subCategory: 'History',
                productId: 10339,
                imageUrl: '/catalog/product/n/e/new-statesman-magazine_3_3.jpg?fm=png&auto=format&fit=fill&fill=solid&bg=0FFF&w=180&h=250',
                id: 'STA1',
                title: 'New Statesman',
                sku: 'New Statesman',
                category: 'News and Current Affairs',
                url: '/magazines/new-statesman-magazine'
              },
              {
                offer: {
                  rrp: '23.50',
                  productId: 225,
                  cpa: '9.50',
                  price: '5.00',
                  name: '5 issues for £5.00',
                  save: 79,
                  numOfIssues: 5,
                  id: 'EVD1',
                  type: 'PRINT',
                  sku: '272',
                  paymentType: 'DD'
                },
                subCategory: 'Cars',
                productId: 3043,
                imageUrl: '/products/evo-magazine-cover.jpg?fm=png&auto=format&fit=fill&fill=solid&bg=0FFF&w=180&h=250',
                id: 'EVD1',
                title: 'Evo',
                sku: 'Evo',
                category: 'Motoring and Transport',
                url: '/magazines/evo-magazine'
              },
              {
                offer: {
                  rrp: '51.00',
                  productId: 1298,
                  cpa: '9.50',
                  price: '12.00',
                  name: '12 issues for £12.00',
                  save: 76,
                  numOfIssues: 12,
                  id: 'MYWK',
                  type: 'PRINT',
                  sku: '2587',
                  paymentType: 'DD'
                },
                subCategory: 'Money and Finance',
                productId: 2674,
                imageUrl: '/catalog/product/m/o/moneyweek-magazine_4.jpg?fm=png&auto=format&fit=fill&fill=solid&bg=0FFF&w=180&h=250',
                id: 'MYWK',
                title: 'MoneyWeek',
                sku: 'MoneyWeek',
                category: 'Business and Finance',
                url: '/magazines/moneyweek-magazine-2674'
              }
            ],
            key: 'for-men',
            url: '/magazine-subscriptions-for-men'
          },
          {
            label: 'Magazines for Kids',
            items: [
              {
                offer: {
                  rrp: '52.00',
                  productId: 1154,
                  cpa: '10.00',
                  price: '41.00',
                  name: '12 issues for £41.00',
                  save: 21,
                  numOfIssues: 12,
                  id: 'NGEK',
                  type: 'PRINT',
                  sku: '2255',
                  paymentType: 'DD'
                },
                subCategory: 'Nature and Wildlife',
                productId: 2888,
                imageUrl: '/catalog/product/n/a/nat-geo-kids-magazine_2.jpg?fm=png&auto=format&fit=fill&fill=solid&bg=0FFF&w=180&h=250',
                id: 'NGEK',
                title: 'National Geographic Kids',
                sku: 'National Geographic Kids',
                category: 'Kids',
                url: '/magazines/national-geographic-kids-magazine'
              },
              {
                offer: {
                  rrp: '13.50',
                  productId: 6804,
                  cpa: '9.50',
                  price: '1.00',
                  name: '6 issues for £1.00',
                  save: 93,
                  numOfIssues: 6,
                  id: 'WJD1',
                  type: 'PRINT',
                  sku: '7315',
                  paymentType: 'DD'
                },
                subCategory: 'Science',
                productId: 6820,
                imageUrl: '/catalog/product/t/h/the_week_junior.jpg?fm=png&auto=format&fit=fill&fill=solid&bg=0FFF&w=180&h=250',
                id: 'WJD1',
                title: 'The Week Junior',
                sku: 'The Week Junior',
                category: 'Kids',
                url: '/magazines/the-week-junior-magazine'
              },
              {
                offer: {
                  rrp: '5.97',
                  productId: 2290,
                  cpa: '13.00',
                  price: '1.00',
                  name: '3 issues for £1.00',
                  save: 83,
                  numOfIssues: 3,
                  id: 'FNEW',
                  type: 'PRINT',
                  sku: '4811',
                  paymentType: 'DD'
                },
                subCategory: 'Parenting and Teaching',
                productId: 2719,
                imageUrl: '/products/first-news-magazine-2-cover.jpg?fm=png&auto=format&fit=fill&fill=solid&bg=0FFF&w=180&h=250',
                id: 'FNEW',
                title: 'First News',
                sku: 'First News',
                category: 'Kids',
                url: '/magazines/first-news-magazine'
              },
              {
                offer: {
                  rrp: '59.88',
                  productId: 2325,
                  cpa: '15.00',
                  price: '36.99',
                  name: '12 issues for £36.99',
                  save: 38,
                  numOfIssues: 12,
                  id: 'STL1',
                  type: 'PRINT',
                  sku: '4739',
                  paymentType: 'DD'
                },
                subCategory: 'Parenting and Teaching',
                productId: 2602,
                imageUrl: '/catalog/product/s/t/storytime-magazine_44.jpg?fm=png&auto=format&fit=fill&fill=solid&bg=0FFF&w=180&h=250',
                id: 'STL1',
                title: 'Storytime',
                sku: 'Storytime',
                category: 'Kids',
                url: '/magazines/storytime-magazine'
              },
              {
                offer: {
                  rrp: '20.99',
                  productId: 11458,
                  cpa: '9.50',
                  price: '19.99',
                  name: '6 issues for £19.99',
                  save: 5,
                  numOfIssues: 6,
                  id: 'SCNA',
                  type: 'PRINT',
                  sku: '9627',
                  paymentType: 'DD'
                },
                subCategory: 'Nature and Wildlife',
                productId: 11457,
                imageUrl: '/catalog/product/s/c/science-and-nature-magazine_2.jpg?fm=png&auto=format&fit=fill&fill=solid&bg=0FFF&w=180&h=250',
                id: 'SCNA',
                title: 'Science+Nature',
                sku: 'Science+Nature',
                category: 'Kids',
                url: '/magazines/science-nature-magazine'
              },
              {
                offer: {
                  rrp: '25.50',
                  productId: 4384,
                  cpa: '13.50',
                  price: '14.99',
                  name: '6 issues for £14.99',
                  save: 41,
                  numOfIssues: 6,
                  id: 'ITI1',
                  type: 'PRINT',
                  sku: '5504',
                  paymentType: 'DD'
                },
                subCategory: 'Technology',
                productId: 2596,
                imageUrl: '/products/how-it-works-magazine-cover.jpg?fm=png&auto=format&fit=fill&fill=solid&bg=0FFF&w=180&h=250',
                id: 'ITI1',
                title: 'How It Works',
                sku: 'How It Works',
                category: 'Science and Nature',
                url: '/magazines/how-it-works-magazine'
              }
            ],
            key: 'magazines-for-kids',
            url: '/kids-magazines'
          },
          {
            label: 'News & Current Affairs',
            items: [
              {
                offer: {
                  rrp: '22.80',
                  productId: 325,
                  cpa: '11.50',
                  price: '6.00',
                  name: '6 issues for £6.00',
                  save: 74,
                  numOfIssues: 6,
                  id: 'TWD1',
                  type: 'PRINT',
                  sku: '1364',
                  paymentType: 'DD'
                },
                subCategory: 'Money and Finance',
                productId: 2704,
                imageUrl: '/products/the-week-magazine-cover.jpg?fm=png&auto=format&fit=fill&fill=solid&bg=0FFF&w=180&h=250',
                id: 'TWD1',
                title: 'The Week',
                sku: 'The Week',
                category: 'Business and Finance',
                url: '/magazines/the-week-magazine'
              },
              {
                offer: {
                  rrp: '54.00',
                  productId: 10340,
                  cpa: '12.00',
                  price: '12.00',
                  name: '12 issues for £12.00',
                  save: 78,
                  numOfIssues: 12,
                  id: 'STA1',
                  type: 'PRINT',
                  sku: '8442',
                  paymentType: 'DD'
                },
                subCategory: 'History',
                productId: 10339,
                imageUrl: '/catalog/product/n/e/new-statesman-magazine_3_3.jpg?fm=png&auto=format&fit=fill&fill=solid&bg=0FFF&w=180&h=250',
                id: 'STA1',
                title: 'New Statesman',
                sku: 'New Statesman',
                category: 'News and Current Affairs',
                url: '/magazines/new-statesman-magazine'
              },
              {
                offer: {
                  rrp: '196.00',
                  productId: 216,
                  cpa: '15.00',
                  price: '39.99',
                  name: '56 issues for £39.99',
                  save: 80,
                  numOfIssues: 56,
                  id: 'TIMZ',
                  type: 'PRINT',
                  sku: '1432',
                  paymentType: 'CC'
                },
                subCategory: 'Money and Finance',
                productId: 2877,
                imageUrl: '/products/time-magazine-cover.jpg?fm=png&auto=format&fit=fill&fill=solid&bg=0FFF&w=180&h=250',
                id: 'TIMZ',
                title: 'Time',
                sku: 'Time',
                category: 'News and Current Affairs',
                url: '/magazines/time-magazine'
              },
              {
                offer: {
                  rrp: '17.85',
                  productId: 3791,
                  cpa: '18.00',
                  price: '5.00',
                  name: '3 issues for £5.00',
                  save: 72,
                  numOfIssues: 3,
                  id: 'PMAG',
                  type: 'PRINT',
                  sku: '5287',
                  paymentType: 'DD'
                },
                subCategory: 'Money and Finance',
                productId: 2689,
                imageUrl: '/products/prospect-magazine-cover.jpg?fm=png&auto=format&fit=fill&fill=solid&bg=0FFF&w=180&h=250',
                id: 'PMAG',
                title: 'Prospect',
                sku: 'Prospect',
                category: 'News and Current Affairs',
                url: '/magazines/prospect-magazine'
              },
              {
                offer: {
                  rrp: '45.48',
                  productId: 219,
                  cpa: '12.00',
                  price: '21.99',
                  name: '12 issues for £21.99',
                  save: 52,
                  numOfIssues: 12,
                  id: 'READ',
                  type: 'PRINT',
                  sku: '1679',
                  paymentType: 'DD'
                },
                subCategory: 'House and Home',
                productId: 2821,
                imageUrl: '/products/readers-digest-magazine-cover.jpg?fm=png&auto=format&fit=fill&fill=solid&bg=0FFF&w=180&h=250',
                id: 'READ',
                title: 'Readers Digest',
                sku: 'Readers Digest',
                category: 'Womens Lifestyle',
                url: '/magazines/readers-digest-magazine'
              },
              {
                offer: {
                  rrp: '51.00',
                  productId: 1298,
                  cpa: '9.50',
                  price: '12.00',
                  name: '12 issues for £12.00',
                  save: 76,
                  numOfIssues: 12,
                  id: 'MYWK',
                  type: 'PRINT',
                  sku: '2587',
                  paymentType: 'DD'
                },
                subCategory: 'Money and Finance',
                productId: 2674,
                imageUrl: '/catalog/product/m/o/moneyweek-magazine_4.jpg?fm=png&auto=format&fit=fill&fill=solid&bg=0FFF&w=180&h=250',
                id: 'MYWK',
                title: 'MoneyWeek',
                sku: 'MoneyWeek',
                category: 'Business and Finance',
                url: '/magazines/moneyweek-magazine-2674'
              }
            ],
            key: 'news-and-current-affairs',
            url: '/news-and-current-affairs-magazines'
          }
        ]
      },
      maxLength: 2500,
      showText: false,
      isMobile: this.$ua.isFromIphone() || this.$ua.isFromAndroidMobile(),
      sitewidemobile: this.$store.getters['utility/sitewidemobile'] || { enabled: false },
      timestamp: this.$store.getters['utility/timestamp']
    }
  },

  computed: {
    textToShow() {
      if (!this?.dataUI?.content) {
        return ''
      }

      const text = this.$options.filters.sanitize(this.dataUI.content)
      if (this.showText) {
        return text
      }

      // Check the 3rd pharagraphs position
      const indexO3rdParagraph = text.split('<br />', 3).join('<br />').length
      return text.substring(0, indexO3rdParagraph - 1) + '<span id="dots">...</span><span id="more">' + text.substring(indexO3rdParagraph) + '</span>'
    }
  },

  created() {},

  mounted() {
    // Facebook Event Deduplication
    const eventId = createUniqueFBEventId('PageView')

    // Customer email address
    const email = this.$store.getters['s/email']

    // UAEE tracking PageView
    this.$config.UAEESyntax &&
      this.$gtm &&
      this.dataUI &&
      this.$gtm.push(getGTMPageViewDetailsPayload({ pageUrl: this.$route.path, pageTitle: this.dataUI.title, routeName: this.$route.name, eventId, email }))

    // FB v12.0
    const eventSourceUrl = `${this.$config.baseURL}${this.$route.fullPath}`

    // Facebook Server-side API PageView
    sendSSAPIPageView({ context: this, parameters: { eventId, eventSourceUrl } })
  },
  methods: {
    imgixUrl(url) {
      let originalUrl = url.includes('//') ? url.replace('//', '/') : url
      originalUrl = url.includes('?') ? url.split('?').shift() : url
      return `${originalUrl}${this.imgixBaseQueryString}${this.queryString}`
    },
    imgixSrcset(imageUrl) {
      return `${this.imgixUrl(imageUrl)} 1x, ${this.imgixUrl(imageUrl)}&dpr=2&q=30 2x, ${this.imgixUrl(imageUrl)}&dpr=2&q=30 3x`
    },
    imgixBannerUrl(url) {
      const originalUrl = url.includes('?') ? url.split('?').shift() : url
      return `${originalUrl}${this.imgixBaseQueryString}`
    }
  },

  // To check more options, link https://github.com/nuxt/vue-meta#script-object
  // To check how it is working https://nuxtjs.org/faq/duplicated-meta-tags/
  head() {
    const bannerPreloadLinks = []
    if (this.dataUI.banners && this.dataUI.banners.length > 0) {
      // this.dataUI.banners.forEach(banner => {
      //   bannerPreloadLinks.push({
      //     rel: 'preload',
      //     as: 'image',
      //     href: `${this.imgixBannerUrl(banner.imageUrl)}`,
      //     imagesrcset: `${this.imgixBannerUrl(banner.imageUrl)}&w=984 1200w, ${this.imgixBannerUrl(banner.imageUrl)}&w=749 749w, ${this.imgixBannerUrl(banner.imageUrl)}&w=449 449w`
      //   })
      // })

      const banner = this.dataUI?.banners[0]?.imageUrl?.split('?')[0]

      // console.log('BANNER=', banner)
      bannerPreloadLinks.push({
        rel: 'preload',
        as: 'image',
        href: `${banner}?fm=png&auto=format&w=749`
      })
    }
    return {
      title: this?.dataUI?.title,
      link: [{ rel: 'canonical', href: 'https://www.magazine.co.uk' }, ...bannerPreloadLinks],

      // script: [{ src: 'https://apis.google.com/js/platform.js?onload=renderBadge', async: true, defer: true, body: true }],
      meta: [
        {
          name: 'robots',
          content: this.$config.robots || 'INDEX,FOLLOW'
        },
        {
          hid: 'description',
          name: 'description',
          content:
            this?.dataUI?.metaDescription ||
            'Explore our huge variety of subscriptions at great prices from the UK’s leading magazine subscriptions website. There’s something for every magazine lover!'
        },
        {
          hid: 'og:title',
          name: 'og:title',
          property: 'og:title',
          content: this?.dataUI?.title || 'magazine.co.uk | Magazine Subscriptions at Great Prices'
        },
        {
          hid: 'og:description',
          name: 'og:description',
          property: 'og:description',
          content:
            this?.dataUI?.metaDescription ||
            'Explore our huge variety of subscriptions at great prices from the UK’s leading magazine subscriptions website. There’s something for every magazine lover!'
        },
        {
          hid: 'og:site_name',
          name: 'og:site_name',
          property: 'og:site_name',
          content: 'magazine.co.uk'
        },
        {
          hid: 'og:image',
          name: 'og:image',
          property: 'og:image',
          content: 'https://www.magazine.co.uk/icon.png'
        }
      ]
    }
  }
}
</script>

<style lang="scss">
#more {
  display: none;
}
.content--description {
  a,
  a:hover {
    color: #ff0d00;
  }

  p {
    margin-bottom: 0rem;
  }
  &__showreadmore {
    font-style: italic;
    float: right;
    bottom: 0;
    border: none;
    background: none;
  }
}
.searchbox {
  width: auto !important;
  max-width: 100vw !important;
}
</style>
