<template>
  <div v-if="values" class="ratings">
    <div class="rating__schema"></div>
    <div>
      <div v-for="(review, index) in values" :key="`Review::${index}`" class="rating__reviews">
        <div class="rating__review--schema">
          <client-only>
            <star-rating
              :inline="true"
              :star-size="20"
              :read-only="true"
              :show-rating="false"
              :rating="review.vote_value"
              :increment="0.5"
              class="rating__review-rating"
            ></star-rating>
          </client-only>
        </div>

        <div class="rating__review--head">
          Review by <strong>{{ $options.filters.sanitize(review.nickname) }}</strong>
        </div>
        <!-- eslint-disable-next-line vue/no-v-html -->
        <div class="rating__review--detail" v-html="$options.filters.sanitize(review.details)"></div>
      </div>
    </div>
  </div>
</template>

<script>
import xss from 'xss'
export default {
  name: 'PDPReviews',
  serverCacheKey: props => `PDPReviews::${props.id}`,
  filters: {
    sanitize(value) {
      if (!value) {
        return ''
      }
      return xss(value)
    }
  },
  props: {
    values: {
      type: Array,
      required: false,
      default() {
        return []
      }
    },
    id: {
      type: String,
      required: false,
      default() {
        return 0
      }
    }
  },
  data() {
    return {}
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
.rating {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  &__review-rating {
    margin: 5px 0;
  }
  &__reviews {
    margin-bottom: 20px;
  }
}
</style>
