<template>
  <div class="content-clp content-clp__main">
    <client-only v-if="$store.getters['UI/debug']">
      <ul class="debug__list">
        <li class="debug__item">
          <tree-view :data="query" :options="{ rootObjectKey: 'QueryString', maxDepth: 0, link: true }"> </tree-view>
        </li>
        <li class="debug__item">
          <tree-view :data="$store.getters['category/settings']" :options="{ rootObjectKey: 'Vuex State', maxDepth: 0, link: true }"> </tree-view>
        </li>
        <li class="debug__item">
          <tree-view :data="$options.filters.objectParser(dataUI)" :options="{ rootObjectKey: 'Data UI', maxDepth: 0, link: true }"> </tree-view>
        </li>
      </ul>
    </client-only>

    <div class="clp-head mt-2">
      <h1 v-if="dataUI.h1Title">{{ dataUI.h1Title }}</h1>
    </div>

    <div v-if="dataUI.description" role="tablist" class="my-4 clp-accordion">
      <b-card no-body class="mb-0">
        <b-card-header header-tag="header" class="p-0 clp-accordion--header clp-accordion--header__first" role="tab">
          <b-button v-b-toggle.accordion-1 block href="#" variant="info">
            Description
            <font-awesome-icon :icon="['fal', 'plus']" class="fa right minus" />
            <font-awesome-icon :icon="['fal', 'minus']" class="fa right plus" />
          </b-button>
        </b-card-header>
        <b-collapse id="accordion-1" class="p-0 clp-accordion--description" accordion="clp-accordion" role="tabpanel">
          <!-- eslint-disable-next-line vue/no-v-html -->
          <b-card-body> <div v-html="$options.filters.parseHTML(dataUI.description)" /> </b-card-body>
        </b-collapse>
      </b-card>

      <b-card v-if="dataUI.childrenCategories && dataUI.childrenCategories.length > 0" no-body class="mb-0">
        <b-card-header header-tag="header" class="p-0 clp-accordion--header" role="tab">
          <b-button v-b-toggle.accordion-2 block href="#" variant="info">
            Sub Categories
            <font-awesome-icon :icon="['fal', 'plus']" class="fa right minus" />
            <font-awesome-icon :icon="['fal', 'minus']" class="fa right plus" />
          </b-button>
        </b-card-header>
        <b-collapse id="accordion-2" class="p-0 clp-accordion--categories" accordion="clp-accordion" role="tabpanel">
          <b-card-body v-for="(item, index) in dataUI.childrenCategories" :key="`i${index}-${item.categoryId}`" class="clp-categories__item clp-categories__item--clp">
            <a :href="item.urlPath">
              {{ item.name }}
            </a>
          </b-card-body>
        </b-collapse>
      </b-card>
    </div>

    <!-- eslint-disable-next-line vue/no-v-html -->
    <div class="clp-description" v-html="$options.filters.parseHTML(dataUI.description)"></div>
    <div class="clp-categories clp-categories--clp">
      <div
        v-for="(item, index) in dataUI.childrenCategories"
        :key="`i${index}-${item.categoryId}`"
        class="clp-categories__item clp-categories__item--clp"
        :class="[item.active && item.active === 1 ? 'clp-categories__item--clp-active' : '']"
      >
        <a :href="item.urlPath">
          {{ item.name }}
          <font-awesome-icon :icon="['fal', 'chevron-right']" class="fa fa-stack-1x rightArrow" />
        </a>
      </div>

      <div class="clp-categories__item clp-categories__item--no-border"></div>
      <div class="clp-categories__item clp-categories__item--no-border"></div>
    </div>
    <div class="clp-top">
      <div class="clp-back" @click.stop="clickBackToPKMGPlus()"><font-awesome-icon :icon="['fal', 'chevron-left']" class="fa leftArrow mr-2" />Back to Pocketmags Plus+ offer</div>
      <div class="clp-sort">
        <b-dropdown right variant="link" class="m-md-2" toggle-class="text-decoration-none" no-caret>
          <template #button-content> Sort: {{ sortLabel }}&nbsp;&nbsp;<font-awesome-icon :icon="['fal', 'chevron-down']" class="fa" /> </template>
          <b-dropdown-item :active="sortLabel == 'Popularity'" aria-describedby="Popularity" @click.stop="clickSortCallback({ type: 'position', value: 'asc' })">
            Popularity
          </b-dropdown-item>
          <b-dropdown-item :active="sortLabel == 'Name (A to Z)'" aria-describedby="Name (A to Z)" @click.stop="clickSortCallback({ type: 'name', value: 'asc' })">
            Name (A to Z)
          </b-dropdown-item>
          <b-dropdown-item :active="sortLabel == 'Name (Z to A)'" aria-describedby="Name (Z to A)" @click.stop="clickSortCallback({ type: 'name', value: 'desc' })">
            Name (Z to A)
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </div>
    <LazyHydrate when-visible>
      <CLPProductsList v-if="products && products.length" :category-id="categoryId" :page-count="pageCount" />
    </LazyHydrate>
  </div>
</template>

<script>
import LazyHydrate from 'vue-lazy-hydration'
import { mapState, mapGetters } from 'vuex'
import { azureCategoryProductsSearch, createAzureSearchQuery, getOrderbyAzureSearchOption, getCategoryProductQuery, mapAzuresearchResultsToProduct } from '~/lib/util/azuresearch'
import { sendSSAPIPageView, createUniqueFBEventId } from '~/lib/util/fb'
import { getGTMPageViewDetailsPayload } from '~/lib/util/gtmUAEE'

export default {
  name: 'Category',
  filters: {
    parseHTML(value) {
      if (!value) {
        return ''
      }
      // Example src="{{media url="wysiwyg/jmag-category-autumn_V1.png"}}"
      let sup = value.replace(/\{\{media url="wysiwyg/, process.env.IMAGES_URL + '/wysiwyg')
      sup = sup.replace(/"\}\}/, '?auto=format')

      // remove comments
      sup = sup.replace(/<!--.*?-->/gs, '')

      // remove first <p> level
      sup = sup.replace(/(^<p>|<\/p>$)/gi, '')
      return sup
    },
    objectParser(obj) {
      const sup = { ...obj }
      sup.bottomText = 'NOT AVAILABLE FOR DEBUG'
      return sup
    }
  },
  components: {
    LazyHydrate,
    CLPProductsList: () => import('~/components/pocketmagsplus/PocketmagsPlusProductsList.vue')
  },
  layout: '1Column',
  async fetch() {
    // Look for an available Query String and retrieve the URL path
    const path = this.$route.path
    const query = this.$nuxt.context.query
    const useCache = this.$store.getters['UI/useCache']
    console.log('page=CLPPocketmags')
    console.log('path=', path)
    console.log('query=', query)
    console.log('QS useCache=', useCache)

    // Fix Status Code to 200
    this.dataUI = {
      statusCode: 200,
      categoryId: 9999,
      canonicalURL: 'https://www.magazine.co.uk/pocketmagsplus/magazines',
      h1Title: 'Pocketmags Plus+ Magazines'
    }

    // IMPORTANT Redirections for 404 and 500 statusCode pages has been shifted to the Vuex UI/statusCode because of fetch()
    // Redirect on the Homepage if there is no data coming from the API
    if (this.dataUI.statusCode !== 200) {
      // IMPORTANT: On Server Side we updating the statusCode if the URL or the REDIRECT have not been found
      // This will fix some SEO problems with SSR
      // https://github.com/nuxt/nuxt.js/issues/7285
      // https://nuxtjs.org/announcements/understanding-how-fetch-works-in-nuxt-2-12/#error-handling
      // https://nuxtjs.org/announcements/understanding-how-fetch-works-in-nuxt-2-12/#5-error-handling
      if (process.server) {
        this.$nuxt.context.res.statusCode = this.dataUI.statusCode
      }
      this.$store.commit('UI/statusCode', this.dataUI.statusCode, { root: true })
      return
    }

    // General Page Data
    this.$store.commit('category/payload', this.dataUI)
    // CLP Filters
    const category = { id: this.dataUI.categoryId }
    this.$store.commit('category/setCategoryId', this.dataUI.categoryId)
    this.$store.commit('category/setQuery', createAzureSearchQuery({ query, category }))
    this.$store.commit('category/addToActiveFacets', {
      type: 'category',
      name: this.dataUI.name
    })

    // Breadcrumbs
    const page = {
      value: 'Pocketmags Plus+ Magazines',
      type: path,
      last: true
    }
    this.$store.commit('ADD_BREADCRUMBS', { path, page })

    // Get the orderby search option
    const orderby = query && getOrderbyAzureSearchOption({ query })
    const azureQuery = getCategoryProductQuery(createAzureSearchQuery({ query, category }))

    console.log('azureQuery=', azureQuery)
    console.log('orderby=', orderby)

    await azureCategoryProductsSearch(
      { searchTerm: azureQuery, orderby, page: 1, viewAll: false, noFacets: true, top: this.$nuxt.$config.PocketmagsPlusCLPpagesize },
      (error, data) => {
        if (data) {
          this.$store.commit('category/setProducts', data.value)
          this.$store.commit('category/setCount', data['@odata.count'])

          this.dataUI.products = data.value.map(product => {
            return mapAzuresearchResultsToProduct(product)
          })
          this.dataUI.count = data['@odata.count']
          console.log(`COUNT=${data['@odata.count']}`)
          // console.log(data['@search.facets'])
        } else if (error) {
          console.log(error)
          this.$store.commit('category/setProducts', [])
        }
      }
    )
  },

  fetchOnServer: true,
  // multiple components can return the same `fetchKey` and Nuxt will track them both separately
  // fetchKey: 'clp',
  // Set the minimum executing time in milliseconds (to avoid quick flashes)
  // fetchDelay: 5000,

  data() {
    return {
      isHomePage: false,
      currentPage: parseInt(this.$route.query.page || this.page),
      showFilter: false,
      isFromMobile: this.$ua.isFromIphone() || this.$ua.isFromAndroidMobile(),
      dataUI: [],
      showFreeGiftOverlay: this.$route.path && this.$route.path.includes('free-gifts'),
      isDigitalMagazinesCats: this.$route.path && this.$route.path.includes('digital-magazines'),
      isMagazineSubscriptionsForSchools: this.$route.path && this.$route.path.includes('magazine-subscriptions-for-schools')
    }
  },

  computed: {
    sortLabel() {
      if (this.query.price === false && this.query.name === 'asc') {
        return 'Name (A to Z)'
      }
      if (this.query.price === false && this.query.name === 'desc') {
        return 'Name (Z to A)'
      }
      return 'Popularity'
    },
    pageCount() {
      if (this.query.limit === 'all') {
        return 1
      }
      return Math.ceil(this.count / this.$nuxt.$config.PocketmagsPlusCLPpagesize)
    },
    ...mapState('category', ['categoryId', 'products', 'query', 'count', 'facets', 'activeFacets']),
    ...mapGetters({
      categoryId: 'category/categoryId',
      products: 'category/products',
      productsForFB: 'category/productsForFB',
      query: 'category/query',
      count: 'category/count',
      page: 'category/page',
      redirect: 'category/redirect',
      facets: 'category/facets',
      activeFacets: 'category/activeFacets'
    })
  },

  watchQuery: ['q', 'name', 'page'],

  created() {
    this.$nuxt.$on('SHOW_FILTER', value => {
      if (this.showFilter !== value) {
        this.showFilter = value
      }
      // this.showNotification = false
    })
  },

  beforeDestroy() {
    // $off method will turned off the event listner
    this.$nuxt.$off('SHOW_FILTER')
  },

  mounted() {
    // Facebook Event Deduplication
    const eventId = createUniqueFBEventId('PageView')

    // Customer email address
    const email = this.$store.getters['s/email']

    // UAEE tracking PageView
    this.$config.UAEESyntax &&
      this.$gtm &&
      this.$gtm.push(getGTMPageViewDetailsPayload({ pageUrl: this.$route.path, pageTitle: this.dataUI.metaTitle, routeName: this.$route.name, eventId, email }))

    // Facebook Pixel ViewCategory Tracking
    this.$fb &&
      this.$fb.track('ViewCategory', {
        content_name: this.dataUI.name,
        content_category: this.dataUI.name,
        content_type: 'product_group',
        content_ids: this.productsForFB
      })

    // FB v12.0
    const eventSourceUrl = `${this.$config.baseURL}${this.$route.fullPath}`

    // Facebook Server-side API PageView
    sendSSAPIPageView({ context: this, parameters: { eventId, eventSourceUrl } })
  },
  methods: {
    clickBackToPKMGPlus() {
      this.$router.push({ path: '/magazines/pocketmagsplus' })
    },
    clickSortCallback({ type, value }) {
      console.log(`clickSortCallback type=${type} value=${value}`)

      if (type === 'position') {
        // Default reccomended sortby
        this.$store.commit('category/addQueryFilter', {
          type: 'price',
          value: undefined
        })
        this.$store.commit('category/addQueryFilter', {
          type: 'name',
          value: undefined
        })
      } else if (type === 'price') {
        // Price
        this.$store.commit('category/addQueryFilter', {
          type,
          value
        })
        this.$store.commit('category/addQueryFilter', {
          type: 'name',
          undefined
        })
      } else if (type === 'name') {
        // Name
        this.$store.commit('category/addQueryFilter', {
          type,
          value
        })
        this.$store.commit('category/addQueryFilter', {
          type: 'price',
          undefined
        })
      } else {
        // desc sortby
        this.$store.commit('category/addQueryFilter', {
          type,
          value
        })
      }

      // This will add a history item on the browser
      // if (this.$ua.browser() !== 'Chrome') {
      //   this.$router.push({ path: this.$router.path, query: this.query })
      // } else {
      const qs = JSON.parse(JSON.stringify(this.query))
      const qsEncoded = Object.keys(qs)
        .map(k => `${encodeURIComponent(k)}=${encodeURIComponent(qs[k])}`)
        .join('&')
      window.location.href = `${this.$config.baseURL}${this.$route.path}?${qsEncoded}`
      // }
    }
  },

  // To check more options, link https://github.com/nuxt/vue-meta#script-object
  // To check how it is working https://nuxtjs.org/faq/duplicated-meta-tags/
  head() {
    return {
      title: this?.dataUI?.metaTitle,
      link: [{ rel: 'canonical', href: this?.dataUI?.canonicalURL }],
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this?.dataUI?.metaDescription || ''
        },
        {
          name: 'keywords',
          content: this?.dataUI?.metaKeywords || ''
        },
        {
          name: 'robots',
          content: 'NOINDEX,NOFOLLOW'
        },
        {
          hid: 'og:title',
          name: 'og:title',
          property: 'og:title',
          content: this?.dataUI?.h1Title
        },
        {
          hid: 'og:description',
          name: 'og:description',
          property: 'og:description',
          content: this?.dataUI?.metaDescription || ''
        },
        {
          hid: 'og:site_name',
          name: 'og:site_name',
          property: 'og:site_name',
          content: 'magazine.co.uk'
        },
        {
          hid: 'og:image',
          name: 'og:image',
          property: 'og:image',
          content: 'https://www.magazine.co.uk/icon.png'
        }
      ]
    }
  }
}
</script>

<style lang="scss">
.clp-back {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  &:hover {
    text-decoration: none;
    color: #ff0d00;
  }
}
.leftArrow {
  float: left;
}
.content-clp {
  &__main {
    flex: 0 0 100%;
    max-width: 1230px;
    margin: 0 auto;
    .carousel__placeholder {
      max-width: 818px;
      min-height: 203px;
      img {
        max-width: 818px;
        width: 100%;
      }
    }
    .pagination__viewall {
      max-height: 38px;
    }
  }
}
.pagination-container {
  display: flex;
  justify-content: center;

  paginate {
    list-style-type: none;
    display: flex;
    margin-top: 1.5rem !important;
    li {
      list-style-type: none;
      max-height: 24px;
    }
  }

  .btn {
    border: 1px solid #dee2e6;
    &:hover {
      z-index: 2;
      color: $navLinkHighlightColor;
      text-decoration: none;
      background-color: #e9ecef;
      border-color: #dee2e6;
    }
  }
  .pagination {
    display: flex;
    padding-left: 0;
    list-style: none;
    border-radius: 0.25rem;
    justify-content: center;
  }
}
.clp {
  &-filter {
    .btn-link,
    div {
      &:hover {
        color: $navLinkHighlightColor;
        cursor: pointer;
      }
      color: #333;
      background-color: #fff;
      border-color: #fff;
    }
  }
  &-accordion {
    display: none;
  }
  &-banner {
    display: flex;
    img {
      max-width: 100%;
      // display: block;
      margin: 0 auto;
      width: 100%;
      height: auto;
    }
  }
  &-head {
    h1 {
      font-weight: 700;
      font-size: 22px;
      margin: 0 0 15px;
      color: #ff0d00;
    }
  }
  &-top {
    display: flex;
    justify-content: space-between;
  }
  &-description {
    margin: 0 0 10px;
    line-height: 20px;
    font-size: 14px;
    color: #333;
    p img {
      width: 100%;
    }
  }
  &-no-results {
    font-size: 22px;
    text-align: center;
    margin: 20px;
  }
  &-filter {
    color: #474747;
    text-decoration: none;
  }
  &-top-ten {
    .items-list__item--topx {
      &:hover {
        .actions > * {
          -webkit-transition: all 0s;
          transition: all 0s;
          background-color: $categoryItemHoverBg;
        }
      }
    }
    h2.category-heading {
      background: #333;
      padding: 0 20px;
      position: relative;
      font-size: 1.5rem;
      a {
        font-size: 14px;
        display: inline-block;
        vertical-align: middle;
        color: #fff;
        padding: 7px 0;
        line-height: 20px;
        text-transform: uppercase;
        font-weight: 600;
      }
    }
    .actions {
      margin-top: 10px;
      display: flex;
      justify-content: space-between;
      a {
        -webkit-transition: all 0s;
        transition: all 0s;

        &:hover {
          text-decoration: none;
        }
        margin-top: 0px;
        font-size: 12px;
        color: $buttonAddToCartColor;
        line-height: 12px;
        margin: 0;
        text-decoration: underline;
        border: unset;
        background-color: white;
        min-width: 50px;
        padding: 15px 0;
        text-align: center;
      }
    }
  }
  &-bottom-text {
    border-top: 1px solid #d9d9d9;
    h2 {
      font-size: 21px;
      margin: 0 0 15px;
      font-weight: 400;
      color: #ff0d00;
    }
  }
  &-categories {
    .rightArrow {
      margin: 5px;
      position: relative;
      vertical-align: middle;
    }

    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: center;
    background-color: #ffffff;
    &--clp {
      padding: 10px 0px;
    }

    &__item {
      border-bottom: 1px solid #d9d9d9;
      &--clp {
        a {
          padding-left: 10px;
          vertical-align: middle;
          margin: 0.2em 0 0.2em 0.2em;
          min-width: 33%;
        }
      }

      &--clp-active {
        border-bottom: 2px solid black;
        // background: $sectionBackground;
      }

      margin-left: 1px;
      width: 32%;
      &:hover {
        background: $sectionBackground;
      }
      a {
        display: block;
        font-size: 13px;
        color: $navLinkColor;
        text-decoration: none;

        display: flex;
        justify-content: space-between;
        align-items: center;
        min-height: 35px;
        height: 30px;
      }

      i {
        margin-left: 0.5em;
        margin-right: 0.5em;
      }

      &--no-border {
        border-bottom: none;
        &:hover {
          background: none;
        }
      }
    }
  }
}

.nav-link {
  color: #212529;

  &:hover {
    border-color: $borderColor $borderColor white !important;
    color: #212529 !important;
  }
}
.nav-tabs {
  background: #ededed;
  border-bottom: unset;
}
.nav {
  flex-wrap: nowrap;
}
.tab-content {
  border-radius: 0 0 0.25rem 0.25rem;
  border: 1px solid #dee2e6;
}
.tab-text {
  font-size: 14px;
  p:last-child {
    margin-bottom: 0px;
  }
}
.product {
  &__recentlyViewed {
    .product__offer {
      display: none;
    }
    .actions {
      display: none;
    }
  }
}
.clp-list {
  .items-list__item {
    &:hover {
      a.view-btn,
      button {
        background-color: #ededed !important;
      }
    }
    .actions {
      margin-top: 10px;
      display: flex;
      justify-content: space-between;
      a {
        -webkit-transition: all 0s;
        transition: all 0s;

        &:hover {
          text-decoration: none;
        }
        margin-top: 0px;
        font-size: 12px;
        color: $buttonAddToCartColor;
        line-height: 12px;
        margin: 0px 2px;
        text-decoration: underline;
        border: unset;
        min-width: 50px;
        padding: 15px 0;
        text-align: center;
      }
    }
  }
}

@media (max-width: $screen-md-min) {
  .content__right,
  .content__left {
    display: none;
  }

  .content-clp {
    &__main {
      flex: 0 1 100%;
      max-width: 100%;
    }
  }
}

@media (max-width: $screen-sm-min) {
  .content-clp {
    &__main {
      margin: 0px;
      padding: 0px;
      max-width: 750px;
    }
  }
  .clp-list {
    .items-list__item {
      flex: 0 0 24.5%;
    }
  }
  .items-list__item,
  .items-list__item--topx {
    .actions {
      a {
        text-align: center;
        font-size: 12px;
        font-weight: 700;
        color: $buttonAddToCartColor;
        line-height: 12px;
        margin: 0em;
        text-decoration: underline;
        min-width: 85px;
        padding: 15px 0;
        &:hover {
          text-decoration: none;
        }
        .btn-divider {
          font-weight: 400;
        }
      }
    }
  }
}

@media (max-width: $screen-xs-max) {
  .content-clp {
    &__main {
      margin: unset;
    }
    &--description {
      h1 {
        line-height: 1.2em;
      }
    }
  }
  .content-clp__main {
    max-width: 100%;
    flex: 0 1 100%;
    padding: unset;
  }
  .clp {
    &-description {
      padding: 10px;
    }
  }

  .clp-list {
    .items-list__item {
      text-align: center;
      flex: 0 0 45%;
      .actions {
        &:hover {
          button.add-to-basket-clp {
            background-color: $buttonAddToCartColor !important;
          }
        }

        flex-wrap: wrap;
        justify-content: center;
        a.view-btn {
          color: #333;
          -webkit-transition: all 0.2s;
          transition: all 0.2s;
        }

        button.add-to-basket-clp {
          @include button-add-to-cart;
          margin-top: 10px;
          -webkit-transition: all 0.2s;
          transition: all 0.2s;
        }
      }
    }
  }

  .clp {
    &-description,
    &-categories {
      display: none;
    }
    &-accordion {
      margin-top: 0 !important;
      .right {
        margin: 5px;
        position: relative;
        float: right;
        vertical-align: middle;
      }
      display: block;
      .card {
        border: 0;
        border-radius: 0;
      }
      .card-header {
        border-radius: 0px;
      }
      &--header {
        &__first {
          border-top: 1px solid #ccc;
        }
        .btn-info:not(:disabled):not(.disabled):active {
          color: #666;
        }
        a.btn-info {
          background: white !important;

          text-decoration: none;
          font-size: 16px;
          color: #666;
          text-align: left;
          padding: 12px;
          border-radius: 0px;
          border: 0px solid #666;
        }
        a.btn-info {
          .minus {
            display: none;
          }
          .plus {
            display: inline-block;
          }
        }
        a.btn-info.collapsed {
          .minus {
            display: inline-block;
          }
          .plus {
            display: none;
          }
        }
      }
      &--categories,
      &--description {
        background: #eee;
        p {
          font-size: 16px;
          color: #666;
        }
        .clp-categories__item {
          background: #eee;
          border-bottom: 1px solid #ccc;
          width: 100%;
          padding: 7px;
          a {
            text-decoration: none;
            font-size: 16px;
            color: #666;
          }
        }
      }
    }
    &-banner {
      margin-bottom: 0 !important;
    }
    &-head {
      background: #333;
      position: relative;
      margin-top: 2px;
      padding: 0 10px;
      h1 {
        text-align: center;
        font-size: 16px;
        color: #fff;
        font-weight: 500;
        text-transform: uppercase;
        margin: 0;
        padding: 9px 0;
      }
    }
    &-top {
      display: flex;
      justify-content: space-between;
      margin: 10px 20px 10px 20px;
    }
    &-no-results {
      font-size: 22px;
      text-align: center;
      margin: 20px;
    }
    &-bottom-text {
      padding: 0 10px;
    }
    &-top-ten {
      h2.category-heading {
        background: #333;
        padding: 8px 0;
        position: relative;
        margin-bottom: 20px;
        text-align: center;
        a {
          text-align: center;
          font-size: 18px;
          color: #fff;
          font-family: 'Open Sans', sans-serif;
          text-transform: uppercase;
          margin: 0;
          padding: 8px 0 8px 10px;
        }
      }
      .items-list__item {
        text-align: center;
        flex: 0 0 45%;
        .actions {
          &:hover {
            button.add-to-basket-clp {
              background-color: $buttonAddToCartColor !important;
            }
          }

          flex-wrap: wrap;
          justify-content: center;
          a.view-btn {
            color: #333;
            -webkit-transition: all 0.2s;
            transition: all 0.2s;
          }

          button.add-to-basket-clp {
            @include button-add-to-cart;
            margin-top: 10px;
            -webkit-transition: all 0.2s;
            transition: all 0.2s;
          }
        }
      }
    }
  }
}

@media (max-width: 414px) {
  .content-clp {
    &__main {
      margin: unset;
    }
  }
  .items-list__item {
    .actions {
      div.add-to-basket-btn {
        display: inline-block;
        vertical-align: middle;
        *vertical-align: auto;
        *zoom: 1;
        *display: inline;
        border-radius: 3px;
        border: none;
        background: #00933e;
        font-family: Open Sans, sans-serif;
        font-weight: 600;
        font-size: 12px;
        padding: 10px;
        text-transform: uppercase;
        text-decoration: none;
        text-align: center;
        color: #fff;
        width: 100%;
        padding: 10px !important;
        margin-top: 10px;
        transition: all 0.2s;
        :hover {
          text-decoration: none;
          cursor: pointer;
        }
      }
    }
  }
}
@media (max-width: 360px) {
  .content-clp {
    &__main {
      .pagination-container {
        flex-wrap: wrap;
        .pagination {
          padding-bottom: unset !important;
        }
      }
      .pagination__viewall {
        max-height: 38px;
      }
    }
  }
}
/* ==========================================================================
    Internet Explorer Section
   ========================================================================== */
/*  IE10+ */
@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  .content-clp {
    &__main {
      width: 100%;
      max-width: 960px;
      flex: 1 1 auto;
    }
  }
}
</style>
