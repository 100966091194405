<template>
  <nav aria-label="breadcrumb">
    <client-only>
      <ol class="breadcrumb">
        <li v-if="!breadcrumbs.length" :key="0" :aria-current="false" class="breadcrumb-item">
          <a alt="Magazine subscriptions" href="/" class="breadcrumb__link"> <font-awesome-icon :icon="['fal', 'home']" class="fa fa-stack-1x" /> magazine subscriptions </a>
        </li>
        <li v-for="(bc, index) in breadcrumbs" :key="index" :aria-current="bc.last === true ? 'page' : false" class="breadcrumb-item">
          <a v-if="bc.first && bc.first === true" alt="Magazine subscriptions" href="/" class="breadcrumb__link">
            <font-awesome-icon :icon="['fal', 'home']" class="fa fa-stack-1x" /> magazine subscriptions
          </a>
          <a v-else-if="bc.last === false" :alt="bc.value" :href="bc.url" class="breadcrumb__link">{{ bc.value }}</a>
          <a v-else :alt="bc.value" :href="bc.url" class="breadcrumb__link">
            <strong>{{ bc.value }}</strong>
          </a>
        </li>
      </ol>
    </client-only>
  </nav>
</template>

<script>
export default {
  name: 'Breadcrumbs',
  serverCacheKey: props => {
    return `Breadcrumbs::${props.path}`
  },
  props: {
    path: {
      type: String,
      required: true,
      default() {
        return '/'
      }
    }
  },
  data() {
    return {
      breadcrumbs: this.$store.getters['UI/breadcrumbs']
    }
  }
}
</script>

<style lang="scss" scoped>
.breadcrumb {
  a {
    cursor: pointer;
  }
  font-size: 13px;
  background: white;
  padding: 0;
  margin: 0;
  min-height: 20px;
  .breadcrumb-item {
    text-transform: capitalize;
  }
  .fa {
    position: relative;
  }
}
@media (max-width: $screen-xs-max) {
  .breadcrumb {
    display: none;
  }
}
</style>
