<script>
import LazyHydrate from 'vue-lazy-hydration'
import { mapState, mapGetters } from 'vuex'
import {
  azureCategoryProductsSearch,
  createAzureSearchQuery,
  getOrderbyAzureSearchOption,
  getCategoryProductQuery,
  getCategoryProductFilter,
  createAzureSearchIntervals,
  mapAzuresearchResultsToProduct
} from '~/lib/util/azuresearch'
import { parseCLPPathURL, activeBannersImages } from '~/lib/util/page'
import { getBreadcrumbsMicrodataJSONLD } from '~/lib/util/microdata'

import { sendSSAPIPageView, createUniqueFBEventId } from '~/lib/util/fb'
import { getGTMPageViewDetailsPayload } from '~/lib/util/gtmUAEE'
import { insertPocketmagsPlusInCLPProductList } from '~/lib/util/pocketmagsPlus'
import { getStatusCodeFromPath, callAWSFrontendAPI } from '~/lib/util/aws'

export default {
  name: 'Category',
  filters: {
    parseHTML(value) {
      if (!value) {
        return ''
      }
      // console.log('parseHTML original=', value)
      // Example src="{{media url="wysiwyg/jmag-category-autumn_V1.png"}}"
      let sup = value.replace(/\{\{media url="wysiwyg/, process.env.IMAGES_URL + '/wysiwyg')
      sup = sup.replace(/"\}\}/, '?auto=format')

      // remove comments
      sup = sup.replace(/<!--.*?-->/gs, '')
      sup = sup.replace(/<div/gs, '<span')
      sup = sup.replace(/div>/gs, 'span>')

      // remove first <p> tag level
      sup = sup.replace(/(^<p>|<\/p>$)/gi, '')

      // remove all other <p> tags
      sup = sup.replace(/(<p>|<\/p>)/gi, '<br/>')
      // console.log('parseHTML final=', sup)
      return sup
    },
    objectParser(obj) {
      const sup = { ...obj }
      sup.bottomText = 'NOT AVAILABLE FOR DEBUG'
      return sup
    }
  },
  components: {
    LazyHydrate,
    Carousel3Images: () => import('~/components/common/Carousel3Images.vue'),
    Carousel2Images: () => import('~/components/common/Carousel2Images.vue'),
    CLPFilter: () => import('~/components/CLP/CLPFilter.vue'),
    CLPProductsList: () => import('~/components/CLP/CLPProductsList.vue'),
    CLPTopxList: () => import('~/components/CLP/CLPTopxList.vue'),
    PromotionsSitewideMobile: () => import('~/components/common/promotions/PromotionsSitewideMobile.vue')
  },
  layout: 'default',
  async fetch() {
    const status = getStatusCodeFromPath({ context: this })
    if (status !== 200) {
      if (process.server) {
        this.$nuxt.context.res.statusCode = status
      }
      this.$store.commit('UI/statusCode', status, { root: true })
      return
    }

    // Look for an available Query String and retrieve the URL path
    const path = this.$route.path
    const query = this.$nuxt.context.query
    const useCache = this.$store.getters['UI/useCache']
    console.log('page=CLP')
    console.log('path=', path)
    console.log('query=', query)
    console.log('QS useCache=', useCache)
    console.log('AWSAPIGatewayURL=', this.$nuxt.$config.AWSAPIGatewayURL)

    const pageURL = parseCLPPathURL(path)
    console.log(`fetch CATEGORY path=${path} -> category=${pageURL}`)

    this.dataUI = {
      ...(await callAWSFrontendAPI({ context: this, path: pageURL, query, useCache, type: 'CATEGORYPAGE' }))
    }

    if (this.dataUI && this.dataUI.banners) {
      // Fill the Banners data with the up to date banner's images
      const activeBanners = activeBannersImages({ banners: this.dataUI.banners, date: this.$route.query.date || undefined, addExtraImage: false })
      this.dataUI.banners = [...activeBanners]
    }

    // this.dataUI.banners = [
    //   {
    //     imageUrl: 'https://magazine.imgix.net/easybanner/JMAG_Spotlight_Spring_2.png?fm=png&auto=format&w=950',
    //     link: '/discounted-magazine-sale-offers',
    //     alt: 'SPOTLIGHT - Spring 2024',
    //     activeTo: '2025-03-14 06:33:23',
    //     id: 844,
    //     key: 0,
    //     activeFrom: '2023-03-14 06:33:23'
    //   }
    // {
    //   imageUrl: 'https://magazine.imgix.net/easybanner/JMAG_Spotlight_TheWeek_Mar24.png?fm=png&auto=format&w=950',
    //   link: '/magazines/the-week-magazine',
    //   alt: 'SPOTLIGHT - The Week - 6 free issues',
    //   activeTo: '2025-03-14 06:33:23',
    //   id: 597,
    //   key: 1,
    //   activeFrom: '2023-03-14 06:33:23'
    // }
    // {
    //   imageUrl: 'https://magazine.imgix.net/easybanner/JMAG_Spotlight_Instant_Access_Mar24.png?fm=png&auto=format&w=950',
    //   link: '/magazines-with-free-digital-access',
    //   alt: 'SPOTLIGHT - Digital Access',
    //   activeTo: '2025-03-14 06:33:23',
    //   id: 648,
    //   key: 2,
    //   activeFrom: '2023-03-14 06:33:23'
    // }
    // ]

    if (this.dataUI.statusCode !== 200) {
      return
    }
    // General Page Data
    this.$store.commit('category/payload', this.dataUI)

    // CLP Filters
    const category = { id: this.dataUI.categoryId }
    this.$store.commit('category/setCategoryId', this.dataUI.categoryId)
    this.$store.commit('category/setQuery', createAzureSearchQuery({ query, category }))
    this.$store.commit('category/addToActiveFacets', {
      type: 'category',
      name: this.dataUI.name
    })

    // Breadcrumbs
    const magazine = undefined
    const breadcrumbs = this.dataUI.breadcrumbs
    console.log('BREADCRUMBS=', breadcrumbs)
    this.$store.commit('ADD_BREADCRUMBS', { path, magazine, breadcrumbs })

    const pageCount = query.page || 1

    // Get the orderby search option
    const orderby = query && getOrderbyAzureSearchOption({ query })
    const azureQuery = getCategoryProductQuery(createAzureSearchQuery({ query, category }))
    const categoryFilter = {
      priceFrom: this.dataUI.priceFrom,
      priceTo: this.dataUI.priceTo,
      percentageFrom: this.dataUI.percentageFrom,
      percentageTo: this.dataUI.percentageTo,
      urlPath: this.dataUI.urlPath
    }
    const azureFilter = getCategoryProductFilter({
      query: createAzureSearchQuery({ query, category }),
      categoryFilter
    })

    // View All -> limit=all
    let viewAll = false
    if (query && query.limit === 'all') {
      viewAll = true
    }

    await azureCategoryProductsSearch(
      {
        searchTerm: azureQuery,
        orderby,
        page: pageCount,
        filter: azureFilter,
        ...createAzureSearchIntervals(categoryFilter),
        viewAll,
        isPocketmagsplus: this.dataUI.pocketmagsplusEnabled
      },
      (error, data) => {
        if (data) {
          // Pocketmags Plus integration on CLP
          const newData = insertPocketmagsPlusInCLPProductList({
            data: data.value,
            position: parseInt(this.dataUI.pocketmagsplusPosition),
            enabled: this.dataUI.pocketmagsplusEnabled,
            page: pageCount
          })

          this.$store.commit('category/setProducts', newData)
          this.$store.commit('category/setCount', data['@odata.count'])
          this.$store.commit('category/setFacets', {
            offerPrice: data['@search.facets'].offerPrice,
            offerProductType: data['@search.facets'].offerProductType,
            hasFreeGift: data['@search.facets'].hasFreeGift,
            offerSave: data['@search.facets'].offerSave,
            delivery: data['@search.facets'].delivery
          })

          this.dataUI.products = data.value.map(product => {
            return mapAzuresearchResultsToProduct(product)
          })
          this.dataUI.count = data['@odata.count']
          this.dataUI.facets = {
            offerPrice: data['@search.facets'].offerPrice,
            offerProductType: data['@search.facets'].offerProductType,
            hasFreeGift: data['@search.facets'].hasFreeGift,
            offerSave: data['@search.facets'].offerSave,
            delivery: data['@search.facets'].delivery
          }
          console.log(`COUNT=${data['@odata.count']}`)
          // console.log(data['@search.facets'])
        } else if (error) {
          console.log(error)
          this.$store.commit('category/setProducts', [])
        }
      }
    )
  },

  fetchOnServer: true,
  // multiple components can return the same `fetchKey` and Nuxt will track them both separately
  // fetchKey: 'clp',
  // Set the minimum executing time in milliseconds (to avoid quick flashes)
  // fetchDelay: 5000,

  data() {
    return {
      isHomePage: false,
      currentPage: parseInt(this.$route.query.page || this.page),
      showFilter: false,
      isFromMobile: this.$ua.isFromIphone() || this.$ua.isFromAndroidMobile(),
      dataUI: [],
      showFreeGiftOverlay: this.$route.path && this.$route.path.includes('free-gifts'),
      isDigitalMagazinesCats: this.$route.path && this.$route.path.includes('digital-magazines'),
      isMagazineSubscriptionsForSchools: this.$route.path && this.$route.path.includes('magazine-subscriptions-for-schools'),
      imgixBaseQueryString: '?auto=format',
      sitewidemobile: this.$store.getters['utility/sitewidemobile'] || { enabled: false },
      timestamp: this.$store.getters['utility/timestamp']
    }
  },

  computed: {
    sortLabel() {
      if (this.query.price === false && this.query.name === 'asc') {
        return 'Name (A to Z)'
      }
      if (this.query.price === false && this.query.name === 'desc') {
        return 'Name (Z to A)'
      }
      if (this.query.name === false && this.query.price === 'asc') {
        return 'Price (Low to High)'
      }
      if (this.query.name === false && this.query.price === 'desc') {
        return 'Price (High to Low)'
      }
      return 'Popularity'
    },
    pageCount() {
      if (this.query.limit === 'all') {
        return 1
      }
      return Math.ceil(this.count / process.env.AZURE_CATEGORYPRODUCTS_PAGESIZE)
    },
    ...mapState('category', ['categoryId', 'products', 'query', 'count', 'facets', 'activeFacets']),
    ...mapGetters({
      categoryId: 'category/categoryId',
      products: 'category/products',
      productsForFB: 'category/productsForFB',
      query: 'category/query',
      count: 'category/count',
      page: 'category/page',
      redirect: 'category/redirect',
      facets: 'category/facets',
      activeFacets: 'category/activeFacets'
    })
  },

  watchQuery: ['q', 'price', 'name', 'page', 'ofg', 'op', 'opt', 'os', 'delivery'],

  created() {
    this.$nuxt.$on('SHOW_FILTER', value => {
      if (this.showFilter !== value) {
        this.showFilter = value
      }
      // this.showNotification = false
    })
  },

  beforeDestroy() {
    // $off method will turned off the event listner
    this.$nuxt.$off('SHOW_FILTER')
  },

  mounted() {
    // Facebook Event Deduplication
    const eventId = createUniqueFBEventId('PageView')

    // Customer email address
    const email = this.$store.getters['s/email']

    // UAEE tracking PageView
    this.$config.UAEESyntax &&
      this.$gtm &&
      this.$gtm.push(getGTMPageViewDetailsPayload({ pageUrl: this.$route.path, pageTitle: this.dataUI.metaTitle, routeName: this.$route.name, eventId, email }))

    // Facebook Pixel ViewCategory Tracking
    this.$fb &&
      this.$fb.track('ViewCategory', {
        content_name: this.dataUI.name,
        content_category: this.dataUI.name,
        content_type: 'product_group',
        content_ids: this.productsForFB
      })

    // FB v12.0
    const eventSourceUrl = `${this.$config.baseURL}${this.$route.fullPath}`

    // Facebook Server-side API PageView
    sendSSAPIPageView({ context: this, parameters: { eventId, eventSourceUrl } })
  },
  methods: {
    clickSortCallback({ type, value }) {
      console.log(`clickSortCallback type=${type} value=${value}`)

      if (type === 'position') {
        // Default reccomended sortby
        this.$store.commit('category/addQueryFilter', {
          type: 'price',
          value: undefined
        })
        this.$store.commit('category/addQueryFilter', {
          type: 'name',
          value: undefined
        })
      } else if (type === 'price') {
        // Price
        this.$store.commit('category/addQueryFilter', {
          type,
          value
        })
        this.$store.commit('category/addQueryFilter', {
          type: 'name',
          undefined
        })
      } else if (type === 'name') {
        // Name
        this.$store.commit('category/addQueryFilter', {
          type,
          value
        })
        this.$store.commit('category/addQueryFilter', {
          type: 'price',
          undefined
        })
      } else {
        // desc sortby
        this.$store.commit('category/addQueryFilter', {
          type,
          value
        })
      }

      // This will add a history item on the browser
      // if (this.$ua.browser() !== 'Chrome') {
      //   this.$router.push({ path: this.$router.path, query: this.query })
      // } else {
      const qs = JSON.parse(JSON.stringify(this.query))
      const qsEncoded = Object.keys(qs)
        .map(k => `${encodeURIComponent(k)}=${encodeURIComponent(qs[k])}`)
        .join('&')
      window.location.href = `${this.$config.baseURL}${this.$route.path}?${qsEncoded}`
      // }
    },
    clickPageCallback(pageNum) {
      console.log('clickPaginationCallback')
      this.$store.commit('category/setPage', pageNum)
      console.log(this.query)

      // This will add a history item on the browser
      // if (this.$ua.browser() !== 'Chrome') {
      //   this.$router.push({ path: this.$router.path, query: this.query })
      // } else {
      const qs = JSON.parse(JSON.stringify(this.query))
      const qsEncoded = Object.keys(qs)
        .map(k => `${encodeURIComponent(k)}=${encodeURIComponent(qs[k])}`)
        .join('&')
      window.location.href = `${this.$config.baseURL}${this.$route.path}?${qsEncoded}`
      // }
    },

    handleFilterClick() {
      console.log('handleFilterClick')
      this.showFilter = true
      // Trigger GTM event
      // this.$gtm && this.$gtm.push({ event: 'lightboxcheckout' })
    },

    imgixBannerUrl(url) {
      const originalUrl = url.includes('?') ? url.split('?').shift() : url
      return `${originalUrl}${this.imgixBaseQueryString}`
    }
  },

  // To check more options, link https://github.com/nuxt/vue-meta#script-object
  // To check how it is working https://nuxtjs.org/faq/duplicated-meta-tags/
  head() {
    const bannerPreloadLinks = []
    if (this.dataUI.banners && this.dataUI.banners.length > 0) {
      bannerPreloadLinks.push({
        rel: 'preload',
        as: 'image',
        href: `${this.imgixBannerUrl(this.dataUI.banners[0].imageUrl)}&blur=50&px=16`
      })

      this.dataUI.banners.forEach(banner => {
        bannerPreloadLinks.push({
          rel: 'preload',
          as: 'image',
          href: `${this.imgixBannerUrl(banner.imageUrl)}`,
          imagesrcset: `${this.imgixBannerUrl(banner.imageUrl)}&w=984 1200w, ${this.imgixBannerUrl(banner.imageUrl)}&w=749 749w, ${this.imgixBannerUrl(banner.imageUrl)}&w=449 449w`
        })
      })
    }
    return {
      title: this?.dataUI?.metaTitle,
      link: [{ rel: 'canonical', href: this?.dataUI?.canonicalURL }, ...bannerPreloadLinks],
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this?.dataUI?.metaDescription
        },
        {
          name: 'keywords',
          content: this?.dataUI?.metaKeywords || ''
        },
        {
          name: 'robots',
          content: this.$config.robots || 'INDEX,FOLLOW'
        },
        {
          hid: 'og:title',
          name: 'og:title',
          property: 'og:title',
          content: this?.dataUI?.h1Title
        },
        {
          hid: 'og:description',
          name: 'og:description',
          property: 'og:description',
          content: this?.dataUI?.metaDescription
        },
        {
          hid: 'og:site_name',
          name: 'og:site_name',
          property: 'og:site_name',
          content: 'magazine.co.uk'
        },
        {
          hid: 'og:image',
          name: 'og:image',
          property: 'og:image',
          content: 'https://www.magazine.co.uk/icon.png'
        }
      ]
    }
  },
  jsonld() {
    return getBreadcrumbsMicrodataJSONLD({ breadcrumbs: this?.dataUI?.breadcrumbs })
  }
}
</script>

<style lang="scss">
.content-clp {
  &__main {
    flex: 1 1 65%;
    margin: 0 10px;
    padding: 10px;
    width: 100%;
    .carousel__placeholder {
      max-width: 818px;
      min-height: 203px;
      img {
        max-width: 818px;
        width: 100%;
      }
    }
    .pagination__viewall {
      max-height: 38px;
    }
  }
}
.pagination-container {
  display: flex;
  justify-content: center;

  paginate {
    list-style-type: none;
    display: flex;
    margin-top: 1.5rem !important;
    li {
      list-style-type: none;
      max-height: 24px;
    }
  }

  .btn {
    border: 1px solid #dee2e6;
    &:hover {
      z-index: 2;
      color: $navLinkHighlightColor;
      text-decoration: none;
      background-color: #e9ecef;
      border-color: #dee2e6;
    }
  }
  .pagination {
    display: flex;
    padding-left: 0;
    list-style: none;
    border-radius: 0.25rem;
    justify-content: center;
  }
}
.clp {
  &-filter {
    .btn-link,
    div {
      &:hover {
        color: $navLinkHighlightColor;
        cursor: pointer;
      }
      color: #333;
      background-color: #fff;
      border-color: #fff;
    }
  }
  &-accordion {
    display: none;
  }
  &-banner {
    display: flex;
    img {
      max-width: 100%;
      // display: block;
      margin: 0 auto;
      width: 100%;
      height: auto;
    }
  }
  &-head {
    h1 {
      font-weight: 700;
      font-size: 22px;
      margin: 0 0 15px;
      color: #ff0d00;
    }
  }
  &-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-items: center;
  }
  &-description {
    margin: 0 0 10px;
    line-height: 20px;
    font-size: 14px;
    color: #333;
    p img {
      width: 100%;
    }
  }
  &-no-results {
    font-size: 22px;
    text-align: center;
    margin: 20px;
  }
  &-filter {
    color: #474747;
    text-decoration: none;
  }
  &-top-ten {
    .items-list__item--topx {
      &:hover {
        .actions > * {
          -webkit-transition: all 0s;
          transition: all 0s;
          background-color: $categoryItemHoverBg;
        }
      }
    }
    h2.category-heading {
      background: #333;
      padding: 0 20px;
      position: relative;
      font-size: 1.5rem;
      a {
        font-size: 14px;
        display: inline-block;
        vertical-align: middle;
        color: #fff;
        padding: 7px 0;
        line-height: 20px;
        text-transform: uppercase;
        font-weight: 600;
      }
    }
    .actions {
      margin-top: 10px;
      display: flex;
      justify-content: space-between;
      a {
        -webkit-transition: all 0s;
        transition: all 0s;

        &:hover {
          text-decoration: none;
        }
        margin-top: 0px;
        font-size: 12px;
        color: $buttonAddToCartColor;
        line-height: 12px;
        margin: 0;
        text-decoration: underline;
        border: unset;
        background-color: white;
        min-width: 50px;
        padding: 15px 0;
        text-align: center;
      }
    }
  }
  &-bottom-text {
    border-top: 1px solid #d9d9d9;
    h2 {
      font-size: 21px;
      margin: 0 0 15px;
      font-weight: 400;
      color: #ff0d00;
    }
  }
  &-categories {
    .rightArrow {
      margin: 5px;
      position: relative;
      vertical-align: middle;
    }

    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: center;
    background-color: #ffffff;
    &--clp {
      padding: 10px 0px;
    }

    &__item {
      border-bottom: 1px solid #d9d9d9;
      &--clp {
        a {
          padding-left: 10px;
          vertical-align: middle;
          margin: 0.2em 0 0.2em 0.2em;
          min-width: 33%;
        }
      }

      &--clp-active {
        border-bottom: 2px solid black;
        // background: $sectionBackground;
      }

      margin-left: 1px;
      width: 32%;
      &:hover {
        background: $sectionBackground;
      }
      a {
        display: block;
        font-size: 13px;
        color: $navLinkColor;
        text-decoration: none;

        display: flex;
        justify-content: space-between;
        align-items: center;
        min-height: 35px;
        height: 30px;
      }

      i {
        margin-left: 0.5em;
        margin-right: 0.5em;
      }

      &--no-border {
        border-bottom: none;
        &:hover {
          background: none;
        }
      }
    }
  }
}

.nav-link {
  color: #212529;

  &:hover {
    border-color: $borderColor $borderColor white !important;
    color: #212529 !important;
  }
}
.nav-tabs {
  background: #ededed;
  border-bottom: unset;
}
.nav {
  flex-wrap: nowrap;
}
.tab-content {
  border-radius: 0 0 0.25rem 0.25rem;
  border: 1px solid #dee2e6;
}
.tab-text {
  font-size: 14px;
  p:last-child {
    margin-bottom: 0px;
  }
}
.clp-list {
  .items-list__item {
    &:hover {
      a.view-btn,
      button {
        background-color: #ededed !important;
      }
    }
    .actions {
      margin-top: 10px;
      display: flex;
      justify-content: space-between;
      a {
        -webkit-transition: all 0s;
        transition: all 0s;

        &:hover {
          text-decoration: none;
        }
        margin-top: 0px;
        font-size: 12px;
        color: $buttonAddToCartColor;
        line-height: 12px;
        margin: 0px 2px;
        text-decoration: underline;
        border: unset;
        min-width: 50px;
        padding: 15px 0;
        text-align: center;
      }
    }
  }
}

@media (max-width: $screen-md-min) {
  .content-clp {
    &__main {
      flex: 0 1 100%;
      max-width: 100%;
      .carousel__placeholder {
        width: 98%;
      }
    }
  }
}

@media (max-width: $screen-sm-min) {
  .content-clp {
    &__main {
      margin: 0px;
      padding: 0px;
      max-width: 750px;
    }
    .carousel__placeholder {
      width: 100%;
      min-height: 203px;
      // max-width: 730px;
    }
  }
  .clp-list {
    .items-list__item {
      flex: 0 0 24.5%;
    }
  }
  .items-list__item,
  .items-list__item--topx {
    .actions {
      a {
        text-align: center;
        font-size: 12px;
        font-weight: 700;
        color: $buttonAddToCartColor;
        line-height: 12px;
        margin: 0em;
        text-decoration: underline;
        min-width: 85px;
        padding: 15px 0;
        &:hover {
          text-decoration: none;
        }
        .btn-divider {
          font-weight: 400;
        }
      }
    }
  }
}

@media (max-width: $screen-xs-max) {
  .content-clp {
    &__main {
      margin: unset;
      .carousel__placeholder {
        width: 100%;
        max-height: 104px;
        min-height: 104px;
      }
    }
    &--description {
      h1 {
        line-height: 1.2em;
      }
    }
  }
  .content-clp__main {
    max-width: 100%;
    flex: 0 1 100%;
    padding: unset;
  }
  .clp {
    &-description {
      padding: 10px;
    }
  }

  .clp-list {
    .items-list__item {
      text-align: center;
      flex: 0 0 45%;
      .actions {
        &:hover {
          button.add-to-basket-clp {
            background-color: $buttonAddToCartColor !important;
          }
        }

        flex-wrap: wrap;
        justify-content: center;
        a.view-btn {
          color: #333;
          -webkit-transition: all 0.2s;
          transition: all 0.2s;
        }

        button.add-to-basket-clp {
          @include button-add-to-cart;
          margin-top: 10px;
          -webkit-transition: all 0.2s;
          transition: all 0.2s;
        }
      }
    }
  }

  .clp {
    &-description,
    &-categories {
      display: none;
    }
    &-accordion {
      margin-top: 0 !important;
      .right {
        margin: 5px;
        position: relative;
        float: right;
        vertical-align: middle;
      }
      display: block;
      .card {
        border: 0;
        border-radius: 0;
      }
      .card-header {
        border-radius: 0px;
      }
      &--header {
        &__first {
          border-top: 1px solid #ccc;
        }
        .btn-info:not(:disabled):not(.disabled):active {
          color: #666;
        }
        a.btn-info {
          background: white !important;

          text-decoration: none;
          font-size: 16px;
          color: #666;
          text-align: left;
          padding: 12px;
          border-radius: 0px;
          border: 0px solid #666;
        }
        a.btn-info {
          .minus {
            display: none;
          }
          .plus {
            display: inline-block;
          }
        }
        a.btn-info.collapsed {
          .minus {
            display: inline-block;
          }
          .plus {
            display: none;
          }
        }
      }
      &--categories,
      &--description {
        background: #eee;
        p {
          font-size: 16px;
          color: #666;
        }
        .clp-categories__item {
          background: #eee;
          border-bottom: 1px solid #ccc;
          width: 100%;
          padding: 7px;
          a {
            text-decoration: none;
            font-size: 16px;
            color: #666;
          }
        }
      }
    }
    &-banner {
      margin-bottom: 0 !important;
    }
    &-head {
      background: #333;
      position: relative;
      margin-top: 2px;
      padding: 0 10px;
      h1 {
        text-align: center;
        font-size: 16px;
        color: #fff;
        font-weight: 500;
        // text-transform: uppercase;
        margin: 0;
        padding: 9px 0;
      }
    }
    &-top {
      display: flex;
      justify-content: space-between;
      margin: 10px 20px 10px 20px;
    }
    &-no-results {
      font-size: 22px;
      text-align: center;
      margin: 20px;
    }
    &-bottom-text {
      padding: 0 10px;
    }
    &-top-ten {
      h2.category-heading {
        background: #333;
        padding: 8px 0;
        position: relative;
        margin-bottom: 20px;
        text-align: center;
        a {
          text-align: center;
          font-size: 18px;
          color: #fff;
          font-family: 'Open Sans', sans-serif;
          text-transform: uppercase;
          margin: 0;
          padding: 8px 0 8px 10px;
        }
      }
      .items-list__item {
        text-align: center;
        flex: 0 0 45%;
        .actions {
          &:hover {
            button.add-to-basket-clp {
              background-color: $buttonAddToCartColor !important;
            }
          }

          flex-wrap: wrap;
          justify-content: center;
          a.view-btn {
            color: #333;
            -webkit-transition: all 0.2s;
            transition: all 0.2s;
          }

          button.add-to-basket-clp {
            @include button-add-to-cart;
            margin-top: 10px;
            -webkit-transition: all 0.2s;
            transition: all 0.2s;
          }
        }
      }
    }
  }
}

@media (max-width: 414px) {
  .content-clp {
    &__main {
      margin: unset;
      .carousel__placeholder {
        width: 100%;
        margin: unset;
        min-height: 104px;
      }
    }
  }
  .items-list__item {
    .actions {
      div.add-to-basket-btn,
      a.view-btn--nooffer {
        display: inline-block;
        vertical-align: middle;
        *vertical-align: auto;
        *zoom: 1;
        *display: inline;
        border-radius: 3px;
        border: none;
        background: #00933e;
        font-family: Open Sans, sans-serif;
        font-weight: 600;
        font-size: 12px;
        padding: 10px;
        text-transform: uppercase;
        text-decoration: none;
        text-align: center;
        color: #fff;
        width: 100%;
        padding: 10px !important;
        margin-top: 10px;
        transition: all 0.2s;
        :hover {
          text-decoration: none;
          cursor: pointer;
        }
      }
    }
  }
}
@media (max-width: 360px) {
  .content-clp {
    &__main {
      .pagination-container {
        flex-wrap: wrap;
        .pagination {
          padding-bottom: unset !important;
        }
      }
      .pagination__viewall {
        max-height: 38px;
      }
    }
  }
}
/* ==========================================================================
    Internet Explorer Section
   ========================================================================== */
/*  IE10+ */
@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  .content-clp {
    &__main {
      width: 100%;
      max-width: 960px;
      flex: 1 1 auto;
    }
  }
}

/* ----------- iPad and iPad Mini  ----------- */
/* Portrait */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .content__right {
    display: none;
    content-visibility: hidden;
  }
}
/* Landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  .content__right {
    display: block;
    content-visibility: visible;
  }
}
</style>
