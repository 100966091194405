// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/images/sidebar_home_security.jpg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".dropdown-toggle:after{content:normal}ul{list-style:none;margin:0;padding:0}.sidebar-security{background:#ededed;display:grid;max-width:175px;padding:10px;width:auto}.sidebar-security h2{color:#333;font-family:\"Open Sans\",sans-serif;font-style:italic;font-weight:700}.sidebar-security ul{list-style:none}.sidebar-security ul li{float:left;margin-right:10px}.sidebar-security ul li a{display:inline-block;vertical-align:middle;*vertical-align:auto;*zoom:1;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;color:#333;*display:inline;font-family:\"Open Sans\",sans-serif;font-size:14px;min-height:54px;padding:10px 0 0 54px;-webkit-text-decoration:none;text-decoration:none}.sidebar-security ul li a.security-offers{background-position:0 -54px}.sidebar-security ul li a.security-delivery{background-position:0 -108px}.sidebar-security ul li a.security-gift{background-position:0 -164px}.sidebar-security ul li a:hover{-webkit-text-decoration:underline;text-decoration:underline}.customer-feedback{margin-bottom:20px}.customer-feedback img{height:100%;width:100%}.block{margin-bottom:20px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
