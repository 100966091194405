<template>
  <div>
    <div id="giftcard" class="gc-container">
      <client-only v-if="$store.getters['UI/debug']">
        <ul class="debug__list">
          <li v-if="params" class="debug__item">
            <tree-view :data="params" :options="{ rootObjectKey: 'Params', maxDepth: 0, link: true }"> </tree-view>
          </li>
          <li class="debug__item">
            <tree-view :data="selection" :options="{ rootObjectKey: 'Selection', maxDepth: 0, link: true }"> </tree-view>
          </li>
          <!-- <li class="debug__item">
          <tree-view :data="$v.selection" :options="{ rootObjectKey: '$v', maxDepth: 0, link: true }"> </tree-view>
        </li> -->
          <!-- <li class="debug__item">
          <tree-view :data="dataUI" :options="{ rootObjectKey: 'Data UI', maxDepth: 0, link: true }"> </tree-view>
        </li> -->
        </ul>
      </client-only>

      <div id="gc-detail-page">
        <div id="gc-detail-page-left-column" class="m-section">
          <div id="gc-main-image-container">
            <div id="gc-main-image-block">
              <div id="gc-main-preview" class="m-section">
                <div class="m-section gc-live-preview-outer-wrapper">
                  <div class="m-section gc-live-preview-parent">
                    <div class="mobile magazine__sub-heading-bar"><h2>Create your eCard</h2></div>
                    <div class="m-section gc-live-preview-content" @click="togglePreview = !togglePreview">
                      <ECardTemplate
                        :image="selection.design && createImgixURL(selection.design.image_path, designPreviewQueryString)"
                        :message="selection.message == '' ? undefined : selection.message"
                        :magazine-cover="selection.magazineCover"
                        :magazine-name="selection.magazineName"
                        :from-name="selection.fromName"
                        :from-email="selection.fromEmail"
                        :to-name="selection.toName"
                        :to-email="selection.toEmail"
                        :to-date="selection.toDate"
                        :show-preview-button="true"
                      />
                      <div v-if="!isFromMobile" class="m-section m-spacing-top-medium m-text-center">
                        <span class="m-color-3"> Click image to preview </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="gc-detail-page-right-column" class="m-section">
          <client-only>
            <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true"></loading>
          </client-only>
          <h1 class="gc-detail-page__title">Create your eCard</h1>
          <!-- <div class="gc-detail-page__description m-section m-color-1">
          <p>
            Give the gift of choice with a magazine.co.uk E-Gift Card! With hundreds of magazines to choose from, there’s something for everyone, so your recipient can pick the
            perfect subscription for them.
          </p>
          <p>
            Simply select the value of the gift card, pick a design, add your own personalised message and we’ll send it to your lucky recipient via email. Alternatively, you can
            always email the card to yourself to print out at home!
          </p>
        </div> -->
          <div class="gc-detail-page__steps-container m-section">
            <div class="gc-detail-page__step m-color-1">1. What did you buy?</div>
            <div id="gc-detail-magazine-cover" class="gc-detail-page__wrapper">
              <div class="gc-detail-page__list">
                <select
                  v-model.trim="selection.magazineShortsiteCode"
                  class="m-input-text m-input-text--medium m-input-text--max-width m-nofocus m-border-radius"
                  :class="[isFirstSend && $v.selection.magazineCover.$invalid ? 'm-input-error' : 'm-input--border']"
                  @change="optionOnChange($event)"
                >
                  <option selected :value="undefined">Choose your magazine</option>
                  <option v-for="(mags, index) in dataUI.magazines" :key="`mag-option-${index}`" :value="mags.shortsite_code">
                    {{ mags.title }}
                  </option>
                </select>
                <div v-if="isFirstSend && $v.selection.magazineCover.$invalid" class="m-section m-text-error m-color-error m-input-text--max-width ml-10">
                  Please choose your magazine
                </div>
              </div>
            </div>
          </div>
          <div class="gc-detail-page__steps-container m-section">
            <div class="gc-detail-page__step m-color-1">2. Choose your design</div>
            <div id="gc-detail-designs" class="gc-detail-page__wrapper">
              <ul class="gc-detail-page__list gc-detail-page__list--stretch">
                <li v-for="(template, index) in latestDesigns" :key="`${template.id}`" class="gc-detail-page__list-item">
                  <span
                    class="m-button m-button-toggle"
                    :class="{
                      'm-button-selected': selection.design.id === template.id
                    }"
                  >
                    <span class="m-button-inner">
                      <button class="m-button-text m-padding-2 m-nofocus" type="button" @click="updateSelection('design', index, template)">
                        <img :src="createImgixURL(template.image_path, designThumbnailQueryString)" class="m-button-thumbnail-small" :alt="template.title" />
                      </button>
                    </span>
                  </span>
                </li>
                <li class="gc-detail-page__list-item">
                  <button class="m-button m-button-inner m-button-text m-nofocus gc-detail-page__button-more" type="button" @click="togglePreviewDesigns = !togglePreviewDesigns">
                    More
                  </button>
                </li>
              </ul>
            </div>
          </div>
          <div class="gc-detail-page__steps-container m-section">
            <div class="gc-detail-page__step m-color-1">3. Personalise your eCard<span id="gc-detail-page__required">* Required fields</span></div>
            <div id="gc-detail-form" class="gc-detail-page__wrapper">
              <div class="gc-detail-page__list gc-detail-page__list--form">
                <div class="gc-detail-page__list-item m-section">
                  <div class="gc-detail-page__form-label">To* <span v-if="isFromMobile" id="gc-detail-page__required--mobile">* Required fields</span></div>
                  <div class="gc-detail-page__form-field m-input m-input--noborder m-input-toggle m-border-radius">
                    <input
                      id="toName"
                      v-model.trim="selection.toName"
                      class="m-input-text m-input-text--medium m-nofocus m-border-radius"
                      :class="[isFirstSend && $v.selection.toName.$invalid ? 'm-input-error' : 'm-input--border']"
                      type="text"
                      aria-label="Recipient Name"
                      name="toName"
                      placeholder="Recipient Name"
                    />
                    <div v-if="isFirstSend && $v.selection.toName.$invalid" class="m-padding-2 m-text-error m-color-error">Please insert a valid name</div>
                  </div>
                </div>
                <div class="gc-detail-page__list-item m-section">
                  <div class="gc-detail-page__form-label"></div>
                  <div class="gc-detail-page__form-field m-input m-input--noborder m-input-toggle m-border-radius">
                    <input
                      id="toEmail"
                      v-model.trim="selection.toEmail"
                      class="m-input-text m-input-text--medium m-nofocus m-border-radius"
                      :class="[isFirstSend && $v.selection.toEmail.$invalid ? 'm-input-error' : 'm-input--border']"
                      type="text"
                      name="toEmail"
                      aria-label="Recipient Email"
                      placeholder="Recipient Email"
                    />
                    <div v-if="isFirstSend && $v.selection.toEmail.$invalid" class="m-padding-2 m-text-error m-color-error">Please insert a valid email address</div>
                  </div>
                </div>
                <div class="gc-detail-page__list-item m-section">
                  <div class="gc-detail-page__form-label">From*</div>
                  <div class="gc-detail-page__form-field m-input m-input--noborder m-input-toggle m-border-radius">
                    <input
                      id="fromName"
                      v-model.trim="selection.fromName"
                      class="m-input-text m-input-text--medium m-nofocus m-border-radius"
                      :class="[isFirstSend && $v.selection.fromName.$invalid ? 'm-input-error' : 'm-input--border']"
                      type="text"
                      name="fromName"
                      aria-label="Your Name"
                      placeholder="Your Name"
                    />
                    <div v-if="isFirstSend && $v.selection.fromName.$invalid" class="m-padding-2 m-text-error m-color-error">Please insert a valid name</div>
                  </div>
                </div>
                <div class="gc-detail-page__list-item m-section">
                  <div class="gc-detail-page__form-label"></div>
                  <div class="gc-detail-page__form-field m-input m-input--noborder m-input-toggle m-border-radius">
                    <input
                      id="fromEmail"
                      v-model.trim="selection.fromEmail"
                      class="m-input-text m-input-text--medium m-nofocus m-border-radius"
                      :class="[isFirstSend && $v.selection.fromEmail.$invalid ? 'm-input-error' : 'm-input--border']"
                      type="text"
                      name="fromEmail"
                      aria-label="Your Email"
                      placeholder="Your Email"
                    />
                    <div v-if="isFirstSend && $v.selection.fromEmail.$invalid" class="m-padding-2 m-text-error m-color-error">Please insert a valid email address</div>
                  </div>
                </div>
                <div class="gc-detail-page__list-item m-section">
                  <div class="gc-detail-page__form-label">Message</div>
                  <div class="gc-detail-page__form-field m-input m-input--noborder m-input-toggle m-border-radius">
                    <textarea
                      id="message"
                      v-model="selection.message"
                      class="m-textarea m-input-text--medium m-nofocus m-border-radius"
                      :class="[isFirstSend && $v.selection.message.$invalid ? 'm-input-error' : 'm-input--border']"
                      name="message"
                      :aria-label="defaultMessage"
                      :placeholder="defaultMessage"
                      rows="4"
                      cols="45"
                      maxlength="120"
                      @keydown.enter.prevent="parseEnterTextarea"
                    />
                    <!-- <div class="m-section m-text--chars-remaining m-color-1 m-padding-2">{{ 4 - lineCount }} new lines remaining</div> -->
                    <div class="m-section m-text--chars-remaining m-color-1 m-padding-2">{{ 120 - selection.message.length }} characters remaining</div>
                    <div v-if="isFirstSend && $v.selection.message.$invalid" class="m-padding-2 m-text-error m-color-error">
                      The eCard message must be between £{{ $v.selection.message.$params.between.min }} and £{{ $v.selection.message.$params.between.max }}
                    </div>
                  </div>
                </div>
                <client-only>
                  <div class="gc-detail-page__list-item m-section m-spacing-bottom-big">
                    <div class="gc-detail-page__form-label">E-Delivery Date*</div>
                    <div id="gc-detail-page__date-picker" class="gc-detail-page__form-field">
                      <div id="gc-detail-page__date-picker-wrapper" class="m-flex">
                        <div class="gc-detail-page__input-calendar m-border-radius-left">
                          <font-awesome-icon :icon="['fal', 'calendar-alt']" class="m-1" />
                        </div>
                        <date-picker
                          id="toDate"
                          v-model="selection.toDate"
                          input-class="m-input m-date-picker m-input-text--normal m-nofocus m-border-radius-right"
                          placeholder="DD/MM/YYYY"
                          :clear-button="false"
                          name="toDate"
                          format="dd/MM/yyyy"
                          :disabled-dates="selection.disabledDates"
                          @input="updateSelectionAfterToDateChanged() && $v.selection.toDate && $v.selection.toDate.$touch"
                        >
                        </date-picker>
                      </div>
                      <div v-if="isFirstSend && $v.selection.toDate.$invalid" class="m-padding-2 m-text-error m-color-error">Please insert a valid date in the future</div>
                    </div>
                  </div>
                </client-only>
                <div class="gc-detail-page__list-item m-section m-no-margin m-flex-justify-content-center">
                  <div id="gc-buttons-wrapper">
                    <span id="gc-button-print" :class="{ 'm-no-margin': isFromMobile }" class="m-button m-button__print">
                      <span class="m-button-inner">
                        <button
                          id="gc-print-button"
                          class="m-button-text m-button-text__print m-text-center"
                          :class="{ 'm-button-text__send--mobile': isFromMobile }"
                          alt="Print eCard"
                          @click.prevent="printECard()"
                        >
                          PRINT CARD
                        </button>
                      </span>
                    </span>
                    <span id="gc-button-send" :class="{ 'm-0 mb-3': isFromMobile }" class="m-button m-margin-left-20percent m-button__send">
                      <span class="m-button-inner">
                        <button
                          id="gc-send-button"
                          class="m-button-text m-button-text__send m-text-center"
                          :class="{ 'm-button-text__send--mobile': isFromMobile }"
                          alt="Add To Basket"
                          :disabled="friendlyCaptchaSolution === undefined"
                          @click.prevent="!isClicked && submitECard()"
                        >
                          SEND
                        </button>
                      </span>
                    </span>
                  </div>
                </div>
                <client-only>
                  <div class="friendlycaptcha flex-center">
                    <div ref="captcha" class="vue-friendly-captcha frc-captcha" />
                  </div>
                </client-only>
              </div>
            </div>
          </div>
        </div>
        <ECardPreview
          :toggle="togglePreview === true"
          :image="createImgixURL(selection['design'].image_path, designPreviewQueryString)"
          :message="selection.message == '' ? undefined : selection.message"
          :magazine-cover="selection.magazineCover"
          :magazine-name="selection.magazineName"
          :from-name="selection.fromName"
          :from-email="selection.fromEmail"
          :to-name="selection.toName"
          :to-email="selection.toEmail"
          :to-date="selection.toDate"
        />
        <ECardDesigns :toggle="togglePreviewDesigns === true" :designs="restOfDesigns" />
        <div v-show="togglePreview || togglePreviewDesigns" class="m-popover-lightbox"></div>
      </div>
      <div>
        <div v-if="description" id="description" class="magazine__info">
          <b-tabs content-class="p-4" fill align="left">
            <b-tab title="Description">
              <!-- eslint-disable-next-line vue/no-v-html -->
              <div class="tab-text" v-html="$options.filters.sanitize(`<p>${description}</p>`)"></div>
            </b-tab>
            <b-tab disabled></b-tab>
          </b-tabs>
        </div>

        <LazyHydrate when-visible>
          <div class="product__upsell">
            <div class="product__panel">
              <p class="product__upsell-heading">Share this page</p>
            </div>

            <div class="basket__panel upsell-grid items-list">
              <Social :magazine="dataUI.magazine" />
            </div>
          </div>
        </LazyHydrate>

        <!-- <LazyHydrate when-visible>
        <div v-if="dataUI && dataUI.upsell && dataUI.upsell.length > 0" class="product__upsell">
          <div class="product__panel">
            <p class="product__upsell-heading">You might also like...</p>
          </div>

          <div class="basket__panel upsell-grid items-list">
            <UpsellProduct
              v-for="(item, index) in dataUI.upsell.slice(0, 4)"
              :key="`${index}-${item.productId}-upsell`"
              :type="'upsell'"
              :item="item"
              class="product__upsell-item items-list__item"
              imgix-query-string="&w=130&h=180"
            />
          </div>
        </div>
      </LazyHydrate> -->
      </div>
    </div>
    <div id="giftcard--print">
      <ECardPrint
        :image="selection.design.image_path && createImgixURL(selection.design.image_path, designPreviewQueryString)"
        :message="selection.message || defaultMessage"
        :magazine-cover="selection.magazineCover"
        :magazine-name="selection.magazineName"
        :from-name="selection.fromName"
        :from-email="selection.fromEmail"
        :to-name="selection.toName"
        :to-email="selection.toEmail"
      />
    </div>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, email, minLength, maxLength, minValue } from 'vuelidate/lib/validators'
import LazyHydrate from 'vue-lazy-hydration'
import xss from 'xss'
import product from '@/mixins/product'
import gtmProductPageMixin from '@/mixins/gtm/product-page'
// import { getGA4EcommerceNullPayload } from '~/lib/util/gtmGA4'
import { getBreadcrumbsMicrodataJSONLD } from '~/lib/util/microdata'
import { friendlyCaptchaSiteverify } from '~/lib/util/friendlycaptcha'

export default {
  name: 'ECard',
  fetchOnServer: true,
  components: {
    LazyHydrate,
    ECardTemplate: () => import('~/components/ecard/ECardTemplate.vue'),
    ECardPreview: () => import('~/components/ecard/ECardPreview.vue'),
    ECardDesigns: () => import('~/components/ecard/ECardDesigns.vue'),
    ECardPrint: () => import('~/components/ecard/ECardPrint.vue')
  },
  filters: {
    sanitize(value) {
      if (!value) {
        return ''
      }
      return xss(value)
    }
  },
  mixins: [gtmProductPageMixin, product, validationMixin],
  validations: {
    selection: {
      magazineCover: { required },
      fromName: { required, minLength: minLength(1), maxLength: maxLength(50) },
      fromEmail: {
        // required: requiredIf(function() {
        //   return this.isFromEmailRequired
        // }),
        required,
        email
      },
      toName: { required, minLength: minLength(1), maxLength: maxLength(50) },
      toEmail: { required, email },
      toDate: {
        required,
        minValue: minValue((d => new Date(d.setDate(d.getDate() - 1)))(new Date()))
      },
      message: { minLength: minLength(1), maxLength: maxLength(120) }
    }
  },
  layout: '2Columns',

  async fetch() {
    // Look for an available Query String and retrieve the URL path
    const path = '/magazines/ecard'
    const query = this.$nuxt.context.query
    const useCache = this.$store.getters['UI/useCache']
    console.log('page=eCard')
    console.log('path=', path)
    console.log('query=', query)
    console.log('QS useCache=', useCache)

    this.queryString = query

    // Calling the Frontend Api for the Page (we have axios-cache LRU in place)
    process.env.NODE_ENV !== 'production' && require('axios-debug-log')

    this.dataUI = {
      magazines: [],
      magazine: {
        title: this.metaTitle,
        imageUrl: '/ecard/gift1.png?auto=format&w=200&dpr=2&rect=right,top,400,300',
        url: '/ecard',
        h1Title: this.metaTitle,
        description: this.description
      }
    }

    if (process.server) {
      // Call the GET /ecard/list-all-ecards-magazines ecard/list-all-ecards-magazinesAPI
      // Each magazine retrived should be something like
      // {
      //   fourDigitCode: 'OTRI',
      //   image_path: '/products/220-triathlon-magazine-cover.jpg',
      //   name: '220 Triathlon',
      //   sku: '220 Triathlon'
      // }
      await this.$axios
        .get(`${this.$config.AWSAPIGatewayURL}/ecard/list-all-ecards-magazines`, {
          headers: {
            'Content-Type': 'application/json',
            'x-api-key': this.$config.AWSAPIGatewayXAPIKEY,
            'Cache-Control': useCache ? 'max-age=3600' : 'max-age=0'
          },
          timeout: this.$nuxt.$config.AWSAPIGatewayTimeout,
          useCache
        })
        .then(response => {
          // console.log('RESPONSE=', response)
          console.log('RESPONSE items #', response?.data?.payload.length)
          this.$store.commit('UI/statusCode', 200, { root: true })

          this.dataUI = {
            magazines: [...(response?.data?.payload || [])]
          }
        })
        .catch(e => {
          console.log('ERROR=', e)
          // Display the 500 Satus Code Info and force 500 to the real StatusCode of the page
          if (process.server) {
            this.$nuxt.context.res.statusCode = 500
          }
          this.$store.commit('UI/statusCode', 500, { root: true })
        })
    }

    // Breadcrumbs
    const breadcrumbs = [
      {
        first: true,
        value: 'magazine subscriptions',
        url: '/',
        last: false
      },
      {
        first: false,
        value: 'Create ECard',
        url: '/ecard',
        last: true
      }
    ]
    this.$store.commit('ADD_BREADCRUMBS', { path: '/ecard', breadcrumbs })

    if (this?.params) {
      // Quote Item Info by API -> M1 backend
      if (this.params.id === 'jmag') {
        console.log('STUB-ECard action=prefill params=', this.params)
        // STUB prefill data
        this.selection = {
          magazineCover: `${this.$config.imgixURL}/products/110percent-gaming-magazine-cover.jpg?auto=format&fit=fill&fill=solid&bg=0FFF&w=200&dpr=3`,
          magazineName: '110% Gaming',
          magazineShortsiteCode: 'GAD1',
          design: this.designs[0],
          fromName: 'John & Bob',
          fromEmail: 'gabriele.ravanelli@jellyfish.com',
          toName: 'Alfred & Bella',
          toEmail: 'testers@magazine.co.uk',
          toDate: new Date(),
          toDateLongformat: new Date().toLocaleDateString('en-GB', { year: 'numeric', month: 'long', day: 'numeric' }).replace(',', ''),
          type: 'SEND_NOW', // Values: SEND_IN_THE_FUTURE, SEND_NOW, SEND_CONFIRMATION_FUTURE_DELIVERY_TOBUYER
          message: 'Ciao GUYS\nHow are you and how doing?\nShall we meet on Saturday?\n;)\nJohn & Bod',
          disabledDates: {
            to: new Date(Date.now() - 86400000), // Disable all dates up to specific date (YESTERDAY)
            from: new Date(Date.now() + 86400000 * 180) // Disable all dates after specific date (IN 180 days)
          }
        }

        // It is not unusual having shortsite_code=undefined on DB
        const magazine = this.dataUI.magazines.filter(e => e.shortsite_code && e.shortsite_code.toLowerCase() === 'gad1').pop()

        console.log('STUB-ECARD action=prefill found magazine=', magazine)

        this.dataUI.magazine = { ...magazine }
      } else {
        console.log('ECard action=prefill params=', this.params)
        // update the magazineCover with the correct image
        // each magazine will contain something similar as the following object
        // {
        //   "_id": "6321993fb810dd1fca6bd573",
        //   "image_path": "/products/3d-world-magazine-cover.jpg",
        //   "shortsite_code": "3DF1",
        //   "sku": "3D World",
        //   "status": "ENABLED",
        //   "type": "MAGAZINE"
        // }

        // It is not unusual having shortsite_code=undefined on DB
        const magazine = this.dataUI.magazines
          .filter(e => e.shortsite_code && e.shortsite_code.toLowerCase() === this.$options.filters.sanitize(this.params.id).toLowerCase())
          .pop()

        console.log('ECARD action=prefill found magazine=', magazine)

        this.dataUI.magazine = { ...magazine }

        if (magazine) {
          this.selection.magazineCover = `${this.$config.imgixURL}${magazine.image_path}`
          this.selection.magazineName = magazine.title
          this.selection.magazineShortsiteCode = magazine.shortsite_code
        }

        if (this?.params?.fromName) {
          this.selection.fromName = this.$options.filters.sanitize(decodeURIComponent(this.params.fromName))
        }

        if (this?.params?.toName) {
          this.selection.toName = this.$options.filters.sanitize(decodeURIComponent(this.params.toName))
        }
      }
    }
  },

  data() {
    return {
      isLoading: false,
      dataUI: {},
      togglePreview: false,
      togglePreviewDesigns: false,
      isFirstSend: false,
      isClicked: false,
      isFromEmailEnabled: false,
      moreDesignSelected: false,
      magazineCoverQueryString: '?auto=format&fit=fill&fill=solid&bg=0FFF&w=200&dpr=3',
      designPreviewQueryString: '?auto=format&w=540&dpr=3&rect=left,top,1506,375',
      designThumbnailQueryString: '?auto=format&h64&dpr=2&rect=right,top,400,300',
      selection: {
        magazineCover: undefined,
        magazineName: undefined,
        magazineShortsiteCode: undefined,
        design: {
          index: 0,
          title: 'Tropical 2',
          image_path: '/ecard/tropical2.png',
          id: 'tropical-2'
        },
        fromName: '',
        fromEmail: '',
        toName: '',
        toEmail: '',
        toDate: new Date(),
        toDateLongformat: '',
        message: '',
        type: 'SEND_NOW',
        disabledDates: {
          to: new Date(Date.now() - 86400000), // Disable all dates up to specific date (YESTERDAY)
          from: new Date(Date.now() + 86400000 * 180) // Disable all dates after specific date (IN 180 days)
        }
      },
      designs: [
        {
          title: 'Tropical 2',
          image_path: '/ecard/tropical2.png',
          id: 'tropical-2'
        },
        {
          title: 'Gift 1',
          image_path: '/ecard/gift1.png',
          id: 'gift-1'
        },
        {
          title: 'Pink Stars',
          image_path: '/ecard/pinkstars.png',
          id: 'pink-stars'
        },
        {
          title: 'Green Confetti',
          image_path: '/ecard/greenconfetti.png',
          id: 'green-confetti'
        },
        {
          title: 'Gift 2',
          image_path: '/ecard/gift2.png',
          id: 'gift-2'
        },
        {
          title: 'Tropical 1',
          image_path: '/ecard/tropical1.png',
          id: 'tropical-1'
        },
        {
          title: 'Blue Stars',
          image_path: '/ecard/bluestars.png',
          id: 'blue-stars'
        },
        {
          title: 'Pink Confetti',
          image_path: '/ecard/pinkconfetti.png',
          id: 'pink-confetti'
        }
      ],
      description:
        "Create your magazine.co.uk eCard using our FREE Personalised eCard Creator. When you're happy with the design, you can either email it to the recipient on a specified date or print your card and give to the recipient yourself on the big day.",
      canonicalURL: 'https://www.magazine.co.uk/ecard',
      metaTitle: 'ECard Creator',
      metaDescription:
        "Create your magazine.co.uk eCard using our FREE Personalised eCard Creator. When you're happy with the design, you can either email it to the recipient on a specified date or print your card and give to the recipient yourself on the big day.",
      // metaDescription: `A magazine subscription gift card is the perfect gift. Our E-cards can be emailed instantly to the lucky recipient or you can print it off yourself and give it in person.`,
      metaKeywords: 'magazine.co.uk',
      defaultMessage: "You've been bought a magazine gift subscription from magazine.co.uk",
      isFromMobile: this.$ua.isFromIphone() || this.$ua.isFromAndroidMobile(),
      widget: null,
      friendlyCaptchaSolution: undefined
    }
  },

  computed: {
    // isFromEmailRequired() {
    //   return this.selection.toDate.toLocaleDateString('en-GB') !== new Date().toLocaleDateString('en-GB')
    // },
    latestDesigns() {
      // console.log('isFromIphone=', this.$ua.isFromIphone())
      // console.log('isFromAndroidMobile=', this.$ua.isFromAndroidMobile())
      // console.log('isFromMobile=', this.isFromMobile)
      if (this.isFromMobile) {
        if (this.selection.design.index >= 3) {
          return [this.selection.design, ...this.designs.slice(0, 2)]
        }
        if (this.moreDesignSelected) {
          return [this.moreDesignSelected, ...this.designs.slice(0, 2)]
        }
        return this.designs.slice(0, 3)
      }

      if (this.selection.design.index >= 4) {
        // Add a design as first element if it has been selected from the more choice
        return [this.selection.design, ...this.designs.slice(0, 3)]
      }

      if (this.moreDesignSelected) {
        return [this.moreDesignSelected, ...this.designs.slice(0, 3)]
      }

      return this.designs.slice(0, 4)
    },
    restOfDesigns() {
      if (this.isFromMobile) {
        if (this.selection.design.index >= 3) {
          // Show one design more if we have already selected one from the more choice
          return this.designs.slice(2)
        }
        if (this.moreDesignSelected) {
          return this.designs.slice(2)
        }

        return this.designs.slice(2)
      }

      if (this.selection.design.index >= 4) {
        // Show one design more if we have already selected one from the more choice
        return this.designs.slice(3)
      }

      if (this.moreDesignSelected) {
        return this.designs.slice(3)
      }

      return this.designs.slice(3)
    },
    lineCount() {
      // Return number of lines using regex if not empty
      return (this?.selection?.message.length && this.selection.message.split(/\r\n|\r|\n/).length) || 0
    }
  },
  async mounted() {
    // GA4 Fire view_item event
    // this.$config.GA4Syntax && this.$config.GA4FireEcommerceNull && this.$gtm && this.$gtm.push(getGA4EcommerceNullPayload())
    // this.$config.GA4Syntax && this.$gtm && this.$gtm.push(getGA4ViewItemPayload({ rawItems: this.trackingItemsForGA4 }))

    const { WidgetInstance } = await import('friendly-challenge')
    if (!this.widget) {
      this.widget = new WidgetInstance(this.$refs.captcha, {
        sitekey: this.$config.FriendlyCaptchaSiteKey,
        startMode: 'auto',

        doneCallback: this.onDone.bind(this),
        errorCallback: this.onError.bind(this)
      })
    }
  },
  created() {
    this.$nuxt.$on('SHOW_PREVIEW', value => {
      this.togglePreview = value
    })
    this.$nuxt.$on('SHOW_DESIGNS', value => {
      console.log('on SHOW_DESIGNS')
      this.togglePreviewDesigns = value
    })
    this.$nuxt.$on('UPDATE_DESIGN_SELECTION', ({ action, index, data }) => {
      this.updateSelection(action, index, data)
      this.moreDesignSelected = data
    })
  },

  beforeDestroy() {
    if (this.widget) {
      this.widget.destroy()
    }
  },

  methods: {
    onDone(solution) {
      console.log('onDone solution=', solution)
      this.friendlyCaptchaSolution = solution || undefined
    },
    onError(err) {
      console.log('onError err=', err)
      this.friendlyCaptchaSolution = undefined
    },
    updateSelectionAfterToDateChanged() {
      // Check the toDate to determine when we need to send the eCard
      this.selection.type = 'SEND_IN_THE_FUTURE'
      if (this.selection.toDate.toLocaleDateString('en-GB') === new Date().toLocaleDateString('en-GB')) {
        this.selection.type = 'SEND_NOW'
      }

      // Used on Frontend and Emails, for example: 3 October 2022
      this.selection.toDateLongformat = new Date(this.selection.toDate).toLocaleDateString('en-GB', { year: 'numeric', month: 'long', day: 'numeric' }).replace(',', '')
    },

    parseEnterTextarea(e) {
      // Chekc the position for the new line
      const newLineIndex = e.target.selectionStart
      console.log('parseEnterTextarea e.target.selectionStart:', e.target.selectionStart)
      if (this.lineCount >= 6) {
        return false
      }

      // Add the new line right in the index position of the message
      this.selection.message = this.selection.message.slice(0, newLineIndex) + '\r\n' + this.selection.message.slice(newLineIndex)
    },

    optionOnChange(event) {
      // Fill all the magazine information from the chosen option
      if (event?.target?.value) {
        this.dataUI.magazines.find(e => {
          if (e.shortsite_code === event.target.value) {
            this.selection.magazineCover = `${this.$config.imgixURL}${e.image_path}${this.magazineCoverQueryString}`
            this.selection.magazineName = `${e.title} Subscription`
            this.selection.magazineShortsiteCode = e.shortsite_code
            this.dataUI.magazine = { ...e }
          }
        })
      } else {
        this.selection.magazineCover = undefined
        this.selection.magazineName = undefined
        this.selection.magazineShortsiteCode = undefined
        this.dataUI.magazine = {}
      }
    },
    createImgixURL(image, qs) {
      return `${this.$config.imgixURL}${image}${qs}`
    },

    updateSelection(action, index, data) {
      // console.log('updateSelection action=', action)
      // console.log('updateSelection index=', index)
      // console.log('updateSelection data=', data)

      switch (action) {
        case 'amount':
          this.selection.amount.id = index
          this.selection.amount.value = Number(data)
          if (index > 0) {
            this.selection.amount.input = undefined
          }
          break
        case 'design':
          this.selection.design = {
            index,
            ...data
          }
          break
        default:
          break
      }
    },
    focusFirstStatus(component = this) {
      if (component.status) {
        component.$el.focus()
        return true
      }

      let focused = false

      component.$children.some(childComponent => {
        focused = this.focusFirstStatus(childComponent)
        return focused
      })

      return focused
    },
    // validate() {
    // this.$v.$touch()
    // this.$nextTick(() => this.focusFirstStatus())
    // },

    async submitECard() {
      this.isClicked = true
      this.isFirstSend = true

      // force a validation
      this.$v.$touch()

      if (this.$v.$invalid) {
        console.log('eCard - submitECard - Form Validation ERROR')
        this.isClicked = false
        this.isLoading = false
        return false
      }

      // if the Friendly Captcha solution is undefined DO NOTHING
      if (this.friendlyCaptchaSolution === undefined) {
        console.log('eCard - submitECard - friendlyCaptchaSolution=', this.friendlyCaptchaSolution)
        this.isClicked = false
        this.isLoading = false
        return
      }

      // 2 - Verify the solution on the Server API
      const recaptchaResponse = await friendlyCaptchaSiteverify({ solution: this.friendlyCaptchaSolution, context: this })
      this.$config.debug && console.log(`friendlyCaptchaSiteverify recaptchaResponse=${recaptchaResponse}`)

      // if the Friendly Captcha Siteverify response is false DO NOTHING
      if (recaptchaResponse === false) {
        this.isClicked = false
        this.isLoading = false
        return
      }

      // 3 - Sublit the form to the AWS API GW Endpoint
      // SHOW infinite loading
      this.isLoading = true

      // toDate should be in the format YYYY-mm-dd
      const toDate = this.selection.toDate.toISOString().substring(0, 10)
      // toDate should be in the format Ex. 8 May 2024
      const toDateLongformat = new Date(this.selection.toDate).toLocaleDateString('en-GB', { year: 'numeric', month: 'long', day: 'numeric' }).replace(',', '')

      const data = {
        magazine: {
          ...this.dataUI.magazine,
          image_url: this.createImgixURL(this.dataUI.magazine.image_path, ''),
          name: this.dataUI.magazine.title,
          _id: undefined
        },
        design: {
          ...this.selection.design,
          image_url: this.createImgixURL(this.selection.design.image_path, '')
        },
        from_name: this.selection.fromName,
        from_email: this.selection.fromEmail,
        to_name: this.selection.toName,
        to_email: this.selection.toEmail,
        to_date: toDate,
        to_date_longformat: toDateLongformat, // For example: 3 October 2022
        message: this.selection.message || this.defaultMessage,
        type: this.selection.type, // Values: SEND_IN_THE_FUTURE, SEND_NOW, SEND_CONFIRMATION_FUTURE_DELIVERY_TOBUYER
        enabled: true
      }

      // Call the POST /ecard API
      // Set the axios Header API GW API_KEY
      this.$config.AWSAPIGatewayXAPIKEY && this.$axios.setHeader('x-api-key', this.$config.AWSAPIGatewayXAPIKEY, ['post'])
      const url = `${this.$config.AWSAPIGatewayURL}/ecard`
      let ret = await this.$axios
        .$post(url, data)
        .then(function(response) {
          console.log('RESPONSE=', response)
          return response
        })
        .catch(function(error) {
          console.log('ERROR=', error)
          return {
            error
          }
        })

      // We are sending an extra confirmation email, to the buyer only, if we have a future delivery
      if (data.type === 'SEND_IN_THE_FUTURE') {
        data.type = 'SEND_CONFIRMATION_FUTURE_DELIVERY_TOBUYER'
        this.$config.AWSAPIGatewayXAPIKEY && this.$axios.setHeader('x-api-key', this.$config.AWSAPIGatewayXAPIKEY, ['post'])
        const url = `${this.$config.AWSAPIGatewayURL}/ecard`
        ret = await this.$axios
          .$post(url, data)
          .then(function(response) {
            console.log('RESPONSE=', response)
            return response
          })
          .catch(function(error) {
            console.log('ERROR=', error)
            return {
              error
            }
          })
      }

      console.log('ret=', ret)

      setTimeout(() => {
        // Show an error overlay if needed
        if (ret.error) {
          this.$nuxt.$emit('SHOW_NOTIFICATION', { data: undefined, show: true })
        } else {
          // GTM push events integration
          this.$config.UAEESyntax && this.$gtm && this.$gtm.push({ event: 'submitECard' })

          // // Redirect to the ECARD success page
          // this.$router.push({ path: '/success/ecard' })
          window.location.href = `/success/ecard/${ret.payload.insertedId}`
        }

        this.isClicked = false
        // HIDE infinite loading
        this.isLoading = false
      }, 1000)
    },

    printECard() {
      // GTM push events integration
      this.$config.UAEESyntax && this.$gtm && this.$gtm.push({ event: 'printECard' })

      window.print()
      return false
    }
  },

  // To check more options, link https://github.com/nuxt/vue-meta#script-object
  // To chcek how it is working https://nuxtjs.org/faq/duplicated-meta-tags/
  head() {
    return {
      title: this?.metaTitle || '',
      link: [{ rel: 'canonical', href: this?.canonicalURL || '' }],
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this?.metaDescription || ''
        },
        {
          name: 'keywords',
          content: this?.metaKeywords || ''
        },
        {
          name: 'robots',
          content: this.$config.robots || 'INDEX,FOLLOW'
        },
        {
          hid: 'og:title',
          name: 'og:title',
          property: 'og:title',
          content: this?.metaTitle || ''
        },
        {
          hid: 'og:description',
          name: 'og:description',
          property: 'og:description',
          content: this?.metaDescription || ''
        },
        {
          hid: 'og:site_name',
          name: 'og:site_name',
          property: 'og:site_name',
          content: 'magazine.co.uk'
        },
        {
          hid: 'og:image',
          name: 'og:image',
          property: 'og:image',
          // TODO
          content: 'https://magazine.imgix.net/gift-card-images/JMAG_Giftcards.png?auto=format'
        }
      ]
    }
  },

  jsonld() {
    return [getBreadcrumbsMicrodataJSONLD({ breadcrumbs: this.$store.getters['UI/breadcrumbs'] })]
  }
}
</script>
<style lang="scss">
.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen {
  .m-popover-lightbox {
    z-index: 1008;
    opacity: 0.5;
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: #0f1111;
  }
  // .m-section:nth-last-child(-n + 2)
  .m-section:last-child {
    margin-bottom: 0px;
  }
  .m-section {
    margin-bottom: 10px;
  }
  .m-section-no-margin {
    margin-bottom: 0;
  }
  .m-text-center {
    text-align: center !important;
  }
  .m-spacing-bottom-big {
    margin-bottom: 30px;
  }
  .m-spacing-top-medium {
    margin-top: 16px !important;
  }
  .m-spacing-top-big {
    margin-top: 30px !important;
  }
  .m-color-1 {
    color: $gcTextColor1 !important;
  }
  .m-color-2 {
    color: $gcTextColor2 !important;
  }
  .m-color-3 {
    color: $gcTextColor3 !important;
  }
  .m-text-error {
    color: $gcTextColorError !important;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
  }
  .m-text {
    &--chars-remaining {
      text-align: right;
      font-size: 12px;
      font-weight: 400;
    }
  }
  .m-button-close {
    float: right;
    top: 5px;
    position: absolute;
    right: 5px;
    width: 24px;
    padding: 23px;
    cursor: pointer;
  }
  .m-text-bold {
    font-weight: 700;
  }
  .m-no-margin {
    margin: 0 !important;
  }
  .m-margin-left-20percent {
    margin-left: 20%;
  }
  .m-button {
    background-color: white;
    border-radius: 6px;
    border: 1px solid $gcBorderColor;
    cursor: pointer;
    display: inline-block;
    padding: 0;
    text-align: center;
    text-decoration: none !important;
    vertical-align: middle;
    &__send {
      border: unset;
      text-align: center;
      padding: 0px 0;
      &:hover {
        opacity: 0.7;
        filter: alpha(opacity=70);
      }
    }
    &__print {
      background: #ffffff;
      border: 1px solid #b0b0b0;
      border-radius: 5px;
      text-align: center;
      padding: 0px 0;
      font-size: 16px;
      &:hover {
        opacity: 0.7;
        filter: alpha(opacity=70);
      }
    }
  }
  .m-button-selected {
    outline: 0;
    overflow: visible;
    border: 1px solid $gcItemSelectBorderColor;
    background-color: $gcItemSelectBgColor;
  }
  .m-button-inner {
    background-color: transparent;
    background-image: none;
  }
  .m-button-text {
    padding: 10px;
    background-color: transparent;
    border: 0;
    display: block;
    line-height: 19px;
    margin: 0;
    outline: 0;
    text-align: center;
    white-space: nowrap;
    &__send {
      text-transform: uppercase;
      text-align: center;
      font-size: 16px;
      font-weight: 600;
      background: $buttonBgColorRed;
      color: white;
      line-height: 19px;
      margin: 0;
      text-decoration: none;
      min-width: 165px;
      padding: 10px 15px;
      &--mobile {
        width: 100%;
        margin: 0;
      }
    }
    &__print {
      text-transform: uppercase;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      letter-spacing: -0.02em;
      margin: 0;
      text-decoration: none;
      min-width: 165px;
      padding: 10px 15px;
      color: $gcTextColor4;
      &--mobile {
        width: 100%;
        margin: 0;
      }
    }
  }
  .m-input {
    background-color: white;
    border: 1px solid $gcBorderColor;
    cursor: pointer;
    display: inline-block;
    padding: 0;
    text-align: center;
    text-decoration: none !important;
    vertical-align: middle;
    &--noborder {
      border: unset;
    }
    &--border {
      border: 1px solid $gcBorderColor !important;
    }
  }
  .m-input-text {
    &--big {
      padding: 6px;
      font-size: 20px;
    }
    &--medium {
      padding: 9px;
      font-size: 16px;
    }
    &--normal {
      padding: 10px;
      font-size: 16px;
    }
    &--max-width {
      width: 100%;
      max-width: 358px;
    }
    background-color: transparent;
    border: 0;
    display: block;
    line-height: 20px;
    margin: 0;
    outline: 0;
    text-align: left;
    white-space: nowrap;
  }
  .m-textarea {
    background-color: transparent;
    border: 0;
    display: block;
    line-height: 20px;
    margin: 0;
    outline: 0;
    text-align: left;
    width: 100%;
    resize: vertical;
    word-break: break-all;
    overflow: auto;
    overflow-wrap: anywhere;
    white-space: pre-wrap;
  }
  .m-input-selected {
    outline: 0;
    overflow: visible;
    border: 1px solid $gcItemSelectBorderColor;
    background-color: $gcItemSelectBgColor;
  }
  .m-input-error {
    outline: 0;
    overflow: visible;
    border: 2px solid $gcTextColorError;
    background-color: $gcTextColorBgColor;
    .m-nofocus {
      &:focus {
        outline: 0;
        box-shadow: 0 0 3px 2px $gcTextColorError;
      }
    }
  }
  .m-nofocus {
    &:focus {
      outline: 0;
      box-shadow: 0 0 3px 2px $gcItemSelectBorderColor;
    }
  }
  .m-button-thumbnail-small {
    width: 100%;
    max-width: 75px;
    border-radius: 4px;
    max-height: 38px;
  }
  .m-button-thumbnail-large {
    width: 100%;
    border-radius: 4px;
  }
  .m-padding-2 {
    padding: 2px;
  }
  .m-padding-10 {
    padding: 0 10px;
  }
  .m-flex {
    display: flex;
  }
  .m-flex-stretch {
    align-items: stretch;
  }
  .m-flex-justify-content-center {
    justify-content: center;
  }
  .m-border-radius-right {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }
  .m-border-radius-left {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  }
  .m-border-radius {
    border-radius: 6px;
  }
  .m-date-picker {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: auto;
    background-color: transparent;
    display: block;
    line-height: 22px;
    margin: 0;
    outline: 0;
    text-align: left;
    white-space: nowrap;
    max-width: 110px;
  }

  button:disabled,
  button[disabled] {
    // border: 1px solid #ff0d00;
    // background-color: #f3d4d2;
    color: white;
    opacity: 0.7;
  }

  #giftcard {
    width: 100%;
  }
  #gc-buttons-wrapper {
    display: flex;
    flex-flow: column-reverse nowrap;
    justify-content: flex-start;
    align-content: center;
    // margin-bottom: 200px;
  }
  #gc-detail-page__required {
    display: none;
    &--mobile {
      display: block;
      float: right;
      position: relative;
      top: 0;
      right: 0;
      font-size: 12px;
      margin-right: 20px;
      color: $gcTextColor1;
      font-weight: 400;
    }
  }
  .vdp-datepicker__calendar {
    z-index: 10000;
    left: -50px;
  }
  #gc-detail-page-right-column {
    padding: 10px;
    ul.gc-detail-page__list {
      margin-left: 20px;
      padding: 0;
    }
    .gc-detail-page {
      &__title {
        display: none;
        // font-weight: 400;
        font-style: normal;
        font-size: 23px;
        color: $headingColor;
      }
      &__description {
        display: none;
        // font-weight: 400;
        font-style: normal;
        font-size: 14px;
      }
      &__step {
        font-weight: 600;
        font-style: normal;
        font-size: 16px;
        max-width: 595px;
      }
      &__wrapper {
        margin-top: 10px;
      }
      &__list {
        list-style-type: none;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-flow: row nowrap;
        &--stretch {
          align-items: stretch;
        }
      }
      &__list--form {
        display: block;
      }
      &__list-other {
        // display: inline-table;
        margin-right: 10px;
        display: flex;
        flex-flow: row nowrap;
      }
      &__list-item {
        display: flex;
        flex-flow: column nowrap;
        margin-right: 15px;
        font-size: 20px;
        button {
          border-radius: 6px;
        }
      }
      &__button-more {
        width: 100%;
        padding: 10px;
        height: 100%;
        border-radius: 6px;
        border: 1px solid $gcBorderColor;
      }
      &__input-currency {
        background-color: #f6f6f6;
        padding: 8px 6px;
        white-space: nowrap;
        vertical-align: middle;
        font-size: 20px;
        margin: 0px;
        line-height: 21px;
        border-color: $gcBorderColor;
        border-style: solid;
        border-width: 1px 0 1px 1px;
      }
      &__input-calendar {
        background-color: #f6f6f6;
        padding: 6px 7px;
        line-height: 1;
        width: 42px;
        white-space: nowrap;
        vertical-align: middle;
        font-size: 20px;
        margin: 0px;
        border-color: $gcBorderColor;
        border-style: solid;
        border-width: 1px 0 1px 1px;
      }
      &__form-label {
        float: left;
        text-align: left;
        width: 100%;
        padding: 10px;
        font-size: 16px;
        margin-left: 20px;
        color: $gcTextColor1;
      }
      &__form-field {
        margin-left: 20px;
        width: 90%;
        max-width: 700px;
        input {
          width: 100%;
        }
      }
    }
  }

  #gc-detail-magazine-cover .gc-detail-page__list {
    display: block;
    .m-input-text {
      margin-left: 20px;
      width: 90%;
    }
  }

  .items-list {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: stretch;
    align-content: space-around;
  }

  .product__upsell {
    padding: 20px 0;
  }

  @media (max-width: 320px) {
    .m-input-text--medium {
      padding: 9px;
      font-size: 15px;
    }
    #gc-detail-page-right-column {
      .gc-detail-page {
        &__list-item {
          &:nth-child(3) {
            display: none;
          }
        }
      }
    }
  }
  @media (min-width: $screen-sm-min) {
    .m-input-text--medium {
      padding: 9px;
      font-size: 15px;
    }
    .m-button-thumbnail-small {
      // width: 80%;
      max-width: 75px;
      max-height: 37px;
      border-radius: 4px;
    }
    .gc-container {
      max-width: 1230px !important;
      display: flex;
      flex-flow: column nowrap;
      align-content: center;
    }
    #giftcard {
      width: 100%;
      #gc-detail-page {
        margin: 5px 0px;
        max-width: 1230px !important;
        display: flex;
        flex-flow: row nowrap;
        #gc-detail-page-left-column {
          padding: 5px 10px;
          flex: 1 1 700px;
          // LIVE  flex: 1 1 40%;
          // LIVE  max-width: 500px;
        }
        #gc-detail-page-right-column {
          flex: 1 1 60%;
          padding: 5px 10px;
        }
        #gc-buttons-wrapper {
          display: flex;
          flex-flow: row nowrap;
          justify-content: flex-start;
          align-content: center;
          margin-bottom: 20px;
        }
        #gc-detail-page__required {
          display: block;
          float: right;
          position: relative;
          top: 0;
          right: 0;
          font-size: 12px;
          margin-right: 25px;
          color: $gcTextColor1;
          font-weight: 400;
        }
        #gc-detail-page__date-picker {
          display: flex;
          flex-flow: column nowrap;
          align-content: center;
          .vdp-datepicker div {
            .vdp-datepicker__clear-button span i span {
              padding: 10px;
              float: right;
              top: -42px;
              bottom: 0px;
              position: relative;
              font-style: normal;
              font-size: 25px;
              font-weight: 400;
              color: black;
            }
          }
          .vdp-datepicker__calendar {
            z-index: 10000;
            left: 0px;
          }
        }
        #gc-detail-page__date-picker-wrapper {
          max-height: 44px;
        }
      }

      #gc-detail-page-right-column {
        #gc-detail-designs {
          max-width: 70%;
        }
        .gc-detail-page {
          &__title {
            display: block;
            // font-weight: 400;
            font-style: normal;
            font-size: 23px;
            color: $headingColor;
          }
          &__description {
            display: block;
            // font-weight: 400;
            font-style: normal;
            font-size: 14px;
          }
          &__step {
            font-weight: 600;
            font-style: normal;
            font-size: 16px;
            max-width: 595px;
          }
          &__wrapper {
            margin-top: 15px;
          }
          &__list {
            list-style-type: none;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex-flow: row nowrap;
            &--stretch {
              align-items: stretch;
            }
          }
          &__list--form {
            display: block;
          }
          &__list-other {
            display: inline-table;
            margin-right: 10px;
          }
          &__list-item {
            display: flex;
            flex-flow: row nowrap;
            margin-right: 15px;
            font-size: 15px;
            button {
              // padding-top: 3px;
              border-radius: 6px;
            }
          }
          &__button-more {
            width: 100%;
            padding: 10px;
            height: 100%;
            border-radius: 6px;
            border: 1px solid $gcBorderColor;
          }
          &__input-currency {
            background-color: #f6f6f6;
            padding: 8px 7px 10px 7px;
            line-height: 1;
            width: 1%;
            white-space: nowrap;
            vertical-align: middle;
            font-size: 15px;
            margin: 0px;
            border-color: $gcBorderColor;
            border-style: solid;
            border-width: 1px 0 1px 1px;
          }
          &__input-calendar {
            background-color: #f6f6f6;
            padding: 6px 7px;
            line-height: 1;
            width: 42px;
            white-space: nowrap;
            vertical-align: middle;
            font-size: 20px;
            margin: 0px;
            border-color: $gcBorderColor;
            border-style: solid;
            border-width: 1px 0 1px 1px;
          }
          &__form-label {
            float: left;
            text-align: left;
            width: 150px;
            padding: 10px;
            font-size: 15px;
            margin-left: 30px;
            color: $gcTextColor1;
          }
          &__form-field {
            margin-left: 10px;
            width: 55%;
            input {
              width: 100%;
            }
          }
        }
      }

      .magazine {
        position: relative;
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;

        align-items: flex-start;

        align-content: space-between;
        margin-bottom: 20px;

        &__info {
          margin-bottom: 20px;
          .tab-content ul {
            display: block;
            list-style-type: disc;
            margin-block-start: 1em;
            margin-block-end: 1em;
            margin-inline-start: 0px;
            margin-inline-end: 0px;
            padding-left: 40px;
          }

          .nav-item {
            a,
            a::before,
            a::after {
              box-sizing: unset !important;
              font-size: 14px;
            }
          }

          .nav-link {
            color: #212529;

            &:hover {
              border-color: $borderColor $borderColor white !important;
              color: #212529 !important;
            }
          }
          .nav-tabs {
            background: #ededed;
            border-bottom: unset;
          }
          .nav {
            flex-wrap: nowrap;
          }
          .tab-content {
            border-radius: 0 0 0.25rem 0.25rem;
            border: 1px solid #dee2e6;
          }
          .tab-text {
            font-size: 14px;
            p:last-child {
              margin-bottom: 0px;
            }
          }
        }
      }
      .product {
        &__recentlyViewed {
          .product__offer {
            display: none;
          }
          .actions {
            display: none;
          }
        }
        &__upsell {
          flex: 1 1 100%;
          z-index: 100;
          transform: none;
          // width: 400px;
          height: auto;

          position: relative;
          clear: both;
          background-color: #fff;
          text-align: center;
        }
        &__upsell-heading {
          margin: 0 0 10px;
          letter-spacing: 0.1em;
          font-weight: 700;
          padding: 10px;
        }
      }
      .upsell-grid {
        .items-list {
          &__item {
            padding: 0px 20px;
            flex: 1 0 20%;
            max-width: 170px;
            min-width: 100px;
            .actions button {
              background-color: transparent;
              text-align: center;
              font-size: 12px;
              color: $buttonAddToCartColor;
              line-height: 12px;
              margin: 0em;
              text-decoration: underline;
              min-width: 100px;
              padding: 15px 0px;
              border: unset;

              &:hover {
                text-decoration: none;
              }
              .spinner {
                top: 40%;
              }
            }
            .view-btn {
              display: none;
            }
            .btn-divider {
              display: none;
            }
          }
        }
      }
    }
  }
  #giftcard--print {
    display: none;
  }
}

@media print {
  .header-top-news,
  header,
  .header-bottom-container,
  .footer-container,
  #giftcard {
    display: none;
  }
  #giftcard--print {
    display: block;
  }
}
</style>
