<template>
  <div class="searchbox">
    <form action="/search/">
      <div class="search-wrapper">
        <input
          v-model="term"
          v-focus="focused === 99"
          class="search-wrapper__input"
          type="search"
          name="q"
          placeholder="Search for magazines"
          title="Search for magazines"
          autocomplete="off"
          @keydown="presearch"
          @focus="focused == 99"
          @blur="focused == null"
        />
        <button :disabled="term === ''" class="search-wrapper__button mdl-button" type="submit" title="Search" @click="redirectToSearch">
          Search<font-awesome-icon :icon="['fal', 'chevron-right']" class="fa fa-stack-1x right" />
        </button>
      </div>
    </form>

    <div v-if="allResults.length > 0" ref="searchResults" :class="{ 'results-wrapper--homepage': isHomepage, 'results-wrapper': true }">
      <div v-if="categoriesResults.length" class="results-wrapper__suggestions">
        <div class="search-head">
          <h5>Categories</h5>
        </div>
        <div
          v-for="(result, index) in categoriesResults"
          :key="`${result.id}-${index}`"
          v-focus="getIndex(result) === focused"
          :tabindex="getIndex(result)"
          class="results-wrapper__results results-wrapper__results--cats"
          @keydown.down.prevent="moveDown"
          @keydown.up.prevent="moveUp"
          @keydown.right.prevent="moveDown"
          @keydown.left.prevent="moveUp"
          @keydown.tab.prevent="moveDown"
          @keydown.enter="submitEnter(getIndex(result))"
          @focus="focused = getIndex(result)"
          @blur="focused = null"
        >
          <a :href="`${$config.baseURL}/${result.url}`" class="results-wrapper-item" target="_self">
            <div v-if="result.level == 2" class="results-wrapper-item__content results-wrapper-item__content--category">
              <!-- eslint-disable-next-line vue/no-v-html -->
              <span class="search-categories__suggest-term" v-html="result['@search.text']"> </span>
            </div>
            <div v-if="result.level == 3" class="results-wrapper-item__content results-wrapper-item__content--category">
              <!-- eslint-disable-next-line vue/no-v-html -->
              <span class="search-categories__suggest-term" v-html="result['@search.text']"> </span>
              <span v-if="result.level == 3"> in {{ result.category1 }}</span>
            </div>
          </a>
        </div>
      </div>
      <div v-if="productResults.length" class="results-wrapper__suggestions">
        <div class="search-head">
          <h5>Magazines</h5>
        </div>
        <div
          v-for="(result, index) in productResults"
          :key="`${result.id}-${index}`"
          v-focus="getIndex(result) === focused"
          :tabindex="getIndex(result)"
          class="results-wrapper__results"
          @keydown.down.prevent="moveDown"
          @keydown.up.prevent="moveUp"
          @keydown.right.prevent="moveDown"
          @keydown.left.prevent="moveUp"
          @keydown.tab.prevent="moveDown"
          @keydown.enter="submitEnter(getIndex(result))"
          @focus="focused = getIndex(result)"
          @blur="focused = null"
        >
          <a :href="linkURL(result)" class="results-wrapper-item" target="_self">
            <div class="results-wrapper-item__img shadow5">
              <BImageLazyImgix
                :image-url="getImageURL(result)"
                :title="`${result.title} Magazine Subscription`"
                :alt="`${result.title} Magazine Subscription`"
                :query-string="imgixQueryStringValue"
                :width="`70`"
                :height="`95`"
              />
            </div>
            <div class="results-wrapper-item__content">
              <p>
                <b>{{ result.title }}</b>
              </p>
              <!-- eslint-disable-next-line vue/no-v-html -->
              <p class="results-wrapper-item__content--small" v-html="$options.filters.truncate(result.desc)"></p>
            </div>
          </a>
        </div>
      </div>
      <div class="results-wrapper__link" @focus="100" @click="redirectToSearch">
        <div class="mdl-button">All search results<font-awesome-icon :icon="['fal', 'chevron-right']" class="fa fa-stack-1x right" /></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mixin as focusMixin } from 'vue-focus'
import xss from 'xss'
import search from '@/mixins/search'

export default {
  name: 'TheSearch',
  serverCacheKey: props => 'TheSearch',
  filters: {
    truncate(value) {
      if (!value) {
        return ''
      }
      return xss(value.substring(0, 150)) + '...'
    }
  },
  components: {
    BImageLazyImgix: () => import('~/components/common/BImageLazyImgix')
  },
  mixins: [focusMixin, search],

  data() {
    return {
      imgixQueryStringValue: '?fm=png&auto=format&fit=fill&fill=solid&bg=0FFF&w=210'
    }
  },

  // To check more options, link https://github.com/nuxt/vue-meta#script-object
  // To chcek how it is working https://nuxtjs.org/faq/duplicated-meta-tags/
  head() {
    let link
    if (this.canonicalURL) {
      link = [{ rel: 'canonical', href: this.canonicalURL }]
    }
    if (this.$route.path.includes('search')) {
      return {
        title: this.title,
        ...link,
        meta: [
          {
            hid: 'description',
            name: 'description',
            content: this.metaDescription
          },
          {
            name: 'keywords',
            content: this.metaKeywords || ''
          },
          {
            name: 'robotsSearch',
            content: this.$config.robots || 'INDEX,FOLLOW'
          },
          {
            hid: 'og:title',
            name: 'og:title',
            property: 'og:title',
            content: this.title
          },
          {
            hid: 'og:description',
            name: 'og:description',
            property: 'og:description',
            content: this.metaDescription
          },
          {
            hid: 'og:site_name',
            name: 'og:site_name',
            property: 'og:site_name',
            content: 'magazine.co.uk'
          },
          {
            hid: 'og:image',
            name: 'og:image',
            property: 'og:image',
            content: 'https://www.magazine.co.uk/icon.png'
          }
        ]
      }
    } else {
      return {}
    }
  }
}
</script>

<style lang="scss" scoped>
.search-head {
  background: white;
  min-height: 40px;
  h5 {
    font-size: 16px;
    color: #474747;
    font-weight: 600;
    margin-left: 8px;
    padding: 5px 10px 0px 10px;
    line-height: 40px;
    margin: unset;
  }
}
.results-wrapper__suggestions {
  margin-bottom: 15px;
}
.results-wrapper__results--cats {
  a {
    padding: 0px 0px 0px 15px !important;
  }
}
.results-wrapper-item__content--category {
  flex: 1 1 95% !important;
  // font-size: 14px;
  font-weight: 400;
  color: #474747;
  display: grid;
  // line-height: 30px;
  display: block;
  padding: 5px;
  min-height: 40px !important;
  .search-categories {
    font-size: 15px;
    font-weight: 400;
    color: #474747;
    display: grid;
    line-height: 30px;
    a {
      margin-left: 15px;
      color: #474747;
      &:hover {
        color: #ff0d00;
      }
    }
    &__suggest-term {
      margin-left: 0px;
      color: #474747;
    }
  }
}
.searchbox {
  width: 100%;
  margin: 20px 10px;
  position: relative;
  // display: flex;
  // flex-flow: column nowrap;
  // justify-content: center;
  height: 35px;
  form {
    height: 35px;
  }

  .search-wrapper {
    content-visibility: auto;
    position: relative;
    align-self: center;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    min-height: 35px;
    width: 100%;
    font-size: 18px;

    &__input {
      @include border-radius(5px);
      flex: 0 1 50%;
      background: $inputSearchBgColorDesktop;
      border: 1px solid $inputSearchBorder;
      color: $inputSearchFontColorDesktop;
      padding: 0 10px;
      min-height: 35px;
      margin-right: 30px;
      font-size: 18px;
      line-height: normal;
    }

    &__button {
      @include border-radius(5px);
      flex: 0 1 140px;
      background-color: $buttonSearchBgColorDesktop;
      font-weight: 600;
      padding-left: 12px;
      border: none;
      text-transform: uppercase;
      text-align: center;
      min-height: 35px;
      color: $buttonSearchFontColorDesktop;
      font-size: 18px;
      &:hover {
        background-color: $buttonSearchBgColorHvDesktop;
      }
    }
  }

  .results-wrapper {
    position: relative;
    border: 1px solid #ccc;
    top: 0px;
    left: 10%;
    width: 50%;
    z-index: 200;
    background: white;
    margin: 5px;
    align-self: center;

    &--homepage {
      left: 50%;
      transform: translateX(-68%);
    }
    &__results {
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      // min-height: 50px;
      width: 100%;
    }

    &__link {
      padding: 10px;
      margin-left: 10px;
      margin-right: 10px;
      text-align: center;
      min-height: 30px;
      // width: 100%;

      color: $searchFontColor;
      vertical-align: middle;

      &:hover {
        text-decoration: none;
        cursor: pointer;
        color: $searchFontColorHv;
      }
    }
  }

  .results-wrapper-item {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    background: white;
    margin: 0px;
    padding: 5px 10px;
    vertical-align: middle;
    // min-height: 50px;
    color: $searchFontColor;
    border: 2px solid white;

    &:hover {
      text-decoration: none;
      border: 2px solid $inputSearchBorder;
    }

    &:visited {
      text-decoration: none;
    }

    &__img {
      //flex: 0 1 20%;
      align-self: center;
      margin: 5px 10px 5px 5px;
      min-height: 50px;
      img {
        border-radius: 5px !important;
      }
    }

    &__content {
      align-self: center;
      flex: 0 1 80%;
      min-height: 50px;
      &--small {
        font-size: 12px;
        //line-height: 10px;
        margin-bottom: 5px;
      }
    }
  }
  .fa {
    position: relative;
  }

  .items-list__item .actions button {
    @include border-radius(5px);
    flex: 0 1 140px;
    background-color: $buttonSearchBgColorDesktop;
    font-weight: 600;
    padding-left: 12px;
    border: none;
    text-transform: uppercase;
    text-align: center;
    min-height: 35px;
    color: $buttonSearchFontColorDesktop;
    font-size: 18px;
    &:hover {
      background-color: $buttonSearchBgColorHvDesktop;
    }
  }
}

@media (max-width: $screen-xs-max) {
  .searchbox {
    display: none;
  }
}

@media (max-width: $screen-sm-min) {
  .results-wrapper {
    // position: absolute;
    // border: 1px solid #ccc;
    // top: 35px;
    left: 0 !important;
    width: 70% !important;
  }
}
</style>
