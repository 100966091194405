<template>
  <div id="search-generic" class="searchbox searchbox__generic">
    <form :action="`${$config.baseURL}/search/`">
      <div class="search-wrapper">
        <input
          v-model="term"
          v-focus="focused === 99"
          class="search-wrapper__input"
          type="search"
          name="q"
          placeholder="Search for magazines"
          title="Search for magazines"
          autocomplete="off"
          @keydown="presearch"
          @focus="focused == 99"
          @blur="focused == null"
        />
        <button :disabled="term === ''" class="search-wrapper__button mdl-button" type="submit" title="Search">
          <font-awesome-icon :icon="['fal', 'search']" class="fa-stack fa-1x" />
        </button>
      </div>
    </form>
    <div v-if="allResults.length > 0" class="overlay"></div>
    <div v-if="allResults.length > 0" ref="searchResults" class="results-wrapper results-wrapper--mobile">
      <div v-if="categoriesResults.length" class="results-wrapper__suggestions">
        <div class="search-head">
          <h5>Categories</h5>
        </div>
        <div
          v-for="(result, index) in categoriesResults"
          :key="`${result.id}-${index}`"
          v-focus="getIndex(result) === focused"
          :tabindex="getIndex(result)"
          class="results-wrapper__results results-wrapper__results--cats"
          @keydown.down.prevent="moveDown"
          @keydown.up.prevent="moveUp"
          @keydown.right.prevent="moveDown"
          @keydown.left.prevent="moveUp"
          @keydown.tab.prevent="moveDown"
          @keydown.enter="submitEnter(getIndex(result))"
          @focus="focused = getIndex(result)"
          @blur="focused = null"
        >
          <a :href="`${$config.baseURL}/${result.url}`" class="results-wrapper-item" target="_self">
            <div v-if="result.level == 2" class="results-wrapper-item__content results-wrapper-item__content--category">
              <!-- eslint-disable-next-line vue/no-v-html -->
              <span class="search-categories__suggest-term" v-html="result['@search.text']"> </span>
            </div>
            <div v-if="result.level == 3" class="results-wrapper-item__content results-wrapper-item__content--category">
              <!-- eslint-disable-next-line vue/no-v-html -->
              <span class="search-categories__suggest-term" v-html="result['@search.text']"> </span>
              <span v-if="result.level == 3"> in {{ result.category1 }}</span>
            </div>
          </a>
        </div>
      </div>
      <div v-if="productResults.length" class="results-wrapper__suggestions">
        <div class="search-head">
          <h5>Magazines</h5>
        </div>
        <div
          v-for="(result, index) in productResults"
          :key="`${result.id}-${index}`"
          v-focus="getIndex(result) === focused"
          :tabindex="getIndex(result)"
          class="results-wrapper__results"
          @keydown.down.prevent="moveDown"
          @keydown.up.prevent="moveUp"
          @keydown.right.prevent="moveDown"
          @keydown.left.prevent="moveUp"
          @keydown.tab.prevent="moveDown"
          @keydown.enter="submitEnter(getIndex(result))"
          @focus="focused = getIndex(result)"
          @blur="focused = null"
        >
          <a :href="linkURL(result)" class="results-wrapper-item" target="_self">
            <div class="results-wrapper-item__img shadow5">
              <BImageLazyImgix
                :image-url="getImageURL(result)"
                :title="`${result.title} Magazine Subscription`"
                :alt="`${result.title} Magazine Subscription`"
                :query-string="imgixQueryStringValue"
                :width="`70`"
                :height="`95`"
              />
            </div>
            <div class="results-wrapper-item__content">
              <p>
                <b>{{ result.title }}</b>
              </p>
              <!-- eslint-disable-next-line vue/no-v-html -->
              <p class="results-wrapper-item__content--small" v-html="$options.filters.truncate(result.desc)"></p>
            </div>
          </a>
        </div>
      </div>
      <div class="results-wrapper__link" @focus="100" @click="redirectToSearch">
        <div class="mdl-button">All search results ></div>
      </div>
    </div>
  </div>
</template>

<script>
import xss from 'xss'
import search from '@/mixins/search'

export default {
  name: 'MobileSearch',
  filters: {
    truncate(value) {
      if (!value) {
        return ''
      }
      return xss(value.substring(0, 135)) + '...'
    }
  },
  components: {
    BImageLazyImgix: () => import('~/components/common/BImageLazyImgix')
  },
  serverCacheKey: props => `MobileSearch`,
  mixins: [search],
  data() {
    return {
      term: '',
      imgixQueryStringValue: '?fm=png&auto=format&fit=fill&fill=solid&bg=0FFF&w=210'
    }
  }
}
</script>

<style lang="scss" scoped>
.searchbox {
  content-visibility: auto;
  display: none;
}

@media (max-width: $screen-xs-max) {
  .searchbox__generic {
    content-visibility: auto;
    position: relative;
    display: contents;
    flex-flow: column nowrap;
    justify-content: flex-start;
    background: white;
    width: 100%;
    float: none;
    .search-wrapper {
      content-visibility: auto;
      position: relative;
      align-self: center;
      display: flex;
      flex-flow: row nowrap;
      height: 50px;
      width: 100%;
      font-size: 18px;
      justify-content: space-around;
      .fa-stack {
        width: 1.6rem;
        top: -5px;
      }
      &__input {
        font-size: 16px;
        border-radius: 0 !important;
        border: 1px solid #ffffff;
        border-bottom: 1px solid #a9a9a9;
        flex: 0 1 100%;
        margin: 0;
        line-height: 35px;
        padding: 0;
        min-height: 48px;
        background: white;
        text-indent: 10px;
        &::-ms-clear {
          margin-right: 20px;
        }
      }
      &__button {
        border-radius: 0 !important;
        margin: 0;
        border: none;
        flex: 1 1 40px;
        min-height: 40px;
        background: red;
        color: white;
        padding: 12px;
        .material-icons {
          margin: 0px;
        }
        .searchicon {
          padding: 0;
          margin: 0;
          font-size: 32px;
        }
      }
    }
    .overlay {
      top: 109px;
      position: absolute;
      background-color: black;
      opacity: 0.5;
      width: 100%;
      height: 2000px;
      min-height: 100%;
      z-index: 190;
    }
    .results-wrapper {
      top: 109px;
      position: absolute;
      border-bottom: 1px solid #ccc;
      z-index: 200;
      background: white;
      align-self: center;

      &--mobile {
        padding: 5px;
        overflow-x: hidden;
        overflow-y: auto;
      }
      &__results {
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        // min-height: 50px;
        width: 100%;
      }

      &__results--cats {
        max-height: 50px;
      }

      &__link {
        padding: 10px;
        margin-left: 10px;
        margin-right: 10px;
        text-align: center;
        min-height: 30px;
        // width: 100%;

        color: $searchFontColor;
        vertical-align: middle;

        &:hover {
          text-decoration: none;
          cursor: pointer;
          color: $searchFontColorHv;
        }
      }
    }

    .results-wrapper-item {
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-start;
      background: white;
      margin: 0px;
      padding: 5px;
      vertical-align: middle;
      color: $searchFontColor;
      border: 2px solid white;

      &:hover {
        text-decoration: none;
        border: 2px solid $inputSearchBorder;
      }

      &:visited {
        text-decoration: none;
      }

      &__img {
        //flex: 0 1 20%;
        align-self: center;
        margin: 5px 10px 5px 5px;
        min-height: 50px;
        img {
          border-radius: 5px !important;
        }
      }

      &__content {
        align-self: center;
        flex: 0 1 80%;
        min-height: 50px;
        &--small {
          font-size: 12px;
          //line-height: 10px;
          margin-bottom: 5px;
        }
        &--category {
          flex: 0 1 100%;
        }
      }
    }
  }
}
</style>
