<script>
import _find from 'lodash/find'
import { getGTMEcommerceActionPayload, getGTMViewItemPayload, getGTMRemarketingPayload, getGTMViewProductDetailsPayload, getGTMPageViewDetailsPayload } from '~/lib/util/gtmUAEE'
import { getGA4EcommerceNullPayload, getGA4EcommerceBasketActionPayload, getGA4ViewItemPayload } from '~/lib/util/gtmGA4'
import { sendSSAPIPageView, sendSSAPIViewContent, createFBContentCategory, sendSSAPIAddToCart, createUniqueFBEventId } from '~/lib/util/fb'

export default {
  methods: {
    pushPageView() {
      // Facebook Event Deduplication
      const eventId = createUniqueFBEventId('PageView')

      // Customer email address
      const email = this.$store.getters['s/email']

      // UAEE tracking PageView
      this.$config.UAEESyntax &&
        this.$gtm &&
        this.$gtm.push(getGTMPageViewDetailsPayload({ pageUrl: this.$route.path, pageTitle: this.dataUI.metaTitle, routeName: this.$route.name, eventId, email }))

      // Facebook Pixel ViewCategory Tracking
      this.$fb &&
        this.$fb.track('ViewCategory', {
          content_name: this.dataUI.name,
          content_category: this.dataUI.name,
          content_type: 'product_group',
          content_ids: this.productsForFB
        })

      // FB v12.0
      const eventSourceUrl = `${this.$config.baseURL}${this.$route.fullPath}`

      // Facebook Server-side API PageView
      sendSSAPIPageView({ context: this, parameters: { eventId, eventSourceUrl } })
    },

    pushGTMAndFBViewItemEvent() {
      // Facebook Event Deduplication
      const eventId = createUniqueFBEventId('PageView')
      const eventIdViewContent = createUniqueFBEventId('ViewContent')
      const eventIdProductDetail = createUniqueFBEventId('productDetail')

      // Customer email address
      const email = this.$store.getters['s/email']

      // UAEE tracking PageView
      this.$config.UAEESyntax &&
        this.$gtm &&
        this.$gtm.push(getGTMPageViewDetailsPayload({ pageUrl: this.$route.path, pageTitle: this.dataUI.title, routeName: this.$route.name, eventId, email }))

      // UAEE productView with the cheapest offer
      let data = getGTMViewItemPayload(this.defaultOffer())
      const products = getGTMViewProductDetailsPayload({ magazine: this.dataUI.magazine, offers: this.dataUI.offers })
      this.$config.UAEESyntax &&
        data &&
        this.$gtm &&
        this.$gtm.push({
          event: 'productView',
          ...data,
          ...products
        })

      // UAEE Measuring Views of Product Details
      // link https://developers.google.com/tag-manager/enhanced-ecommerce#details
      this.$config.UAEESyntax &&
        products &&
        this.$gtm &&
        this.$gtm.push({
          event: 'productDetail',
          ecommerce: {
            detail: {
              ...products
            }
          },
          eventID: eventIdProductDetail,
          eventID4ViewContent: eventIdViewContent
        })

      // GA4 Fire view_item event
      this.$config.GA4Syntax && this.$config.GA4FireEcommerceNull && this.$gtm && this.$gtm.push(getGA4EcommerceNullPayload())
      this.$config.GA4Syntax && this.$gtm && this.$gtm.push(getGA4ViewItemPayload({ magazine: this.dataUI.magazine, offers: this.dataUI.offers }))

      // GTM fire remaketing tag with the cheapest offer
      // Based on https://magento.stackexchange.com/questions/82965/how-to-put-the-code-google-remarketing
      data = getGTMRemarketingPayload(this.defaultOffer())
      data &&
        this.$gtm &&
        this.$gtm.push({
          event: 'fireRemarketingTag',
          ...data
        })

      // FB v12.0
      const eventSourceUrl = `${this.$config.baseURL}${this.$route.fullPath}`

      // Facebook Server-side API PageView
      sendSSAPIPageView({ context: this, parameters: { eventId, eventSourceUrl } })

      // Facebook Server-side API ViewContent
      console.log('MAG=', this.dataUI.magazine)
      const content = {
        name,
        category: createFBContentCategory({ magazine: this.dataUI.magazine }),
        ids: this.offersForFB(),
        idsAndPrice: this.offersForFBSSAPI()
      }
      sendSSAPIViewContent({ context: this, content, parameters: { eventId: eventIdViewContent, eventSourceUrl } })
    },

    pushGTMAndFBAfterButtonClick({ action, item, isDefaultOffer }) {
      // Facebook Event Deduplication
      const eventId = createUniqueFBEventId('AddToCart')

      // Customer email address
      const email = this.$store.getters['s/email']

      // UAEES event for each Basket Button's Click
      this.$config.UAEESyntax && this.$gtm && this.$gtm.push({ event: 'addtobasket' })
      this.$config.UAEESyntax && this.$gtm && this.$gtm.push({ event: 'lightboxcheckout' })
      switch (this.type) {
        case 'upsell':
          this.$config.UAEESyntax && this.$gtm && this.$gtm.push({ event: 'purchaseUpsell' })
          break
        case 'bestsellers':
          this.$config.UAEESyntax && this.$gtm && this.$gtm.push({ event: 'bestsellers' })
          break
        case 'search':
          this.$config.UAEESyntax && this.$gtm && this.$gtm.push({ event: 'search' })
          break
        case 'clp':
          this.$config.UAEESyntax && this.$gtm && this.$gtm.push({ event: 'clp' })
          break
        case 'viewall':
          this.$config.UAEESyntax && this.$gtm && this.$gtm.push({ event: 'search' })
          break
        default:
          break
      }

      this.$config.UAEESyntax && isDefaultOffer && this.$gtm && this.$gtm.push({ event: 'addToCartMobileTopOffer' })
      this.$config.UAEESyntax && this.$gtm && this.$gtm.push(getGTMEcommerceActionPayload({ action, item, eventId, email }))

      // GA4 events for each Basket Button's Click
      this.$config.GA4Syntax && isDefaultOffer && this.$gtm && this.$gtm.push({ event: 'add_to_cart_mobile_topoffer' })
      this.$config.GA4Syntax && this.$config.GA4FireEcommerceNull && this.$gtm && this.$gtm.push(getGA4EcommerceNullPayload())
      this.$config.GA4Syntax && this.$gtm && this.$gtm.push(getGA4EcommerceBasketActionPayload({ action, item, eventId, email }))

      // FB v12.0
      const eventSourceUrl = `${this.$config.baseURL}${this.$route.fullPath}`

      // Facebook Server-side API AddToCart
      sendSSAPIAddToCart({ context: this, item, parameters: { eventId, eventSourceUrl } })
    },

    offersForFB() {
      if (!this.dataUI.offers) {
        return []
      }

      const print = (this.dataUI?.offers?.PRINT && this.dataUI?.offers?.PRINT.map(prod => prod.sku + '')) || []
      const international = (this.dataUI?.offers?.INTERNATIONAL && this.dataUI?.offers?.INTERNATIONAL.map(prod => prod.sku + '')) || []
      const singleIssues = (this.dataUI?.offers?.SINGLE_ISSUES && this.dataUI?.offers?.SINGLE_ISSUES.map(prod => prod.sku + '')) || []

      if (print || international || singleIssues) {
        return [...print, ...international, ...singleIssues]
      } else {
        return []
      }
    },
    offersForFBSSAPI() {
      if (!this.dataUI?.offers) {
        return []
      }

      const print =
        (this.dataUI?.offers?.PRINT &&
          this.dataUI?.offers?.PRINT.map(prod => {
            return { sku: prod.sku + '', price: prod.price }
          })) ||
        []
      const international =
        (this.dataUI?.offers?.INTERNATIONAL &&
          this.dataUI?.offers?.INTERNATIONAL.map(prod => {
            return { sku: prod.sku + '', price: prod.price }
          })) ||
        []
      const singleIssues =
        (this.dataUI?.offers?.SINGLE_ISSUES &&
          this.dataUI?.offers?.SINGLE_ISSUES.map(prod => {
            return { sku: prod.sku + '', price: prod.price }
          })) ||
        []

      if (print || international || singleIssues) {
        return [...print, ...international, ...singleIssues]
      } else {
        return []
      }
    },
    defaultOffer() {
      if (!this.dataUI.offers) {
        return {}
      }

      let df =
        this.dataUI.offers.PRINT &&
        _find(this.dataUI.offers.PRINT, i => {
          return i.productId === this.dataUI.magazine.cheapestOfferId
        })

      if (!df) {
        df =
          this.dataUI.offers.INTERNATIONAL &&
          _find(this.dataUI.offers.INTERNATIONAL, i => {
            return i.productId === this.dataUI.magazine.cheapestOfferId
          })
      }
      if (!df) {
        df =
          this.dataUI.offers.SINGLE_ISSUES &&
          _find(this.dataUI.offers.SINGLE_ISSUES, i => {
            return i.productId === this.dataUI.magazine.cheapestOfferId
          })
      }
      return df
    }
  }
}
</script>
