<script>
import pocketmagsplus from '~/mixins/pocketmagsplus.vue'

export default {
  mixins: [pocketmagsplus],
  asyncData({ params, redirect }) {
    if (!params.id || !params.uuid) {
      redirect('/magazines/pocketmagsplus')
    }
    return {
      params,
      isUpdate: false
    }
  }
}
</script>
