<script>
import ecard from '~/mixins/ecard.vue'

export default {
  mixins: [ecard],

  asyncData({ store, route, params, query, $ua }) {
    const path = '/ecard'
    console.log(`fetch eCard path=${path}`)

    return {
      isFromMobile: $ua.isFromIphone() || $ua.isFromAndroidMobile(),
      params
    }
  }
}
</script>
