<template>
  <div class="ecard">
    <div class="giftcard-intro">
      <div class="giftcard-column giftcard-info">
        <h1>FREE eCard with every gift subscription</h1>
      </div>
    </div>
    <div class="steps">
      <ol class="giftcard-steps">
        <li>
          <div id="giftcard-step1" class="giftcard-step"></div>
          <p>First, buy a magazine gift subscription from magazine.co.uk</p>
        </li>
        <li>
          <div id="giftcard-step2" class="giftcard-step"></div>

          <p>Then create your eCard using our FREE Personalised eCard Creator</p>
        </li>
        <li>
          <div id="giftcard-step3" class="giftcard-step"></div>

          <p>When you’re happy with the design, you can either email it to the recipient...</p>
        </li>
        <li>
          <div id="giftcard-step4" class="giftcard-step"></div>

          <p>Or print your card and give to the recipient yourself on the big day</p>
        </li>
      </ol>
    </div>
    <div class="gc-buttons">
      <button id="gc-start-shopping" class="gc-start-shopping" :class="{ 'm-button-text__send--mobile': isFromMobile }" alt="Add To Basket" @click="redirectToHomepage()">
        Start Shopping
      </button>
    </div>
  </div>
</template>

<script>
import { getBreadcrumbsMicrodataJSONLD } from '~/lib/util/microdata'

export default {
  layout: '2Columns',
  fetch() {
    // Breadcrumbs
    const breadcrumbs = [
      {
        first: true,
        value: 'magazine subscriptions',
        url: '/',
        last: false
      },
      {
        first: false,
        value: 'ECard',
        url: '/magazines/free-ecard',
        last: true
      }
    ]
    this.$store.commit('ADD_BREADCRUMBS', { path: '/magazines/ecard', breadcrumbs })
  },
  methods: {
    redirectToHomepage() {
      window.location.href = this.$config.baseURL
    }
  },
  // To check more options, link https://github.com/nuxt/vue-meta#script-object
  // To chcek how it is working https://nuxtjs.org/faq/duplicated-meta-tags/
  head() {
    return {
      title: this.dataUI?.title || 'Free eCard Generator | magazine.co.uk',
      link: [{ rel: 'canonical', href: 'https://www.magazine.co.uk/free-ecard' }],
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: 'Free eCard Generator | magazine.co.uk'
        },
        {
          name: 'keywords',
          content: ''
        },
        {
          name: 'robots',
          content: this.$config.robots || 'INDEX,FOLLOW'
        },
        {
          hid: 'og:title',
          name: 'og:title',
          property: 'og:title',
          content: ''
        },
        {
          hid: 'og:description',
          name: 'og:description',
          property: 'og:description',
          content: ''
        },
        {
          hid: 'og:site_name',
          name: 'og:site_name',
          property: 'og:site_name',
          content: 'magazine.co.uk'
        },
        {
          hid: 'og:image',
          name: 'og:image',
          property: 'og:image',
          // TODO
          content: 'https://magazine.imgix.net/gift-card-images/JMAG_Giftcards.png?auto=format'
        }
      ]
    }
  },

  jsonld() {
    return [getBreadcrumbsMicrodataJSONLD({ breadcrumbs: this.$store.getters['UI/breadcrumbs'] })]
  }
}
</script>

<style lang="scss" scoped>
.giftcard-column {
  display: inline;
}

.giftcard-intro {
}

.giftcard-info {
  h1 {
    font-size: 27px;
  }
  p {
    font-size: 15px;
  }
  width: 100%;
}
.giftcard-steps {
  overflow: hidden;
  list-style: none;
  padding: 0;
  margin: 0 0 40px 0;
  li {
    float: left;
    width: 25%;
    padding: 0 10px !important;
  }
  p {
    font-size: 14px;
    margin-bottom: 2px;
    text-align: center;
  }
}
.giftcard-step {
  margin: 0 auto;
  background: url('~assets/images/ecard-steps.jpeg') no-repeat left top;
  display: block;
  height: 117px;
  width: 95px;
}
.giftcard-column {
}
#giftcard-step2 {
  margin: 0 auto;
  background-position: 0 -105px;
}
#giftcard-step3 {
  margin: 0 auto;
  background-position: 0 -210px;
}
#giftcard-step4 {
  margin: 0 auto;
  background-position: 0 -315px;
}
.gc-buttons {
  margin-top: 35px;
  width: 100%;
  display: flex;
  justify-content: center;
}
.gc-start-shopping {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  text-transform: uppercase;
  background: #ff0d00;
  border: 1px solid #ff0d00;
  border-radius: 6px;
  color: white;
  width: 200px;
  height: 35px;
}

@media (max-width: 1005px) {
  .container {
    width: 100% !important;
  }
  .giftcard-info {
    margin: 15px 0 0 0;
    h1 {
      font-size: 24px;
    }
    p {
      font-size: 15px;
    }
    width: 100%;
  }

  .giftcard-column {
    float: left;
    width: 100%;
    padding: 0;
    // margin-bottom: 55px;
    clear: both;
    h1 {
      text-align: center;
    }
  }

  .giftcard-steps {
    margin: 0 auto;
    clear: both;
    padding: 0;
    text-align: center;
    li {
      display: inline-flex;
      max-height: 80px;
      .giftcard-step {
        transform: scale(0.7);
        display: inline-block;
        vertical-align: middle;
        max-height: 100px;
        align-self: center;
      }

      p {
        align-self: center;
        flex: 1 1 75%;
        display: inline-block;
        text-align: initial;
      }
      margin: 0 auto;
      clear: both;
      width: 100%;
      padding: 10px;
      text-align: center;
    }
  }
  .gc-start-shopping {
    width: 95%;
  }
  .gc-start-shopping {
    height: 40px;
  }
}
</style>
