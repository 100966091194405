<template>
  <section class="content-cms">
    <div class="content-cms__main cms">
      <!-- <client-only v-if="$store.getters['UI/debug']">
        <ul class="debug__list">
          <li class="debug__item">
            <tree-view :data="queryString" :options="{ rootObjectKey: 'QueryString', maxDepth: 0, link: true }"> </tree-view>
          </li>
          <li class="debug__item">
            <tree-view :data="query" :options="{ rootObjectKey: 'Filter Query', maxDepth: 0, link: true }"> </tree-view>
          </li>
          <li class="debug__item">
            <tree-view :data="products" :options="{ rootObjectKey: 'Products', maxDepth: 0, link: true }"> </tree-view>
          </li>
        </ul>
      </client-only> -->
      <h1 class="view-all--title">Magazines A-Z</h1>
      <LazyHydrate when-visible>
        <div class="view-all--filters-az">
          <div v-for="(filter, index) in filters" :key="index" class="view-all--filters-az-cell">
            <a
              :class="{ activeViewAll: activeFilter == filter.key }"
              :title="filter.key.toUpperCase()"
              :href="`/magazines/view-all?t=${filter.key}`"
              class="view-all--filters-az-item"
            >
              {{ filter.key === '0' ? '#' : filter.key.toUpperCase() }}
            </a>
          </div>
        </div>
      </LazyHydrate>
      <div v-if="products" class="view-all--top">
        <div class="view-all--filters m-md-2">
          <div class="view-all__count">
            <strong>{{ count }} Titles</strong>
          </div>
        </div>
        <div class="view-all-sort">
          <b-dropdown right variant="link" class="m-md-2" toggle-class="text-decoration-none" no-caret>
            <template #button-content> Sort: {{ sortLabel }}&nbsp;&nbsp;<font-awesome-icon :icon="['fal', 'chevron-down']" class="fa" /> </template>
            <b-dropdown-item :href="`${getSearchURL()}&name=asc`" :active="sortLabel == 'Name (A to Z)'" aria-describedby="Name (A to Z)">Name (A to Z)</b-dropdown-item>
            <b-dropdown-item :href="`${getSearchURL()}&name=desc`" :active="sortLabel == 'Name (Z to A)'" aria-describedby="Name (Z to A)">Name (Z to A)</b-dropdown-item>
            <b-dropdown-item :href="`${getSearchURL()}&price=asc`" :active="sortLabel == 'Price (Low to High)'" aria-describedby="Price (Low to High)">
              Price (Low to High)
            </b-dropdown-item>
            <b-dropdown-item :href="`${getSearchURL()}&price=desc`" :active="sortLabel == 'Price (High to Low)'" aria-describedby="Price (High to Low)">
              Price (High to Low)
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </div>

      <LazyHydrate when-visible>
        <ViewAllProductsList v-if="products && products.length" :values="products" />
      </LazyHydrate>

      <div v-if="!products" class="view-all__no-results">
        No results for this option
      </div>
    </div>
  </section>
</template>

<script>
import LazyHydrate from 'vue-lazy-hydration'
import { mapState, mapGetters } from 'vuex'
import { azureViewAllSearch, createAzureSearchQuery, getOrderbyAzureSearchOption } from '~/lib/util/azuresearch'
import { sendSSAPIPageView, createUniqueFBEventId } from '~/lib/util/fb'
import { getGTMPageViewDetailsPayload } from '~/lib/util/gtmUAEE'

import offlineData from '~/offlineData/view-all.js'

export default {
  name: 'ViewAll',
  serverCacheKey() {
    return false
  },
  components: {
    LazyHydrate,
    ViewAllProductsList: () => import('~/components/ViewAll/ViewAllProductsList.vue')
  },
  layout: 'default',
  async fetch() {
    // Look for an available Query String and retrieve the URL path
    const path = this.$route.path
    const query = this.$nuxt.context.query
    const useCache = this.$store.getters['UI/useCache']
    console.log('page=ViewAll')
    console.log('path=', path)
    console.log('query=', query)
    console.log('QS useCache=', useCache)

    // Breadcrumbs
    const page = {
      value: this.title,
      type: this.$route.path,
      last: true
    }
    this.$store.commit('ADD_BREADCRUMBS', { path, page })

    this.queryString = query

    // Get the orderby search option
    const orderby = getOrderbyAzureSearchOption({ query, useFirstDigit: true })
    if (query.t === undefined) {
      this.$store.commit('search/setProducts', [])
      this.$store.commit('search/setActiveFilter', 'a')
      query.t = 'a'
      this.$store.commit('search/setQuery', createAzureSearchQuery({ query: { t: 'a', name: 'asc' } }))
    } else {
      this.$store.commit('search/setActiveFilter', query.t)
      this.$store.commit('search/setQuery', createAzureSearchQuery({ query }))
    }

    this.$config.debug && console.log('this.$config.UseOfflineData=', this.$config.UseOfflineData)
    this.$config.debug && console.log('process.env.USE_OFFLINE_DATA=', process.env.USE_OFFLINE_DATA)

    if (this.$config.UseOfflineData) {
      // Use OFFILE data ingegration
      const pageData = await offlineData
      this.$store.commit('search/setProducts', pageData.value)
      this.$store.commit('search/setCount', pageData['@odata.count'])
      console.log('OFFLINE view-all=', pageData)
      console.log(`COUNT=${pageData['@odata.count']}`)
    } else {
      await azureViewAllSearch(query && query.t.trim(), orderby, (error, data) => {
        if (data) {
          this.$store.commit('search/setProducts', data.value)
          this.$store.commit('search/setCount', data['@odata.count'])
          console.log(data)
          console.log(`COUNT=${data['@odata.count']}`)
        } else if (error) {
          console.log(error)
          this.$store.commit('search/setProducts', [])
        }
      })
    }
  },

  fetchOnServer: true,
  // multiple components can return the same `fetchKey` and Nuxt will track them both separately
  // fetchKey: 'viewall',
  // Set the minimum executing time in milliseconds (to avoid quick flashes)
  // fetchDelay: 5000,

  data() {
    return {
      title: 'A_Z Page',
      canonicalURL: 'https://www.magazine.co.uk/magazines/view-all',
      metaDescription: `View all the magazine subscriptions available on magazine.co.uk with our handy A-Z finder. (Can you also update the meta title for this page to be - View All Magazine Subscriptions (A-Z) | magazine.co.uk`,
      metaKeywords: 'Magazine.co.uk',
      metaTitle: 'A_Z Page',
      filters: [
        { key: '0' },
        { key: 'a' },
        { key: 'b' },
        { key: 'c' },
        { key: 'd' },
        { key: 'e' },
        { key: 'f' },
        { key: 'g' },
        { key: 'h' },
        { key: 'i' },
        { key: 'j' },
        { key: 'k' },
        { key: 'l' },
        { key: 'm' },
        { key: 'n' },
        { key: 'o' },
        { key: 'p' },
        { key: 'q' },
        { key: 'r' },
        { key: 's' },
        { key: 't' },
        { key: 'u' },
        { key: 'v' },
        { key: 'w' },
        { key: 'x' },
        { key: 'y' },
        { key: 'z' }
      ],
      queryString: [],
      dataUI: []
    }
  },

  computed: {
    sortLabel() {
      if (this.query.price === false && this.query.name === 'asc') {
        return 'Name (A to Z)'
      }
      if (this.query.price === false && this.query.name === 'desc') {
        return 'Name (Z to A)'
      }
      if (this.query.name === false && this.query.price === 'asc') {
        return 'Price (Low to High)'
      }
      if (this.query.name === false && this.query.price === 'desc') {
        return 'Price (High to Low)'
      }
      return 'Name (A to Z)'
    },

    ...mapState('search', ['products', 'query', 'count', 'activeFilter']),
    ...mapGetters({
      products: 'search/products',
      query: 'search/query',
      count: 'search/count',
      activeFilter: 'search/activeFilter'
    })
  },

  watchQuery: ['t', 'price', 'name'],

  mounted() {
    // Facebook Event Deduplication
    const eventId = createUniqueFBEventId('PageView')

    // Customer email address
    const email = this.$store.getters['s/email']

    // UAEE tracking PageView
    this.$config.UAEESyntax &&
      this.$gtm &&
      this.$gtm.push(
        getGTMPageViewDetailsPayload({ pageUrl: this.$route.path, pageTitle: (this.page && this.page.title) || this.title, routeName: this.$route.name, eventId, email })
      )

    // FB v12.0
    const eventSourceUrl = `${this.$config.baseURL}${this.$route.fullPath}`

    // Facebook Server-side API PageView
    sendSSAPIPageView({ context: this, parameters: { eventId, eventSourceUrl } })
  },
  created() {},

  methods: {
    getSearchURL() {
      if (this.$route.query && this.$route.query.t === undefined) {
        return `${this.$config.baseURL}${this.$route.path}?t=a`
      }

      return `${this.$config.baseURL}${this.$route.path}?t=${this.$route.query.t}`
    }
  },

  head() {
    return {
      title: (this.page && this.page.title) || this.title,
      link: [{ rel: 'canonical', href: this.canonicalURL }],
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.metaDescription || ''
        },
        {
          name: 'keywords',
          content: this.metaKeywords || ''
        },
        {
          name: 'robots',
          content: this.$config.robots || 'INDEX,FOLLOW'
        },
        {
          hid: 'og:title',
          name: 'og:title',
          property: 'og:title',
          content: this?.metaTitle || ''
        },
        {
          hid: 'og:description',
          name: 'og:description',
          property: 'og:description',
          content: this?.metaDescription || ''
        },
        {
          hid: 'og:site_name',
          name: 'og:site_name',
          property: 'og:site_name',
          content: 'magazine.co.uk'
        },
        {
          hid: 'og:image',
          name: 'og:image',
          property: 'og:image',
          content: 'https://www.magazine.co.uk/icon.png'
        }
      ]
    }
  }
}
</script>

<style lang="scss">
a.product__text {
  div.product__offer {
    display: block;
  }
}
.content-cms {
  margin: 0 auto;
  flex-flow: row nowrap;
  display: flex;
  width: 100%;
  justify-content: space-around;
  max-width: 1230px !important;

  &__main {
    flex: 1 1 100%;
    margin: 0 10px;
    padding: 10px;
  }

  .cms {
    padding: 10px 15px;
    &__title h1 {
      font-size: 28px;
      margin: 0 0 15px;
      font-weight: 400;
      color: #ff0d00;
    }
    &__content p {
      font-size: 14px;
      margin: 0 0 10px;
      line-height: 20px;
      font-size: 14px;
      color: #333;
    }
    &__content ul {
      font-size: 14px;
      line-height: 1.42857;
      color: #333;
      display: block;
      list-style-type: disc;
      margin-block-start: 1em;
      margin-block-end: 1em;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
      padding: 0 40px;
    }
  }
}
.view-all__no-results {
  display: block;
  text-align: center;
  font-size: 22px;
  text-align: center;
  margin: 20px;
}
.view-all__list {
  .items-list__item .actions {
    a {
      box-shadow: 0 0;
      background: #00933e;
      font-weight: 600;
      display: inline-block;
      vertical-align: middle;
      transition-property: all;
      transition-duration: 0.1s;
      transition-timing-function: linear;
      border-radius: 3px;
      border: none;
      font-size: 12px;
      padding: 5px;
      text-transform: uppercase;
      text-decoration: none;
      text-align: center;
      width: 100%;
      margin-top: 10px;
      color: #fff;
      cursor: pointer;
      &:hover {
        opacity: 0.7;
      }
    }
    a.add-to-basket {
      display: none;
    }
  }
}

.search-list {
  .items-list__item {
    flex: 0 0 23.5%;
  }
}

@media (max-width: $screen-md-min) {
  .content-cms__right {
    display: none;
  }
}

@media (max-width: $screen-sm-min) {
  .content-cms {
    &__main {
      flex: 0 1 100%;
      margin: 0;
      padding: 0px;
    }
    &__description {
      h1 {
        line-height: 1.2em;
      }
    }
  }
  .search-list {
    .items-list__item {
      flex: 0 0 24.5%;
    }
  }
}

@media (max-width: $screen-xs-max) {
  .content-cms {
    &__main {
      flex: 0 1 100%;
      margin: 0;
      padding: 0px;
    }
    &__description {
      h1 {
        line-height: 1.2em;
      }
    }
  }
  .search-list {
    .items-list__item {
      flex: 0 0 45%;
    }
  }
}

a.activeViewAll {
  color: currentColor;
  display: flex; /* For IE11/ MS Edge bug */
  pointer-events: none;
  text-decoration: none;

  background-color: #6c757d;
  color: #fff;
  &:hover,
  &:visited,
  &:active,
  &:focus {
    text-decoration: none;
    background-color: #6c757d;
    color: #fff;
  }
}

.view-all {
  &--title {
    margin: 1em 0;
    text-align: center;
    font-size: 28px;
  }
  &--top {
    display: flex;
    justify-content: space-between;
    margin: 10px 20px 10px 20px;
    align-items: center;
  }
  &--filters-az {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0;
    &-cell {
      flex: 0 0 34px;
      display: flex;
      justify-content: center;
      align-items: stretch;
      margin: 5px;
      box-sizing: border-box;
      &:before {
        content: '';
        display: table;
        padding-top: 100%;
      }
    }
    &-item {
      flex-grow: 1;
      border: 1px solid $navLinkColor;
      color: $navLinkColor;

      display: flex;
      justify-content: center;
      align-items: center;

      &:hover,
      &:visited,
      &:active,
      &:focus {
        text-decoration: none;
        &:not(.activeViewAll) {
          background-color: #ddd;
          color: #000;
        }
      }
    }
  }
}
</style>
