<template>
  <section>
    <p class="whatsinside__heading">What's inside {{ title }} Magazine?</p>
    <div class="whatsinside__container">
      <div v-for="(column, index) in columnsEnabled" :key="`what-s-inside::${index}`" class="whatsinside__item">
        <div class="whatsinside__item-image-container"><img class="whatsinside__item-image" :src="createImgixURL(column.image_path)" width="250px" loading="lazy" /></div>
        <p class="whatsinside__item-title">{{ column.title }}</p>
        <p class="whatsinside__item-description">{{ column.description }}</p>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: 'PDPWhatSInside',
  serverCacheKey: props => `PDPWhatSInside-${props.sku}`,
  props: {
    payload: {
      type: Array,
      required: true
    },
    sku: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      qs: '?auto=forma&w=250',
      columnsEnabled: this?.payload?.filter(el => el.enabled === true)
    }
  },
  methods: {
    createImgixURL(imagePath) {
      return `${this.$config.imgixURL}${imagePath}${this.qs}`
    }
  }
}
</script>
<style lang="scss" scoped>
.whatsinside {
  &__heading {
    margin: 0 0 10px;
    // letter-spacing: 0.1em;
    font-weight: 700;
    padding: 10px;
    font-size: 16px;
    text-align: center;
  }
  &__container {
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    max-width: 780px;
  }
  &__item-image-container {
    display: flex;
    justify-content: center;
  }
  &__item {
    flex: 0 0 100%;
    margin-bottom: 10px;
    border-bottom: 1px solid #dee2e6;
  }
  &__item:last-child {
    border: unset;
  }
  &__item-image {
    max-width: 100%;
    margin-bottom: 20px;
    padding-top: 10px;
  }
  &__item-title {
    font-size: 14px;
    font-weight: 600;
    padding: 0 10px;
    margin-bottom: 5px;
  }
  &__item-description {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 0rem;
    padding: 0 10px 10px 10px;
  }

  @media (min-width: 768px) {
    &__container {
      flex-flow: row;
    }
    &__item {
      margin-bottom: 30px;
      flex: 0 0 32%;
      max-width: 250px;
      border-radius: 0.25rem;
      border: 1px solid #dee2e6;
    }
    &__item:last-child {
      border: 1px solid #dee2e6;
    }
  }
}
</style>
