<template>
  <div class="grid-filters" style="content-visibility: auto;">
    <div class="grid-filters__cell">
      <a href="/magazines/view-all?t=0" class="grid-filters__item">#</a>
    </div>
    <div class="grid-filters__cell">
      <a href="/magazines/view-all?t=a" class="grid-filters__item">A</a>
    </div>
    <div class="grid-filters__cell">
      <a href="/magazines/view-all?t=b" class="grid-filters__item">B</a>
    </div>
    <div class="grid-filters__cell">
      <a href="/magazines/view-all?t=c" class="grid-filters__item">C</a>
    </div>
    <div class="grid-filters__cell">
      <a href="/magazines/view-all?t=d" class="grid-filters__item">D</a>
    </div>
    <div class="grid-filters__cell">
      <a href="/magazines/view-all?t=r" class="grid-filters__item">E</a>
    </div>
    <div class="grid-filters__cell">
      <a href="/magazines/view-all?t=f" class="grid-filters__item">F</a>
    </div>
    <div class="grid-filters__cell">
      <a href="/magazines/view-all?t=g" class="grid-filters__item">G</a>
    </div>
    <div class="grid-filters__cell">
      <a href="/magazines/view-all?t=h" class="grid-filters__item">H</a>
    </div>
    <div class="grid-filters__cell">
      <a href="/magazines/view-all?t=i" class="grid-filters__item">I</a>
    </div>
    <div class="grid-filters__cell">
      <a href="/magazines/view-all?t=j" class="grid-filters__item">J</a>
    </div>
    <div class="grid-filters__cell">
      <a href="/magazines/view-all?t=k" class="grid-filters__item">K</a>
    </div>
    <div class="grid-filters__cell">
      <a href="/magazines/view-all?t=l" class="grid-filters__item">L</a>
    </div>
    <div class="grid-filters__cell">
      <a href="/magazines/view-all?t=m" class="grid-filters__item">M</a>
    </div>
    <div class="grid-filters__cell">
      <a href="/magazines/view-all?t=n" class="grid-filters__item">N</a>
    </div>
    <div class="grid-filters__cell">
      <a href="/magazines/view-all?t=o" class="grid-filters__item">O</a>
    </div>
    <div class="grid-filters__cell">
      <a href="/magazines/view-all?t=p" class="grid-filters__item">P</a>
    </div>
    <div class="grid-filters__cell">
      <a href="/magazines/view-all?t=q" class="grid-filters__item">Q</a>
    </div>
    <div class="grid-filters__cell">
      <a href="/magazines/view-all?t=r" class="grid-filters__item">R</a>
    </div>
    <div class="grid-filters__cell">
      <a href="/magazines/view-all?t=s" class="grid-filters__item">S</a>
    </div>
    <div class="grid-filters__cell">
      <a href="/magazines/view-all?t=t" class="grid-filters__item">T</a>
    </div>
    <div class="grid-filters__cell">
      <a href="/magazines/view-all?t=u" class="grid-filters__item">U</a>
    </div>
    <div class="grid-filters__cell">
      <a href="/magazines/view-all?t=v" class="grid-filters__item">V</a>
    </div>
    <div class="grid-filters__cell">
      <a href="/magazines/view-all?t=w" class="grid-filters__item">W</a>
    </div>
    <div class="grid-filters__cell">
      <a href="/magazines/view-all?t=x" class="grid-filters__item">X</a>
    </div>
    <div class="grid-filters__cell">
      <a href="/magazines/view-all?t=y" class="grid-filters__item">Y</a>
    </div>
    <div class="grid-filters__cell">
      <a href="/magazines/view-all?t=z" class="grid-filters__item">Z</a>
    </div>
    <!-- <div v-for="(filter, index) in filters" :key="index" class="grid-filters__cell">
        <a :href="`/magazines/view-all?t=${filter.key}`" class="grid-filters__item">{{ filter.key === '0' ? '#' : filter.key.toUpperCase() }}</a>
      </div> -->
  </div>
</template>

<script>
export default {
  name: 'HeaderBottomFilter',
  serverCacheKey: props => `HeaderBottomFilter`,
  data() {
    return {
      // filters: [
      //   { key: '0' },
      //   { key: 'a' },
      //   { key: 'b' },
      //   { key: 'c' },
      //   { key: 'd' },
      //   { key: 'e' },
      //   { key: 'f' },
      //   { key: 'g' },
      //   { key: 'h' },
      //   { key: 'i' },
      //   { key: 'j' },
      //   { key: 'k' },
      //   { key: 'l' },
      //   { key: 'm' },
      //   { key: 'n' },
      //   { key: 'o' },
      //   { key: 'p' },
      //   { key: 'q' },
      //   { key: 'r' },
      //   { key: 's' },
      //   { key: 't' },
      //   { key: 'u' },
      //   { key: 'v' },
      //   { key: 'w' },
      //   { key: 'x' },
      //   { key: 'y' },
      //   { key: 'z' }
      // ]
    }
  }
}
</script>

<style lang="scss">
.grid-filters {
  content-visibility: auto;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 28px 14px;
  &__cell {
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: stretch;
    padding: 0px;
    &:before {
      content: '';
      display: table;
      padding-top: 100%;
    }
    &:hover {
      background-color: #ededed;
    }
  }
  &__item {
    flex-grow: 1;
    border: 0px solid $navLinkColor;
    color: $navLinkColor;

    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      color: $navLinkColor;
    }
  }
}
.grid-filters__cell {
  flex: 0 0 28px;
}

@media (max-width: $screen-md-min) {
  .grid-filters {
    min-width: unset;
    padding: 1px;
  }
}

@media (max-width: $screen-xs-max) {
  .grid-filters {
    display: none;
  }
}
</style>
