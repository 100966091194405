<template>
  <transition name="slide-fade">
    <div v-if="showFilter" class="filter" @click.stop.prevent="handleClick">
      <div class="filter__container" @click.stop="doNothing">
        <h2 class="filter__title"><font-awesome-icon :icon="['fal', 'filter']" class="fa" />&nbsp;&nbsp;FILTER</h2>
        <div class="filter__icon" @click.stop.prevent="handleClick">
          <font-awesome-icon :icon="['fal', 'times']" class="fa fa-stack-1x right" />
        </div>
      </div>
      <section class="filter__top">
        <div class="filter__top-title" @click.stop="clearAll">Active Filters</div>
        <div class="filter__clear-all" @click.stop="clearAll">Clear All&nbsp;&nbsp;<font-awesome-icon :icon="['fal', 'times']" class="fa" /></div>
      </section>
      <section class="filter__activefacets p-2" @click.stop="doNothing">
        <b-button v-for="(f, index) in activeFacets" :key="index" squared variant="info" class="my-1 mx-2">
          <div v-if="f.type === 'category'">{{ f.name }}</div>
          <div v-else @click.stop="deleteActiveFacet(f, index)">{{ f.name }}&nbsp;&nbsp;&nbsp;<font-awesome-icon :icon="['fal', 'times']" class="fa" /></div>
        </b-button>
      </section>

      <section v-if="facets" class="filter__facets pt-0 pb-1" role="tablist" @click.stop="doNothing">
        <div v-for="(value, name, index) in facets" :key="index" class="filter__facets-group">
          <b-card v-if="value && value.length > 1 && name !== 'hasFreeGift'" no-body class="mb-1">
            <b-card-header header-tag="header" class="m-0 py-0 px-0" role="tab">
              <b-button v-b-toggle="`accordion-facets-${index + 1}`" block href="#" variant="info" class="py-2">
                <font-awesome-icon :icon="['fal', 'plus']" class="fa plus" /><font-awesome-icon :icon="['fal', 'minus']" class="fa minus" />&nbsp;&nbsp;{{ getOfferNameById(name) }}
              </b-button>
            </b-card-header>
            <b-collapse :id="`accordion-facets-${index + 1}`" visible accordion="filter-accordion" role="tabpanel">
              <b-card-body v-if="name === 'offerPrice'" class="mb-4">
                <vue-slider v-model="sliderValue" v-bind="sliderOptions" @change="change"></vue-slider>
              </b-card-body>
              <b-list-group v-else>
                <b-list-group-item
                  v-for="item in value"
                  :key="`${index}-${item.value}`"
                  :class="{ active: findActiveFacets(name) ? findActiveFacets(name).value === item.value : false }"
                  class="d-flex justify-content-between align-items-center"
                  @click.stop="refineSearch(name, item, findActiveFacets(name).value === item.value)"
                >
                  {{ getOfferValueNameyId(name, item.value) }}
                  <!-- <b-badge variant="primary" pill>{{ item.count }}</b-badge> -->
                  <font-awesome-icon v-if="findActiveFacets(name).value === item.value" :icon="['fal', 'times']" class="fa" />
                  <font-awesome-icon v-else :icon="['fal', 'check']" class="fa fa-hover" />
                </b-list-group-item>
              </b-list-group>
            </b-collapse>
          </b-card>
        </div>
      </section>
      <section class="filter__bottom pb-4" @click.stop="doNothing">
        <div class="filter__count">{{ count }} Titles</div>
      </section>
    </div>
  </transition>
</template>

<script>
import _find from 'lodash/find'
import { mapGetters, mapState } from 'vuex'
import {
  azureCategoryProductsSearch,
  createAzureSearchQuery,
  getOrderbyAzureSearchOption,
  getCategoryProductQuery,
  getCategoryProductFilter,
  createAzureSearchIntervals
} from '~/lib/util/azuresearch'

export default {
  props: {
    showFilter: {
      type: Boolean,
      required: true,
      default() {
        return false
      }
    },
    facets: {
      type: Object,
      required: true,
      default() {
        return {}
      }
    },
    count: {
      type: Number,
      requred: false,
      default() {
        return 0
      }
    }
  },
  data() {
    return {
      sliderValue: undefined,

      sliderOptions: {
        data: this.facets.offerPrice.map(({ count, value }) => value),
        tooltip: 'focus',
        marks: true,
        lazy: true,
        adsorb: true,
        contained: true,
        enableCross: false
      }
    }
  },
  computed: {
    ...mapState('category', ['categoryId', 'priceFrom', 'priceTo', 'percentageFrom', 'percentageTo', 'query', 'activeFacets']),
    ...mapGetters({
      query: 'category/query',
      categoryId: 'category/categoryId',
      priceFrom: 'category/priceFrom',
      priceTo: 'category/priceTo',
      percentageFrom: 'category/percentageFrom',
      percentageTo: 'category/percentageTo',
      activeFacets: 'category/activeFacets'
    })
  },
  mounted() {
    this.reloadActiveFacet()
  },

  methods: {
    reloadActiveFacet() {
      this.reloadPriceFacet()
      this.reloadAllOtherFacet()
    },
    reloadAllOtherFacet() {
      Object.keys(this.query).forEach(prop => {
        // console.log(`key=${prop}  value=${this.query[prop]}`)

        let strToshow = ''
        let value = this.query[prop]
        let customType = ''

        switch (prop) {
          case 'os':
            customType = 'offerSave'
            if (value === 100) {
              strToshow = `Up to 100%`
            } else if (value !== undefined) {
              strToshow = `More than ${value}%`
            }
            value = parseInt(value)
            break

          case 'ofg':
            customType = 'hasFreeGift'
            if (value === 'true') {
              strToshow = `Free Gifts`
              value = true
            } else if (value !== undefined) {
              strToshow = `No Gift`
              value = false
            }

            break

          case 'opt':
            customType = 'offerProductType'
            if (value === 'BLANK') {
              strToshow = 'Other Offer'
            } else if (value !== undefined) {
              strToshow = `${value} Offer`
            }

            break

          case 'delivery':
            console.log('Delivery VALUE=', value)
            if (value === 'UK,International') {
              strToshow = `International`
            } else if (value === 'UK') {
              strToshow = `UK`
            } else if (value === 'International') {
              strToshow = `International`
            } else {
              strToshow = ``
            }

            break
        }
        if (strToshow !== '') {
          this.$store.commit('category/addToActiveFacets', {
            type: customType,
            name: strToshow,
            value
          })
        }
      })
    },
    reloadPriceFacet() {
      const length = this.facets.offerPrice && this.facets.offerPrice.length
      if (length) {
        this.min = this.min || this.facets.offerPrice[0].value
        this.max = this.max || this.facets.offerPrice[length - 1].value
        this.sliderValue = [this.min, this.max]
      }
    },
    handleClick() {
      console.log('filter handleClick CLOSE')
      this.$nuxt.$emit('SHOW_FILTER', false)
    },
    doNothing() {
      console.log('Filter doNothing')
      // this.$nuxt.$emit('SHOW_FILTER', true)
    },
    async clearAll() {
      console.log('clearAll')
      const defaultQuery = {}
      this.$store.commit('category/setQuery', createAzureSearchQuery({ query: defaultQuery, category: { id: this.categoryId } }))
      const pageCount = 1
      // Get the orderby search option
      const orderby = this.query && getOrderbyAzureSearchOption({ query: defaultQuery })
      const azureQuery = getCategoryProductQuery(createAzureSearchQuery({ query: defaultQuery, category: { id: this.categoryId } }))
      const categoryFilter = { priceFrom: this.priceFrom, priceTo: this.priceTo, percentageFrom: this.percentageFrom, percentageTo: this.percentageTo, urlPath: this.urlPath }
      const azureFilter = getCategoryProductFilter({
        query: createAzureSearchQuery({ query: defaultQuery, category: { id: this.categoryId } }),
        categoryFilter
      })
      await azureCategoryProductsSearch({ searchTerm: azureQuery, orderby, page: pageCount, filter: azureFilter, ...createAzureSearchIntervals(categoryFilter) }, (error, data) => {
        if (data) {
          this.$store.commit('category/setProducts', data.value)
          this.$store.commit('category/setCount', data['@odata.count'])
          // Reset vue-slider to the default one
          if (this.facets.offerPrice) {
            this.reloadPriceFacet()
          }
          this.$store.commit('category/setFacets', {
            offerPrice: data['@search.facets'].offerPrice,
            offerProductType: data['@search.facets'].offerProductType,
            hasFreeGift: data['@search.facets'].hasFreeGift,
            offerSave: data['@search.facets'].offerSave,
            delivery: data['@search.facets'].delivery
          })
          this.$store.commit('category/resetActiveFacets')
        } else if (error) {
          console.log(error)
          this.$store.commit('category/setProducts', [])
        }
      })
    },
    getOfferNameById(id) {
      let value = ''
      switch (id) {
        case 'category_id':
          value = 'Categories'
          break
        case 'offerPrice':
          value = 'Price Range'
          break
        case 'offerProductType':
          value = 'Type'
          break
        case 'hasFreeGift':
          value = 'Offer'
          break
        case 'offerSave':
          value = 'Savings'
          break
        case 'delivery':
          value = 'Delivery'
          break
        default:
          break
      }
      return value
    },
    getOfferValueNameyId(id, key) {
      let value = ''
      switch (id) {
        case 'category_id':
          value = 'Categories'
          break
        case 'offerPrice':
          value = 'Price Range'
          break
        case 'offerProductType':
          if (key === 'BLANK') {
            value = 'Other Offer'
          } else if (key === '') {
            value = 'Other Offer'
          } else {
            value = `${key} Offer`
          }
          break
        case 'hasFreeGift':
          if (key === true) {
            value = 'Free Gifts'
          } else {
            value = 'No Gift'
          }
          break
        case 'offerSave':
          if (key === 100) {
            value = `Up to 100%`
          } else {
            value = `More than ${key}%`
          }
          break
        case 'delivery':
          if (key === 'UK,International') {
            value = `International`
          } else if (key === 'UK') {
            value = `UK`
          } else if (key === 'International') {
            value = `International`
          } else {
            value = `NOT AVAILABLE`
          }
          break
        default:
          break
      }
      return value
    },
    async change(offerPrice) {
      console.log(`Change offerPrice=[${offerPrice[0]},${offerPrice[1]}]`)
      const sup = createAzureSearchQuery({
        query: this.$route.query,
        filter: {
          offerPrice
        }
      })

      this.$store.commit('category/addQueryFilter', {
        type: 'op',
        value: sup.op
      })

      if (offerPrice) {
        this.sliderValue = offerPrice
      }

      const pageCount = this.query.page || 1
      // Get the orderby search option
      const orderby = this.query && getOrderbyAzureSearchOption({ query: this.query })
      const azureQuery = getCategoryProductQuery(createAzureSearchQuery({ query: this.query, category: { id: this.categoryId } }))
      const categoryFilter = { priceFrom: this.priceFrom, priceTo: this.priceTo, percentageFrom: this.percentageFrom, percentageTo: this.percentageTo }
      const azureFilter = getCategoryProductFilter({
        query: createAzureSearchQuery({ query: this.query, category: { id: this.categoryId } }),
        categoryFilter
      })

      // View All -> limit=all
      let viewAll = false
      if (this.query && this.query.limit === 'all') {
        viewAll = true
      }

      await azureCategoryProductsSearch(
        { searchTerm: azureQuery, orderby, page: pageCount, filter: azureFilter, ...createAzureSearchIntervals(categoryFilter), viewAll },
        (error, data) => {
          if (data) {
            this.$store.commit('category/setProducts', data.value)
            this.$store.commit('category/setCount', data['@odata.count'])

            let priceString = `Price £${this.sliderValue[0]} to £${this.sliderValue[1]}`
            if (this.sliderValue[0] === 0) {
              priceString = `Price up to £${this.sliderValue[1]}`
            }
            this.$store.commit('category/addToActiveFacets', {
              type: 'offerPrice',
              name: priceString
            })
            console.log(data)
            console.log(`1-COUNT=${data['@odata.count']}`)
            console.log(data['@search.facets'])
            this.$nuxt.$emit('RESET_INFINITE_LOADING', this.$store.getters['category/products'])
          } else if (error) {
            console.log(error)
            this.$store.commit('category/setProducts', [])
          }
        }
      )
    },

    async refineSearch(type, item, remove) {
      console.log(`refineSearch type=${type} value=${item.value}`)
      console.log(this.query)
      let strToshow = ''
      if (remove === true) {
        let customType
        switch (type) {
          case 'offerSave':
            customType = 'os'
            break

          case 'hasFreeGift':
            customType = 'ofg'
            break

          case 'offerProductType':
            customType = 'opt'
            break

          case 'delivery':
            customType = 'delivery'
            break
        }
        this.$store.commit('category/removeQueryFilter', {
          type: customType,
          value: item.value
        })
      } else {
        switch (type) {
          case 'offerSave':
            this.$store.commit('category/addQueryFilter', {
              type: 'os',
              value: item.value
            })

            if (item.value === 100) {
              strToshow = `Up to 100%`
            } else {
              strToshow = `More than ${item.value}%`
            }
            break

          case 'hasFreeGift':
            this.$store.commit('category/addQueryFilter', {
              type: 'ofg',
              value: item.value
            })

            if (item.value === true) {
              strToshow = `Free Gifts`
            } else {
              strToshow = `No Gift`
            }
            break

          case 'offerProductType':
            this.$store.commit('category/addQueryFilter', {
              type: 'opt',
              value: item.value
            })

            if (item.value === 'BLANK') {
              strToshow = 'Other Offer'
            } else {
              strToshow = `${item.value} Offer`
            }
            break

          case 'delivery':
            this.$store.commit('category/addQueryFilter', {
              type: 'delivery',
              value: item.value
            })

            if (item.value === 'BLANK') {
              strToshow = 'Other Offer'
            } else {
              strToshow = `${item.value} Offer`
            }

            if (item.value === 'UK,International') {
              strToshow = `International`
            } else if (item.value === 'UK') {
              strToshow = `UK`
            } else if (item.value === 'International') {
              strToshow = `International`
            } else {
              strToshow = `NOT AVAILABLE`
            }
            break
        }
      }

      const pageCount = this.query.page || 1
      console.log(this.query)
      // Get the orderby search option
      const orderby = this.query && getOrderbyAzureSearchOption({ query: this.query })
      const azureQuery = getCategoryProductQuery(createAzureSearchQuery({ query: this.query, category: { id: this.categoryId } }))
      const categoryFilter = { priceFrom: this.priceFrom, priceTo: this.priceTo, percentageFrom: this.percentageFrom, percentageTo: this.percentageTo }
      const azureFilter = getCategoryProductFilter({
        query: createAzureSearchQuery({ query: this.query, category: { id: this.categoryId } }),
        categoryFilter
      })

      // View All -> limit=all
      let viewAll = false
      if (this.query && this.query.limit === 'all') {
        viewAll = true
      }

      await azureCategoryProductsSearch(
        { searchTerm: azureQuery, orderby, page: pageCount, filter: azureFilter, ...createAzureSearchIntervals(categoryFilter), viewAll },
        (error, data) => {
          if (data) {
            this.$store.commit('category/setProducts', data.value)
            this.$store.commit('category/setCount', data['@odata.count'])
            if (remove === true) {
              this.$store.commit('category/removeFromActiveFacets', {
                facetToRemove: {
                  type,
                  name: strToshow,
                  value: item.value
                }
              })
            } else {
              this.$store.commit('category/addToActiveFacets', {
                type,
                name: strToshow,
                value: item.value
              })
            }
            console.log(data)
            console.log(`2-COUNT=${data['@odata.count']}`)
            console.log(data['@search.facets'])
            this.$nuxt.$emit('RESET_INFINITE_LOADING', this.$store.getters['category/products'])
          } else if (error) {
            console.log(error)
            this.$store.commit('category/setProducts', [])
          }
        }
      )
    },

    findActiveFacets(type) {
      const found = _find(this.activeFacets, function(facet) {
        return facet.type === type
      })
      if (found === undefined) {
        return false
      }
      return found
    },
    async deleteActiveFacet(facet, index) {
      let customType
      switch (facet.type) {
        case 'offerPrice':
          customType = 'op'
          break

        case 'offerSave':
          customType = 'os'
          break

        case 'hasFreeGift':
          customType = 'ofg'
          break

        case 'offerProductType':
          customType = 'opt'
          break

        case 'delivery':
          customType = 'delivery'
          break
      }
      this.$store.commit('category/removeQueryFilter', {
        type: customType,
        value: facet.value
      })

      // Reset the OfferPrice OP finter
      const found = _find(this.activeFacets, function(facet) {
        return facet.type === 'offerPrice'
      })
      if (found !== undefined) {
        this.reloadPriceFacet()
      }

      const pageCount = this.query.page || 1
      console.log(this.query)
      // Get the orderby search option
      const orderby = this.query && getOrderbyAzureSearchOption({ query: this.query })
      const azureQuery = getCategoryProductQuery(createAzureSearchQuery({ query: this.query, category: { id: this.categoryId } }))
      const categoryFilter = { priceFrom: this.priceFrom, priceTo: this.priceTo, percentageFrom: this.percentageFrom, percentageTo: this.percentageTo, urlPath: this.urlPath }

      const azureFilter = getCategoryProductFilter({
        query: createAzureSearchQuery({ query: this.query, category: { id: this.categoryId } }),
        categoryFilter
      })

      // View All -> limit=all
      let viewAll = false
      if (this.query && this.query.limit === 'all') {
        viewAll = true
      }

      await azureCategoryProductsSearch(
        { searchTerm: azureQuery, orderby, page: pageCount, filter: azureFilter, ...createAzureSearchIntervals(categoryFilter), viewAll },
        (error, data) => {
          if (data) {
            this.$store.commit('category/setProducts', data.value)
            this.$store.commit('category/setCount', data['@odata.count'])
            this.$store.commit('category/removeFromActiveFacets', { index })
            console.log(data)
            console.log(`3-COUNT=${data['@odata.count']}`)
            console.log(data['@search.facets'])
            this.$nuxt.$emit('RESET_INFINITE_LOADING', this.$store.getters['category/products'])
          } else if (error) {
            console.log(error)
            this.$store.commit('category/setProducts', [])
          }
        }
      )
    }
  }
}
</script>

<style lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.card {
  transition: all 0.5s;
}
.card-enter, .card-leave-to
/* .card-leave-active for <2.1.8 */ {
  opacity: 0;
  transform: scale(0);
}
.card-enter-to {
  opacity: 1;
  transform: scale(1);
}

.card-leave-active {
  position: absolute;
}

.card-move {
  opacity: 1;
  transition: all 0.5s;
}

.filter {
  content-visibility: auto;
  overflow: auto;
  max-height: 100vh;
  position: fixed;
  z-index: 2147483647;
  top: 0;
  left: 0;
  visibility: visible;
  width: 100%;
  height: 100%;
  -webkit-transition: opacity 250ms ease-in-out, visibility 250ms ease-in-out;
  transition: opacity 250ms ease-in-out, visibility 250ms ease-in-out;
  opacity: 1;
  background-color: rgba(0, 0, 0, 0.7);
  &__container {
    z-index: 100;
    transform: none;
    width: 400px;
    height: 65px;
    line-height: 65px;
    border-bottom: 1px solid #000;
    float: left;
    position: relative;
    clear: both;
    background-color: #fff;
    text-align: center;
  }
  &__title {
    float: left;
    padding: 0 20px;
    padding: 0 20px;
    font-size: 16px;
    text-align: left;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #000;
    line-height: unset;
  }
  &__icon {
    float: right;
    vertical-align: middle;
    &:hover {
      cursor: pointer;
    }
    .right {
      box-sizing: content-box;
      position: relative;
      vertical-align: middle;
      padding: 0 15px;
      height: 1.5em;
      width: 1.5em;
    }
  }
  &__content,
  &__facets,
  &__top,
  &__bottom,
  &__activefacets {
    border: 1px solid white;
    padding-top: 20px;
    z-index: 100;
    transform: none;
    width: 400px;
    height: auto;
    float: left;
    position: relative;
    clear: both;
    background-color: #fff;
    text-align: center;
    .card {
      border: 5px solid white;
      border-radius: unset;
      .card-header {
        border: 5px solid white;
        border-radius: unset;
        .btn-info {
          color: #333;
          background-color: #fff;
          border-radius: unset;
          border: unset;
          text-align: left;
          font-weight: 600;
        }
        a {
          .minus {
            display: inline-block;
          }
          .plus {
            display: none;
          }
        }
        a.collapsed {
          .minus {
            display: none;
          }
          .plus {
            display: inline-block;
          }
        }
      }
      .list-group {
        .list-group-item {
          // text-transform: uppercase;
          border-radius: unset;
          border: unset;
          font-size: 14px;
          // border-top: 1px solid #ccc;
          &:hover {
            cursor: pointer;
            color: #0056b3;
            background-color: #eee;
            .fa-hover {
              display: flex !important;
            }
          }
          .fa-hover {
            display: none;
          }
        }
      }
    }
  }
  &__empty {
    margin: 40px 0;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
  }
  &__empty-heading {
    margin: 0 0 10px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    font-weight: 700;
  }
  &__empty-button {
    width: 50%;
    min-width: 300px;
    margin: 10px 4%;
    font-size: 14px;
    font-weight: 600;
    color: #fff;
    background-color: $prodcutOfferColor;
    display: block;
    height: 40px;
    font-size: 13px;
    line-height: 40px;
    cursor: pointer;
    text-align: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    border-radius: 0;
  }
  &__empty-button:hover {
    opacity: 0.7;
    filter: alpha(opacity=70);
    color: #fff;
  }
  &__top-title {
    margin-left: 10px;
    color: #333;
    font-weight: 600;
  }
  &__top {
    padding-top: 10px;

    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__bottom {
    display: flex;
    justify-content: center;
  }
  &__count {
    margin-left: 10px;
    color: #333;

    font-weight: 600;
  }
  &__clear-all {
    &:hover {
      cursor: pointer;
      color: #0056b3;
    }
    padding: 10px;
    margin-right: 10px;
  }
  &__activefacets {
    // border-bottom: 1px solid #ccc;
    button {
      font-size: 14px;
    }
  }
}
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
@media (max-width: $screen-xs-max) {
  .filter__activefacets button {
    font-size: 12px;
  }
  .filter {
    overflow: auto;
    max-height: 100vh;

    &__container,
    &__activefacets,
    &__facets,
    &__top,
    &__bottom {
      min-width: 80%;
      max-width: 80%;
    }

    &__empty-button {
      width: 40%;
      min-width: 200px;
    }
  }
  .filter__icon .right {
    box-sizing: content-box;
    padding: 0 15px;
  }
}
@media (max-width: 320px) {
  .filter {
    overflow: auto;
    max-height: 100vh;

    &__container,
    &__activefacets,
    &__facets,
    &__top,
    &__bottom {
      min-width: 100%;
      max-width: 100%;
      max-width: 320px;
    }

    &__empty-button {
      width: 40%;
      min-width: 200px;
    }
  }
  .filter__icon .right {
    box-sizing: content-box;
    padding: 0 20px;
  }
}

/* ==========================================================================
    Internet Explorer Section
   ========================================================================== */
/*  IE10+ */
@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  .upsell-grid {
    .items-list {
      justify-content: space-around;
    }
  }
}
</style>
