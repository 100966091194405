<script>
import giftcard from '~/mixins/giftcard.vue'

export default {
  mixins: [giftcard],
  asyncData({ store, route, params, query, $ua }) {
    const path = '/magazines/gift-cards'
    console.log(`fetch Gift Card path=${path}`)

    return {
      isFromMobile: $ua.isFromIphone() || $ua.isFromAndroidMobile(),
      params,
      isUpdate: false
    }
  }
}
</script>
