<template>
  <section class="content-cms">
    <client-only v-if="$store.getters['UI/debug']">
      <ul class="debug__list">
        <li class="debug__item">
          <tree-view :data="queryString" :options="{ rootObjectKey: 'QueryString', maxDepth: 0, link: true }"> </tree-view>
        </li>
        <li class="debug__item">
          <tree-view :data="$options.filters.objectParser(dataUI)" :options="{ rootObjectKey: 'Data UI', maxDepth: 0, link: true }"> </tree-view>
        </li>
      </ul>
    </client-only>
    <div class="content-cms__main cms">
      <div class="cms__title">
        <h1>{{ dataUI.title }}</h1>
      </div>
      <LazyHydrate>
        <!-- eslint-disable-next-line vue/no-v-html -->
        <div class="cms__content" v-html="dataUI.content"></div>
      </LazyHydrate>
    </div>
  </section>
</template>

<script>
import LazyHydrate from 'vue-lazy-hydration'
import { sendSSAPIPageView, createUniqueFBEventId } from '~/lib/util/fb'
import { getGTMPageViewDetailsPayload } from '~/lib/util/gtmUAEE'
import { getBreadcrumbsMicrodataJSONLD } from '~/lib/util/microdata'
import { getStatusCodeFromPath, callAWSFrontendAPI } from '~/lib/util/aws'

export default {
  name: 'Cms',
  filters: {
    objectParser(obj) {
      const sup = { ...obj }
      sup.bottomText = 'NOT AVAILABLE FOR DEBUG'
      return sup
    }
  },

  components: {
    LazyHydrate
  },

  async fetch() {
    // Skipping any ECard, Gift-card, EGift-card and Pocketmags Plus pages
    if (
      this.$route.path.includes('/ecard') ||
      this.$route.path.includes('/magazines/gift-cards') ||
      this.$route.path.includes('/magazines/e-giftcards') ||
      this.$route.path.includes('/magazines/pocketmagsplus') ||
      this.$route.path.includes('href')
    ) {
      return
    }

    const status = getStatusCodeFromPath({ context: this })
    if (status !== 200) {
      if (process.server) {
        this.$nuxt.context.res.statusCode = status
      }
      this.$store.commit('UI/statusCode', status, { root: true })
      return
    }

    // Look for an available Query String and retrieve the URL path
    const path = this.$route.path
    const query = this.$nuxt.context.query
    const useCache = this.$store.getters['UI/useCache']
    console.log('page=CMS')
    console.log('path=', path)
    console.log('query=', query)
    console.log('QS useCache=', useCache)
    console.log('AWSAPIGatewayURL=', this.$nuxt.$config.AWSAPIGatewayURL)

    this.queryString = query

    this.dataUI = {
      ...(await callAWSFrontendAPI({ context: this, path, query, useCache, type: 'CMSPAGE' }))
    }

    // Breadcrumbs
    const page = {
      value: this.dataUI.title,
      type: path,
      last: true
    }
    this.$store.commit('ADD_BREADCRUMBS', { path, page })
  },

  data() {
    return {
      dataUI: {},
      queryString: []
    }
  },

  serverCacheKey() {
    return false
  },
  layout: 'default',
  watchQuery: ['layout'],

  fetchOnServer: true,
  // multiple components can return the same `fetchKey` and Nuxt will track them both separately
  // fetchKey: 'cms',
  // Set the minimum executing time in milliseconds (to avoid quick flashes)
  // fetchDelay: 5000,

  mounted() {
    // Skipping any ECard, Gift-card, EGift-card and Pocketmags Plus pages
    if (
      this.$route.path.includes('/ecard') ||
      this.$route.path.includes('/magazines/gift-cards') ||
      this.$route.path.includes('/magazines/e-giftcards') ||
      this.$route.path.includes('/magazines/pocketmagsplus') ||
      this.$route.path.includes('/404') ||
      this.$route.path.includes('/500') ||
      this.$route.path.includes('href')
    ) {
      return
    }

    // Facebook Event Deduplication
    const eventId = createUniqueFBEventId('PageView')

    // Customer email address
    const email = this.$store.getters['s/email']

    // UAEE tracking PageView
    this.$config.UAEESyntax &&
      this.$gtm &&
      this.$gtm.push(
        getGTMPageViewDetailsPayload({ pageUrl: this.$route.path, pageTitle: (this.page && this.page.title) || this.dataUI.title, routeName: this.$route.name, eventId, email })
      )

    // FB v12.0
    const eventSourceUrl = `${this.$config.baseURL}${this.$route.fullPath}`

    // Facebook Server-side API PageView
    sendSSAPIPageView({ context: this, parameters: { eventId, eventSourceUrl } })
  },

  created() {},

  // To check more options, link https://github.com/nuxt/vue-meta#script-object
  // To chcek how it is working https://nuxtjs.org/faq/duplicated-meta-tags/
  head() {
    return {
      title: this?.dataUI?.title,
      link: [{ rel: 'canonical', href: this?.dataUI?.canonicalURL }],
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this?.dataUI?.metaDescription
        },
        {
          name: 'keywords',
          content: this?.dataUI?.metaKeywords || ''
        },
        {
          name: 'robots',
          content: (this.$route.path.includes('/welcome-back') && 'NOINDEX,NOFOLLOW') || this.$config.robots || 'INDEX,FOLLOW'
        },
        {
          hid: 'og:title',
          name: 'og:title',
          property: 'og:title',
          content: this?.dataUI?.title
        },
        {
          hid: 'og:description',
          name: 'og:description',
          property: 'og:description',
          content: this?.dataUI?.metaDescription
        },
        {
          hid: 'og:site_name',
          name: 'og:site_name',
          property: 'og:site_name',
          content: 'magazine.co.uk'
        },
        {
          hid: 'og:image',
          name: 'og:image',
          property: 'og:image',
          content: 'https://www.magazine.co.uk/icon.png'
        },
        {
          hid: 'og:type',
          name: 'og:type',
          property: 'og:type',
          content: 'article'
        }
      ]
    }
  },
  jsonld() {
    return [getBreadcrumbsMicrodataJSONLD({ breadcrumbs: this.$store.getters['UI/breadcrumbs'] })]
  }
}
</script>

<style lang="scss">
.content-cms {
  font-size: 14px;
  margin: 0 auto;
  flex-flow: row nowrap;
  display: flex;
  width: 100%;
  justify-content: space-around;
  max-width: 1230px !important;
  flex-direction: column;

  &--main {
    &__3col {
      flex: 1 1 65%;
      margin: 0 10px;
      padding: 10px;
    }
  }

  .cms {
    padding: 10px 15px;
    &__title h1 {
      font-size: 28px;
      margin: 0 0 15px;
      font-weight: 400;
      color: #ff0d00;
    }
    &__content p {
      font-size: 14px;
      margin: 0 0 10px;
      line-height: 20px;
      font-size: 14px;
      color: #333;
      a:hover {
        cursor: pointer;
      }
    }
    &__content ul {
      font-size: 14px;
      line-height: 1.42857;
      color: #333;
      display: block;
      list-style-type: disc;
      margin-block-start: 1em;
      margin-block-end: 1em;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
      padding: 0 40px;
    }

    a {
      color: #333 !important;
      text-decoration: underline !important;
      :hover {
        color: #333;
      }
    }
    td {
      border: 1px solid black;
      ul {
        padding: 0 8px;
      }
    }
    h3 {
      font-size: 20px;
    }
  }
}

@media (max-width: $screen-sm-min) {
  .content-cms {
    &__main {
      flex: 0 1 100%;
      margin: 0;
      padding: 0px;
    }
    &__description {
      h1 {
        line-height: 1.2em;
      }
    }
  }
}

@media (max-width: $screen-xs-max) {
  .content-cms {
    &__main {
      flex: 0 1 100%;
      margin: 0;
      padding: 0px;
    }
    &__description {
      h1 {
        line-height: 1.2em;
      }
    }
  }
}
</style>
