<template>
  <div>
    <ul class="vert-nav">
      <li v-for="item in navigationLinksDefaultValues" :key="item.key">
        <a :href="item.href" :target="item.target || '_self'" rel="" :title="item.text" :alt="item.alt" class="nav-cats__link">
          {{ item.text }}
        </a>
      </li>
      <li class="nav-cats__headline"><h4>More Categories</h4></li>
      <li v-for="item in navigationLinksMoreValues" :key="item.key">
        <a :href="item.href" :target="item.target || '_self'" rel="" :title="item.text" :alt="item.alt" class="nav-cats__link">
          {{ item.text }}
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import { NAVIGATION_LINKS_MORE_VALUES } from '~/lib/util/defaultLinks'

export default {
  name: 'LeftNav',
  serverCacheKey: props => `LeftNav::isHomePage::${props.isHomePage}`,
  props: {
    isHomePage: {
      type: Boolean,
      required: false,
      default() {
        return false
      }
    }
  },
  data() {
    return {
      navigationLinksDefaultValues: this.$store.getters['utility/sitelinks'],
      navigationLinksMoreValues: NAVIGATION_LINKS_MORE_VALUES
    }
  }
}
</script>

<style lang="scss" scoped>
ul.vert-nav {
  margin-top: 10px;
}
ul.vert-nav > li {
  font-size: 13px;
  // border-bottom: 1px solid $borderColor;
  display: list-item;
  text-align: -webkit-match-parent;
  &:last-child {
    border-bottom: 0px;
    margin-bottom: 20px;
  }
  a {
    min-height: 30px;
    padding: 0 6px;
    text-decoration: none;
    display: flex;
    justify-content: left;
    align-items: center;
    color: #ff0d00;
    &:hover {
      color: #333;
      // background: #ededed;
    }
  }
}
ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.nav-cats {
  &__headline h4 {
    font-size: 12px;
    font-weight: 600;
    min-height: 30px;
    padding: 15px 6px 6px 6px;
    line-height: 19px;
    text-transform: uppercase;
    color: #333;
    margin-bottom: unset;
  }
}
</style>
