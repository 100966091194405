<template>
  <transition name="fade">
    <div v-if="showNotification" class="notification" @click="closeClicked">
      <div class="notification-grid content--max">
        {{ getCode() }}
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    showNotification: {
      type: Boolean,
      required: true,
      default() {
        return false
      }
    },
    notification: {
      type: Object,
      required: true,
      default() {
        return {}
      }
    }
  },

  methods: {
    getCode() {
      return `${this.notification.message}`
    },
    closeClicked() {
      this.$nuxt.$emit('SHOW_NOTIFICATION', { show: false })
    }
  }
}
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.notification {
  position: fixed;
  bottom: 0;
  z-index: 2147483647;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
  background: $warningNotificationBgColor;
  min-height: 45px;
  margin: 0px;
  cursor: pointer;
  .notification-grid {
    color: $warningNotificationTextColor;
    flex: 0 0 100%;
    text-align: center;
    margin: 20px;
  }
}
</style>
