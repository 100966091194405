<script>
const jwt = require('jwt-simple')
export default {
  data() {
    return {
      isLoading: false,
      showViewAndBuyButton: true
    }
  },
  methods: {
    getProductUrl(item) {
      // Custom redirect ALWAYS to the canonical url for a Pocketmags Plus product
      if (this.item.sku === 'Pocketmags Plus+') {
        return item.url
      }
      if (this.categoryUrl) {
        const productPath = item.url.replace(/^\/magazines/, '')
        return `${this.categoryUrl}${productPath}`
      }
      return item.url.replace(/magazine-(\d{4,5})$/g, 'magazine')
    },
    async updateBasket(item, action) {
      this.isLoading = true
      // console.log(`updateBasket action=${action} offer.productId=${item.offer.productId} offer.productCartId=${item.offer.productCartId}`)
      let ret = null
      if (this.$nuxt.$config.MongoDBIntegration) {
        console.log('UPDATE_CART MongoDB Integration')
        // TODO Call MONGODB INTEGRATION
        console.log('ret=', ret)
        if (ret && ret.error) {
          // Error, show an overlay on the page
          this.$nuxt.$emit('SHOW_NOTIFICATION', { data: ret.error, show: true })
        }
      } else {
        console.log('UPDATE_CART MAGENTO Integration')
        console.log('UPDATE_CART payload=', {
          cartId: this.$store.state.basket.id,
          productId: item.offer.productId,
          action,
          item
        })
        ret = await this.$store.dispatch('UPDATE_CART', {
          cartId: this.$store.state.basket.id,
          productId: item.offer.productId,
          action,
          item
        })

        if (ret.error) {
          // Error, show an overlay on the page
          this.$nuxt.$emit('SHOW_NOTIFICATION', { data: ret.error, show: true })
        } else if (!this.$cookies.get('cart')) {
          this.$cookies.set('cart', jwt.encode({ cartId: this.$store.state.basket.id }, process.env.JWT_SECRET), {
            path: '/',
            maxAge: 3600 * 24 * 30,
            domain: process.env.BACKEND_COOKIES_DOMAIN,
            secure: process.env.NODE_ENV === 'production'
          })

          this.$nuxt.$emit('BASKET_READY', { ready: true, basket: ret })
        }
      }
      this.isLoading = false
      return ret
    }
  }
}
</script>
