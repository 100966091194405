<template>
  <div v-if="sitewide.title && sitewide.enabled === true" class="block sitewide--desktop">
    <a :href="sitewide.href" @click.prevent="handleClick()">
      <img :title="sitewide.title" :alt="sitewide.alt" :src="sitewideImageURLWithImgxQS" width="175px" height="346px" loading="lazy" />
    </a>
  </div>
</template>

<script>
import { getGA4EcommerceNullPayload, getGA4SelectPromotionPayload } from '~/lib/util/gtmGA4'

export default {
  name: 'PromotionsSitewideDesktop',
  serverCacheKey: props => `PromotionsSitewideDesktop::${props.sitewide.title}`,

  components: {},
  props: {
    creativeSlot: {
      type: String,
      required: false,
      default() {
        return ''
      }
    },
    locationId: {
      type: String,
      required: false,
      default() {
        return ''
      }
    },
    sitewide: {
      type: Object,
      required: false,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      sitewideImageURLWithImgxQS: this.sitewide.image_url + '?fn=png&auto=format&w=175'
    }
  },
  methods: {
    handleClick(path) {
      // GA4 events for every  Banner Image Click
      const banner = { id: '1', alt: `SITEWIDE - ${this.sitewide.title}`, key: this.creativeSlot }
      this.$config.GA4Syntax && this.$config.GA4FireEcommerceNull && this.$gtm && this.$gtm.push(getGA4EcommerceNullPayload())
      this.$config.GA4Syntax && this.$gtm && this.$gtm.push(getGA4SelectPromotionPayload({ banner, locationId: this.locationId }))
      this.$config.GA4Syntax && this.$gtm && this.$gtm.push({ event: 'sitewideBannerClick', promotion: `SITEWIDE - ${this.sitewide.title}` })

      window.location.href = this.sitewide.href
    }
  }
}
</script>

<style lang="scss" scoped>
.sitewide {
  content-visibility: auto;
  &--desktop {
    img {
      width: 175px;
      height: auto;
    }
  }
}
</style>
