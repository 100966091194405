<template>
  <div class="error-404">
    <div class="gbg404">
      <div class="oh404">Uh oh!</div>
      <div>
        <img src="~/assets/images/404page.png" alt="404" style="width:100%; max-width:800px; height:auto;" blank-color="#ededed" width="400" height="277" loading="lazy" />
      </div>
    </div>
    <div class="bbg404">
      <div class="head404">This page is missing</div>
      <div class="subhead404">Why not try one of these instead</div>
      <div class="btnhold404">
        <div style="float:left;"><a href="/" class="btn404">HOME</a></div>
        <div style="float:right;"><a href="/contact" class="btn404">CONTACT</a></div>
        <div style="clear:both;"></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Error404',
  serverCacheKey() {
    return 'Error404'
  },
  props: {
    error: {
      type: Object,
      default: () => {}
    }
  }
}
</script>
<style lang="scss" scoped>
.error-404 {
  -webkit-box-flex: 0;
  flex: 0 0 100%;
  max-width: 1230px;
  margin: 0px auto 20px auto;
}
.gbg404 {
  background-color: #ededed;
  text-align: center;
  padding: 50px;
  box-sizing: border-box;
}
.bbg404 {
  background-color: black;
  text-align: center;
  padding: 50px;
  box-sizing: border-box;
  color: white;
}
.oh404 {
  font-size: 70px;
  line-height: 70px;
}
.head404 {
  font-size: 50px;
  line-height: 50px;
  margin-bottom: 20px;
}
.subhead404 {
  font-size: 30px;
  line-height: 30px;
  margin-bottom: 30px;
}
.btn404 {
  width: 200px;
  height: 72px;
  background-color: #e20d0c;
  color: white !important;
  text-decoration: none !important;
  font-size: 26px;
  font-weight: 600;
  line-height: 72px;
  display: block;
}
.btnhold404 {
  width: 500px;
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (max-width: 768px) {
  .gbg404 {
    padding: 40px;
  }
  .bbg404 {
    padding: 40px;
  }
  .oh404 {
    font-size: 60px;
    line-height: 60px;
  }
  .head404 {
    font-size: 40px;
    line-height: 40px;
  }
  .subhead404 {
    font-size: 24px;
    line-height: 24px;
  }
  .btn404 {
    width: 180px;
    height: 62px;
    line-height: 62px;
    font-size: 23px;
  }
  .btnhold404 {
    width: 420px;
  }
}
@media only screen and (max-width: 767px) {
  .gbg404 {
    padding: 30px;
  }
  .bbg404 {
    padding: 30px;
  }
  .oh404 {
    font-size: 60px;
    line-height: 60px;
  }
  .head404 {
    font-size: 26px;
    line-height: 26px;
  }
  .subhead404 {
    font-size: 16px;
    line-height: 16px;
  }
  .btn404 {
    width: 120px;
    height: 42px;
    line-height: 42px;
    font-size: 18px;
  }
  .btnhold404 {
    width: 90%;
  }
}
</style>
