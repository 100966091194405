<template>
  <div>
    <div class="category-heading" @click.prevent="handleClick(gridCategory)">
      <h2>
        <span class="text">{{ gridCategory.label }}</span>
        <span class="view-all">View all<font-awesome-icon :icon="['fal', 'chevron-right']" class="fa fa-stack-1x right"/></span>
      </h2>
    </div>
    <div class="items-list offer-container block">
      <Product v-for="(item, index) in gridCategory.items" :key="`${item.productId}-${index}-${gridCategory.key}`" :item="item" :show-save-overlay="true" />
    </div>
  </div>
</template>

<script>
import { sendSSAPIViewAll, createUniqueFBEventId } from '~/lib/util/fb'
export default {
  name: 'HomepageGirdCategory',
  serverCacheKey: props => `HomepageGirdCategory::${props.gridCategory.key}`,
  components: {
    Product: () => import('~/components/common/list/Product.vue')
  },
  props: {
    gridCategory: {
      type: Object,
      required: true
    }
  },
  methods: {
    handleClick(item) {
      // Facebook Event Deduplication
      const eventId = createUniqueFBEventId(item.label)

      //  Tracking GTM ViewAll
      this.$config.UAEESyntax && this.$gtm && this.$gtm.push({ event: item.label, eventID: eventId })

      // FB v12.0
      const eventSourceUrl = `${this.$config.baseURL}${this.$route.fullPath}`

      // Facebook Server-side API ViewAll
      sendSSAPIViewAll({ context: this, parameters: { eventId, category: item.label, eventSourceUrl } })

      // Redirct to Category Page
      window.location.href = `${this.$config.baseURL}${item.url}`
    }
  }
}
</script>

<style lang="scss">
.category-heading {
  background: $categoryHeadingBg;
  min-height: 40px;
  position: relative;
  cursor: pointer;
  width: 100%;
  padding: 11px;
  h2 {
    margin: unset;
    font-size: 16px;
    color: #fff;
    text-transform: uppercase;
    font-weight: 600;
  }
  .view-all {
    float: right;
    text-transform: none;
    font-weight: normal;
  }
  &:hover {
    cursor: pointer;
    opacity: 0.9;
  }
  .fa {
    position: relative;
  }
}
.grid-outer-container {
  // min-height: 459px;
}
.grid-container {
  // min-height: 459px;
  // margin-bottom: 20px;
  a {
    text-decoration: none;
  }

  button {
    @include button-style;
    min-width: 250px;
    font-size: 14px;
    &:disabled {
      background-color: #cccccc;
      color: #666666;
    }
  }

  .form-section-title {
    h2 {
      padding-bottom: 0px;
      border-bottom: 0px solid #eee;
      font-size: 18px;
    }
  }
  .offer-container {
    background: white;
  }

  .items-list {
    &__item {
      // min-height: 180px;
      &:nth-child(n + 6) {
        display: none;
      }
      &--home:nth-child(n + 6) {
        display: flex;
      }
      flex: 0 0 19.5%;
      .view-btn,
      .btn-divider {
        display: none;
      }
      .actions {
        padding: 10px 0 !important ;
        height: 32px;
      }
      .add-to-basket {
        @include button-add-to-cart;
        text-decoration: none !important;
        color: white !important;
      }
    }
  }
}

@media (max-width: $screen-md-min) {
  .grid-container {
    .items-list {
      &__item {
        &:nth-child(n + 5) {
          display: none;
        }
        &--home:nth-child(n + 5) {
          display: flex;
        }
        flex: 0 0 24.3%;
        .view-btn,
        .btn-divider {
          display: none;
        }
        .add-to-basket {
          @include button-add-to-cart;
          // margin-top: 10px;
        }
      }
    }
  }
}

@media (max-width: $screen-sm-min) {
  .offer-container {
    background: white;
    // min-height: 1254px;
  }
  .grid-outer-container {
    min-height: 2900px;
  }

  .grid-container {
    .items-list {
      &__item {
        &:nth-child(n + 6),
        &:nth-child(n + 5) {
          display: flex;
        }
        &:nth-child(n + 7) {
          display: none;
        }

        &--home:nth-child(n + 6),
        &--home:nth-child(n + 5),
        &--home:nth-child(n + 7) {
          display: flex;
        }
        flex: 0 0 32%;
      }
    }
  }
  .category-heading {
    background: $categoryHeadingBg;
    padding: 10px;
    position: relative;
    margin-bottom: 20px;
    text-align: left;
    font-size: 18px;
    color: #fff;
    font-family: $fontRegularMobile;
    margin: 0;
    &:hover {
      text-decoration: none;
    }
    width: 100%;
    &:before {
      padding-top: 8px;
      font-size: 30px;
    }
    &:after {
      content: '';
      display: block;
      width: 100%;
      height: 2px;
      background-size: 6px 2px;
      bottom: -2px;
      left: 0;
      position: absolute;
    }
    .view-all {
      line-height: 20px;
      font-size: 14px;
    }
    h2 {
      font-weight: 400;
      padding-left: 10px;
      text-transform: none !important;
    }
  }
}

@media (max-width: 414px) {
  .grid-container {
    .items-list {
      &__item {
        &:nth-child(n + 6),
        &:nth-child(n + 5) {
          display: flex;
        }
        &:nth-child(n + 7) {
          display: none;
        }
        &--home:nth-child(n + 6),
        &--home:nth-child(n + 5),
        &--home:nth-child(n + 7) {
          display: flex;
        }
        flex: 0 0 46%;
      }
    }
  }
}

/* ==========================================================================
    Internet Explorer Section
   ========================================================================== */
/*  IE10+ */
@media (-ms-high-contrast: active) and (-ms-high-contrast: none) and (max-width: 767px) {
  .grid-container {
    .items-list {
      justify-content: space-around;
      &__item {
        flex: 0 0 44%;
      }
    }
  }
}

/*  IE10+ */
@media (-ms-high-contrast: active) and (-ms-high-contrast: none) and (min-width: $screen-sm-min) {
  .grid-container {
    .items-list {
      justify-content: space-around;
      &__item {
        flex: 0 0 16%;
      }
    }
  }
}
/* iPhone 14 Pro Max - 1284×2778 pixels at 460ppi */
@media only screen and (max-device-width: 430px) and (max-device-height: 932px) and (-webkit-device-pixel-ratio: 3) {
  .offer-container {
    // min-height: 708px;
  }
}

/* ----------- iPad and iPad Mini  ----------- */
/* Landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  .offer-container {
    // min-height: 430px;
  }
}
/* Portrait */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .offer-container {
    // min-height: 963px;
  }
}
</style>
