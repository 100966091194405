<template> <div></div></template>
<script>
import debounce from 'lodash/debounce'
import find from 'lodash/find'
import xss from 'xss'
import { azureSearch } from '~/lib/util/azuresearch'

import { magazines, categories } from '~/offlineData/search-suggestions.js'

export default {
  name: 'TheSearch',
  serverCacheKey: props => `TheSearch`,
  filters: {
    truncate(value) {
      if (!value) {
        return ''
      }
      return xss(value.substring(0, 150)) + '...'
    }
  },

  data() {
    return {
      searchAPIURL: `${process.env.AZURE_SEARCH_API_ENDPOINT}`,
      searchAPIKey: process.env.AZURE_SEARCH_API_KEY,
      searchAPIVersion: process.env.AZURE_SEARCH_API_VERSION,
      term: '',
      productResults: [],
      categoriesResults: [],
      allResults: [],
      searching: false,
      azureSearchTerm: '',
      limit: 4,
      window: {
        width: 0,
        height: 0
      },
      focused: false,
      isHomepage: this.$route.path === '/'
    }
  },

  mounted() {
    // document.addEventListener('click', this.documentClick)
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
    this.isMobile()
  },

  destroyed() {
    document.removeEventListener('keyup', this.eventHandler)
    document.removeEventListener('click', this.documentClick, { passive: true })
    window.removeEventListener('resize', this.handleResize)
  },

  methods: {
    linkURL(item) {
      const url = item?.url
      if (!url) {
        return this.$config.baseURL
      }
      return `${this.$config.baseURL}/${url.replace(/magazine-(\d{4,5})$/g, 'magazine')}`
    },
    getIndex(r) {
      return this.allResults.map(e => e.id).indexOf(r.id)
    },
    getImageURL(item) {
      const imagePath = item?.imageUrl?.split('?')[0] || item?.image?.split('?')[0]
      // console.log(`SEARCH getImagePath imageURL=${item?.image} imagePath=${imagePath} item=`, item)

      if (item.title === 'Pocketmags Plus+') {
        return `/pocketmagsplus/image.png`
      }

      // Custom image ONLY for a Pocketmags Plus product
      if (this.isPocketmagsPlus) {
        console.log('isPocketmagsPlus imagePath=', imagePath)
        return `${imagePath}`
      }

      // IMGIX CDN new Cover Update Logic
      if (imagePath.startsWith('/products/')) {
        return imagePath
      }

      // JMAG deafult M1 Image
      if (imagePath.startsWith('/catalog/product')) {
        return imagePath
      }

      return `/catalog/product${imagePath}`
    },
    isMobile() {
      if (this.window.width < this.window.height && this.window.width < 768) {
        // Mobile
        this.$store.dispatch('SAVE_ISMOBILE', true)
        return true
      } else if (this.window.width > this.window.height && this.window.width < 768) {
        // Tablet
        this.$store.dispatch('SAVE_ISMOBILE', true)
        return true
      }
      this.$store.dispatch('SAVE_ISMOBILE', false)
      return false
    },

    handleResize() {
      this.window.width = window.innerWidth
      this.window.height = window.innerHeight
      // hide all the results
      this.productResults = []
      this.categoriesResults = []
      this.allResults = []
    },

    documentClick(e) {
      console.log('documentClick')
      const el = this.$refs.searchResults
      const target = e.target
      this.focused = 99
      if (el !== target && el && !el.contains(target)) {
        this.term = this.term || ''
        this.productResults = this.term || []
        this.categoriesResults = this.term || []
        this.allResults = this.term || []
      }
    },

    presearch(event) {
      // console.log(`KEYCODE ${event.keyCode}`)
      switch (event.keyCode) {
        case 40:
        case 9:
          this.moveDown()
          break
        case 38:
          this.moveUp()
          break
        default:
          this.search()
          this.focused = 99
          break
      }
    },

    submitEnter(index) {
      console.log(`submitEnter index=${index} url=${this.$config.baseURL}/${this.allResults[index].url}`)
      window.location.href = `${this.$config.baseURL}/${this.allResults[index].url}`
    },

    search: debounce(function() {
      this.searching = true

      if (this.term === '') {
        this.productResults = []
        this.categoriesResults = []
        this.allResults = []
        this.searching = false
        return
      }
      this.azureSearchTerm = this.term.trim()

      this.$config.debug && console.log('this.$config.UseOfflineData=', this.$config.UseOfflineData)
      this.$config.debug && console.log('process.env.USE_OFFLINE_DATA=', process.env.USE_OFFLINE_DATA)

      if (this.$config.UseOfflineData) {
        // Use OFFILE data ingegration
        this.searching = false
        this.productResults = magazines.value || []
        this.categoriesResults = categories.value || []

        // ALL Results
        this.allResults = [...this.categoriesResults, ...this.productResults]

        console.log('OFFLINE SUGGESTIONS MAGAZINES=', this.productResults)
        console.log('OFFLINE SUGGESTIONS CATEGORIES=', this.categoriesResults)
      } else {
        azureSearch({ searchTerm: this.azureSearchTerm.trim(), orderby: undefined, top: this.limit }, (error, data) => {
          if (error) {
            console.log(error)
            return
          }

          data.products && console.log('RESULT -> PRODUCTS=', data.products)
          data.products && console.log('RESULT -> CATEGORIES=', data.categories)

          this.searching = false
          this.productResults = data.products.value || []

          this.categoriesResults = data.categories.value || []

          // ALL Results
          this.allResults = [...this.categoriesResults, ...this.productResults]

          // Check if there is a redirect terms
          const redirectObj = find(this.productResults, function(obj) {
            return obj.redirect !== ''
          })

          if (redirectObj) {
            this.searching = false
          }
        })
      }
    }, 300),

    eventHandler(event) {
      if (event.keyCode === 27) {
        this.term = this.term || ''
        this.searching = this.term || false
        this.productResults = this.term || []
        this.categoriesResults = this.term || []
        this.allResults = this.term || []
      }
    },

    redirectToSearch() {
      if (!this.term) {
        return true
      }

      // Check if there is a redirect terms
      const redirectObj = find(this.allResults, function(obj) {
        return !obj || obj.length === 0
      })

      if (redirectObj) {
        window.location.href = redirectObj.redirect
      } else {
        window.location.href = `${this.$config.baseURL}/search/?q=${encodeURIComponent(this.term)}`
      }
    },
    moveDown() {
      if (this.focused === 99) {
        this.focused = 0
      } else {
        this.focused = this.focused + 1 >= this.allResults.length ? 0 : this.focused + 1
      }
      console.log(`moveDown -> focused=${this.focused}`)
    },
    moveUp() {
      this.focused = this.focused - 1 >= 0 ? this.focused - 1 : this.allResults.length - 1
      console.log(`moveUp -> focused=${this.focused}`)
    }
  },

  // To check more options, link https://github.com/nuxt/vue-meta#script-object
  // To chcek how it is working https://nuxtjs.org/faq/duplicated-meta-tags/
  head() {
    let link
    if (this.canonicalURL) {
      link = [{ rel: 'canonical', href: this.canonicalURL }]
    }
    if (this.$route.path.includes('search')) {
      return {
        title: this.title,
        ...link,
        meta: [
          {
            hid: 'description',
            name: 'description',
            content: this.metaDescription
          },
          {
            name: 'keywords',
            content: this.metaKeywords || ''
          },
          {
            name: 'robotsSearch',
            content: this.$config.robots || 'INDEX,FOLLOW'
          },
          {
            hid: 'og:title',
            name: 'og:title',
            property: 'og:title',
            content: this.title
          },
          {
            hid: 'og:description',
            name: 'og:description',
            property: 'og:description',
            content: this.metaDescription
          },
          {
            hid: 'og:site_name',
            name: 'og:site_name',
            property: 'og:site_name',
            content: 'magazine.co.uk'
          },
          {
            hid: 'og:image',
            name: 'og:image',
            property: 'og:image',
            content: 'https://www.magazine.co.uk/icon.png'
          }
        ]
      }
    } else {
      return {}
    }
  }
}
</script>

<style lang="scss"></style>
