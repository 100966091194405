<template>
  <div id="giftcard" class="gc-container">
    <client-only v-if="$store.getters['UI/debug']">
      <ul class="debug__list">
        <li v-if="params" class="debug__item">
          <tree-view :data="params" :options="{ rootObjectKey: 'Params', maxDepth: 0, link: true }"> </tree-view>
        </li>
        <li class="debug__item">
          <tree-view :data="selection" :options="{ rootObjectKey: 'Selection', maxDepth: 0, link: true }"> </tree-view>
        </li>
        <!-- <li class="debug__item">
          <tree-view :data="$options.filters.objectParser(dataUI)" :options="{ rootObjectKey: 'Data UI', maxDepth: 0, link: true }"> </tree-view>
        </li> -->
      </ul>
    </client-only>
    <div id="gc-detail-page">
      <div id="gc-detail-page-left-column" class="m-section ">
        <div id="gc-main-image-container">
          <div id="gc-main-image-block">
            <div id="gc-main-preview" class="m-section">
              <div class="m-section gc-live-preview-outer-wrapper">
                <div class="m-section gc-live-preview-parent">
                  <div class="mobile magazine__sub-heading-bar"><h2>magazine.co.uk Gift Cards</h2></div>
                  <div class="m-section gc-live-preview-content" @click="togglePreviewEGiftCard = !togglePreviewEGiftCard">
                    <EGiftCardTemplate
                      :image="selection.design && createImgixURL(selection.design.image, designPreviewQueryString)"
                      :message="selection.message == '' ? undefined : selection.message"
                      :amount="selection.amount.value"
                    />

                    <a href="/magazines/e-giftcards" alt="E-Gift Card" class="m-color-1">
                      <div class="gc-live-preview-content__egiftcard m-section m-spacing-top-big m-text-center">
                        <font-awesome-icon :icon="['fal', 'envelope']" class="fa-2x" />
                        <span class="m-color-1 ml-2"> To send a gift card by Email, Click Here </span>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="gc-detail-page-right-column" class="m-section">
        <h1 class="gc-detail-page__title">
          <span>magazine.co.uk Gift Cards</span>
        </h1>
        <div class="gc-detail-page__description m-section m-color-1">
          Give the perfect gift with this beautifully presented magazine gift card and pack. Just select the value of the gift card and add a personalised message. In the checkout
          you can choose whether you’d like us to post it to you, so you can wrap it and give it on the day, or if you’d prefer us to post it straight to the gift card recipient.
        </div>
        <div class="gc-detail-page__description m-section m-color-1">
          The recipient of the card can then use it towards buying a subscription to any of the hundreds of different magazines available on magazine.co.uk. Gift Cards are sent 1st
          Class Royal Mail with a postage & handling fee of £2.99 added to all orders.
        </div>

        <!-- XMAS STRIKE -->
        <!-- <div class="gc-detail-page__description gc-detail-page__strike m-section container--red">
          The final posting date for Christmas physical gift cards is Friday 16th December.<br />
          Please be advised that Royal Mail industrial action could impact delivery timeframes. For the latest information regarding the strikes please click
          <a href="https://www.royalmail.com/latest-news">here</a>.<br />
          If you are concerned about the Royal Mail strikes, why not send an <a href="https://www.magazine.co.uk/magazines/e-giftcards">e-gift card</a> instead?<br />
        </div> -->
        <div class="gc-detail-page__steps-container m-section">
          <div class="gc-detail-page__step m-color-1">1. Choose Gift Card amount</div>
          <div id="gc-detail-amount" class="gc-detail-page__wrapper">
            <ul class="gc-detail-page__list">
              <li v-for="(value, index) in dataAmountValues" :key="`${value}-${index}`" class="gc-detail-page__list-item">
                <span
                  class="m-button m-button-toggle"
                  :class="{
                    'm-button-selected': selection.amount.value === value && selection.amount.id >= 0
                  }"
                >
                  <span class="m-button-inner">
                    <button class="m-button-text m-nofocus" type="button" :value="`${value}`" @click="updateSelection('amount', index, value)">£{{ value }}</button>
                  </span>
                </span>
              </li>
              <li class="gc-detail-page__list-item--text gc-detail-page__list-item--delivery m-color-1 ">
                <span class="m-text"
                  >£2.99 P&P will be added at Checkout<br />
                  UK delivery Only</span
                >
              </li>
            </ul>
            <div v-if="$v.selection.amount.input.$invalid" class="m-section m-text-error" :class="{ 'm-color-error': $v.selection.amount.input.$invalid }">
              The Gift Card amount must be between £{{ $v.selection.amount.input.$params.between.min }} and £{{ $v.selection.amount.input.$params.between.max }}
            </div>
          </div>
        </div>
        <div class="gc-detail-page__steps-container m-section">
          <div class="gc-detail-page__step m-color-1">2. Personalise your Gift Card (Optional)</div>
          <div id="gc-detail-form" class="gc-detail-page__wrapper">
            <div class="gc-detail-page__list gc-detail-page__list--form">
              <div class="gc-detail-page__list-item m-section">
                <div class="gc-detail-page__form-label">Message</div>
                <div class="gc-detail-page__form-field m-input m-input--noborder m-input-toggle m-border-radius">
                  <textarea
                    id="message"
                    v-model="selection.message"
                    class="m-textarea m-input-text--medium m-nofocus m-border-radius"
                    :class="[errors.message ? 'm-input-error' : 'm-input--border']"
                    name="message"
                    aria-label="Enjoy this magazine.co.uk Gift Card!"
                    placeholder="Enjoy this magazine.co.uk Gift Card!"
                    rows="4"
                    cols="45"
                    maxlength="120"
                    @blur="checkValidity('message', $v.selection.message)"
                  />
                  <div class="m-section m-text--chars-remaining m-color-1 m-padding-2">{{ 120 - selection.message.length }} characters remaining</div>
                  <div v-if="errors.message" class="m-padding-2 m-text-error m-color-error">
                    The E-Gift Card message must be between £{{ $v.selection.message.$params.between.min }} and £{{ $v.selection.message.$params.between.max }}
                  </div>
                </div>
              </div>
              <div class="gc-detail-page__list-item m-section m-flex-justify-content-center" :class="{ 'm-no-margin': isFromMobile }">
                <div id="gc-buttons-wrapper">
                  <span id="gc-button-addtobasket" :class="{ 'm-0 mb-3': isFromMobile }" class="m-button m-button__addtobasket">
                    <span class="m-button-inner">
                      <button
                        v-if="!isUpdate"
                        id="gc-add-to-basket-button"
                        :disabled="$v.selection.$invalid"
                        class="m-button-text m-button-text__addtobasket m-text-center "
                        :class="{ 'm-button-text__addtobasket--mobile': isFromMobile }"
                        alt="Add To Basket"
                        @click.prevent="!isClicked && handleUpdateSelection()"
                      >
                        Add To Basket
                      </button>
                      <button
                        v-else
                        id="gc-add-to-basket-button"
                        :disabled="$v.selection.$invalid"
                        class="m-button-text m-button-text__addtobasket m-text-center"
                        :class="{ 'm-button-text__addtobasket--mobile': isFromMobile }"
                        alt="Add To Basket"
                        @click.prevent="!isClicked && handleUpdateSelection()"
                      >
                        Update Gift Card
                      </button>
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div v-if="dataUI && dataUI.magazine.description" id="description" class="magazine__info">
        <b-tabs content-class="p-4" fill align="left">
          <!-- eslint-disable-next-line vue/no-v-html -->
          <b-tab title="Description"><div class="tab-text" v-html="$options.filters.sanitize(dataUI.magazine.description)"></div></b-tab>
          <b-tab title="Additional Information">
            <div class="tab-text">
              <table id="product-attribute-specs-table" class="data-table">
                <colgroup>
                  <col width="25%" />
                  <col />
                </colgroup>
                <tbody>
                  <tr class="first last odd">
                    <th class="label" style="text-align: center">Custom Delivery</th>
                    <!-- eslint-disable-next-line vue/no-v-html -->
                    <td class="data last" v-html="$options.filters.sanitize(dataUI.magazine.delivery)"></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </b-tab>
        </b-tabs>
      </div>

      <LazyHydrate when-visible>
        <div v-if="dataUI && dataUI.upsell && dataUI.upsell.length > 0" class="product__upsell">
          <div class="product__panel">
            <p class="product__upsell-heading">Share this page</p>
          </div>

          <div class="basket__panel upsell-grid items-list">
            <Social :magazine="dataUI.magazine" />
          </div>
        </div>
      </LazyHydrate>

      <LazyHydrate when-visible>
        <div v-if="dataUI && dataUI.upsell && dataUI.upsell.length > 0" class="product__upsell">
          <div class="product__panel">
            <p class="product__upsell-heading">You might also like...</p>
          </div>

          <div class="basket__panel upsell-grid items-list">
            <UpsellProduct
              v-for="(item, index) in dataUI.upsell.slice(0, 4)"
              :key="`${index}-${item.productId}-upsell`"
              :type="'upsell'"
              :item="item"
              class="product__upsell-item items-list__item"
              imgix-query-string="&w=130&h=180"
            />
          </div>
        </div>
      </LazyHydrate>
    </div>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { between, minLength, maxLength, integer } from 'vuelidate/lib/validators'
import LazyHydrate from 'vue-lazy-hydration'
import product from '@/mixins/product'
import gtmProductPageMixin from '@/mixins/gtm/product-page'
import { createUniqueFBEventId } from '~/lib/util/fb'
import { getGTMViewItemPayload, getGTMRemarketingPayload, getGTMPageViewDetailsPayload, mapGTMGiftCardsOffers } from '~/lib/util/gtmUAEE'
import { getGA4EcommerceNullPayload, getGA4ViewItemPayload } from '~/lib/util/gtmGA4'
import { getBreadcrumbsMicrodataJSONLD } from '~/lib/util/microdata'

import { getStatusCodeFromPath, callAWSFrontendAPI } from '~/lib/util/aws'

export default {
  name: 'EGiftCard',
  fetchOnServer: true,
  components: {
    LazyHydrate,
    EGiftCardTemplate: () => import('~/components/giftcard/EGiftCardTemplate.vue')
  },
  mixins: [gtmProductPageMixin, product, validationMixin],
  validations: {
    selection: {
      amount: {
        input: {
          integer,
          between: between(10, 50)
        }
      },
      message: { minLength: minLength(1), maxLength: maxLength(120) }
    }
  },
  layout: '2Columns',
  async fetch() {
    // Skip the refresh client side that will trigger a CORS error
    if (this.isUpdate) {
      return
    }

    const status = getStatusCodeFromPath({ context: this })
    if (status !== 200) {
      if (process.server) {
        this.$nuxt.context.res.statusCode = status
      }
      this.$store.commit('UI/statusCode', status, { root: true })
      return
    }

    // Look for an available Query String and retrieve the URL path
    const path = '/magazines/gift-cards'
    const query = this.$nuxt.context.query
    const useCache = this.$store.getters['UI/useCache']
    console.log('page=GiftCard')
    console.log('path=', path)
    console.log('query=', query)
    console.log('QS useCache=', useCache)
    console.log('AWSAPIGatewayURL=', this.$nuxt.$config.AWSAPIGatewayURL)

    this.dataUI = {
      ...(await callAWSFrontendAPI({ context: this, path, query, useCache, type: 'PRODUCTPAGE' }))
    }

    // Breadcrumbs
    const breadcrumbs = [
      {
        first: true,
        value: 'magazine subscriptions',
        url: '/',
        last: false
      },
      {
        first: false,
        value: 'Gift Card Options',
        url: '/gift-card-options',
        last: false
      },
      {
        first: false,
        value: 'Gift Cards',
        url: '/magazines/gift-cards',
        last: true
      }
    ]
    this.$store.commit('ADD_BREADCRUMBS', { path: '/magazines/gift-cards', breadcrumbs })

    if (this.params?.id && this.params?.uuid) {
      // Quote Item Info by API -> M1 backend
      console.log('E-Gift Card action=edit params=', this.$route.params)
      this.$axios.setHeader('Content-Type', 'application/x-www-form-urlencoded', ['post'])
      this.$config.backenApiSecret && this.$axios.setHeader('emailSender-sls-backend-apiKey', this.$config.backenApiSecret)

      const data = {
        type: 'gift-card',
        id: this.$options.filters.sanitize(this.params.id),
        uuid: this.$options.filters.sanitize(this.params.uuid)
        // mock: true
      }

      const url = `${process.env.BACKEND_URL}/mcheckout/giftcard/index`
      const response = await this.$axios
        .$post(url, data)
        .then(function(response) {
          console.log('RESPONSE=', response)
          return response
        })
        .catch(function(error) {
          console.log('ERROR=', error)
          return error
        })

      this.isUpdate = false
      if (response?.amount) {
        this.isUpdate = true
        this.selection = { ...response }
        this.$v.$touch()
      } else {
        this.$router.push({ path: '/magazines/gift-cards' })
      }
    }
  },
  data() {
    return {
      togglePreviewEGiftCard: false,
      togglePreviewEGiftCardDesigns: false,
      isUpdate: false,
      isClicked: false,
      fromEmailEnabled: false,
      moreDesignSelected: false,
      designPreviewQueryString: '?auto=format&w=470&dpr=2',
      designThumbnailQueryString: '?auto=format&fit=fill&fill=solid&bg=0FFF&w=99&h64&dpr=2',
      errors: {
        amount: false,
        fromName: false,
        fromEmail: false,
        toName: false,
        toEmail: false,
        toDate: false,
        message: false
      },
      selection: {
        amount: {
          value: 20,
          id: 0,
          input: undefined
        },
        design: {
          index: 0,
          id: 'default',
          image: '/gift-card-images/JMAG_Giftcards.png',
          title: 'magazine.co.uk'
        },
        fromName: '',
        fromEmail: '',
        toName: '',
        toEmail: '',
        toDate: new Date(),
        message: ''
      },
      designs: [
        {
          title: 'magazine.co.uk',
          image: '/gift-card-images/JMAG_Giftcards.png',
          id: 'default'
        },
        {
          title: 'Happy Birthday LightBlue',
          image: '/gift-card-images/Birthday.png',
          id: 'bday-0'
        },
        {
          title: 'Thank You',
          image: '/gift-card-images/Birthday-1.png',
          id: 'bday-1'
        },
        {
          title: 'Happy Birthday Black',
          image: '/gift-card-images/Birthday-2.png',
          id: 'bday-2'
        },
        {
          title: 'Valentines',
          image: '/gift-card-images/Valentines.png',
          id: 'valentines-1'
        },
        {
          title: 'Valentines Hearts',
          image: '/gift-card-images/Valentines-1.png',
          id: 'valentines-2'
        },
        {
          title: 'From All Of Us',
          image: '/gift-card-images/From-all_of_us.png',
          id: 'from-all-of-us-1'
        },
        {
          title: 'Thank You',
          image: '/gift-card-images/Gift_card.png',
          id: 'thank-you-1'
        },
        {
          title: 'Xmas',
          image: '/gift-card-images/Xmas.png',
          id: 'xmas-0'
        },
        {
          title: 'Christmas Yellow',
          image: '/gift-card-images/Christmas.png',
          id: 'xmas-1'
        },
        {
          title: 'Christmas Blue',
          image: '/gift-card-images/Christmas-1.png',
          id: 'xmas-2'
        }
      ],
      image: undefined,
      metaTitleDefault: 'Gift Cards',
      dataAmountValuesDefault: [20, 30, 40, 50],
      amountToSKU: { 20: 2492, 30: 2491, 40: 2493, 50: 11502 },
      trackingItemsForUAEE: [
        {
          name: 'Gift Card - £20.00',
          id: '2492',
          price: '0.00',
          brand: 'Gift Card',
          category: '',
          sub_category: '',
          product_price: '20.00',
          product_rrp: '0.00',
          product_issues: 0,
          product_type: 'GIFTCARD',
          payment_type: 'CC',
          productId: '436',
          sku: '2492'
        },
        {
          name: 'Gift Card - £30.00',
          id: '2491',
          price: '0.00',
          brand: 'Gift Card',
          category: '',
          sub_category: '',
          product_price: '30.00',
          product_rrp: '0.00',
          product_issues: 0,
          product_type: 'GIFTCARD',
          payment_type: 'CC',
          productId: '386',
          sku: '2491'
        },
        {
          name: 'Gift Card - £40.00',
          id: '2493',
          price: '0.00',
          brand: 'Gift Card',
          category: '',
          sub_category: '',
          product_price: '40.00',
          product_rrp: '0.00',
          product_issues: 0,
          product_type: 'GIFTCARD',
          payment_type: 'CC',
          productId: '484',
          sku: '2493'
        },
        {
          name: 'Gift Card - £50.00',
          id: '11502',
          price: '0.00',
          brand: 'Gift Card',
          category: '',
          sub_category: '',
          product_price: '50.00',
          product_rrp: '0.00',
          product_issues: 0,
          product_type: 'GIFTCARD',
          payment_type: 'CC',
          productId: '13574',
          sku: '11502'
        }
      ],
      trackingItemsForGA4: [
        {
          item_name: 'Gift Card - £20.00',
          item_id: '2492',
          price: '0.00',
          currency: 'GBP',
          discount: '0',
          item_brand: 'Gift Card',
          item_category: '',
          item_categoyr2: '',
          product_price: '20.00',
          product_rrp: '0.00',
          product_issues: 0,
          product_type: 'GIFTCARD',
          payment_type: 'CC',
          productId: '436',
          sku: '2492'
        },
        {
          item_name: 'Gift Card - £30.00',
          item_id: '2491',
          price: '0.00',
          currency: 'GBP',
          discount: '0',
          item_brand: 'Gift Card',
          item_category: '',
          item_category2: '',
          product_price: '30.00',
          product_rrp: '0.00',
          product_issues: 0,
          product_type: 'GIFTCARD',
          payment_type: 'CC',
          productId: '386',
          sku: '2491'
        },
        {
          item_name: 'Gift Card - £40.00',
          item_id: '2493',
          price: '0.00',
          currency: 'GBP',
          discount: '0',
          item_brand: 'Gift Card',
          item_category: '',
          item_category2: '',
          product_price: '40.00',
          product_rrp: '0.00',
          product_issues: 0,
          product_type: 'GIFTCARD',
          payment_type: 'CC',
          productId: '484',
          sku: '2493'
        },
        {
          item_name: 'Gift Card - £50.00',
          item_id: '11502',
          price: '0.00',
          currency: 'GBP',
          discount: '0',
          item_brand: 'Gift Card',
          item_category: '',
          item_category2: '',
          product_price: '50.00',
          product_rrp: '0.00',
          product_issues: 0,
          product_type: 'GIFTCARD',
          payment_type: 'CC',
          productId: '13574',
          sku: '11502'
        }
      ],
      h1TitleDefault: 'Gift Card',
      canonicalURLDefault: 'https://www.magazine.co.uk/magazines/gift-cards',
      metaDescriptionDefault: `Give the perfect gift with this beautifully presented magazine gift card and pack. Shop our £20, £30, £40 & £50 gift cards on magazine.co.uk now.`,
      metaKeywordsDefault: 'Magazine.co.uk',
      isFromMobile: this.$ua.isFromIphone() || this.$ua.isFromAndroidMobile()
    }
  },

  computed: {
    dataAmountValues() {
      return this.dataAmountValuesDefault
    }
  },

  mounted() {
    const defaultOffer = {
      price: '20.00',
      sku: '2492'
    }

    // Facebook Event Deduplication
    const eventId = createUniqueFBEventId('PageView')
    const eventIdViewContent = createUniqueFBEventId('ViewContent')
    const eventIdProductDetail = createUniqueFBEventId('productDetail')

    // Customer email address
    const email = this.$store.getters['s/email']

    // UAEE tracking PageView
    this.$config.UAEESyntax &&
      this.$gtm &&
      this.$gtm.push(
        getGTMPageViewDetailsPayload({ pageUrl: '/magazines/gift-cards', pageTitle: 'Gift Cards - Magazine Subscription Gift Cards', routeName: this.$route.name, eventId, email })
      )

    // UAEE productView with the cheapest offer
    let data = getGTMViewItemPayload(defaultOffer)
    const products = mapGTMGiftCardsOffers({ offers: this.trackingItemsForUAEE })
    this.$config.UAEESyntax &&
      data &&
      this.$gtm &&
      this.$gtm.push({
        event: 'productView',
        ...data,
        ...products
      })

    // UAEE Measuring Views of Product Details
    // link https://developers.google.com/tag-manager/enhanced-ecommerce#details
    this.$config.UAEESyntax &&
      products &&
      this.$gtm &&
      this.$gtm.push({
        event: 'productDetail',
        ecommerce: {
          detail: {
            ...products
          }
        },
        eventID: eventIdProductDetail,
        eventID4ViewContent: eventIdViewContent
      })

    // GA4 Fire view_item event
    this.$config.GA4Syntax && this.$config.GA4FireEcommerceNull && this.$gtm && this.$gtm.push(getGA4EcommerceNullPayload())
    this.$config.GA4Syntax && this.$gtm && this.$gtm.push(getGA4ViewItemPayload({ rawItems: this.trackingItemsForGA4 }))

    // GTM fire remaketing tag with the cheapest offer
    // Based on https://magento.stackexchange.com/questions/82965/how-to-put-the-code-google-remarketing
    data = getGTMRemarketingPayload(defaultOffer)
    data &&
      this.$gtm &&
      this.$gtm.push({
        event: 'fireRemarketingTag',
        ...data
      })
  },
  created() {},
  methods: {
    createImgixURL(image, qs) {
      return `${this.$config.imgixURL}${image}${qs}`
    },

    checkValidity(field, el) {
      // console.log('checkValidity field=', field)
      // console.log('checkValidity el=', el)

      if (field === 'toDate') {
        // console.log('checkValidity el.$invalid=', el.$invalid)
        return (this.errors[field] = el.$invalid)
      }

      // console.log('checkValidity el.required=', el.required)
      // console.log('checkValidity el.$invalid=', el.$invalid)
      return (this.errors[field] = el.$invalid)
    },

    updateSelection(action, index, data) {
      switch (action) {
        case 'amount':
          this.selection.amount.id = index
          this.selection.amount.value = Number(data)
          if (index > 0) {
            this.selection.amount.input = undefined
          }
          break
        case 'design':
          this.selection.design = {
            index,
            ...data
          }
          break
        default:
          break
      }
    },
    formatInput(value) {
      // console.log('formatInput value=', value)
      let numericValue = parseInt(value.replace('£', ''))
      if (isNaN(numericValue)) {
        numericValue = undefined
        this.selection.amount.input = undefined
        this.updateSelection('amount', -1, numericValue)
      }
      // console.log('formatInput numericValue=', numericValue)
      if (numericValue > 0) {
        this.updateSelection('amount', -1, numericValue)
        this.selection.amount.input = `${numericValue}`
      }
      return undefined
    },
    focusFirstStatus(component = this) {
      if (component.status) {
        component.$el.focus()
        return true
      }

      let focused = false

      component.$children.some(childComponent => {
        focused = this.focusFirstStatus(childComponent)
        return focused
      })

      return focused
    },
    validate() {
      this.$v.$touch()
      this.$nextTick(() => this.focusFirstStatus())
    },

    async handleUpdateSelection() {
      this.isClicked = true

      const item = {
        title: 'Gift Card',
        productId: 'gift-card',
        id: 'GIFT',
        sku: 'gift-card',
        offer: {
          productId: 'gift-card',
          price: Number(this.selection.amount.value).toFixed(2),
          cpa: '0.00',
          ...this.selection,
          sku: this.amountToSKU[parseInt(this.selection.amount.value)] + '',
          rrp: '0.00',
          numOfIssues: 0,
          type: 'GIFTCARD',
          paymentType: 'CC'
        },
        category: '',
        subCategory: ''
      }

      // // Default message
      // if (!item.offer.message) {
      //   item.offer.message = 'Enjoy this magazine.co.uk Gift Card!'
      // }

      const action = (this.isUpdate && 'update') || 'add'

      console.log('giftcard handleClickAction item=', item)
      console.log('giftcard handleClickAction action=', action)

      // Update the BE Basket data
      const ret = await this.updateBasket(item, action)

      if (!ret.error && !this.isUpdate) {
        this.selection = {
          amount: {
            value: 20,
            id: 0,
            input: undefined
          },
          message: ''
        }
      }

      // GTM & FB push events integration
      !ret.error && this.pushGTMAndFBAfterButtonClick({ action, item })

      // Show the basket overlay or an error notification
      if (ret.error) {
        console.log('ERROR', ret.error)
        this.$nuxt.$emit('SHOW_NOTIFICATION', { data: ret.error, show: true })
      } else {
        this.$router.push({ path: '/gift-card-options?showBasket=1' })
      }

      this.isClicked = false
    }
  },

  // To check more options, link https://github.com/nuxt/vue-meta#script-object
  // To chcek how it is working https://nuxtjs.org/faq/duplicated-meta-tags/
  head() {
    return {
      title: this.dataUI?.title || '',
      link: [{ rel: 'canonical', href: this.dataUI?.magazine?.canonicalURL }],
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.dataUI?.magazine?.metaDescription || ''
        },
        {
          name: 'keywords',
          content: this.dataUI.metaKeywords || ''
        },
        {
          name: 'robots',
          content: this.$config.robots || 'INDEX,FOLLOW'
        },
        {
          hid: 'og:title',
          name: 'og:title',
          property: 'og:title',
          content: this.dataUI?.title || ''
        },
        {
          hid: 'og:description',
          name: 'og:description',
          property: 'og:description',
          content: this.dataUI?.magazine?.metaDescription || ''
        },
        {
          hid: 'og:site_name',
          name: 'og:site_name',
          property: 'og:site_name',
          content: 'magazine.co.uk'
        },
        {
          hid: 'og:image',
          name: 'og:image',
          property: 'og:image',
          content: 'https://magazine.imgix.net/gift-card-images/JMAG_Giftcards.png?auto=format'
        }
      ]
    }
  },

  jsonld() {
    return [getBreadcrumbsMicrodataJSONLD({ breadcrumbs: this.$store.getters['UI/breadcrumbs'] })]
  }
}
</script>
<style lang="scss">
.container--red {
  border: 2px solid #ff0d00;
  border-radius: 10px;
  padding: 10px;
}
.m-popover-lightbox {
  z-index: 1008;
  opacity: 0.5;
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: #0f1111;
}
.m-section:last-child {
  margin-bottom: 0px;
}
.m-section {
  margin-bottom: 10px;
}
.m-section-no-margin {
  margin-bottom: 0;
}
.m-text-center {
  text-align: center !important;
}
.m-spacing-bottom-big {
  margin-bottom: 30px;
}
.m-spacing-top-medium {
  margin-top: 16px !important;
}
.m-spacing-top-big {
  margin-top: 30px !important;
}
.m-color-1 {
  color: $gcTextColor1 !important;
}
.m-color-2 {
  color: $gcTextColor2 !important;
}
.m-color-3 {
  color: $gcTextColor3 !important;
}
.m-text-error {
  color: $gcTextColorError !important;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
}
.m-text {
  &--chars-remaining {
    text-align: right;
    font-size: 12px;
    font-weight: 400;
  }
}
.m-button-close {
  float: right;
  top: 5px;
  position: absolute;
  right: 5px;
  width: 24px;
  padding: 23px;
  cursor: pointer;
}
.m-text-bold {
  font-weight: 700;
}
.m-no-margin {
  margin: 0 !important;
}
.m-margin-left-20percent {
  margin-left: 20%;
}
.m-button {
  background-color: white;
  border-radius: 6px;
  border: 1px solid $gcBorderColor;
  cursor: pointer;
  display: inline-block;
  padding: 0;
  text-align: center;
  text-decoration: none !important;
  vertical-align: middle;
  &__addtobasket {
    border: unset;
    text-align: center;
    padding: 0px 0;
    &:hover {
      opacity: 0.7;
      filter: alpha(opacity=70);
    }
  }
  &__preview {
    border: 1px solid transparent;
    text-align: center;
    padding: 0px 0;
    &:hover {
      opacity: 0.7;
      filter: alpha(opacity=70);
    }
  }
}
.m-button-selected {
  outline: 0;
  overflow: visible;
  border: 2px solid $gcItemSelectBorderColor;
  background-color: $gcItemSelectBgColor;
}
.m-button-inner {
  background-color: transparent;
  background-image: none;
}
.m-button-text {
  padding: 10px;
  background-color: transparent;
  border: 0;
  display: block;
  line-height: 19px;
  margin: 0;
  outline: 0;
  text-align: center;
  white-space: nowrap;
  &__addtobasket {
    text-transform: uppercase;
    text-align: center;
    font-size: 12px;
    font-weight: 600;
    background: $gcButtonColor;
    color: white;
    line-height: 19px;
    margin: 0;
    text-decoration: none;
    min-width: 150px;
    padding: 10px 15px;
    &--mobile {
      width: 100%;
      margin: 0;
    }
  }
  &__preview {
    text-transform: uppercase;
    text-align: center;
    font-size: 12px;
    font-weight: 600;
    line-height: 19px;
    margin: 0;
    text-decoration: none;
    min-width: 150px;
    padding: 10px 15px;
    background-color: $gcBgColorDisabled;
    color: $gcTextColor1;
    &--mobile {
      width: 100%;
      margin: 0;
    }
  }
}
.m-input {
  background-color: white;
  border: 1px solid $gcBorderColor;
  cursor: pointer;
  display: inline-block;
  padding: 0;
  text-align: center;
  text-decoration: none !important;
  vertical-align: middle;
  &--noborder {
    border: unset;
  }
  &--border {
    border: 1px solid $gcBorderColor !important;
  }
}
.m-input-text {
  &--big {
    padding: 6px;
    font-size: 20px;
  }
  &--medium {
    padding: 9px;
    font-size: 16px;
  }
  &--normal {
    padding: 10px;
    font-size: 16px;
  }
  background-color: transparent;
  border: 0;
  display: block;
  line-height: 20px;
  margin: 0;
  outline: 0;
  text-align: left;
  white-space: nowrap;
}
.m-textarea {
  background-color: transparent;
  border: 0;
  display: block;
  line-height: 20px;
  margin: 0;
  outline: 0;
  text-align: left;
  width: 100%;
  resize: vertical;
  word-break: break-all;
  overflow: auto;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
}
.m-input-selected {
  outline: 0;
  overflow: visible;
  border: 2px solid $gcItemSelectBorderColor;
  background-color: $gcItemSelectBgColor;
}
.m-input-error {
  outline: 0;
  overflow: visible;
  border: 2px solid $gcTextColorError;
  background-color: $gcTextColorBgColor;
  .m-nofocus {
    &:focus {
      outline: 0;
      box-shadow: 0 0 3px 2px $gcTextColorError;
    }
  }
}
.m-nofocus {
  &:focus {
    outline: 0;
    box-shadow: 0 0 3px 2px $gcItemSelectBorderColor;
  }
}
.m-button-thumbnail-small {
  width: 100%;
  max-width: 100px;
  border-radius: 4px;
}
.m-button-thumbnail-large {
  width: 100%;
  border-radius: 4px;
}
.m-padding-2 {
  padding: 2px;
}
.m-padding-10 {
  padding: 0 10px;
}
.m-flex {
  display: flex;
}
.m-flex-stretch {
  align-items: stretch;
}
.m-flex-justify-content-center {
  justify-content: center;
}
.m-border-radius-right {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}
.m-border-radius-left {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}
.m-border-radius {
  border-radius: 6px;
}
.m-date-picker {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: auto;
  background-color: transparent;
  display: block;
  line-height: 22px;
  margin: 0;
  outline: 0;
  text-align: left;
  white-space: nowrap;
  max-width: 110px;
}

button:disabled,
button[disabled] {
  // border: 1px solid #ff0d00;
  // background-color: #f3d4d2;
  color: white;
  opacity: 0.7;
}

#giftcard {
  width: 100%;
}
#gc-live-preview-container {
  cursor: default !important;
}
#gc-buttons-wrapper {
  display: flex;
  flex-flow: column-reverse nowrap;
  justify-content: flex-start;
  align-content: center;
}
#gc-detail-page__required {
  display: none;
  &--mobile {
    display: block;
    float: right;
    position: relative;
    top: 0;
    right: 0;
    font-size: 14px;
    margin-right: 20px;
    color: $gcTextColor1;
  }
}
.vdp-datepicker__calendar {
  left: -50px;
}

.gc-live-preview-content {
  &__egiftcard {
    background-color: #ededed;
    margin: 10px;
    padding: 10px;
    display: flex;
    align-items: center;
    border-radius: 6px;
    border: 1px solid #ddd;
    margin-top: 30px;
    box-shadow: 0 4px 10px -3px #ddd;
    justify-content: center;
  }
  a {
    color: #212529;
  }
}
#gc-detail-page-right-column {
  padding: 10px;
  ul.gc-detail-page__list {
    margin-left: 20px;
    padding: 0;
  }
  .gc-detail-page {
    &__title {
      display: none;
      // font-weight: 400;
      font-style: normal;
      font-size: 23px;
      color: $headingColor;
    }
    &__description {
      display: none;
      // font-weight: 400;
      font-style: normal;
      font-size: 14px;
    }
    &__step {
      font-weight: 600;
      font-style: normal;
      font-size: 16px;
      max-width: 595px;
    }
    &__wrapper {
      margin-top: 10px;
    }
    &__list {
      list-style-type: none;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-flow: row wrap;
      &--stretch {
        align-items: stretch;
      }
    }
    &__list--form {
      display: block;
    }
    &__list-other {
      // display: inline-table;
      margin-right: 10px;
      display: flex;
      flex-flow: row nowrap;
    }
    &__list-item {
      display: flex;
      flex-flow: column nowrap;
      margin-right: 15px;
      font-size: 20px;
      button {
        border-radius: 6px;
      }
      &--text {
        margin-top: 10px;
        max-width: 90%;
      }
    }
    &__button-more {
      width: 100%;
      padding: 10px;
      height: 100%;
      border-radius: 6px;
      border: 1px solid $gcBorderColor;
    }
    &__input-currency {
      background-color: #f6f6f6;
      padding: 8px 6px;
      white-space: nowrap;
      vertical-align: middle;
      font-size: 20px;
      margin: 0px;
      line-height: 21px;
      border-color: $gcBorderColor;
      border-style: solid;
      border-width: 1px 0 1px 1px;
    }
    &__input-calendar {
      background-color: #f6f6f6;
      padding: 6px 7px;
      line-height: 1;
      width: 42px;
      white-space: nowrap;
      vertical-align: middle;
      font-size: 20px;
      margin: 0px;
      border-color: $gcBorderColor;
      border-style: solid;
      border-width: 1px 0 1px 1px;
    }
    &__form-label {
      float: left;
      text-align: left;
      width: 100%;
      padding: 10px;
      font-size: 16px;
      margin-left: 20px;
      color: $gcTextColor1;
    }
    &__form-field {
      margin-left: 20px;
      width: 90%;
      max-width: 700px;
      input {
        width: 100%;
      }
    }
    &__strike {
      display: block !important;
    }
  }
}

@media (max-width: 320px) {
  .m-input-text--medium {
    padding: 9px;
    font-size: 15px;
  }
  #gc-detail-page-right-column {
    .gc-detail-page {
      &__list-item {
        &:nth-child(3) {
          display: none;
        }
      }
      &__step {
        font-weight: 400;
        font-style: normal;
        font-size: 16px;
      }
    }
  }
}
@media (min-width: $screen-sm-min) {
  .container--red {
    display: block;
  }
  .m-input-text--medium {
    padding: 9px;
    font-size: 15px;
  }
  .gc-container {
    max-width: 1230px !important;
    display: flex;
    flex-flow: column nowrap;
    align-content: center;
  }
  #giftcard {
    width: 100%;
    #gc-detail-page {
      margin: 5px 0px;
      max-width: 1230px !important;
      display: flex;
      flex-flow: row nowrap;
      #gc-detail-page-left-column {
        padding: 5px 10px;
        flex: 1 1 40%;
        max-width: 500px;
      }
      #gc-detail-page-right-column {
        flex: 1 1 60%;
        padding: 5px 10px;
      }
      #gc-buttons-wrapper {
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-content: center;
        margin-bottom: 20px;
      }
      #gc-detail-page__required {
        display: block;
        float: right;
        position: relative;
        top: 0;
        right: 0;
        font-size: 14px;
        margin-right: 20px;
        color: $gcTextColor1;
      }
      #gc-detail-page__date-picker {
        display: flex;
        flex-flow: column nowrap;
        align-content: center;
        .vdp-datepicker div {
          .vdp-datepicker__clear-button span i span {
            padding: 10px;
            float: right;
            top: -42px;
            bottom: 0px;
            position: relative;
            font-style: normal;
            font-size: 25px;
            font-weight: 400;
            color: black;
          }
        }
        .vdp-datepicker__calendar {
          left: 0px;
        }
      }
      #gc-detail-page__date-picker-wrapper {
        max-height: 44px;
      }
    }

    #gc-detail-page-right-column {
      .gc-detail-page {
        &__title {
          display: block;
          // font-weight: 400;
          font-style: normal;
          font-size: 23px;
          color: $headingColor;
        }
        &__description {
          display: block;
          // font-weight: 400;
          font-style: normal;
          font-size: 14px;
        }
        &__step {
          font-weight: 600;
          font-style: normal;
          font-size: 16px;
          max-width: 595px;
        }
        &__wrapper {
          margin-top: 15px;
        }
        &__list {
          list-style-type: none;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          flex-flow: row nowrap;
          &--stretch {
            align-items: stretch;
          }
        }
        &__list--form {
          display: block;
        }
        &__list-other {
          display: inline-table;
          margin-right: 10px;
        }
        &__list-item {
          display: flex;
          flex-flow: row nowrap;
          margin-right: 15px;
          font-size: 15px;
          button {
            border-radius: 6px;
          }
          &--text {
            margin-top: 0px;
            max-width: 210px;
          }
          &--delivery {
            font-size: 12px;
          }
        }
        &__button-more {
          width: 100%;
          padding: 10px;
          height: 100%;
          border-radius: 6px;
          border: 1px solid $gcBorderColor;
        }
        &__input-currency {
          background-color: #f6f6f6;
          padding: 6px 7px;
          line-height: 1;
          width: 1%;
          white-space: nowrap;
          vertical-align: middle;
          font-size: 20px;
          margin: 0px;
          border-color: $gcBorderColor;
          border-style: solid;
          border-width: 1px 0 1px 1px;
        }
        &__input-calendar {
          background-color: #f6f6f6;
          padding: 6px 7px;
          line-height: 1;
          width: 42px;
          white-space: nowrap;
          vertical-align: middle;
          font-size: 20px;
          margin: 0px;
          border-color: $gcBorderColor;
          border-style: solid;
          border-width: 1px 0 1px 1px;
        }
        &__form-label {
          float: left;
          text-align: left;
          width: 130px;
          padding: 10px;
          font-size: 15px;
          margin-left: 30px;
          color: $gcTextColor1;
        }
        &__form-field {
          margin-left: 10px;
          width: 55%;
          input {
            width: 100%;
          }
        }
      }
    }

    .magazine {
      position: relative;
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-start;

      align-items: flex-start;

      align-content: space-between;
      margin-bottom: 20px;

      &__info {
        margin-bottom: 20px;
        .tab-content ul {
          display: block;
          list-style-type: disc;
          margin-block-start: 1em;
          margin-block-end: 1em;
          margin-inline-start: 0px;
          margin-inline-end: 0px;
          padding-left: 40px;
        }

        .nav-item {
          a,
          a::before,
          a::after {
            box-sizing: unset !important;
            font-size: 14px;
          }
        }

        .nav-link {
          color: #212529;

          &:hover {
            border-color: $borderColor $borderColor white !important;
            color: #212529 !important;
          }
        }
        .nav-tabs {
          background: #ededed;
          border-bottom: unset;
        }
        .nav {
          flex-wrap: nowrap;
        }
        .tab-content {
          border-radius: 0 0 0.25rem 0.25rem;
          border: 1px solid #dee2e6;
        }
        .tab-text {
          font-size: 14px;
          p:last-child {
            margin-bottom: 0px;
          }
        }
      }
    }
    .product {
      &__recentlyViewed {
        .product__offer {
          display: none;
        }
        .actions {
          display: none;
        }
      }
      &__upsell {
        flex: 1 1 100%;
        padding-top: 20px;
        z-index: 100;
        transform: none;
        // width: 400px;
        height: auto;

        position: relative;
        clear: both;
        background-color: #fff;
        text-align: center;
      }
      &__upsell-heading {
        margin: 0 0 10px;
        letter-spacing: 0.1em;
        font-weight: 700;
        padding: 10px;
      }
    }
    .upsell-grid {
      .items-list {
        &__item {
          padding: 0px 20px;
          flex: 1 0 20%;
          max-width: 170px;
          min-width: 100px;
          .actions button {
            background-color: transparent;
            text-align: center;
            font-size: 12px;
            color: $buttonAddToCartColor;
            line-height: 12px;
            margin: 0em;
            text-decoration: underline;
            min-width: 100px;
            padding: 15px 0px;
            border: unset;

            &:hover {
              text-decoration: none;
            }
            .spinner {
              top: 40%;
            }
          }
          .view-btn {
            display: none;
          }
          .btn-divider {
            display: none;
          }
        }
      }
    }
  }
}
</style>
