<template>
  <div v-if="offer.name" :class="styledClass">
    <div class="pdp-left">
      <div class="pdp-name">{{ offer.name }}</div>
      <div v-if="offer.save" class="pdp-saving">{{ `Saving ${formatCurrency(offer.rrp - offer.price)}` }}</div>
      <div class="red-text pdp-price">
        {{ formatCurrency(offer.price) }}
        <!-- <img
          v-if="offer.freeGiftEnabled && isDefault === '0'"
          :alt="offer.freeGiftDescription"
          class="free-gift"
          src="https://magazine.imgix.net/wysiwyg/free_gift.png?auto=format&fit=fill&fill=solid&bg=0FFF&w=100"
        /> -->
      </div>
    </div>
    <div class="pdp-right">
      <div class="payment">
        <PaymentImages
          :is-mobile="true"
          :offer-payment-type="offer.paymentType"
          :is-pdp="styledClass === 'pdp'"
          :is-giftcard="offer.sku === 'gift-card' || offer.sku === 'e-giftcards'"
        />
      </div>
    </div>
    <div v-if="offer.freeGiftEnabled" class="pdp-free-gift">
      <span><strong>Free Gift Details</strong></span
      ><br />
      <span class="green-text">{{ offer.freeGiftDescription }}</span>
    </div>
  </div>
</template>

<script>
import { formatCurrency } from '~/lib/util/basket'
export default {
  name: 'MobileOfferDefault',
  components: {
    PaymentImages: () => import('~/components/common/product/PaymentImages.vue')
  },
  props: {
    offer: {
      type: Object,
      required: true,
      default() {
        return {}
      }
    },
    magazine: {
      type: Object,
      required: true,
      default() {
        return {}
      }
    },
    isDefault: {
      type: String,
      required: false,
      default() {
        return '0'
      }
    },
    styledClass: {
      type: String,
      required: false,
      default() {
        return 'pdp'
      }
    }
  },
  data() {
    return {}
  },
  computed: {},
  methods: {
    formatCurrency(value) {
      return formatCurrency(value)
    }
  }
}
</script>

<style lang="scss">
@media (max-width: $screen-xs-max) {
  .text-small {
    // font-size: 14px;
  }
  .pdp {
    align-items: center;
    justify-content: center;
    div {
      margin-bottom: 10px;
    }
    display: flex;
    flex-wrap: wrap;

    padding: 20px 10px 0 10px;
    border-bottom: solid 1px $table-border;

    &-title h1 {
      font-size: 18px;
      color: #333;
      margin: 5px 0;
      font-weight: 700;
      line-height: 20px;
    }
    &-name {
      color: #666;
      font-size: 12px;
    }
    &-saving {
      color: #666;
      font-weight: 700;
      font-size: 16px;
    }
    &-price {
      margin: 10px 0px;
      color: #e20c0b;
      font-weight: 700 !important;
      font-size: 24px !important;
    }
    &__default {
      padding: 0 10px;
      align-items: flex-start;
      flex-flow: column;
      border-bottom: unset;
      .offer-payment-type--cc {
        min-width: 170px;
        font-size: 16px !important;
      }

      .pdp-felt {
        flex: 1 1 100%;
      }
      .pdp-right {
        flex: 1 1 100%;
        align-items: flex-start;
        button {
          width: 300px;
        }
      }
    }
  }
  .pdp-left {
    flex: 1 1 50%;
  }
  .pdp-right {
    display: flex;
    flex-flow: column;
    align-items: left;
    justify-content: center;
    flex: 1 1 25%;
  }
  .pdp-free-gift {
    flex: 1 1 100%;
    padding: 0px 10px 10px 0px;
    font-size: 14px;
    border-top: unset;
  }
}
</style>
