<template>
  <div class="category-products items-list clp-list">
    <Products
      v-for="(item, index) in infiniteLoadingValues"
      :key="`${index}::${item.productId}`"
      :type="'clp'"
      :item="item"
      :category-url="$route.path"
      class="clp-list__item items-list__item"
    />
    <client-only>
      <br />
      <infinite-loading spinner="waveDots" :distance="300" @infinite="infiniteHandler"></infinite-loading>
    </client-only>
  </div>
</template>

<script>
import { azureCategoryProductsSearch, createAzureSearchQuery, getOrderbyAzureSearchOption, getCategoryProductQuery, mapAzuresearchResultsToProduct } from '~/lib/util/azuresearch'

export default {
  name: 'PocketmagsPlusProductsList',
  serverCacheKey() {
    return false
  },
  // serverCacheKey: props => `CLPProductsList::${props.categoryId}::${props.pageCount}`,
  components: {
    Products: () => import('~/components/pocketmagsplus/PocketmagsPlusProduct.vue')
  },
  props: {
    values: {
      type: Array,
      required: false,
      default() {
        return this.$store.getters['category/products']
      }
    },
    categoryId: {
      type: Number,
      required: true,
      default() {
        return 0
      }
    },
    pageCount: {
      type: Number,
      required: true,
      default() {
        return 1
      }
    }
  },
  data() {
    return {
      infiniteLoadingValues: process.server ? [...this.values] : [...this.values].splice(0, this.$nuxt.$config.PocketmagsPlusCLPpagesize),
      localValues: [...this.values].slice(this.$nuxt.$config.PocketmagsPlusCLPpagesize),
      page: 1
    }
  },
  created() {
    this.$nuxt.$on('RESET_INFINITE_LOADING', newValues => {
      // console.log('RESET_INFINITE_LOADING newValues=', newValues)
      if (newValues && newValues.length) {
        this.infiniteLoadingValues = [...newValues]
        this.localValues = [...newValues]
      } else {
        this.infiniteLoadingValues = []
        this.localValues = []
      }
      // console.log('RESET_INFINITE_LOADING this.infiniteLoadingValues=', this.infiniteLoadingValues)
    })
  },
  beforeDestroy() {
    this.$nuxt.$off('RESET_INFINITE_LOADING')
  },
  methods: {
    async infiniteHandler($state) {
      // we will add an inifnite scroll Logic  to minimise the HTML payload
      if (this.page === 1) {
        const data = this.localValues.splice(0, this.$nuxt.$config.PocketmagsPlusCLPpagesize)
        // console.log('PocketmagsPlusProductsList > infiniteHandler ADD data=', data)
        // console.log('PocketmagsPlusProductsList > this.localValues.length=', this.localValues.length)
        // console.log('PocketmagsPlusProductsList > this.infiniteLoadingValues data=', this.infiniteLoadingValues)
        // console.log('PocketmagsPlusProductsList > this.infiniteLoadingValues data=', this.infiniteLoadingValues)
        this.infiniteLoadingValues.push(...data)
        $state.loaded()
      } else if (this.page <= this.pageCount) {
        // console.log(`PocketmagsPlusProductsList > this.page=`, this.page)
        // console.log(`PocketmagsPlusProductsList > this.pageCount=`, this.pageCount)
        // Get the orderby search option
        const query = this.$nuxt.context.query
        const category = { id: 9999 }
        const orderby = query && getOrderbyAzureSearchOption({ query })
        const azureQuery = getCategoryProductQuery(createAzureSearchQuery({ query, category }))
        console.log('azureQuery=', azureQuery)
        console.log('orderby=', orderby)
        await azureCategoryProductsSearch(
          { searchTerm: azureQuery, orderby, page: this.page, viewAll: false, noFacets: true, top: this.$nuxt.$config.PocketmagsPlusCLPpagesize },
          (error, data) => {
            if (data) {
              const newData = data.value.map(product => {
                return mapAzuresearchResultsToProduct(product)
              })
              // console.log(newData)
              this.infiniteLoadingValues.push(...newData)
              $state.loaded()
            } else if (error) {
              console.log(error)
              $state.complete()
            }
          }
        )
      } else {
        $state.complete()
      }
      this.page++
    }
  }
}
</script>

<style lang="scss">
.infinite-loading-container {
  flex: 0 0 100%;
}
</style>
