<template>
  <div>
    <Nav :is-home-page="isHomepage" />

    <SidebarGiftCards v-if="isHomepage" creative-slot="left_nav" location-id="sitewide_giftcard" />
    <div class="block sidebar-security">
      <span style="font-size: 1.5em; font-style: italic; font-weight: bold; color: #333; padding-bottom: 10px;">You get...</span>
      <ul>
        <li><a :href="`${$config.baseURL}/privacy-notice`" class="security-privacy" title="Privacy Notice">Safe &amp; secure ordering</a></li>
        <li><a :href="`${$config.baseURL}/discounted-magazine-sale-offers`" class="security-offers" title="Special Offers">Huge savings</a></li>
        <li><a :href="`${$config.baseURL}/delivery-policy`" class="security-delivery" title="Delivery Policy">FREE delivery</a></li>
        <li>
          <a :href="`${$config.baseURL}/free-ecard`" class="security-gift" title="Gift Card">
            To create<br />
            an eCard
          </a>
        </li>
      </ul>
    </div>
    <div class="block the-hub">
      <a :href="`${$config.baseURL}/the-hub`" title="The Hub" @click.prevent="handleTheHubClick">
        <BImageLazyImgix alt="The Hub" image-url="/wysiwyg/JMAG-the-hub-cta.png" query-string="&w=175" width="175" height="61" blank-color="#ededed" />
      </a>
    </div>
  </div>
</template>

<script>
import { getGA4EcommerceNullPayload, getGA4SelectPromotionPayload } from '~/lib/util/gtmGA4'

export default {
  name: 'Left',
  serverCacheKey: props => `Left-isHomepage${props.isHomepage}`,
  components: {
    Nav: () => import('~/components/common/left/Nav.vue'),
    BImageLazyImgix: () => import('~/components/common/BImageLazyImgix'),
    SidebarGiftCards: () => import('~/components/giftcard/SidebarGiftCards')
  },
  props: {
    isHomepage: {
      type: Boolean,
      required: false,
      default() {
        return false
      }
    }
  },

  data() {
    return {}
  },

  methods: {
    handleTheHubClick() {
      // UAEES event for every Banner Image Click
      this.$config.UAEESyntax && this.$gtm && this.$gtm.push({ event: 'thehub' })

      // GA4 events for every  Banner Image Click
      const banner = { id: '1', alt: 'SITEWIDE - The HUB', key: 'the_hub' }
      this.$config.GA4Syntax && this.$config.GA4FireEcommerceNull && this.$gtm && this.$gtm.push(getGA4EcommerceNullPayload())
      this.$config.GA4Syntax && this.$gtm && this.$gtm.push(getGA4SelectPromotionPayload({ banner, locationId: 'thehub_hero_banner' }))

      window.location.href = `${this.$config.baseURL}/the-hub`
    }
  }
}
</script>

<style lang="scss">
ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.sidebar-security {
  width: auto;
  background: #ededed;
  padding: 10px;
  display: grid;
  max-width: 175px;
  h2 {
    font-style: italic;
    font-weight: bold;
    font-family: $fontRegular;
    color: $bodyTextColor;
  }
  ul {
    list-style: none;
    li {
      float: left;
      margin-right: 10px;
      a {
        @include inline-block;
        padding: 10px 0 0 54px;
        min-height: 54px;
        text-decoration: none;
        font-size: 14px;
        font-family: $fontRegular;
        color: $bodyTextColor;
        background: url('~assets/images/sidebar_home_security.jpg') no-repeat;
        &.security-offers {
          background-position: 0 -54px;
        }
        &.security-delivery {
          background-position: 0 -108px;
        }
        &.security-gift {
          background-position: 0 -164px;
        }
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
.customer-feedback {
  margin-bottom: 20px;
  img {
    width: 100%;
    height: 100%;
  }
}
.block {
  margin-bottom: 20px;
}
</style>
