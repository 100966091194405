<template>
  <div v-if="showOverlay" id="nonBrowser">
    <div class="overlayPreview">
      <h3>Service Update</h3>
      <p>Due to a recent update, we are no longer able to support Internet Explorer.</p>
      <br />
      <p>magazine.co.uk can be used in the following browsers, all of which can be downloaded and used for free.</p>
      <br />
      <div class="browsers">
        <div class="browser">
          <a class="image" href="http://www.google.com/chrome/" target="_blank">
            <img src="//mcgeneral.azureedge.net/html5reader/content/logo-chrome.png" class="browserimg" />
          </a>
          <a class="browser-title" href="http://www.google.com/chrome/" target="_blank">Google Chrome</a>
        </div>
        <div class="browser">
          <a class="image" href="http://www.firefox.com" target="_blank">
            <img src="//mcgeneral.azureedge.net/html5reader/content/logo-firefox.png" class="browserimg" />
          </a>
          <a class="browser-title" href="http://www.firefox.com" target="_blank">FireFox</a>
        </div>
        <div class="browser">
          <a class="image" href="http://www.opera.com" target="_blank">
            <img src="//mcgeneral.azureedge.net/html5reader/content/logo-opera.png" class="browserimg" />
          </a>
          <a class="browser-title" href="http://www.opera.com" target="_blank">Opera</a>
        </div>
        <div class="browser">
          <a class="image" href="https://www.microsoft.com/en-us/windows/microsoft-edge" target="_blank">
            <img src="//mcgeneral.azureedge.net/html5reader/content/logo-edge.png" class="browserimg" />
          </a>
          <a class="browser-title" href="https://www.microsoft.com/en-us/windows/microsoft-edge" target="_blank">Microsoft Edge</a>
        </div>
        <div class="browser">
          <a class="image" href="https://support.apple.com/downloads/#safari" target="_blank">
            <img src="//mcgeneral.azureedge.net/html5reader/content/logo-safari.png" class="browserimg" />
          </a>
          <a class="browser-title" href="https://support.apple.com/downloads/#safari" target="_blank">Safari (macOS 10.8+ Only)</a>
        </div>
      </div>
      <div style="clear: both"></div>
      <p>Please download (or launch) one of these browsers, and launch magazine.co.uk</p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showOverlay: false
    }
  },

  created() {
    this.$nuxt.$on('SHOW_NON-BROWSER', value => {
      console.log('INSIDE SHOW_NON-BROWSER value=', value)
      this.showOverlay = value
    })

    // Check for IE compatibility, otherwise show an overlay
    // console.log('browser=', this.$ua.browser())
    if (this.$ua.browser() === 'Internet Explorer') {
      this.$nuxt.$emit('SHOW_NON-BROWSER', true)
    }
  }
}
</script>

<style lang="scss" scoped>
#nonBrowser {
  overflow: auto;
  max-height: 100vh;
  position: fixed;
  z-index: 2147483647;
  top: 0;
  left: 0;
  visibility: visible;
  width: 100%;
  height: 100%;
  transition: opacity 0.25s ease-in-out, visibility 0.25s ease-in-out;
  opacity: 1;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  flex-flow: row;
  justify-content: center;
}
.overlayPreview {
  h3 {
    font-size: 22px;
    margin-bottom: 10px;
  }
  flex: 0 1 50%;
  background: white;
  align-self: center;
  max-width: 700px;
  max-height: 700px;
  padding: 20px;
  border-radius: 10px;
}
.browser {
  float: left;
  width: 33%;
  height: 70px;
  margin-bottom: 20px;
  padding: 10px;
  box-sizing: border-box;
  text-align: left;
}
.browser:hover {
  background-color: #efefef;
  border-radius: 8px;
  -moz-border-radius: 8px;
  -webkit-border-radius: 8px;
}
.browserimg {
  float: left;
  width: 50px;
  margin-right: 10px;
}
.browser-title {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 17px;
  padding-left: 6px;
  display: table-cell;
  vertical-align: middle;
  height: 52px;
  text-align: left;
  text-decoration: underline;
}
</style>
