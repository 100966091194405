<template>
  <div class="container">
    <div class="newsletter-container">
      <form id="newsletterSignUpForm" action="" novalidate="true" :disabled="friendlyCaptchaSolution === undefined" @click.prevent="!isClicked && onSubmit()">
        <input ref="campaignRef" name="campaign_ref" type="hidden" value="JFOT" />
        <div class="newsletter-form">
          <div v-if="!show">
            <p>Sign up to receive the latest offers</p>
            <div class="newsletter-form-fields">
              <input
                id="emailsocial"
                v-model="form.email"
                class="newsletter-form-fields__email"
                :class="{ hasError: $v.form.email.$error }"
                type="email"
                aria-label="Enter email"
                name="email"
                placeholder="Enter email"
              />
              <input type="submit" class="newsletter-form-fields__submit" value="SIGN UP" />
            </div>
            <p v-if="$v.form.email.$error" :class="{ hasError: $v.form.email.$error }">Please insert a valid email address</p>
          </div>
          <transition name="fade">
            <div v-if="show">
              <div class="newsletter-form__done">Thank You<br />Your details have been submitted for our newsletter</div>
            </div>
          </transition>
        </div>
      </form>
      <p class="newsletter-form__privacy-notice">
        For further information on how we process your personal data, please see our <a href="privacy-notice" alt="Privacy Notice">Privacy Notice</a>
      </p>
      <client-only v-if="!show">
        <div class="friendlycaptcha flex-center">
          <div ref="captcha" class="vue-friendly-captcha frc-captcha" />
        </div>
      </client-only>
    </div>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, email } from 'vuelidate/lib/validators'
import { getBreadcrumbsMicrodataJSONLD } from '~/lib/util/microdata'
import { friendlyCaptchaSiteverify } from '~/lib/util/friendlycaptcha'

export default {
  name: 'NewsletterSignUp',
  layout: 'defautl',
  serverCacheKey() {
    return false
  },
  mixins: [validationMixin],
  fetch() {
    // Breadcrumbs
    const breadcrumbs = [
      {
        first: true,
        value: 'magazine subscriptions',
        url: '/',
        last: false
      },
      {
        first: false,
        value: 'Newsletter Sign Up',
        url: '/newsletter-signup',
        last: true
      }
    ]
    this.$store.commit('ADD_BREADCRUMBS', { path: '/newsletter-signup', breadcrumbs })
  },

  data() {
    return {
      error: false,
      show: false,
      form: {
        email: ''
      },
      isClicked: false,
      widget: null,
      friendlyCaptchaSolution: undefined,
      metaDescription: 'Sign up for the magazine.co.uk newsletter to hear about all our latest offers and promotions.',
      metaTitle: 'Newsletter Sign Up',
      metaKeywords: '',
      canonicalURL: 'https://www.magazine.co.uk/newsletter-signup'
    }
  },
  validations: {
    form: {
      email: { required, email }
    }
  },

  async mounted() {
    // GA4 Fire view_item event
    // this.$config.GA4Syntax && this.$config.GA4FireEcommerceNull && this.$gtm && this.$gtm.push(getGA4EcommerceNullPayload())
    // this.$config.GA4Syntax && this.$gtm && this.$gtm.push(getGA4ViewItemPayload({ rawItems: this.trackingItemsForGA4 }))

    const { WidgetInstance } = await import('friendly-challenge')
    if (!this.widget) {
      this.widget = new WidgetInstance(this.$refs.captcha, {
        sitekey: this.$config.FriendlyCaptchaSiteKey,
        startMode: 'auto',

        doneCallback: this.onDone.bind(this),
        errorCallback: this.onError.bind(this)
      })
    }
  },

  beforeDestroy() {
    // this.$recaptcha && this.$recaptcha.destroy()
  },

  methods: {
    onDone(solution) {
      console.log('onDone solution=', solution)
      this.friendlyCaptchaSolution = solution || undefined
    },
    onError(err) {
      console.log('onError err=', err)
      this.friendlyCaptchaSolution = undefined
    },
    async onSubmit() {
      try {
        this.isClicked = true
        // 1 - Validate the Form
        this.$v.form.$touch()

        if (this.$v.form.$pending || this.$v.form.$error || this.$v.$invalid) {
          console.log('Newsletter Sign Up Form Validation ERROR')
          this.isClicked = false
          return false
        }

        // if the Friendly Captcha solution is undefined DO NOTHING
        if (this.friendlyCaptchaSolution === undefined) {
          console.log('Newsletter Signup - onSubmit - friendlyCaptchaSolution=', this.friendlyCaptchaSolution)
          this.isClicked = false
          return false
        }

        // 2 - Verify the solution on the Server API
        const recaptchaResponse = await friendlyCaptchaSiteverify({ solution: this.friendlyCaptchaSolution, context: this })
        this.$config.debug && console.log(`friendlyCaptchaSiteverify recaptchaResponse=${recaptchaResponse}`)

        // if the Friendly Captcha Siteverify response is false DO NOTHING
        if (recaptchaResponse === false) {
          this.isClicked = false
          this.show = true
          return false
        }

        // 2 - Call the Newsletter API
        await this.$axios
          .$post('/newsletter/signup', {
            email: this.form.email || undefined,
            campaign: this.$refs.campaignRef.value || 'JNEW'
          })
          .then(response => {
            console.log('/newsletter/signup API response=', response)
            this.show = true
          })
          .catch(error => {
            console.log('/newsletter/signup API error=', error)
            this.show = true
          })

        // 3 - Save email address to the Session Storage
        this.$store.commit('s/email', this.form.email)

        // TRACK GTM Newsletter Signup
        this.$config.GA4Syntax && this.$gtm && this.$gtm.push({ event: 'newsletterSignup' })
      } catch (error) {
        console.log('Signup to Newsletter error:', error)
        return false
      }
    }
  },
  // To check more options, link https://github.com/nuxt/vue-meta#script-object
  // To chcek how it is working https://nuxtjs.org/faq/duplicated-meta-tags/
  head() {
    return {
      title: this?.metaTitle || '',
      link: [{ rel: 'canonical', href: this?.canonicalURL || '' }],
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this?.metaDescription || ''
        },
        {
          name: 'keywords',
          content: this?.metaKeywords || ''
        },
        {
          name: 'robots',
          content: this.$config.robots || 'INDEX,FOLLOW'
        },
        {
          hid: 'og:title',
          name: 'og:title',
          property: 'og:title',
          content: this?.metaTitle || ''
        },
        {
          hid: 'og:description',
          name: 'og:description',
          property: 'og:description',
          content: this?.metaDescription || ''
        },
        {
          hid: 'og:site_name',
          name: 'og:site_name',
          property: 'og:site_name',
          content: 'magazine.co.uk'
        },
        {
          hid: 'og:image',
          name: 'og:image',
          property: 'og:image',
          content: 'https://www.magazine.co.uk/icon.png'
        }
      ]
    }
  },

  jsonld() {
    return [getBreadcrumbsMicrodataJSONLD({ breadcrumbs: this.$store.getters['UI/breadcrumbs'] })]
  }
}
</script>

<style lang="scss" scoped>
@media (max-width: $screen-xs-max) {
  .container {
    height: 100% !important;
    background: #ededed !important;
    margin: 0;
    .newsletter-container {
      max-height: 200px;
    }
    .newsletter-form__privacy-notice {
      margin: 15px 10px !important;
      padding: unset !important;
    }
  }
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.container {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
  height: auto;
  flex-direction: column;

  .newsletter-container {
    flex: 0 0 100%;
    background: #ededed;
    padding: 15px;

    .newsletter-form {
      p.hasError {
        color: red;
        font-size: 14px;
      }
      .recaptcha {
        font-size: 8px;
        color: gray;
        a {
          color: gray;
        }
      }
      margin: 10px;
      &__done {
        text-align: center;
        color: black;
        font-size: 18px;
        font-weight: 500;
        line-height: 1.2;
        margin: 10px;
      }
      p {
        text-align: center;
        color: black;
        font-size: 18px;
        font-weight: 500;
        line-height: 1.2;
        margin: 10px;
      }
      .newsletter-form-fields {
        display: flex;
        justify-content: center;
        flex-flow: row nowrap;
        &__email {
          border: 1px solid #b7b7b7;
          border-radius: 2px 0 0 2px;
          font-size: 16px;
          min-height: 30px;
          outline: 0;
          padding-left: 15px;
          width: 55%;
          max-width: 300px;
        }
        &__submit {
          appearance: none;
          background: #fff;
          border: 1px solid #b7b7b7;
          border-radius: 0 2px 2px 0;
          color: #000;
          font-size: 14px;
          font-weight: 700;
          min-height: 34px;
          left: -2px;
          padding-left: 15px;
          padding-right: 15px;
          position: relative;
          vertical-align: top;
        }
        .hasError {
          color: red;
          border-color: red;
          margin-right: 2px;
        }
      }
      &__privacy-notice {
        text-align: center;
        a {
          text-decoration: underline;
          color: #000;
        }
        padding: 15px;
        font-size: 12px !important;
      }
    }
  }
}
</style>
