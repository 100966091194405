<template>
  <div class="top-ten" itemscope itemtype="http://schema.org/ItemList" style="content-visibility: auto;">
    <div v-if="values.items" class="grid-container" itemprop="numberOfItems" content="10">
      <div class="category-heading" @click.prevent="handleClick(values)">
        <h2 itemprop="name">
          <span class="text">{{ values.label }}</span>
        </h2>
      </div>

      <div class="items-list offer-container block items-list--topx">
        <Product v-for="(item, index) in values.items" :key="`${item.productId}-${index}`" :item="item" :index="index + 1" :show-save-overlay="showSaveOverlay" />
      </div>
    </div>
  </div>
</template>

<script>
const Product = () => import('~/components/common/topx/Product.vue')

export default {
  name: 'HomepageTopx',
  serverCacheKey() {
    return 'HomepageTopx'
  },
  components: {
    Product
  },
  props: {
    values: {
      type: Object,
      required: true,
      default() {
        return {}
      }
    },
    showSaveOverlay: {
      type: Boolean,
      required: false,
      default() {
        return false
      }
    }
  },
  methods: {
    handleClick(item) {
      this.$config.UAEESyntax && this.$gtm && this.$gtm.push({ event: item.label })
      // Redirct to Topx Page
      window.location.href = `${this.$config.baseURL}${item.url}`
    }
  }
}
</script>

<style lang="scss">
.grid-container {
  .items-list {
    &__item {
      &--topx {
        // min-height: 383px;
        display: flex;
        &:nth-child(n) {
          display: flex !important;
        }
      }
    }
  }
}
.top-ten {
  // min-height: 916px;
  .category-heading {
    height: 40px;
  }
}

@media (max-width: 414px) {
  top-ten {
    // min-height: 916px;
    .category-heading {
      height: 40px;
    }
  }
}

@media (max-width: $screen-sm-min) {
  .offer-container.items-list--topx {
    // min-height: 2063px;
  }
}

/* iPhone 14 Pro Max - 1284×2778 pixels at 460ppi */
@media only screen and (max-device-width: 430px) and (max-device-height: 932px) and (-webkit-device-pixel-ratio: 3) {
  .offer-container.items-list--topx {
    // min-height: 1415px;
  }
}
</style>
