<script>
import pocketmagsplus from '~/mixins/pocketmagsplus.vue'

export default {
  mixins: [pocketmagsplus],
  asyncData({ params }) {
    return {
      params,
      isUpdate: false
    }
  }
}
</script>
