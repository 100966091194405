<template>
  <div :class="{ 'flex-row--noSaving': isSingleIssuesOffer }">
    <client-only>
      <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="false"></loading>
    </client-only>
    <div class="flex-row first red-text" role="cell">
      <div class="flex-row__name">{{ offer.name }}</div>
      <a v-if="offer.freeGiftEnabled" class="freegift-overlay" :alt="offer.freeGiftDescription" :title="offer.freeGiftDescription">
        <span class="freegift-overlay__txt1">FREE</span>
        <span class="freegift-overlay__txt2">GIFT</span>
      </a>
    </div>
    <div class="flex-row small-row" role="cell">{{ offer.numOfIssues }}</div>
    <div v-if="!isSingleIssuesOffer && offer.save" class="flex-row small-row line-through filler" role="cell">{{ formatCurrency(offer.rrp) }}</div>
    <div v-else class="flex-row small-row line-through filler" role="cell"></div>
    <div v-if="!isSingleIssuesOffer && offer.save" class="flex-row save filler" role="cell">{{ `Save ${formatCurrency(offer.rrp - offer.price)} (${offer.save}%)` }}</div>
    <div v-else class="flex-row save filler" role="cell"></div>
    <div class="flex-row" role="cell">
      <PaymentImages :offer-payment-type="offer.paymentType" :is-giftcard="offer.sku === 'gift-card' || offer.sku === 'e-giftcards'" />
    </div>
    <div class="flex-row red-text pdp-price last" role="cell">
      <div class="flex-row__price">{{ formatCurrency(offer.price) }}</div>
      <br />
      <div v-if="isSingleIssuesOffer && offer.available === false && offer.qty === 0" class="add-to-basket-pdp add-to-basket-pdp--disabled" title="Out of Stock">
        OUT OF STOCK
      </div>
      <div v-else-if="magazine.enabled" class="add-to-basket-pdp" title="add to basket" @click.prevent="handleClickAction(offer, 'add')">BUY</div>
    </div>
  </div>
</template>

<script>
import { formatCurrency } from '~/lib/util/basket'
import basketMixin from '~/mixins/basket.vue'
import gtmProductPageMixin from '@/mixins/gtm/product-page'

export default {
  name: 'DesktopOffer',
  components: {
    PaymentImages: () => import('~/components/common/product/PaymentImages.vue')
  },
  mixins: [gtmProductPageMixin, basketMixin],
  props: {
    offer: {
      type: Object,
      required: true,
      default() {
        return {}
      }
    },
    magazine: {
      type: Object,
      required: true,
      default() {
        return {}
      }
    },
    styledClass: {
      type: String,
      required: false,
      default() {
        return 'pdp'
      }
    },
    isSingleIssuesOffer: {
      type: Boolean,
      required: true,
      default() {
        return false
      }
    }
  },
  data() {
    return {
      isLoading: false
    }
  },
  computed: {},
  methods: {
    formatCurrency(value) {
      return formatCurrency(value)
    },
    async handleClickAction(offer, action) {
      console.log('DesktopOffer handleClickAction offer=', offer)
      console.log('DesktopOffer handleClickAction action=', action)

      // OUT OF STOCK for SINGLE_ISSUES
      if (offer.type === 'SINGLE_ISSUES' && offer.qty === 0 && offer.avaiable === false) {
        return
      }

      const item = {
        title: this.magazine.title,
        imageUrl: offer.imageUrl,
        productId: this.magazine.productId,
        id: this.magazine.id,
        sku: this.magazine.sku,
        offer,
        category: this.magazine.category,
        subCategory: this.magazine.subCategory
      }

      // Update the BE Basket data
      const ret = await this.updateBasket(item, action)

      // GTM & FB push events integration
      !ret.error && this.pushGTMAndFBAfterButtonClick({ action, item })

      // Show the basket overlay
      !ret.error && this.$nuxt.$emit('SHOW_BASKET', true)
    }
  }
}
</script>

<style lang="scss">
.add-to-basket-pdp--disabled {
  font-size: 14px !important;
  background-color: gray !important;
}
.table-container {
  display: block;
  //   margin: 2em auto;
  width: 100%;
  &--old {
    font-size: 12px;
    .flex-row {
      font-size: 12px;
      padding: 10px 0 10px 8px;
      &__price {
        margin-right: 10px;
        min-width: 50px;
      }
    }
    .offer-payment-type {
      &--cc {
        // max-width: 70px;
      }
      &--dd {
        // padding: 0;
        // max-width: 70px;
      }
    }

    .pdp-price {
      color: #ff0d00;
      font-weight: 700;
      font-size: 16px;
    }
    .save {
      font-weight: 700;
    }
    .first {
      font-size: 12px;
      text-align: left;
      color: #ff0d00;
      font-weight: 700;
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;
    }
    .last {
      width: calc(100% / 4);
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-evenly;
      align-items: center;
    }
  }
  .header-offers {
    &--old {
      padding: 2px 5px;
      border-left: 1px solid #fff;
      font-size: 12px;
      font-weight: 700;
      font-family: 'Open Sans', sans-serif;
      color: #fff;
      background: #666;
      .flex-row {
        padding: 0;
        text-align: center;
      }
      &:hover {
        background: #666;
      }
      .first {
        display: block;
        font-size: 12px;
        color: #fff;
      }
      .last {
        display: block;
        font-size: 12px;
        color: #fff;
      }
    }
    &--noSaving {
      .first {
        width: calc(100% / 3);
      }
      .small-row {
        width: calc(100% / 6);
      }
      .last {
        width: calc(100% / 3);
      }
      .filler {
        display: none;
      }
    }
  }
  .flex-row--noSaving {
    .first {
      width: calc(100% / 3);
    }
    .small-row {
      width: calc(100% / 6);
    }
    .last {
      width: calc(100% / 3);
    }
    .filler {
      display: none;
    }
  }
}
@media (max-width: $screen-xs-max) {
  .desktop {
    display: none;
  }
}

.desktop {
  .freegift-overlay {
    &:hover {
      cursor: default;
    }
    display: block;
    position: relative;
    min-width: 44px;
    max-width: 44px;
    min-height: 44px;
    max-height: 44px;
    z-index: 0;
    background-color: #df0000;
    border-radius: 30px;
    border: 2px #fff solid;
    text-align: center;
    line-height: 13px;
    left: 5px;
  }
  .freegift-overlay__txt1 {
    position: absolute;
    color: #fff;
    font-weight: 700;
    width: 44px;
    right: -2px;
    top: 8px;
    font-size: 13px;
    text-align: center;
  }

  .freegift-overlay__txt2 {
    position: absolute;
    color: #fff;
    font-weight: 700;
    width: 44px;
    right: -2px;
    top: 19px;
    font-size: 13px;
    text-align: center;
  }
}
</style>
