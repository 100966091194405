<template>
  <transition name="slide-fade">
    <div v-if="showNav" class="nav-cats" @click.stop.prevent="handleClick">
      <div class="nav-cats__container" @click.stop="doNothing">
        <div class="nav-cats__icon" @click.stop.prevent="handleClick">
          <font-awesome-icon :icon="['fal', 'times']" class="fa fa-stack-1x right" />
        </div>
        <h2 class="nav-cats__title">Categories</h2>
      </div>
      <section class="nav-cats__content" @click.stop="doNothing">
        <ul>
          <li v-for="item in navigationLinksValues" :key="item.key">
            <a :href="item.href" target="_self" rel="" :title="item.text" class="nav-cats__link">
              <font-awesome-icon :icon="['fal', 'chevron-right']" class="fa fa-stack-1x rightArrow" />
              {{ item.text }}
            </a>
          </li>
        </ul>
      </section>
    </div>
  </transition>
</template>

<script>
import { getNavigationLinks } from '~/lib/util/defaultLinks'

export default {
  components: {},
  props: {
    showNav: {
      type: Boolean,
      required: true,
      default() {
        return false
      }
    }
  },
  data() {
    return {
      navigationLinksValues: getNavigationLinks({ withMoreLinks: true, defaultLinks: this.$store.getters['utility/sitelinks'] })
    }
  },

  methods: {
    handleClick() {
      console.log('Nav handleClick')
      this.$nuxt.$emit('SHOW_NAV', false)
    },
    doNothing() {
      console.log('Nav doNothing')
    }
  }
}
</script>

<style lang="scss" scoped>
.nav-cats {
  content-visibility: auto;
  ul {
    display: block;
    margin-block-start: 0px;
    margin-block-end: 0px;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding: 0px;
    list-style-type: none;
  }
  // direction: rtl;
  overflow: auto;
  max-height: 100vh;
  position: fixed;
  z-index: 2147483647;
  top: 0;
  left: 0;
  visibility: visible;
  width: 100%;
  height: 100%;
  -webkit-transition: opacity 250ms ease-in-out, visibility 250ms ease-in-out;
  transition: opacity 250ms ease-in-out, visibility 250ms ease-in-out;
  opacity: 1;
  background-color: rgba(0, 0, 0, 0.7);
  &__link {
    font-size: 15px;
    position: relative;
    display: block;
    text-align: left;
    // letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #333;
    border: 0;
    border-bottom: 1px solid #bcbcbc;
    padding: 15px;
    width: 100%;
    overflow-wrap: normal;
    .fa {
      padding: 2px;
      position: relative;
      vertical-align: middle;
    }
  }
  &__container {
    z-index: 100;
    transform: none;
    width: 25%;
    height: 65px;
    line-height: 65px;
    border-bottom: 1px solid #000;
    float: left;
    position: relative;
    clear: both;
    background-color: #fff;
    text-align: center;
  }
  &__title {
    float: left;
    padding: 22px;
    font-size: 16px;
    text-align: left;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #000;
  }
  &__icon {
    float: right;
    vertical-align: middle;
    &:hover {
      cursor: pointer;
    }
    .right {
      box-sizing: content-box;
      top: -2px;
      position: relative;
      vertical-align: middle;
      padding: 0 15px;
    }
  }
  &__content {
    z-index: 100;
    transform: none;
    width: 25%;
    height: auto;
    float: left;
    position: relative;
    clear: both;
    background-color: #ededed;
    text-align: center;
  }
}

@media (max-width: $screen-sm-min) {
  .rightArrow {
    float: right;
    vertical-align: middle;
  }
  .nav-cats {
    &__container,
    &__content {
      min-width: 400px;
      max-width: 400px;
    }
  }
}

@media (max-width: $screen-xs-max) {
  .rightArrow {
    float: right;
    vertical-align: middle;
  }
  .nav-cats {
    &__container,
    &__content {
      min-width: 80%;

      max-width: 400px;
    }
  }
  .slide-fade-enter-active {
    transition: all 0.3s ease;
  }
  .slide-fade-leave-active {
    transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
  }
  .slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateX(10px);
    opacity: 0;
  }
}
</style>
