<template>
  <div class="header-bottom-container">
    <div class="header-bottom-container__grid">
      <FilterAZ />
      <TheSearch />
    </div>
    <Breadcrumbs class="header-bottom-container__breadcrumbs" :path="$route.path" />
  </div>
</template>

<script>
export default {
  components: {
    FilterAZ: () => import('~/components/common/FilterAZ.vue'),
    TheSearch: () => import('~/components/common/TheSearch.vue'),
    Breadcrumbs: () => import('~/components/common/Breadcrumbs.vue')
  },
  data() {
    return {}
  }
}
</script>

<style lang="scss">
.header-bottom-container {
  width: 100%;
  flex: 0 0 100%;
  max-width: 1230px;
  margin: 0 auto;

  &__breadcrumbs {
    padding: 5px;
    margin: 0 auto;
    max-width: 1230px !important;
    flex: 0 1 100%;
    justify-content: center;
    align-items: flex-start;
    border-bottom: unset;
    min-height: 30px;
  }

  &__grid {
    padding: 5px;
    margin: 0 auto;
    border: unset;
    border-bottom: 1px solid $borderColor;
    max-width: 1230px !important;
    flex: 0 1 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;

    .grid-filters {
      display: flex;
      -webkit-box-pack: center;
      justify-content: center;
      flex-wrap: wrap;
      margin: 10px;
      max-height: unset;
      min-width: 600px;
      &__cell {
        border-radius: 3px;
        -webkit-box-flex: 0;
        flex: 0 1 20px;
        margin: 2px 0;
        font-size: 14px;
      }
    }
    .searchbox {
      margin: 0px !important;
      .fa {
        display: none;
      }
    }
  }
}

@media (max-width: $screen-md-min) {
  .header-bottom-container {
    &__grid {
      .grid-filters {
        min-width: 300px;
      }
    }
  }
}
@media (max-width: $screen-xs-max) {
  .header-bottom-container {
    display: none;
  }
}
</style>
