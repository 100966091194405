<template>
  <client-only>
    <div class="social-grid">
      <ShareNetwork
        tag="div"
        class="social-grid__item"
        :url="`${$config.baseURL}${magazine.url}`"
        :title="magazine.h1Title || magazine.title || magazine.name || ''"
        :description="magazine.description"
        network="facebook"
        hashtags="magazinecouk"
      >
        <font-awesome-icon :icon="['fab', 'facebook-square']" class="fa-2x" />
      </ShareNetwork>
      <ShareNetwork
        tag="div"
        class="social-grid__item"
        :url="`${$config.baseURL}${magazine.url}`"
        :title="magazine.h1Title || magazine.title || magazine.name || ''"
        hashtags="magazinecouk"
        network="twitter"
        twitter-user="magazinecouk"
      >
        <font-awesome-icon :icon="['fab', 'twitter-square']" class="fa-2x" />
      </ShareNetwork>
      <ShareNetwork
        tag="div"
        class="social-grid__item"
        :url="`${$config.baseURL}${magazine.url}`"
        :title="magazine.h1Title || magazine.title || magazine.name || ''"
        :media="`${$config.imgixURL}${magazine.imageUrl}`"
        network="pinterest"
      >
        <font-awesome-icon :icon="['fab', 'pinterest-square']" class="fa-2x" />
      </ShareNetwork>
      <ShareNetwork
        v-if="!isFromPc"
        tag="div"
        class="social-grid__item"
        :url="`${$config.baseURL}${magazine.url}`"
        :title="magazine.h1Title || magazine.title || magazine.name || ''"
        :description="$options.filters.sanitize(magazine.description)"
        network="whatsapp"
      >
        <font-awesome-icon :icon="['fab', 'whatsapp-square']" class="fa-2x" />
      </ShareNetwork>
    </div>
  </client-only>
</template>

<script>
import xss from 'xss'

export default {
  filters: {
    sanitize(value) {
      if (!value) {
        return ''
      }
      return xss(value)
    }
  },
  props: {
    magazine: {
      type: Object,
      required: true,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      isFromPc: this.$ua.isFromPc()
    }
  }
}
</script>

<style lang="scss">
.social-grid {
  flex: 0 1 100%;
  display: flex;
  justify-content: space-evenly;
  flex-flow: row wrap;
  padding: 0;
  max-width: 250px;
  &__item {
    color: black !important;
    // flex: 0 0 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 20px;
  }
  &__item:hover {
    cursor: pointer;
    text-decoration: none;
    opacity: 0.7;
    filter: alpha(opacity=70);
  }
}
</style>
