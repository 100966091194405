<template>
  <div v-if="activeImages.length > 0" class="promosliderholder">
    <div v-if="activeImages.length === 1" class="singleimage">
      <a :title="activeImages[0].alt || 'banner'" @click.prevent="handleClick(activeImages[0])">
        <img
          :srcset="
            `${removeImgixWidthQS(activeImages[0].imageUrl)}&w=1025 1200w, ${removeImgixWidthQS(activeImages[0].imageUrl)}&w=749 749w, ${removeImgixWidthQS(
              activeImages[0].imageUrl
            )}&w=449 449w`
          "
          :src="`${removeImgixWidthQS(activeImages[0].imageUrl)}&w=1025`"
          :alt="activeImages[0].alt"
          loading="eager"
        />
      </a>
    </div>
    <div v-else class="st-slider">
      <input v-for="(image, index) in activeImages" :id="`slide${index + 1}`" :key="`slider::${index}`" type="radio" class="cs_anchor radio" name="slider" />
      <input id="play1" type="radio" class="cs_anchor radio" name="slider" checked="" />

      <div :class="{ 'js-loading': jsLoading }" class="images">
        <div class="images-inner">
          <div v-for="(image, index) in activeImages" :key="`image-slide::${index}`" class="image-slide">
            <div class="image">
              <a :href="image.link" @click.prevent="handleClick(image)">
                <img
                  :srcset="`${removeImgixWidthQS(image.imageUrl)}&w=1025 1200w, ${removeImgixWidthQS(image.imageUrl)}&w=749 749w, ${removeImgixWidthQS(image.imageUrl)}&w=449 449w`"
                  :src="`${removeImgixWidthQS(image.imageUrl)}&w=1025`"
                  :alt="image.alt || 'Banner'"
                  class="productcoverimg"
                  style="width:100%; height:auto;"
                  :width="getWidth"
                  :height="getHeight"
                  :loading="index === 0 ? `eager` : `lazy`"
                />
              </a>
            </div>
          </div>
        </div>
      </div>

      <div :class="{ 'js-loading': jsLoading }" class="labels">
        <div class="fake-radio">
          <label v-for="(image, index) in activeImages" :key="`label::${index}`" :for="`slide${index + 1}`" class="radio-btn"></label>

          <!-- <label for="slide1" class="radio-btn"></label>
          <label for="slide2" class="radio-btn"></label>
          <label for="slide3" class="radio-btn"></label> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import carousel from '~/mixins/carousel.vue'

export default {
  name: 'Carousel3Images',
  serverCacheKey() {
    return false
  },
  mixins: [carousel]
}
</script>

<style lang="scss" scoped>
.promosliderholder {
  width: 100%;
  padding-bottom: 10px;
  margin-bottom: 10px;
  img {
    width: 100%;
  }
  min-height: 100px;
  .singleimage {
    min-height: 100px;
  }
}
.image {
  width: 100%;
  height: auto;
}
.radio {
  display: none;
}
.images {
  overflow: hidden;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
}
.images-inner {
  width: 300%;
  transition: all 800ms cubic-bezier(0.77, 0, 0.175, 1);
  transition-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
}
.image-slide {
  width: 33.33%;
  float: left;
}
.image-slide,
.fake-radio,
.radio-btn {
  transition: all 0.5s ease-out;
}
.fake-radio {
  float: right;
}

/* Move slides overflowed container */
#slide1:checked ~ .images .images-inner {
  margin-left: 0;
}
#slide2:checked ~ .images .images-inner {
  margin-left: -100%;
}
#slide3:checked ~ .images .images-inner {
  margin-left: -200%;
}

/* Color of bullets */
#slide1:checked ~ div .fake-radio .radio-btn:nth-child(1),
#slide2:checked ~ div .fake-radio .radio-btn:nth-child(2),
#slide3:checked ~ div .fake-radio .radio-btn:nth-child(3) {
  background: red;
}
.radio-btn {
  width: 9px;
  height: 9px;
  border-radius: 5px;
  background: gray;
  display: inline-block !important;
  margin: 0 4px;
  cursor: pointer;
}
/* Color of bullets - END */

/* Text of slides */
#slide1:checked ~ .labels .label:nth-child(1),
#slide2:checked ~ .labels .label:nth-child(2),
#slide3:checked ~ .labels .label:nth-child(3) {
  opacity: 1;
}

.label {
  opacity: 0;
  position: absolute;
}
/* Text of slides - END */

/* Calculate AUTOPLAY for BULLETS */
@keyframes bullet {
  0%,
  33.32333333333334% {
    background: red;
  }
  33.333333333333336%,
  100% {
    background: gray;
  }
}

#play1:checked ~ div .fake-radio .radio-btn:nth-child(1) {
  animation: bullet 24000ms infinite -1000ms;
}
#play1:checked ~ div .fake-radio .radio-btn:nth-child(2) {
  animation: bullet 24000ms infinite 7000ms;
}
#play1:checked ~ div .fake-radio .radio-btn:nth-child(3) {
  animation: bullet 24000ms infinite 15000ms;
}

/* Calculate AUTOPLAY for SLIDES */
@keyframes slide {
  0%,
  25.203252032520325% {
    margin-left: 0;
  }
  33.333333333333336%,
  58.53658536585366% {
    margin-left: -100%;
  }
  66.66666666666667%,
  91.869918699187% {
    margin-left: -200%;
  }
}

.st-slider > #play1:checked ~ .images .images-inner {
  animation: slide 24000ms infinite;
}
/* Calculate AUTOPLAY for SLIDES - END */

/* Calculate AUTOPLAY for CAPTION */
@keyframes caption {
  0%,
  33.32333333333334% {
    opacity: 1;
  }
  33.333333333333336%,
  100% {
    opacity: 0;
  }
}

#play1:checked ~ .labels .label:nth-child(1) {
  animation: caption 24000ms infinite -1000ms;
}
#play1:checked ~ .labels .label:nth-child(2) {
  animation: caption 24000ms infinite 7000ms;
}
#play1:checked ~ .labels .label:nth-child(3) {
  animation: caption 24000ms infinite 15000ms;
}
/* Calculate AUTOPLAY for CAPTION - END */

@media (min-width: $screen-md-min) {
  .content-clp {
    .promosliderholder {
      min-height: 283px;
    }
  }
  .content-clp__main {
    .promosliderholder {
      min-height: 200px;
      .singleimage {
        min-height: 100px;
        img {
          width: 100%;
          max-width: 820px;
        }
      }
    }
  }
}
.images-inner {
  img {
    width: 100vw;
  }
}
@media (max-width: $screen-xs-max) {
  .promosliderholder {
    padding: 0 !important;
    margin: 0 !important;
    .labels {
      display: none;
    }
  }
}
.js-loading *,
.js-loading *:before,
.js-loading *:after {
  animation-play-state: paused !important;
}
</style>
