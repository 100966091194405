<template>
  <div
    class="items-list__item"
    :class="{
      'items-list__item--digital': isSingleButton || isMagazineSubscriptionsForSchools,
      'items-list__item--nooffer': isDigitalMagazine,
      'items-list__item--search': isASearchResult
    }"
  >
    <client-only>
      <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="false"></loading>
    </client-only>

    <TheMagazineImage :item="item" :show-save-overlay="showSaveOverlay" :show-free-gift-overlay="showFreeGiftOverlay" />

    <div class="product__information">
      <a :href="linkURL(item)" class="product__text" style="display:block" @click.prevent="handleViewOffer(item)">
        <div class="product__magazine">
          <div :title="item.title">{{ item.title }}</div>
        </div>
        <div v-if="isPocketmagsPlus" class="product__offer">
          <p v-if="isPocketmagsPlus && !isDigitalMagazinesCats" class="special-offer">6 months for £39.99</p>
          <span v-if="!isDigitalMagazinesCats && !isPocketmagsPlus" class="savings-offer">&nbsp;</span>
          <span v-if="!isDigitalMagazinesCats && isPocketmagsPlus" class="savings">Saving 33%</span>
          <p v-if="isDigitalMagazinesCats" class="special-offer"></p>
        </div>

        <div v-if="!isDigitalMagazinesCats && !isPocketmagsPlus" class="product__offer">
          <p v-if="!isDigitalMagazine && item.offer.name" class="special-offer">{{ item.offer.name }}</p>
          <p v-else class="special-offer">&#xA0;</p>
          <span v-if="!isDigitalMagazine && item.offer.save > 0" class="savings">Saving {{ item.offer.save }}%</span>
          <span v-else class="savings">&#xA0;</span>
        </div>
      </a>
      <div v-if="isDigitalMagazinesCats || isMagazineSubscriptionsForSchools" class="actions--digital">
        <a :href="linkURL(item)" class="view-btn--digital" title="view product" @click.prevent="handleViewOffer(item)">View Offers</a>
      </div>
      <div
        v-else-if="isDigitalMagazine"
        class="actions actions--no-offers"
        :class="{ 'actions__viewOffer--searchViewAll': type === 'viewall' || type === 'search' ? true : false }"
      >
        <a :href="linkURL(item)" class="view-btn--nooffer" title="view product" @click.prevent="handleViewOffer(item)">View Offers</a>
      </div>
      <div v-else-if="type === 'search'" class="actions--digital">
        <div :href="linkURL(item)" class="view-btn--digital" title="add to basket" @click.prevent="handleClickAction(item, 'add')">Add to basket</div>
      </div>
      <div v-else-if="type === 'viewall'" class="actions--digital">
        <div :href="linkURL(item)" class="view-btn--digital" title="add to basket" @click.prevent="handleClickAction(item, 'add')">Add to basket</div>
      </div>
      <div v-else class="actions">
        <a :href="linkURL(item)" class="view-btn" title="view product" @click.prevent="handleViewOffer(item)">View Offers</a>
        <!-- <span class="btn-divider">|</span> -->
        <div class="add-to-basket add-to-basket-clp add-to-basket-btn" title="add to basket" @click.prevent="handleClickAction(item, 'add')">
          Add to basket
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import basketMixin from '~/mixins/basket.vue'
import gtmProductPageMixin from '@/mixins/gtm/product-page'

export default {
  name: 'ListProduct',
  serverCacheKey() {
    return false
  },
  components: {
    TheMagazineImage: () => import('~/components/common/TheMagazineImage')
  },
  mixins: [gtmProductPageMixin, basketMixin],
  props: {
    item: {
      type: Object,
      required: true,
      default() {
        return {}
      }
    },
    type: {
      type: String,
      required: false,
      default() {
        return 'default'
      }
    },
    showSaveOverlay: {
      type: Boolean,
      required: false,
      default() {
        return false
      }
    },
    showFreeGiftOverlay: {
      type: Boolean,
      required: false,
      default() {
        return false
      }
    },
    isDigitalMagazinesCats: {
      type: Boolean,
      required: false,
      default() {
        return false
      }
    },
    isMagazineSubscriptionsForSchools: {
      type: Boolean,
      required: false,
      default() {
        return false
      }
    },
    categoryUrl: {
      type: String,
      required: false,
      default() {
        return undefined
      }
    },

    imgixQueryString: {
      type: String,
      required: false,
      default() {
        return '?fm=png&auto=format&fit=fill&fill=solid&bg=0FFF&w=178'
      }
    }
  },

  data() {
    return {
      isLoading: false,
      showViewAndBuyButton: true,
      isFromMobile: this.$ua.isFromIphone() || this.$ua.isFromAndroidMobile(),
      isPocketmagsPlus: this.item.sku === 'Pocketmags Plus+',
      imgixQueryStringValue: this.isFromMobile ? '?fm=png&auto=format&fit=fill&fill=solid&bg=0FFF&w=178' : '?fm=png&auto=format&fit=fill&fill=solid&bg=0FFF&w=204'
    }
  },
  computed: {
    isDigitalMagazine() {
      return !this.item.offer || this.item.offer.productId === 0 || !this.item.offer.productId
    },
    isSingleButton() {
      return this.isDigitalMagazinesCats || this.type === 'search' || this.type === 'viewall'
    },
    isASearchResult() {
      return this.type === 'search' || this.type === 'viewall'
    }
  },
  methods: {
    linkURL(item) {
      const url = item?.url
      if (!url) {
        return this.$config.baseURL
      }
      return url.replace(/magazine-(\d{4,5})$/g, 'magazine')
    },
    async handleClickAction(item, action) {
      console.log('common/list/Product.vue handleClickAction item=', item)
      console.log('common/list/Product.vue handleClickAction action=', action)

      // Update the BE Basket data
      const ret = await this.updateBasket(item, action)

      // GTM & FB push events integration
      !ret.error && this.pushGTMAndFBAfterButtonClick({ action, item })

      // Show the basket overlay
      !ret.error && this.$nuxt.$emit('SHOW_BASKET', true)
    },

    handleViewOffer(item) {
      // Trigger GTM event
      if (item && item.url) {
        this.$nuxt.$emit('SHOW_BASKET', false)
        this.$config.UAEESyntax && this.$gtm && this.$gtm.push({ viewspecialofferurl: `${this.$config.baseURL}${item.url}` })
        this.$config.UAEESyntax && this.$gtm && this.$gtm.push({ event: 'viewspecialoffer' })
        this.$store.commit('s/addtorecentlyviewed', this.item, { root: true })
        // Redirct to the offer page
        window.location.href = this.getProductUrl(item)
      }
    }
  }
}
</script>

<style lang="scss">
.items-list__item {
  position: relative;
  &--digital {
    .product__information {
      .product__text {
        min-height: 50px !important;
      }
      .product__offer {
        display: none !important;
      }
    }
    .actions--center {
      justify-content: center !important;
    }
    .actions--digital {
      .view-btn--digital {
        box-shadow: 0 0;
        background: #00933e;
        font-weight: 600;
        display: inline-block;
        vertical-align: middle;
        transition-property: all;
        transition-duration: 0.1s;
        transition-timing-function: linear;
        border-radius: 3px;
        border: none;
        font-size: 12px;
        padding: 5px 0;
        //text-transform: uppercase;
        text-decoration: none;
        text-align: center;
        width: 100%;
        margin-top: 10px;
        color: #fff;
        cursor: pointer;
        &:hover {
          opacity: 0.7;
        }
        button {
          background-color: $buttonAddToCartColor !important;
        }
      }
      &:hover {
        opacity: 0.7;
      }
    }
    .actions__viewOffer {
      &--searchViewAll {
        width: 100%;
        a.view-btn--nooffer {
          box-shadow: 0 0;
          background: #00933e;
          font-weight: 600;
          display: inline-block;
          vertical-align: middle;
          transition-property: all;
          transition-duration: 0.1s;
          transition-timing-function: linear;
          border-radius: 3px;
          border: none;
          font-size: 12px;
          padding: 9px 0 !important;
          //text-transform: uppercase;
          text-decoration: none;
          text-align: center;
          width: 100%;
          margin-top: 10px;
          color: #fff;
          cursor: pointer;
          max-height: 30px;
          line-height: 12px;
          &:hover {
            opacity: 0.7;
          }
          button {
            background-color: $buttonAddToCartColor !important;
          }
        }
      }
    }
  }
  &--nooffer {
    .actions--no-offers {
      justify-content: center !important;
    }
    .actions--nooffer {
      justify-content: center !important;
      .view-btn--nooffer {
        transition: all 0s;
        margin-top: 0px;
        font-size: 12px;
        color: $buttonAddToCartColor;
        line-height: 12px;
        margin: 0;
        text-decoration: underline;
        border: unset;
        background-color: unset;
        min-width: 50px;
        padding: 15px 0;
        cursor: pointer;
        &:hover {
          text-decoration: none;
        }
        button {
          background-color: $buttonAddToCartColor !important;
        }
      }
    }
  }
  &--search {
    .product__information .product__offer {
      display: block !important;
    }
  }
}
a {
  cursor: pointer;
}
.items-list {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: stretch;
  align-content: space-around;
  &__item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    justify-items: flex-start;

    flex: 0 0 24.5%;
    min-width: 0;
    padding: 0.4375em;
    margin: 0.1em 0.1em 1.25em 0.1em;
    font-weight: 700;
    &:hover {
      background-color: $categoryItemHoverBg;
    }

    &--checked,
    &--checked:hover {
      background: #e3fbe3;
      padding: 0.4375em;
      opacity: 1;
      filter: alpha(opacity=100);
    }
    .special-offer {
      color: $prodcutOfferColor;
      font-size: 12px;
      line-height: 12px;
      margin: 0em;
    }
    .savings {
      color: #333;
      font-size: 12px;
      line-height: 12px;
      margin: 0em;
    }
    .actions {
      .add-to-basket-btn {
        text-align: center;
        font-size: 12px;
        font-weight: 700;
        color: $buttonAddToCartColor;
        line-height: 12px;
        margin: 0em;
        text-decoration: underline;
        min-width: 100px;
        padding: 15px 0;
        &:hover {
          text-decoration: none;
          cursor: pointer;
        }
        .btn-divider {
          font-weight: 400;
        }
      }
    }
  }

  .product__information {
    padding: 0.5em 0.1em 0.1em 0.1em;
    min-height: 128px;
    .product__text {
      // border: 1px solid black;
      min-height: 77px;
      .product__magazine {
        vertical-align: middle;
        font-size: 12px;
        min-height: 34px;
        overflow: hidden;
        text-overflow: ellipsis;
        text-transform: uppercase;
        padding-bottom: 5px;
        color: $bodyTextColor;
        a {
          color: $bodyTextColor;
          font-size: 12px;
          font-weight: 700;
        }
      }
      .product__offer {
        min-height: 32px;
        &--red {
          color: $headingColor;
        }
      }
    }
  }

  .product__image {
    position: relative;
    min-height: 30px;
    img {
      max-width: 100%;
      display: block;
      margin: 0 auto;
      width: 100%;
      height: auto;
      aspect-ratio: 159/218;
    }
  }
}

@media (max-width: $screen-sm-min) {
  .items-list__item {
    // min-height: 383px !important;
    &--nooffer {
      .product__offer {
        display: block;
      }
      .actions--nooffer {
        .view-btn--nooffer {
          display: inline-block;
          vertical-align: middle;
          vertical-align: auto;
          zoom: 1;
          display: inline;
          border-radius: 3px;
          border: none;
          background: #00933e;
          color: #fff;
          font-family: 'Open Sans', sans-serif;
          font-weight: 600;
          font-size: 12px;
          padding: 10px;
          text-transform: uppercase;
          text-decoration: none;
          text-align: center;
          color: #fff;
          width: 100%;
          padding: 10px !important;
          margin-top: 10px;
          transition: all 0.2s;
          cursor: pointer;
          &:hover {
            opacity: 0.7;
          }
          button {
            background-color: $buttonAddToCartColor !important;
          }
        }
        &:hover {
          opacity: 0.7;
        }
      }

      // .actions__viewOffer {
      //   &--searchViewAll {
      //     display: block;
      //     max-height: 30px;
      //     line-height: 20px;
      //     margin-top: 0;
      //     width: 100%;
      //   }
      // }
    }
    &--digital {
      .product__offer {
        display: none;
      }
      .product__information {
        display: flex;
        flex-direction: column;
        justify-content: inherit;
        .actions--center {
          margin-top: unset;
          a.view-btn--center {
            box-shadow: 0 0;
            background: #00933e;
            font-weight: 600;
            display: inline-block;
            vertical-align: middle;
            transition-property: all;
            transition-duration: 0.1s;
            transition-timing-function: linear;
            border-radius: 3px;
            border: none;
            font-size: 12px;
            padding: 10px;
            text-transform: uppercase;
            text-decoration: none;
            text-align: center;
            width: 100%;
            margin-top: 10px;
            color: #fff;
            cursor: pointer;
            &:hover {
              opacity: 0.7;
            }
            button {
              background-color: $buttonAddToCartColor !important;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: $screen-sm-min) and (max-width: 1185px) {
  // .items-list__item .actions {
  //   a {
  //     max-width: 65px !important;
  //     min-width: unset !important;
  //     line-height: 16px !important;
  //     margin: 0 5px 0 0 !important;
  //   }
  // }
  // .add-to-basket-btn {
  //   max-width: 80px !important;
  //   min-width: unset !important;
  //   line-height: 16px !important;
  // }
}

/* iPhone 14 Pro Max - 1284×2778 pixels at 460ppi */
@media only screen and (max-device-width: 430px) and (max-device-height: 932px) and (-webkit-device-pixel-ratio: 3) {
  .items-list__item {
    // min-height: 332px !important;
  }
}

.product__recentlyViewed {
  .product__information {
    min-height: unset !important;
    .product__offer {
      display: none !important;
    }
    .product__text {
      min-height: 44px;
    }
    .actions {
      display: none !important;
    }
  }
}
</style>
