<template>
  <div>
    <main id="main" role="main">
      <nuxt />
    </main>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss"></style>
