<template>
  <div class="items-list__item">
    <img
      :src="getImageURL(item.imageUrl)"
      :alt="item.title"
      :srcset="`${getImageURL(item.imageUrl)} 1x, ${getImageURL(item.imageUrl)}&dpr=2&q=30 2x, ${getImageURL(item.imageUrl)}&dpr=2&q=30 3x`"
      loading="lazy"
    />

    <div class="product__information">
      <div class="product__magazine">
        <div :title="item.title">{{ item.title }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PocketmagsPlusProduct',
  serverCacheKey() {
    return false
  },
  props: {
    item: {
      type: Object,
      required: true,
      default() {
        return {}
      }
    },
    type: {
      type: String,
      required: false,
      default() {
        return 'default'
      }
    }
  },

  methods: {
    getImageURL(imageUrl) {
      return `${imageUrl}?auto=format&fit=fill&fill=solid&bg=0FFF&w=178&h=245`
    }
  }
}
</script>

<style lang="scss">
.items-list__item {
  position: relative;
}
a {
  cursor: pointer;
}
.items-list {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: stretch;
  align-content: space-around;
  &__item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    justify-items: flex-start;

    flex: 0 0 16%;
    min-width: 0;
    padding: 0.4375em;
    margin: 0.1em 0.1em 1.25em 0.1em;
    font-weight: 700;
    &:hover {
      background-color: $categoryItemHoverBg;
    }

    &--checked,
    &--checked:hover {
      background: #e3fbe3;
      padding: 0.4375em;
      opacity: 1;
      filter: alpha(opacity=100);
    }
  }

  .product__information {
    padding: 0.5em 0.1em 0.1em 0.1em;
    .product__text {
      .product__magazine {
        vertical-align: middle;
        font-size: 12px;
        min-height: 34px;
        overflow: hidden;
        text-overflow: ellipsis;
        text-transform: uppercase;
        padding-bottom: 5px;
        color: $bodyTextColor;
        a {
          color: $bodyTextColor;
          font-size: 12px;
          font-weight: 700;
        }
      }
      .product__offer {
        &--red {
          color: $headingColor;
        }
      }
    }
  }

  .product__image {
    position: relative;
    min-height: 30px;
    img {
      max-width: 100%;
      display: block;
      margin: 0 auto;
      width: 100%;
      height: auto;
      aspect-ratio: 159/218;
    }
  }
}

@media (max-width: $screen-sm-min) {
  .items-list__item {
    &--nooffer {
      .product__offer {
        display: block;
      }
    }
    &--digital {
      .product__offer {
        display: none;
      }
      .product__information {
        display: flex;
        flex-direction: column;
        justify-content: inherit;
      }
    }
  }
}
</style>
