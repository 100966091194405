<template>
  <span id="gc-preview-modal">
    <div id="gc-live-preview-container" class="m-section gc-live-preview-container">
      <div class="m-section-no-margin gc-live-preview-inner">
        <div id="gc-live-preview-Designs" class="m-section gc-requires-no-js gc-live-preview gc-Email-live-preview gc-live-preview-current" style="display: block;">
          <div class="m-section gc-live-preview-inner">
            <div class="m-section gc-design-image-wrapper">
              <img id="gc-standard-design-image" alt="Pocketmags Plus+" :src="image" class="gc-design-img-preview" />
            </div>
          </div>
        </div>
      </div>

      <div id="gc-live-preview-customizations" class="m-section  m-no-margin gc-live-preview-customizations gc-requires-js">
        <div id="gc-live-preview-inner-center-section" class="m-section  gc-toggle-on-message-content gc-live-preview-message-container">
          <span id="gc-live-preview-message" class="gc-live-preview-message m-color-2">{{ message }}</span>
        </div>

        <div id="gc-live-preview-inner-bottom-section" class="m-section gc-live-preview-inner-bottom-section m-no-margin">
          <div class="gc-live-preview-logo-offer-row">
            <div id="gc-live-preview-offer-container" class="gc-live-preview-offer-container">
              <span id="gc-live-preview-offer" class="gc-live-preview-offer">{{ (offer && offer.type) || '' }}</span>
              <p id="gc-brand" class="gc-brand-text m-color-3">Pocketmags Plus subscription</p>
            </div>
            <div class="gc-live-preview-logo-img">
              <img id="gc-logo-img" alt="" src="https://magazine.imgix.net/pocketmagsplus/logo_transparent.png?auto=format&w=180&dpr=2" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </span>
</template>

<script>
export default {
  name: 'PocketmagsPlusTemplate',
  serverCacheKey() {
    return false
  },
  props: {
    image: {
      type: String,
      required: false,
      default() {
        return 'https://magazine.imgix.net/pocketmagsplus/banner.png?auto=format&w=470&dpr=2'
      }
    },
    message: {
      type: String,
      required: false,
      default() {
        return 'Enjoy this Pocketmags Plus subscription'
      }
    },
    offer: {
      type: Object,
      required: false,
      default() {
        return {}
      }
    }
  }
}
</script>

<style lang="scss" scoped>
#gc-live-preview-container {
  -moz-box-shadow: 0 4px 10px -3px $gcBorderColorTemplate;
  -webkit-box-shadow: 0 4px 10px -3px $gcBorderColorTemplate;
  box-shadow: 0 4px 10px -3px $gcBorderColorTemplate;
  border-collapse: separate;
  border-radius: 6px;
  border: 1px solid $gcBorderColorTemplate;
  cursor: pointer;
  width: 95%;
  margin: 10px;
}

.gc-live-preview-customizations {
  display: none;
}

.gc-design-image-wrapper img {
  width: 100%;
}

.gc-live-preview-inner .gc-design-img-preview {
  border-radius: 6px;
}

.gc-live-preview-message-container {
  padding: 0px 25px 0px;
  height: auto;
}
.gc-live-preview-message-container {
  text-align: left;
  padding: 22px 22px 5px;
  word-wrap: break-word;
  overflow: hidden;
}
.gc-live-preview-message {
  white-space: break-spaces;
}
#gc-standard-design-image {
  width: 100%;
}
.m-text-center {
  text-align: center !important;
}

#gc-detail-live-preview-message {
  font-weight: 400;
  font-style: normal;
  color: $gcTextColor3;
  text-align: center;
  font-size: 14px;
}
#gc-live-preview-inner-bottom-section {
  background-color: $gcBgColor;
  border-radius: 0 0 6px 6px;

  .gc-live-preview-logo-offer-row {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }
  .gc-live-preview-offer-container {
    margin: 2% 10px;
    flex: 1 1 60%;
    min-height: 1px;
    overflow: hidden;
  }
  .gc-live-preview-logo-img {
    margin: 2% auto;
    flex: 1 1 40%;
    min-height: 1px;
    img {
      width: 100%;
    }
  }
}

.gc-live-preview-inner-bottom-section {
  padding: 10px 20px;
}
.gc-live-preview-offer {
  font-weight: 400;
  font-style: normal;
  font-size: 13px;
  color: $gcTextColor1;
}
.gc-brand-text {
  font-weight: 400;
  font-style: normal;
  font-size: 15px;
  margin-bottom: unset;
}

@media (min-width: $screen-xs-max) {
  .gc-live-preview-customizations {
    display: block;
  }
  .gc-live-preview-inner .gc-design-img-preview {
    border-radius: 6px 6px 0 0;
  }

  .gc-design-image-wrapper img {
    width: 470px;
  }
}
@media (max-width: $screen-sm-min) {
  .gc-brand-text {
    line-height: 17px;
    font-weight: 400;
    font-style: normal;
    font-size: 10px;
    margin-bottom: unset;
  }
}
</style>
