<template>
  <div class="sidebar-gift-cards">
    <a :href="`${$config.baseURL}/gift-card-options`" @click.prevent="handleClick('/gift-card-options')">
      <BImageLazyImgix image-url="/wysiwyg/JMAG_Giftcard.jpg" alt="Buy Gift Card" query-string="&w=153&h=96" width="153" height="96" blank-color="#ededed" />
      <div class="sidebar-gift-cards__text">Gift Cards</div>
    </a>
    <a :href="`${$config.baseURL}/gift-card-options`" class="sidebar-btn" @click.prevent="handleClick('/gift-card-options')"> BUY </a>
    <a :href="`${$config.baseURL}/gift-card-redeem`" class="sidebar-btn" @click.prevent="handleClick('/gift-card-redeem')"> HOW TO USE </a>
  </div>
</template>

<script>
import { getGA4EcommerceNullPayload, getGA4SelectPromotionPayload } from '~/lib/util/gtmGA4'

export default {
  name: 'SidebarGiftCards',
  serverCacheKey: props => `SidebarGiftCards`,

  components: {
    BImageLazyImgix: () => import('~/components/common/BImageLazyImgix')
  },
  props: {
    creativeSlot: {
      type: String,
      required: false,
      default() {
        return ''
      }
    },
    locationId: {
      type: String,
      required: false,
      default() {
        return ''
      }
    }
  },
  methods: {
    handleClick(path) {
      // UAEES event for every Banner Image Click
      this.$config.UAEESyntax && this.$gtm && this.$gtm.push({ event: 'giftcard' })

      // GA4 events for every  Banner Image Click
      const banner = { id: '1', alt: 'SITEWIDE - Gift Cards', key: this.creativeSlot }
      this.$config.GA4Syntax && this.$config.GA4FireEcommerceNull && this.$gtm && this.$gtm.push(getGA4EcommerceNullPayload())
      this.$config.GA4Syntax && this.$gtm && this.$gtm.push(getGA4SelectPromotionPayload({ banner, locationId: this.locationId }))

      window.location.href = `${this.$config.baseURL}${path}`
    }
  }
}
</script>

<style lang="scss">
.sidebar-gift-cards {
  width: 175px;
  @include border-radius(5px);
  border: 1px solid $inputSearchBorder;
  padding: 10px;
  margin: 10px 0 30px 0;
  margin-top: 20px;

  img {
    max-width: 175px;
    height: auto;
  }

  &__text {
    font-size: 28px;
    font-style: italic;
    font-weight: 900;
    color: $headingColor;
    text-align: center;
    padding: 6px 0 5px 0;
  }

  a:hover {
    opacity: 0.7;
    filter: alpha(opacity=70);
  }
  .sidebar-btn {
    @include button-style;
    text-decoration: none;
    width: 100%;
    text-align: center;
    margin: 5px 0;
    font-size: 14px;
    &:hover {
      text-decoration: none;
    }
    &:last-child {
      background: #333 !important;
      color: #ffffff;
      &:hover {
        opacity: 0.7;
        filter: alpha(opacity=70);
        background: #cbcbcb;
      }
    }
  }
}
</style>
