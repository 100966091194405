<template>
  <div>
    <div class="desktop table-container table-container--old" role="table" aria-label="Destinations">
      <div :class="{ 'header-offers--noSaving': isSingleIssuesOffer || hideRRPAndSaveFromMobile }" class="flex-table header-offers header-offers--old" role="rowgroup">
        <div class="flex-row first" role="columnheader">Subscription</div>
        <div class="flex-row small-row" role="columnheader">Issues</div>
        <div class="flex-row small-row filler" role="columnheader">RRP</div>
        <div class="flex-row filler" role="columnheader">Saving</div>
        <div class="flex-row" role="columnheader">Payment</div>
        <div class="flex-row last" role="columnheader">Our Price</div>
      </div>
      <DesktopOffer
        v-for="(offer, index) in getOffers()"
        :key="index"
        :offer="offer"
        :magazine="magazine"
        :is-single-issues-offer="type === 'SINGLE_ISSUES' || hideRRPAndSaveFromMobile"
        class="flex-table row"
        role="rowgroup"
      />
    </div>
    <div class="mobile">
      <div v-for="(offer, index) in getOffers()" :key="index" class="pdp-offer">
        <MobileOffer :offer="offer" :magazine="magazine" :is-single-issues-offer="type === 'SINGLE_ISSUES' || hideRRPAndSaveFromMobile" styled-class="pdp--old" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {
    DesktopOffer: () => import('~/components/common/product/DesktopOffer.vue'),
    MobileOffer: () => import('~/components/common/product/MobileOffer.vue')
  },
  props: {
    offers: {
      type: Array,
      required: true,
      default() {
        return []
      }
    },
    magazine: {
      type: Object,
      required: true,
      default() {
        return {}
      }
    },
    type: {
      type: String,
      required: true,
      default() {
        return 'PRINT'
      }
    }
  },
  data() {
    return {
      defaultOffer: {},
      loading: false,
      isSingleIssuesOffer: this.type === 'SINGLE_ISSUES'
    }
  },
  computed: {
    isMobile() {
      return this.$store.getters['UI/isMobile']
    },
    hideRRPAndSaveFromMobile() {
      return this.offers && this.offers.filter(offer => offer.rrp === offer.price).length === this.offers.length
    }
  },
  methods: {
    getOffers() {
      if (this.magazine.productId === 2837 && this.type === 'PRINT') {
        console.log('SWAP ARRAY for productId=2837 Womans Weekly')
        const arr = this.offers
        ;[arr[0], arr[1]] = [arr[1], arr[0]]
        return arr
      }
      return this.offers
    }
  }
}
</script>
<style lang="scss">
.add-to-basket-pdp {
  @include button-add-to-cart;
  // margin-left: 5px !important ;
  margin: 10px 0 !important;
  padding: 5px !important;
  max-width: 100px !important;
  font-size: 16px !important;
  color: white !important;
  background-color: $buttonAddToCartColor !important;
  max-width: 100px !important;
}
.offer-payment-type {
  &--cc {
    // max-width: 80px;
  }
  &--dd {
    // padding: 10px 0;
    // max-width: 80px;
  }
}

.row {
  position: relative;
  font-size: 14px;
  border-bottom: solid 1px #d9d9d9;
  &:last-child {
    border-bottom: unset;
  }
}
.header-offers {
  font-size: 14px;
  background: #ededed;
  color: #212529;
  border: solid 1px #d9d9d9;
  align-self: center;
}

.table-container {
  display: block;
  //   margin: 2em auto;
  width: 100%;
}

.flag-icon {
  margin-right: 0.1em;
}
.flex-table {
  display: flex;
  flex-flow: row nowrap;
  //   border-left: solid 1px $table-border;
  //   border-right: solid 1px $table-border;
  transition: 0.5s;
  &:first-of-type .flex-row {
    align-self: center;
  }
  &:hover {
    background: #f5f5f5;
    transition: 500ms;
  }
}

.flex-row {
  width: calc(100% / 6);
  text-align: center;
  padding: 0.5em 0.5em;
  align-self: center;
  .first {
    border-right: solid 1px $table-border;
  }
}
.small-row {
  width: calc(100% / 12);
  min-width: 60px;
}
.first {
  width: calc(100% / 4);
}
.last {
  width: calc(100% / 4);
}

.rowspan {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: center;
}

.column {
  display: flex;
  flex-flow: column wrap;
  width: 75%;
  padding: 0;
  .flex-row {
    display: flex;
    flex-flow: row wrap;
    width: 100%;
    padding: 0;
    border: 0;
    border-bottom: solid 1px $table-border;
    &:hover {
      background: #f5f5f5;
      transition: 500ms;
    }
  }
}

.flex-cell {
  width: calc(100% / 3);
  text-align: center;
  padding: 0.5em 0.5em;
  border-right: solid 1px $table-border;
}
.row {
  margin-right: unset;
  margin-left: unset;
}
.mobile {
  display: none;
}
@media (max-width: $screen-xs-max) {
  .desktop {
    display: none;
  }
  .mobile {
    display: block;
    .pdp-offer {
      border-bottom: 1px solid $borderColor;
      &:last-child {
        border-bottom: unset !important;
      }
    }
    .pdp-offer:last-child .pdp {
      border-bottom: unset !important;
    }
  }
}
</style>
