<template>
  <div class="category-products items-list clp-list">
    <Products
      v-for="(item, index) in infiniteLoadingValues"
      :key="`${index}::${item.productId}`"
      :show-save-overlay="showSaveOverlay"
      :show-free-gift-overlay="showFreeGiftOverlay"
      :is-digital-magazines-cats="isDigitalMagazinesCats"
      :is-magazine-subscriptions-for-schools="isMagazineSubscriptionsForSchools"
      :type="'clp'"
      :item="item"
      :category-url="$route.path"
      class="clp-list__item items-list__item"
    />
    <!-- <client-only>
      <infinite-loading @infinite="infiniteHandler"></infinite-loading>
    </client-only> -->
  </div>
</template>

<script>
export default {
  name: 'CLPProductsList',
  serverCacheKey() {
    return false
  },
  // serverCacheKey: props => `CLPProductsList::${props.categoryId}::${props.pageCount}`,
  components: {
    Products: () => import('~/components/common/list/Product.vue')
  },
  props: {
    values: {
      type: Array,
      required: false,
      default() {
        return this.$store.getters['category/products']
      }
    },
    categoryId: {
      type: Number,
      required: true,
      default() {
        return 0
      }
    },
    pageCount: {
      type: Number,
      required: true,
      default() {
        return 1
      }
    },
    showSaveOverlay: {
      type: Boolean,
      required: true,
      default() {
        return false
      }
    },
    showFreeGiftOverlay: {
      type: Boolean,
      required: true,
      default() {
        return false
      }
    },
    isDigitalMagazinesCats: {
      type: Boolean,
      required: true,
      default() {
        return false
      }
    },
    isMagazineSubscriptionsForSchools: {
      type: Boolean,
      required: false,
      default() {
        return false
      }
    }
  },
  data() {
    return {
      // infiniteLoadingValues: process.server ? [...this.values] : [...this.values].splice(0, 4),
      infiniteLoadingValues: [...this.values],
      localValues: [...this.values]
    }
  },
  created() {
    this.$nuxt.$on('RESET_INFINITE_LOADING', newValues => {
      // console.log('RESET_INFINITE_LOADING newValues=', newValues)
      if (newValues && newValues.length) {
        this.infiniteLoadingValues = [...newValues]
        this.localValues = [...newValues]
      } else {
        this.infiniteLoadingValues = []
        this.localValues = []
      }
      // console.log('RESET_INFINITE_LOADING this.infiniteLoadingValues=', this.infiniteLoadingValues)
    })
  },
  beforeDestroy() {
    this.$nuxt.$off('RESET_INFINITE_LOADING')
  },
  methods: {
    infiniteHandler($state) {
      // we will add an inifnite scroll Logic  to minimise the HTML payload
      if (this.localValues.length) {
        const data = this.localValues.splice(0, 4)
        console.log(`CLPProductsList > infiniteHandler ADD data=`, data)
        console.log(`CLPProductsList > this.localValues.length=`, this.localValues.length)
        console.log(`CLPProductsList > this.infiniteLoadingValues data=`, this.infiniteLoadingValues)
        this.infiniteLoadingValues.push(...data)
        $state.loaded()
      } else {
        $state.complete()
      }
    }
  }
}
</script>

<style lang="scss"></style>
