import { render, staticRenderFns } from "./gift-card-options.vue?vue&type=template&id=5779084c&scoped=true"
import script from "./gift-card-options.vue?vue&type=script&lang=js"
export * from "./gift-card-options.vue?vue&type=script&lang=js"
import style0 from "./gift-card-options.vue?vue&type=style&index=0&id=5779084c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5779084c",
  null
  
)

export default component.exports