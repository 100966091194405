<template>
  <div>
    <img
      v-if="offerPaymentType === 'DD'"
      alt="Direct debit"
      :class="{ 'offer-payment-type--dd-mobile': isMobile, 'offer-payment-type--dd': !isMobile, 'offer-payment-type--dd-basket': isBasket }"
      :src="imgixDDURL"
      :srcset="`${imgixDDURL} 1x, ${imgixDDURL}&dpr=2&q=30 2x, ${imgixDDURL}&dpr=2&q=30 3x`"
      height="22px"
      width="69px"
      loading="lazy"
    />
    <img
      v-if="offerPaymentType === 'CC'"
      :alt="!isGiftcard ? 'Visa Paypal Mastercard Amex Giftcard' : 'Visa Paypal Mastercard Amex'"
      :class="{
        'offer-payment-type--cc': !isPdp,
        'offer-payment-type--cc-one-line': isPdp || isBasket,
        'offer-payment-type--cc-one-line-no-giftcard': isGiftcard
      }"
      :src="imgixCCURL"
      :srcset="`${imgixCCURL} 1x, ${imgixCCURL}&dpr=2&q=30 2x, ${imgixCCURL}&dpr=2&q=30 3x`"
      :height="isPdp ? '44px' : '24px'"
      :width="getWidth"
      loading="lazy"
    />
  </div>
</template>

<script>
export default {
  name: 'PaymentImages',
  serverCacheKey: props => `PaymentImages::offerPaymentType${props.offerPaymentType}::isPDP${props.isPdp}`,
  props: {
    offerPaymentType: {
      type: String,
      required: true,
      default() {
        return ''
      }
    },
    isPdp: {
      type: Boolean,
      required: false,
      default() {
        return false
      }
    },
    isGiftcard: {
      type: Boolean,
      required: false,
      default() {
        return false
      }
    },
    isMobile: {
      type: Boolean,
      required: false,
      default() {
        return false
      }
    },
    isBasket: {
      type: Boolean,
      required: false,
      default() {
        return false
      }
    }
  },
  data() {
    return {
      imgixDDURL: `${this.$config.imgixURL}/payment/dd.png?auto=format&w69&h=22`
    }
  },
  computed: {
    getWidth() {
      if (this.isGiftcard) {
        return '150px'
      }
      if (this.offerPaymentType === 'CC' && this.isPdp) {
        return '200px'
      }

      return '70px'
    },

    imgixCCURL() {
      if (this.isGiftcard) {
        return !this.isPdp
          ? `${this.$config.imgixURL}/payment/cc-two-lines-no-giftcard.png?auto=format&w=70&h=44`
          : `${this.$config.imgixURL}/payment/cc-one-line-no-giftcard.png?auto=format&w=150`
      }
      return !this.isPdp ? `${this.$config.imgixURL}/payment/cc-two-lines.png?auto=format&w=70&h=44` : `${this.$config.imgixURL}/payment/cc-one-line.png?auto=format&w=200&h=24`
    }
  }
}
</script>

<style lang="scss" scoped>
.offer-payment-type {
  &--dd {
    max-width: 70px;
    padding: 10px 0;
    min-height: 42px;
    aspect-ratio: 69/22;
  }
  &--dd-basket {
    padding: 0px !important;
    min-height: 24px !important;
  }
  &--dd-mobile {
    max-width: 70px;
    min-height: 42px;
    padding: 10px 0;
    aspect-ratio: 69/22;
  }
  &--cc {
    max-width: 70px;
    min-height: 44px;
    aspect-ratio: 35/22;
  }
  &--cc-one-line {
    max-width: 200px;
    max-height: 23.5px;
    aspect-ratio: 400/47;
  }
  &--cc-one-line-no-giftcard {
    max-width: 150px;
    min-height: 24px;
    aspect-ratio: 25/4;
  }
}
</style>
