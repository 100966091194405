<template>
  <section
    class="content content-pdp"
    :class="{
      'content--desktop': !isFromMobile
    }"
  >
    <div v-if="dataUI.magazine" class="content-pdp__main">
      <div v-if="dataUI.status !== 'ENABLED'">
        <b-alert show variant="warning">This product is no longer available</b-alert>
      </div>
      <client-only v-if="$store.getters['UI/debug']">
        <ul class="debug__list">
          <li class="debug__item">
            <tree-view :data="sliceDataForDebug(dataUI)" :options="{ rootObjectKey: 'Data UI', maxDepth: 0, link: true }"> </tree-view>
          </li>
          <li class="debug__item">
            <tree-view :data="dataUI.offers" :options="{ rootObjectKey: 'Offers', maxDepth: 0, link: true }"> </tree-view>
          </li>
          <li class="debug__item">
            <tree-view :data="offersTabs" :options="{ rootObjectKey: 'Offers Tabs', maxDepth: 0, link: true }"> </tree-view>
          </li>
        </ul>
      </client-only>
      <!-- <div v-if="isFromMobile" style="display:none" class="mobile magazine__top">
        <h1>{{ dataUI.magazine.h1Title || dataUI.magazine.title }}</h1>
      </div> -->
      <div class="magazine">
        <client-only>
          <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="false"></loading>
        </client-only>
        <div class="magazine__image">
          <TheMagazineImage :item="dataUI.magazine" :show-save-overlay="false" :show-free-gift-overlay="false" />

          <client-only>
            <star-rating
              v-if="dataUI.magazine.rating && dataUI.magazine.rating.reviews && dataUI.magazine.rating.reviews.length >= 1"
              :inline="true"
              :star-size="20"
              :read-only="true"
              :show-rating="false"
              :rating="dataUI.magazine.rating.ratingValue"
              :increment="0.5"
              class="magazine__rating--mobile"
            >
            </star-rating>
          </client-only>
        </div>
        <div class="magazine__container">
          <h1 class="magazine__head">{{ dataUI.magazine.h1Title || dataUI.magazine.title }}</h1>
          <div class="desktop magazine__extra">
            <div v-if="dataUI.magazine.rating && dataUI.magazine.rating.reviews && dataUI.magazine.rating.reviews.length >= 1" class="magazine__rating-container ">
              <client-only>
                <a href="#reviews" @click="handleReviewClick()">
                  <star-rating
                    :inline="true"
                    :star-size="20"
                    :read-only="true"
                    :show-rating="false"
                    :rating="dataUI.magazine.rating.ratingValue"
                    :increment="0.5"
                    class="magazine__rating"
                  >
                  </star-rating>
                  <span class="m-text-no_underline">(&nbsp;</span><span>{{ dataUI.magazine.rating.reviewCount }}</span
                  ><span class="m-text-no_underline">&nbsp;)</span>
                </a>
              </client-only>
            </div>
            <!-- eslint-disable-next-line vue/no-v-html -->
            <div class="magazine__description" v-html="$options.filters.parseHTML(dataUI.magazine.shortDescription)"></div>
            <!-- eslint-disable vue/no-v-html -->
            <div
              v-if="dataUI.magazine.freeGiftEnabled"
              class="magazine__description magazine__freegift mt-4"
              v-html="$options.filters.removeExtraNewLine(dataUI.magazine.freeGiftDescription)"
            ></div>
            <!-- eslint-enable vue/no-v-html -->
            <InstantAccess v-if="instantAccess" :magazine="dataUI.magazine" class="py-2" />
          </div>
          <div class="mobile magazine__extra">
            <div v-if="defaultOffer()">
              <MobileOfferDefault :offer="defaultOffer()" :magazine="dataUI.magazine" styled-class="pdp--old pdp__default--old" />
            </div>
          </div>
        </div>
      </div>

      <div v-if="!isOnlyDigitalMagazine" class="mobile pdp__button">
        <a
          v-if="dataUI.status === 'ENABLED'"
          class="add-to-basket-pdp add-to-basket-pdp--mobile"
          title="add to basket"
          @click.prevent="handleClickAction(defaultOffer(), 'add', true)"
        >
          ADD TO BASKET
        </a>
      </div>

      <!-- eslint-disable-next-line vue/no-v-html -->
      <div v-if="dataUI.reminderBanner" class="magazine__reminderBanner" v-html="dataUI.reminderBanner" />

      <div class="mobile magazine__sub-heading-bar">
        <!-- eslint-disable-next-line vue/no-v-html -->
        <h2 v-html="mobileTitle()"></h2>
      </div>

      <div class="magazine__offers">
        <b-tabs content-class="p-2" fill align="left">
          <b-tab v-if="dataUI.offers && dataUI.offers.PRINT" :active="offersTabs.PRINT.active" title="UK Offers" h-100>
            <template v-if="!isFromMobile" #title> UK Offers </template>
            <template v-else #title>
              <div class="isPrint">UK Offers <font-awesome-icon v-if="instantAccess" :icon="['fas', 'bolt']" class="fa fa-1x isPrint__bolt" /></div>
            </template>
            <Offers :offers="dataUI.offers.PRINT" :magazine="dataUI.magazine" type="PRINT" />
          </b-tab>

          <b-tab
            v-if="dataUI.offers && dataUI.offers.INTERNATIONAL && parseInternationalOffers(dataUI.offers.INTERNATIONAL)"
            :active="offersTabs.INTERNATIONAL.active"
            :title="isFromMobile ? 'International' : 'International Delivery'"
          >
            <Offers :offers="parseInternationalOffers(dataUI.offers.INTERNATIONAL)" :magazine="dataUI.magazine" type="INTERNATIONAL" />
            <b-alert show variant="warning mt-4">
              Please Note: Print delivery to Europe is currently unavailable.
              <span v-if="dataUI.magazine.PKMGURLWithUTM"
                >However, Digital subscriptions are available from our partner site Pocketmags.
                <a :href="dataUI.magazine.PKMGURLWithUTM" target="_blank">Click here to view digital offers</a></span
              >
            </b-alert>
          </b-tab>

          <b-tab
            v-if="dataUI.magazine.PKMGOnly === false && dataUI.offers && dataUI.offers.DIGITAL"
            :active="offersTabs.DIGITAL.active"
            :title="isFromMobile ? 'Digital' : 'Digital Subscriptions'"
          >
            <Offers :offers="dataUI.offers.DIGITAL" :magazine="dataUI.magazine" type="DIGITAL" />
          </b-tab>
          <b-tab
            v-else-if="dataUI.magazine && dataUI.magazine.PKMGOnly === true && dataUI.magazine.PKMGURLWithUTM"
            :active="offersTabs.DIGITAL.active"
            :title="isFromMobile ? 'Digital' : 'Digital Subscriptions'"
          >
            <div class="tab-text pocketmags-only">
              <div class="pocketmags-only__content">
                Looking for <b>{{ dataUI.magazine.sku }}</b> in digital? Unfortunately <b>magazine.co.uk</b> does not sell digital copies of <b>{{ dataUI.magazine.sku }}</b
                >, but all is not lost. <br />Our partner at
                <a :href="dataUI.magazine.PKMGURLWithUTM" target="_blank">
                  <img
                    src="https://magazine.imgix.net/wysiwyg/pocketmags_logo_bg_transparent.png?amp;auto=format&amp;drp=2&amp;w=400"
                    alt="magazine subscriptions"
                    style="border: none; width: 150px"
                    class="loading"
                    data-was-processed="true"
                    loading="lazy"
                /></a>
                sells digital issues &amp; subscriptions at the best prices online.
              </div>
              <div class="pocketmags-only__button">
                <a :href="dataUI.magazine.PKMGURLWithUTM" class="mdl-button" target="_blank">See all offers</a>
              </div>
            </div>
          </b-tab>
          <b-tab v-if="dataUI.offers && dataUI.offers.SINGLE_ISSUES" :active="offersTabs.SINGLE_ISSUES.active" :title="isFromMobile ? 'Single Issues' : 'Single Issues'">
            <Offers :offers="dataUI.offers.SINGLE_ISSUES" :magazine="dataUI.magazine" type="SINGLE_ISSUES" />
            <b-alert show variant="warning mt-4">Please note that the cover image shown might not be the current issue.</b-alert>
          </b-tab>
          <b-tab v-if="(Object.keys(dataUI.offers).length <= 2 && !dataUI.magazine.PKMGURLWithUTM) || Object.keys(dataUI.offers).length <= 1" disabled title=""></b-tab>
          <b-tab
            v-if="dataUI.offers && dataUI.offers.MEMBERSHIP"
            :active="offersTabs.MEMBERSHIP.active"
            :title="isFromMobile ? 'Membership' : 'Membership'"
            @click="isMembershipTabActive()"
          >
            <Offers :offers="dataUI.offers.MEMBERSHIP" :magazine="dataUI.magazine" type="MEMBERSHIP" />
          </b-tab>
        </b-tabs>
      </div>

      <client-only>
        <PDPWaysToPay />
      </client-only>

      <div id="reviews" class="magazine__info">
        <b-tabs v-if="isFromMobile" content-class="p-4" fill align="left">
          <b-tab title="About" :active="isMembership === undefined || isMembership === false">
            <InstantAccess v-if="instantAccess" :magazine="dataUI.magazine" class="mb-2" />
            <!-- eslint-disable-next-line vue/no-v-html -->
            <div class="tab-text" v-html="$options.filters.parseHTML(dataUI.magazine.shortDescription)"></div>
            <!-- eslint-disable-next-line vue/no-v-html -->
            <div v-if="dataUI.magazine.freeGiftEnabled" class="magazine__description magazine__freegift" v-html="dataUI.magazine.freeGiftDescription"></div>
          </b-tab>
          <b-tab v-if="dataUI.magazine.description" title="Description">
            <!-- eslint-disable-next-line vue/no-v-html -->
            <div class="tab-text" v-html="$options.filters.sanitize(dataUI.magazine.description)"></div>
          </b-tab>
          <b-tab v-if="dataUI.magazine.rating && dataUI.magazine.rating.reviews && dataUI.magazine.rating.reviews.length > 0" :active="isReview === 1" title="Reviews">
            <PDPReviews :id="`mag::${dataUI.magazineid}`" :values="dataUI.magazine.rating.reviews" />
          </b-tab>
          <!-- eslint-disable-next-line vue/no-v-html -->
          <b-tab title="Delivery"><div class="tab-text" v-html="$options.filters.sanitize(dataUI.magazine.delivery)"></div></b-tab>
          <b-tab v-if="!dataUI.magazine.rating && (!dataUI.magazine.description || !dataUI.magazine.delivery)" disabled></b-tab>
          <b-tab v-if="dataUI.offers && dataUI.offers.MEMBERSHIP" title="Membership" :active="isMembership === true">
            <!-- eslint-disable-next-line vue/no-v-html -->
            <div class="tab-text" v-html="$options.filters.sanitize(dataUI.magazine.membership)"></div>
          </b-tab>
        </b-tabs>
        <b-tabs v-else content-class="p-4" fill align="left">
          <b-tab v-if="dataUI.magazine.description" :title="dataUI.magazine.isNewspaper ? 'Newspaper Description' : 'Magazine Description'">
            <!-- eslint-disable-next-line vue/no-v-html -->
            <div class="tab-text" v-html="$options.filters.sanitize(dataUI.magazine.description)"></div>
          </b-tab>
          <b-tab v-if="dataUI.magazine.rating && dataUI.magazine.rating.reviews && dataUI.magazine.rating.reviews.length > 0" :active="isReview === 1" title="Reviews">
            <PDPReviews :id="`mag::${dataUI.magazineid}`" :values="dataUI.magazine.rating.reviews" />
          </b-tab>
          <!-- eslint-disable-next-line vue/no-v-html -->
          <b-tab title="Delivery"><div class="tab-text" v-html="$options.filters.sanitize(dataUI.magazine.delivery)"></div></b-tab>
          <b-tab v-if="!dataUI.magazine.rating && (!dataUI.magazine.description || !dataUI.magazine.delivery)" disabled></b-tab>

          <b-tab v-if="dataUI.offers && dataUI.offers?.MEMBERSHIP" title="Membership" :active="isMembership === true">
            <!-- eslint-disable-next-line vue/no-v-html -->
            <div class="tab-text memebership" v-html="$options.filters.sanitize(dataUI.magazine.membership)"></div>
          </b-tab>
        </b-tabs>
      </div>

      <PDPWhatSInside v-if="dataUI?.magazine?.whatSInside?.enabled" :payload="dataUI?.magazine?.whatSInside?.columns" :title="dataUI.magazine.title" :sku="dataUI?.magazine.sku" />

      <client-only>
        <PDPFAQs v-if="!dataUI.magazine.isNewspaper && dataUI.magazine.cheapestOfferNumOfIssues" :payload="dataUI" />
      </client-only>

      <client-only>
        <div v-if="dataUI && dataUI.upsell && dataUI.upsell.length > 0" class="product__upsell">
          <div class="product__panel">
            <p class="product__upsell-heading">Share this page</p>
          </div>

          <div class="basket__panel upsell-grid items-list">
            <Social :magazine="dataUI.magazine" />
          </div>
        </div>
      </client-only>

      <client-only>
        <div v-if="dataUI && dataUI.upsell && dataUI.upsell.length > 0" class="product__upsell">
          <div class="product__panel">
            <p class="product__upsell-heading">You might also like...</p>
          </div>

          <div class="basket__panel upsell-grid items-list">
            <UpsellProduct
              v-for="(item, index) in dataUI.upsell.slice(0, 4)"
              :key="`${index}-${item.productId}-upsell`"
              :type="'upsell'"
              :item="item"
              class="product__upsell-item items-list__item"
              imgix-query-string="&w=130&h=180"
            />
          </div>
        </div>
      </client-only>

      <client-only>
        <div v-if="bestSellersValues && bestSellersValues.length > 0" class="product__upsell product__bestSellers">
          <div class="product__panel">
            <p class="product__upsell-heading">Best Sellers</p>
          </div>

          <div class="basket__panel upsell-grid items-list">
            <UpsellProduct
              v-for="(item, index) in bestSellersValues"
              :key="`${index}-${item.productId}-bestseller`"
              :type="'bestsellers'"
              :item="item"
              class="product__upsell-item items-list__item"
              imgix-query-string="&w=130&h=180"
            />
          </div>
        </div>
      </client-only>
      <client-only>
        <div v-if="recentlyViewed && recentlyViewed.length > 0" class="product__upsell product__recentlyViewed">
          <div class="product__panel">
            <p class="product__upsell-heading">Recently Viewed</p>
          </div>

          <div class="basket__panel upsell-grid items-list">
            <UpsellProduct
              v-for="(item, index) in recentlyViewed"
              :key="`${index}-${item.productId}-recentlyviewed`"
              :type="'upsell'"
              :item="item"
              class="product__upsell-item items-list__item"
              imgix-query-string="&w=130&h=180"
            />
          </div>
        </div>
      </client-only>
      <PromotionsSitewideMobile
        v-if="sitewidemobile && sitewidemobile.enabled === true"
        style="margin-bottom: 30px;"
        creative-slot="mobile"
        location-id="sitewide_offer_promotion"
        :sitewide="sitewidemobile"
      />
    </div>
  </section>
</template>

<script>
import product from '@/mixins/product'
export default {
  mixins: [product]
}
</script>

<style lang="scss" scoped>
.magazine {
  &__rating-container {
    min-height: 32px;
    a {
      font-size: 11px;
      color: $bodyTextColor;
      span {
        line-height: 13px;
        vertical-align: bottom;
      }
      :hover {
        text-decoration: underline;
        text-underline-offset: 2px;
      }
    }
  }
  &__freegift {
    margin-top: 20px;
    display: inline-table;
  }
}
.m-text-no_underline {
  text-decoration: none !important;
}
.magazine {
  &__container {
    flex: 1 1 50%;
  }
  h1 {
    font-size: 18px;
    font-weight: 700;
    color: $bodyTextColor;
  }
  &__head {
    line-height: 20px;
    margin: 5px 0 5px 10px;
  }
}

@media (max-width: 400px) {
  .magazine {
    h1 {
      font-size: 16px;
    }
  }
}

@media (min-width: $screen-sm-min) {
  .magazine {
    h1 {
      font-size: 22px;
      font-weight: 600;
      color: $headingColor;
      line-height: 1.2;
    }
    &__head {
      margin: initial;
      color: $headingColor;
      border-bottom: 1px solid #d9d9d9;
      margin-bottom: 10px;
      padding: 0 0 10px;
    }
  }
}
/* ----------- iPad and iPad Mini  ----------- */
/* Portrait */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .magazine {
    &__head {
      padding: 20px 0;
      margin: 0 10px;
    }
  }
}
</style>
