<template>
  <div class="footer-up">
    <div class="footer-up__grid content--max">
      <div v-if="!$route.path.includes('/newsletter-signup')" class="footer-newsletter-link">
        <a href="/newsletter-signup" target="_blank">Sign up to receive the latest offers</a>
      </div>
      <div class="footer-social-grid">
        <a class="footer-social-grid__item" title="Like us on Facebook" href="http://www.facebook.com/magazinecouk" target="_blank" rel="noopener">
          <font-awesome-icon :icon="['fab', 'facebook-square']" class="fa-3x" />
        </a>

        <a class="footer-social-grid__item" title="Follow us on Twitter" href="http://twitter.com/Magazinecouk" target="_blank" rel="noopener">
          <font-awesome-icon :icon="['fab', 'twitter-square']" class="fa-3x" />
        </a>
        <a class="footer-social-grid__item" title="Follow us on Instagram" href="https://www.instagram.com/magazinecouk/" target="_blank" rel="noopener">
          <font-awesome-icon :icon="['fab', 'instagram']" class="fa-3x" />
        </a>
        <a class="footer-social-grid__item" title="Follow us on Pinterest" href="https://www.pinterest.com/magazinecouk/" target="_blank" rel="noopener">
          <font-awesome-icon :icon="['fab', 'pinterest-square']" class="fa-3x" />
        </a>
        <a
          class="footer-social-grid__item"
          title="Subscribe to our channel on Youtube"
          href="https://www.youtube.com/channel/UCxhjLfcujpsMhNOCL1b_FWA"
          target="_blank"
          rel="noopener"
        >
          <font-awesome-icon :icon="['fab', 'youtube-square']" class="fa-3x" />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FooterSocial',
  serverCacheKey() {
    return false
  },
  data() {
    return {
      show: false
    }
  }
}
</script>

<style lang="scss">
.footer-up {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
  background: $footerBGColourSocial;

  &__grid {
    flex: 0 0 100%;
    display: flex;
    justify-content: center;
    flex-flow: row wrap;

    .footer-newsletter-link {
      // flex: 0 0 40%;
      padding: 15px;
      align-self: center;
      a {
        margin: 10px;
        color: #fff;
        font-size: 18px;
        font-weight: 500;
        line-height: 1.2;
        margin: 10px;
        text-align: center;
      }
    }

    .footer-social-grid {
      flex: 0 0 45%;
      display: flex;
      justify-content: center;
      flex-flow: row wrap;

      &__item {
        padding: 10px;
        flex: 0 0 44px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        font-size: 14px;

        a:hover {
          text-decoration: none;
        }

        &:hover {
          color: #ededed;
        }

        img {
          max-width: 35px;
        }
      }
    }
  }
}

@media screen and (max-width: $screen-xs-max) {
  .footer-links-grid {
    &__item {
      flex: 0 0 50%;
      padding: 10px;
    }
    img {
      max-width: 160px;
    }
  }

  .footer-up {
    &__grid {
      flex-flow: column nowrap;
    }
  }
}
</style>
