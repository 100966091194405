<template>
  <section class="content-cms">
    <client-only v-if="$store.getters['UI/debug']">
      <ul class="debug__list">
        <li class="debug__item">
          <tree-view :data="showFlag" :options="{ rootObjectKey: 'ShowFlag', maxDepth: 0, link: true }"> </tree-view>
        </li>
        <li class="debug__item">
          <tree-view :data="$options.filters.objectParser(dataUI)" :options="{ rootObjectKey: 'Data UI', maxDepth: 0, link: true }"> </tree-view>
        </li>
      </ul>
    </client-only>
    <div class="content-cms__main cms">
      <LazyHydrate>
        <!-- eslint-disable-next-line vue/no-v-html -->
        <div class="cms__content" v-html="dataUI.content"></div>
      </LazyHydrate>

      <div class="accordion-wrap">
        <ul>
          <li v-for="(faqHeading, faqHeadingIndex) in FAQs" :key="`faq-heading-${faqHeadingIndex}`">
            <!-- <a :id="faqHeading.id" :href="`#${faqHeading.id}`" class="accordion-heading" @click.prevent="toggleShow(faqHeading.id)">
              <span>
                <font-awesome-icon v-if="showFlag[faqHeading.id]" :icon="['fal', 'chevron-down']" />
                <font-awesome-icon v-if="!showFlag[faqHeading.id]" :icon="['fal', 'chevron-right']" />
              </span>
              {{ faqHeading.title }}
            </a> -->
            <div v-show="showFlag[faqHeading.id]" class="accordion-content">
              <ul>
                <li
                  v-for="(item, faqContentIndex) in faqHeading.items"
                  :key="`faq-heading-${faqHeadingIndex}-content-${faqContentIndex}`"
                  :class="{ hash__selected: item.id === hash }"
                  @click="toggleShow(item.id)"
                >
                  <a :id="item.id" :href="`#${item.id}`" class="accordion-heading">
                    <span>
                      <font-awesome-icon v-if="showFlag[item.id]" :icon="['fal', 'chevron-down']" class="" />
                      <font-awesome-icon v-if="!showFlag[item.id]" :icon="['fal', 'chevron-right']" class="" />
                    </span>
                    {{ item.question }}
                  </a>
                  <!-- eslint-disable-next-line vue/no-v-html -->
                  <div v-show="showFlag[item.id]" class="accordion-content" v-html="item.answer" />
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script>
import LazyHydrate from 'vue-lazy-hydration'
import { sendSSAPIPageView, createUniqueFBEventId } from '~/lib/util/fb'
import { getGTMPageViewDetailsPayload } from '~/lib/util/gtmUAEE'
import { getBreadcrumbsMicrodataJSONLD } from '~/lib/util/microdata'
import { callAWSFrontendAPI } from '~/lib/util/aws'
import { createFAQsId } from '~/lib/util/page'
import { FAQs } from '~/lib/data/christmas-faqs'

export default {
  name: 'FAQs',
  filters: {
    objectParser(obj) {
      const sup = { ...obj }
      sup.bottomText = 'NOT AVAILABLE FOR DEBUG'
      return sup
    }
  },

  components: {
    LazyHydrate
  },

  async fetch() {
    // Look for an available Query String and retrieve the URL path
    const path = this.$route.path
    const query = this.$nuxt.context.query
    const useCache = this.$store.getters['UI/useCache']
    console.log('path=', path)
    console.log('query=', query)
    console.log('QS useCache=', useCache)
    console.log('AWSAPIGatewayURL=', this.$nuxt.$config.AWSAPIGatewayURL)

    this.queryString = query

    this.dataUI = {
      ...(await callAWSFrontendAPI({ context: this, path, query, useCache, type: 'CMSPAGE' }))
    }

    // Breadcrumbs
    const page = {
      value: 'Christmas FAQs',
      type: path,
      last: true
    }
    this.$store.commit('ADD_BREADCRUMBS', { path, page })
  },

  data() {
    return {
      dataUI: {},
      queryString: [],
      FAQs,
      hash: undefined,
      showFlag: {}
    }
  },

  serverCacheKey() {
    return false
  },
  layout: 'default',

  fetchOnServer: true,

  mounted() {
    // Open the current question based on the URL Hash provided
    this.hash = this?.$route?.hash.replace(/#/g, '') || undefined
    if (this.hash) {
      console.log('Route hash=', this?.$route?.hash)
      console.log('hash=', this.hash)
      this.showFlag[this.hash] = true
    }

    // Facebook Event Deduplication
    const eventId = createUniqueFBEventId('PageView')

    // Customer email address
    const email = this.$store.getters['s/email']

    // UAEE tracking PageView
    this.$config.UAEESyntax &&
      this.$gtm &&
      this.$gtm.push(
        getGTMPageViewDetailsPayload({ pageUrl: this.$route.path, pageTitle: (this.page && this.page.title) || this.dataUI.title, routeName: this.$route.name, eventId, email })
      )

    // FB v12.0
    const eventSourceUrl = `${this.$config.baseURL}${this.$route.fullPath}`

    // Facebook Server-side API PageView
    sendSSAPIPageView({ context: this, parameters: { eventId, eventSourceUrl } })
  },

  created() {
    this.FAQs.forEach(section => {
      section.id = createFAQsId(section)
      this.showFlag[section.id] = section?.show || false
      section.items.forEach(item => {
        item.id = `${section.id}::${createFAQsId(item)}`
        this.showFlag[item.id] = item?.show || false
      })
    })
  },

  methods: {
    toggleShow(id) {
      this.showFlag[id] = !this.showFlag[id]
    }
  },

  // To check more options, link https://github.com/nuxt/vue-meta#script-object
  // To chcek how it is working https://nuxtjs.org/faq/duplicated-meta-tags/
  head() {
    return {
      title: this?.dataUI?.title,
      link: [{ rel: 'canonical', href: this?.dataUI?.canonicalURL }],
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this?.dataUI?.metaDescription
        },
        {
          name: 'keywords',
          content: this?.dataUI?.metaKeywords || ''
        },
        {
          name: 'robots',
          content: (this.$route.path.includes('/welcome-back') && 'NOINDEX,NOFOLLOW') || this.$config.robots || 'INDEX,FOLLOW'
        },
        {
          hid: 'og:title',
          name: 'og:title',
          property: 'og:title',
          content: this?.dataUI?.title
        },
        {
          hid: 'og:description',
          name: 'og:description',
          property: 'og:description',
          content: this?.dataUI?.metaDescription
        },
        {
          hid: 'og:site_name',
          name: 'og:site_name',
          property: 'og:site_name',
          content: 'magazine.co.uk'
        },
        {
          hid: 'og:image',
          name: 'og:image',
          property: 'og:image',
          content: 'https://www.magazine.co.uk/icon.png'
        },
        {
          hid: 'og:type',
          name: 'og:type',
          property: 'og:type',
          content: 'article'
        }
      ]
    }
  },
  jsonld() {
    return [getBreadcrumbsMicrodataJSONLD({ breadcrumbs: this.$store.getters['UI/breadcrumbs'] })]
  }
}
</script>

<style lang="scss">
.content-cms {
  font-size: 14px;
  margin: 0 auto;
  flex-flow: row nowrap;
  display: flex;
  width: 100%;
  justify-content: space-around;
  max-width: 1230px !important;
  flex-direction: column;

  &--main {
    &__3col {
      flex: 1 1 65%;
      margin: 0 10px;
      padding: 10px;
    }
  }

  .cms {
    padding: 10px 15px;
    &__title h1 {
      font-size: 28px;
      margin: 0 0 15px;
      font-weight: 400;
      color: #ff0d00;
    }
    &__content p {
      font-size: 14px;
      margin: 0 0 10px;
      line-height: 20px;
      font-size: 14px;
      color: #333;
      a:hover {
        cursor: pointer;
      }
    }
    &__content ul {
      font-size: 14px;
      line-height: 1.42857;
      color: #333;
      display: block;
      list-style-type: disc;
      margin-block-start: 1em;
      margin-block-end: 1em;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
      padding: 0 40px;
    }

    a {
      color: #333 !important;
      //   text-decoration: underline !important;
      :hover {
        color: #333;
      }
    }
    td {
      border: 1px solid black;
      ul {
        padding: 0 8px;
      }
    }
    h3 {
      font-size: 20px;
    }
  }
}

@media (max-width: $screen-sm-min) {
  .content-cms {
    &__main {
      flex: 0 1 100%;
      margin: 0;
      padding: 0px;
    }
    &__description {
      h1 {
        line-height: 1.2em;
      }
    }
  }
}

@media (max-width: $screen-xs-max) {
  .content-cms {
    &__main {
      flex: 0 1 100%;
      margin: 0;
      padding: 0px;
    }
    &__description {
      h1 {
        line-height: 1.2em;
      }
    }
  }
}

.accordion-wrap {
  ul {
    list-style: none;
    padding: 0;
    li {
      .accordion-heading {
        padding: 10px 0;
        font-size: 24px;
        color: $bodyTextColor;
        font-family: $fontRegular;
        text-decoration: none;
        display: block;
        border-bottom: 1px dotted #ccc;
        span {
          display: inline-block;
          width: 1.25em;
          //   @include inline-block;
          font-size: 16px;
          padding: 0 10px;
          //   margin-right: 8px;
          &:before {
            font-size: 1em;
          }
        }
        &.open {
          span {
            // &:before {
            //   content: '\f078';
            // }
          }
        }
      }
      .accordion-content {
        p {
          a {
            text-decoration: none !important;
            color: #337ab7 !important;
            &:hover {
              text-decoration: underline !important;
            }
          }
        }
        ol.sub-list {
          list-style-type: decimal;
          display: inline-block;
          margin-left: 0px;
        }
        li {
          background: #e5e5e5;
          margin-bottom: 10px;
        }
        .accordion-heading {
          font-size: 16px;
          padding: 15px 15px 15px 30px;
          border-bottom: unset !important;
          position: relative;
          span {
            padding: 0px 10px;
            position: absolute;
            left: 0px;
            top: 18px;
          }
        }
        .accordion-content {
          padding: 15px 25px;
        }
      }
      &:last-child {
        .accordion-heading {
          border-bottom: none;
        }
      }
    }
  }
}
.accordion-content {
  //   display: none;
  form {
    max-width: 510px;
    margin-top: 20px;
    .input-wrap {
      //   @include clearfix;
      margin-bottom: 10px;
      label {
        // @include inline-block;
        font-family: $fontRegular;
        padding-top: 8px;
        &.error {
          display: block;
          color: $headingColor;
          text-align: right;
        }
      }
      input,
      select,
      textarea {
        // @include border-radius(3px);
        float: right;
        border: 1px solid #ccc;
        width: 260px;
        padding: 5px 10px;
      }
      &.input-textarea {
        textarea {
          height: 150px;
        }
      }
    }
    #loader {
      position: relative;
      top: 5px;
      left: 220px;
      float: left;
    }
  }
}
#query-form {
  input.error,
  select.error,
  textarea.error {
    border: 1px solid $headingColor;
  }
}
.search-results {
  margin-bottom: 20px;
}
.hash__selected {
  border: 2px solid #ff0d00;
  a {
    outline: unset;
  }
}
h1 {
  margin: 0 0 15px;
  font-weight: 400;
  color: #ff0d00;
  font-size: 2em !important;
}
h4 {
  font-size: 14px !important;
  line-height: 1.42857 !important;
}
</style>
