<template>
  <table class="subOpt__content">
    <client-only>
      <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="false"></loading>
    </client-only>
    <tbody>
      <tr class="subOpt__offer">
        <td class="subOpt__field subOpt__name">
          {{ offer.name }}
        </td>
      </tr>
      <tr class="subOpt__offer">
        <td>
          <table class="subOpt__tbl">
            <tbody>
              <tr>
                <td class="subOpt__field subOpt__tblField--25">
                  <span class="subOpt__header">Issues</span>
                  {{ offer.numOfIssues }}
                </td>
                <td class="subOpt__field subOpt__tblField--25">
                  <span class="subOpt__header">RRP</span>
                  <span
                    class="subOpt__rrp"
                    :class="{
                      'text-decoration--none': parseInt(offer.rrp - offer.price) === 0,
                      'text-decoration--line-through': parseInt(offer.rrp - offer.price) > 0
                    }"
                    >{{ formatCurrency(offer.rrp) }}</span
                  >
                </td>
                <td v-if="parseInt(offer.rrp - offer.price) > 0" class="subOpt__field subOpt__tblField--25">
                  <span class="subOpt__header">Saving</span>
                  <span>{{ formatCurrency(offer.rrp - offer.price) }}</span>
                </td>
                <td v-else class="subOpt__field subOpt__tblField--25">
                  <span class="subOpt__header"></span>
                  <span></span>
                </td>
                <td class="subOpt__field subOpt__tblField--25">
                  <PaymentImages :is-mobile="true" :offer-payment-type="offer.paymentType" :is-giftcard="offer.sku === 'gift-card' || offer.sku === 'e-giftcards'" />
                </td>
              </tr>
            </tbody>
          </table>
        </td>
        <td></td>
      </tr>
      <tr v-if="offer.freeGiftEnabled" class="subOpt__freeGift">
        <td v-if="offer.freeGiftEnabled">
          <span><strong>Free Gift Details</strong> </span>
          <br />
          <span class="green-text">{{ offer.freeGiftDescription }}</span>
        </td>
      </tr>
      <tr class="subOpt__offer">
        <td :class="{ 'subOpt__field--25': offer.freeGiftEnabled, 'subOpt__field--50': !offer.freeGiftEnabled }" class="subOpt__field">
          <span class="subOpt__header">Our Price</span>
          <span class="subOpt__price">{{ formatCurrency(offer.price) }}</span>
        </td>

        <td v-if="offer.freeGiftEnabled" class="subOpt__field subOpt__field--25">
          <div class="freegift-overlay">
            <span class="freegift-overlay__txt1">FREE</span>
            <span class="freegift-overlay__txt2">GIFT</span>
          </div>
        </td>

        <td class="subOpt__field subOpt__field--50">
          <div v-if="isSingleIssuesOffer && offer.available === false && offer.qty === 0" class="add-to-basket-pdp subOpt__button add-to-basket-pdp--disabled" title="Out of Stock">
            OUT OF STOCK
          </div>
          <div v-else-if="magazine.enabled" class="add-to-basket-pdp subOpt__button" title="add to basket" @click.prevent="handleClickAction(offer, 'add')">BUY</div>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { formatCurrency } from '~/lib/util/basket'
import basketMixin from '~/mixins/basket.vue'
import gtmProductPageMixin from '@/mixins/gtm/product-page'

export default {
  components: {
    PaymentImages: () => import('~/components/common/product/PaymentImages.vue')
  },
  mixins: [gtmProductPageMixin, basketMixin],
  props: {
    offer: {
      type: Object,
      required: true,
      default() {
        return {}
      }
    },
    magazine: {
      type: Object,
      required: true,
      default() {
        return {}
      }
    },
    isDefault: {
      type: String,
      required: false,
      default() {
        return '0'
      }
    },
    styledClass: {
      type: String,
      required: false,
      default() {
        return 'pdp'
      }
    },
    isSingleIssuesOffer: {
      type: Boolean,
      required: true,
      default() {
        return false
      }
    }
  },
  data() {
    return {
      isLoading: false
    }
  },
  computed: {},
  methods: {
    formatCurrency(value) {
      return formatCurrency(value)
    },
    async handleClickAction(offer, action) {
      console.log('MobileOffer handleClickAction offer=', offer)
      console.log('MobileOffer handleClickAction action=', action)

      const item = {
        title: this.magazine.title,
        imageUrl: offer.imageUrl,
        productId: this.magazine.productId,
        id: this.magazine.id,
        sku: this.magazine.sku,
        offer,
        category: this.magazine.category,
        subCategory: this.magazine.subCategory
      }

      // Update the BE Basket data
      const ret = await this.updateBasket(item, action)

      // GTM & FB push events integration
      !ret?.error && this.pushGTMAndFBAfterButtonClick({ action, item })
      // Using a common mixin basket action method to push the items to the backend

      // Show the basket overlay
      !ret?.error && this.$nuxt.$emit('SHOW_BASKET', true)
    }
  }
}
</script>

<style lang="scss">
@media (max-width: $screen-xs-max) {
  .add-to-basket-pdp--disabled {
    font-size: 16px !important;
    background-color: gray !important;
  }
  .subOpt {
    &__content {
      position: relative;
      padding: 5px 10px;
      width: 100%;
    }
    &__field {
      display: inline-block;
      padding: 10px 8px;
      text-align: left;
      text-align: left;
      font-size: 12px;
    }
    &__name {
      width: 100%;
      font-size: 18px;
      font-weight: 700;
      height: auto;
    }
    &__tbl {
      border: 1px solid #bcbcbc;
      border-radius: 5px;
      width: 95%;
      margin: 0 auto;
    }
    &__header {
      display: block;
      width: 100%;
      font-weight: 700;
      color: #666;
    }
    &__payment {
      max-width: 70px;
      vertical-align: middle;
    }
    &__price {
      color: #ff0d00;
      font-weight: 700;
      font-size: 16px;
    }
    &__tblField--25 {
      width: 25%;
      // img {
      //   min-height: 44px;
      // }
    }
    &__field--50 {
      width: 50%;
    }
    &__field--25 {
      width: 25%;
    }
    &__button {
      max-width: 200px !important;
    }
    &__freeGift {
      display: inline-block;
      padding: 10px 8px;
      text-align: left;
      text-align: left;
      font-size: 14px;
    }
    &__freeGiftImg {
      float: right;
    }
  }
}

.subOpt__field--25 {
  .freegift-overlay {
    float: right;
    position: relative;
    min-width: 36px;
    max-width: 36px;
    min-height: 36px;
    max-height: 36px;
    z-index: 0;
    background-color: #df0000;
    border-radius: 30px;
    border: 2px #fff solid;
    text-align: center;
    line-height: 13px;
    left: 5px;
  }
  .freegift-overlay__txt1 {
    position: absolute;
    color: #fff;
    font-weight: 700;
    width: 36px;
    right: -2px;
    top: 6px;
    font-size: 10px;
    text-align: center;
  }

  .freegift-overlay__txt2 {
    position: absolute;
    color: #fff;
    font-weight: 700;
    width: 36px;
    right: -2px;
    top: 16px;
    font-size: 10px;
    text-align: center;
  }
}
</style>
