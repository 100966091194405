<template>
  <ul class="homepage-nav-main-cats homepage-nav-main-cats--desktop">
    <li v-for="(cat, index) in cats" :key="index" class="homepage-nav-main-cats__item">
      <a :href="cat.href">
        <span><font-awesome-icon :icon="['fal', cat.icon]" class="fa fa-fw fa-stack-2x left homepage-nav-main-cats__icon"/></span>
        <span class="homepage-nav-main-cats__title">{{ cat.title }}</span>
      </a>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'HomepageNavDesktop',
  serverCacheKey: props => `HomepageNavDesktop::${props.timestamp}`,
  props: {
    timestamp: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      cats: [
        {
          title: 'Magazines for Women',
          href: '/magazine-subscriptions-for-women',
          icon: 'female'
        },
        {
          title: 'Hobbies & Leisure',
          href: '/hobbies-and-leisure-magazines',
          icon: 'camera'
        },
        {
          title: 'Entertainment',
          href: '/entertainment-magazines',
          icon: 'gamepad'
        },
        {
          title: 'Lifestyle',
          href: '/lifestyle-magazines',
          icon: 'heart'
        },

        {
          title: 'Magazines for Men',
          href: '/magazine-subscriptions-for-men',
          icon: 'male'
        },
        {
          title: 'Home & Gardening',
          href: '/home-and-gardening-magazines',
          icon: 'flower-daffodil'
        },
        {
          title: 'Fashion & Beauty',
          href: '/fashion-and-beauty-magazines',
          icon: 'shopping-bag'
        },
        {
          title: 'Arts & Culture',
          href: '/arts-and-culture-magazines',
          icon: 'paint-brush-alt'
        },
        {
          title: 'Magazine Gift Ideas',
          href: '/magazine-subscription-gift-ideas',
          icon: 'gift'
        },
        {
          title: 'Food & Drink',
          href: '/food-and-drink-magazines',
          icon: 'utensils'
        },
        {
          title: 'Motoring & Transport',
          href: '/motoring-and-transport-magazines',
          icon: 'car'
        },
        {
          title: 'News & Current Affairs',
          href: '/news-and-current-affairs-magazines',
          icon: 'newspaper'
        },
        {
          title: 'Kids',
          href: '/kids-magazines',
          icon: 'child'
        },
        {
          title: 'Sports',
          href: '/sports-magazines',
          icon: 'running'
        },
        {
          title: 'Science & Nature',
          href: '/science-and-nature-magazines',
          icon: 'paw'
        },
        {
          title: 'Computers & Tech',
          href: '/computers-and-technology-magazines',
          icon: 'laptop'
        },
        {
          title: 'Digital Subscriptions',
          href: '/digital-magazines',
          icon: 'phone-laptop'
        },
        // {
        //   title: 'Single Issues',
        //   href: '/single-issues',
        //   icon: 'file'
        // },
        {
          title: 'Gift Cards',
          href: '/gift-card-options',
          icon: 'gift-card'
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.homepage-nav-main-cats {
  content-visibility: auto;

  &--desktop {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: center;
    background-color: #ffffff;
    min-height: 160px;
  }
  &__item {
    margin-left: 1px;
    width: 33%;
    &:hover {
      background: $sectionBackground;
    }
    a {
      display: block;
      font-size: 13px;
      color: $navLinkColor;
      text-decoration: none;

      flex: 1;
      display: flex;
      justify-content: left;
      align-items: center;
      min-height: 40px;
      height: 30px;
    }

    .material-icon {
      float: left;
      margin-left: 0.5em;
      margin-right: 0.5em;
    }
  }
  .left {
    margin: 10px;
    position: relative;
  }
}

@media (max-width: $screen-xs-max) {
  .homepage-nav-main-cats {
    content-visibility: auto;
    min-height: 53px;
    margin: 0;
    padding: 0;
    &__item {
      width: 100%;
      border-bottom: 1px solid #bcbcbc;
      padding: 16px 15px;
      a {
        color: $navTextColourMobile;
        font-size: 16px;
        padding: 0;
        &:hover {
          background: none;
        }
        min-height: 20px;
        height: 20px;
      }
    }
  }

  .left {
    display: none;
  }
}

@media (min-width: $screen-sm-min) {
  .homepage-nav-main-cats {
    margin: 1em auto;
    &__item {
      // border-bottom: 1px solid $borderColor;
      width: 31%;
      margin: 0 2px;
      a {
        font-size: 13px;
      }
    }
  }
}

@media (min-width: $screen-md-min) {
  .homepage-nav-main-cats {
    align-items: stretch;
    &__item {
      width: 24%;
      padding: 0;
      a {
        font-size: 13px;
      }
      &:nth-last-child(-n + 2) {
        display: none;
      }
    }
  }
}

.feature-grid {
  margin-top: 1em;
}

.feature-grid:first-child {
  margin-top: 0em;
}
</style>
