<template>
  <div class="ec-success-container">
    <client-only>
      <div class="ec-success-grid ec-success-grid--text">
        <ECardNotification v-if="ecard" :ecard="ecard" />
        <!-- <ECardErrorNotification :ecard="ecard" /> -->
      </div>
      <div class="ec-success-grid">
        <ECardTemplate
          v-if="ecard"
          :image="ecard.design && createImgixURL(ecard.design.image_path, designPreviewQueryString)"
          :message="ecard.message == '' ? undefined : ecard.message"
          :magazine-cover="ecard.magazine.image_url"
          :magazine-name="ecard.magazine.name"
          :from-name="ecard.from_name"
          :from-email="ecard.from_email"
          :to-name="ecard.to_name"
          :to-email="ecard.to_email"
          :to-date="new Date(ecard.to_date)"
          :disabled-anchors="true"
        />
      </div>
    </client-only>
  </div>
</template>

<script>
import { getBreadcrumbsMicrodataJSONLD } from '~/lib/util/microdata'

export default {
  name: 'SuccessECard',
  fetchOnServer: false,
  components: {
    ECardTemplate: () => import('~/components/ecard/ECardTemplate.vue'),
    ECardNotification: () => import('~/components/ecard/ECardNotification.vue')
    // ECardErrorNotification: () => import('~/components/ecard/ECardErrorNotification.vue')
  },
  fetch() {
    // Breadcrumbs
    const breadcrumbs = [
      {
        first: true,
        value: 'magazine subscriptions',
        url: '/',
        last: false
      },
      {
        first: false,
        value: 'Create ECard',
        url: '/ecard',
        last: false
      },
      {
        first: false,
        value: 'Confirmation',
        url: '/success/ecard',
        last: true
      }
    ]
    this.$store.commit('ADD_BREADCRUMBS', { path: '/success/ecard', breadcrumbs })
  },

  async asyncData({ store, route, params, query, $ua, redirect, $config, $axios }) {
    console.log('PARAMS=', params)
    if (!Object.keys(params).length || !params?.id) {
      redirect('/')
    }
    const url = `${$config.AWSAPIGatewayURL}/ecard/id/${params.id}`
    const useCache = false
    const ret = await $axios
      .$get(url, {
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': $config.AWSAPIGatewayXAPIKEY,
          'Cache-Control': useCache ? 'max-age=3600' : 'max-age=0'
        },
        timeout: $config.AWSAPIGatewayTimeout,
        useCache
      })
      .then(function(response) {
        console.log('FOUND ECARD=', response.payload)
        return response
      })
      .catch(function(error) {
        console.log('ERROR=', error)
        return {
          error
        }
      })

    if (ret.error) {
      redirect('/')
    }

    return {
      isFromMobile: $ua.isFromIphone() || $ua.isFromAndroidMobile(),
      params,
      ecard: ret.payload
    }
  },

  layout: '2Columns',
  data() {
    return {
      ecard: undefined,
      designPreviewQueryString: '?auto=format&w=540&dpr=3&rect=left,top,1506,375',
      canonicalURL: 'https://www.magazine.co.uk/success/ecard',
      metaTitle: 'ECard Confirmation',
      metaDescription:
        "Create your magazine.co.uk eCard using our FREE Personalised eCard Creator. When you're happy with the design, you can either email it to the recipient on a specified date or print your card and give to the recipient yourself on the big day.",
      metaKeywords: 'magazine.co.uk'
    }
  },
  methods: {
    createImgixURL(image, qs) {
      return `${this.$config.imgixURL}${image}${qs}`
    }
  },
  // To check more options, link https://github.com/nuxt/vue-meta#script-object
  // To chcek how it is working https://nuxtjs.org/faq/duplicated-meta-tags/
  head() {
    return {
      title: this?.metaTitle || '',
      link: [{ rel: 'canonical', href: this?.canonicalURL || '' }],
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this?.metaDescription || ''
        },
        {
          name: 'keywords',
          content: this?.metaKeywords || ''
        },
        {
          name: 'robots',
          content: this.$config.robots || 'INDEX,FOLLOW'
        },
        {
          hid: 'og:title',
          name: 'og:title',
          property: 'og:title',
          content: this?.metaTitle || ''
        },
        {
          hid: 'og:description',
          name: 'og:description',
          property: 'og:description',
          content: this?.metaDescription || ''
        },
        {
          hid: 'og:site_name',
          name: 'og:site_name',
          property: 'og:site_name',
          content: 'magazine.co.uk'
        },
        {
          hid: 'og:image',
          name: 'og:image',
          property: 'og:image',
          // TODO
          content: 'https://magazine.imgix.net/gift-card-images/JMAG_Giftcards.png?auto=format'
        }
      ]
    }
  },

  jsonld() {
    return [getBreadcrumbsMicrodataJSONLD({ breadcrumbs: this.$store.getters['UI/breadcrumbs'] })]
  }
}
</script>

<style lang="scss" scoped>
#main {
  min-height: auto;
}
.ec-success-container {
  flex-flow: column;
  align-items: center;
  display: flex;
  margin-bottom: 20px;
}
.ec-success-grid {
  padding: 10px;
  &--text {
    inline-size: 100%;
    overflow-wrap: break-word;
    max-width: 700px;
  }
}
</style>
