<template>
  <div>
    <LazyHydrate when-idle>
      <NonBrowserOverlay />
    </LazyHydrate>
    <Header :is-home-page="isHomepage" :timestamp="timestamp" />
    <LazyHydrate v-if="!isHomepage && statusCode === 200" when-idle>
      <HeaderBottom />
    </LazyHydrate>
    <section v-if="statusCode === 200" class="content">
      <LazyHydrate when-idle>
        <div class="content__left" style="content-visibility: auto;">
          <Left :is-homepage="isHomepage" />
        </div>
      </LazyHydrate>
      <main
        id="main"
        role="main"
        :class="{
          'content--desktop': !isFromMobile && !isHomepage
        }"
      >
        <nuxt keep-alive :keep-alive-props="{ max: 10 }" />
      </main>
      <LazyHydrate when-idle>
        <div class="content__right" style="content-visibility: auto;">
          <Right />
        </div>
      </LazyHydrate>
    </section>
    <section v-else-if="statusCode === 404">
      <Error404 />
    </section>
    <section v-else-if="statusCode === 500">
      <Error500 />
    </section>
    <Footer />
    <LazyHydrate when-idle>
      <Basket :show-basket="showBasket" :cart="getBasket" />
    </LazyHydrate>
  </div>
</template>

<script>
import LazyHydrate from 'vue-lazy-hydration'
import { mapGetters } from 'vuex'

export default {
  components: {
    LazyHydrate,
    Header: () => import('~/components/Header.vue'),
    HeaderBottom: () => import('~/components/HeaderBottom.vue'),
    Footer: () => import('~/components/Footer.vue'),
    NonBrowserOverlay: () => import('~/components/NonBrowserOverlay.vue'),
    Basket: () => import('~/components/common/Basket.vue'),
    Left: () => import('~/components/content/Left.vue'),
    Right: () => import('~/components/content/Right.vue'),
    Error404: () => import('~/components/error/404.vue'),
    Error500: () => import('~/components/error/500.vue')
  },
  data() {
    return {
      isHomepage: this.$route.path === '/',
      isFromMobile: this.$ua.isFromIphone() || this.$ua.isFromAndroidMobile(),
      isFromTablet: this.$ua.isFromTablet(),
      showBasket: false,
      timestamp: this.$store.getters['utility/timestamp']
    }
  },
  computed: {
    getBasket() {
      return this.$store.getters['basket/payload']
    },
    ...mapGetters({
      statusCode: 'UI/statusCode'
    })
  },
  created() {
    this.$nuxt.$on('SHOW_BASKET', value => {
      if (this.showBasket !== value) {
        this.showBasket = value
      }

      // Trigger GTM event
      this.$config.UAEESyntax && this.$gtm && this.$gtm.push({ event: 'lightboxcheckout' })
    })
  },
  beforeDestroy() {
    // $off method will turned off the event listner
    this.$nuxt.$off('SHOW_BASKET')
  }
}
</script>

<style lang="scss"></style>
