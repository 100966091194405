<template>
  <nav id="nav-header" class="nav-header content--max">
    <ul class="menu-top">
      <li v-for="item in itemValues" :key="`header-navlink-${item.key}`" class="menu-top__item">
        <a :href="item.url" :title="item.title">{{ item.label }}</a>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: 'HeaderNav',
  serverCacheKey() {
    return 'HeaderNav'
  },
  data() {
    return {
      itemValues: [
        {
          key: 1,
          label: 'Best Selling',
          url: '/best-selling-magazine-subscriptions',
          title: 'Bestselling Magazine Subscriptions'
        },
        {
          key: 2,
          label: 'Gift Subscriptions',
          url: '/magazine-subscription-gift-ideas',
          title: 'Gift Subscriptions'
        },
        {
          key: 3,
          label: 'For Women',
          url: '/magazine-subscriptions-for-women',
          title: 'Magazine Subscriptions for Women'
        },
        {
          key: 4,
          label: 'For Men',
          url: '/magazine-subscriptions-for-men',
          title: 'Magazine Subscriptions for Men'
        },
        {
          key: 9,
          label: 'For Kids',
          url: '/kids-magazines',
          title: 'Magazine Subscriptions for Kids'
        },
        {
          key: 5,
          label: 'Hobbies & Leisure',
          url: '/hobbies-and-leisure-magazines',
          title: 'Hobbies & Leisure Magazine Subscriptions'
        },
        {
          key: 10,
          label: 'Home & Gardening',
          url: '/home-and-gardening-magazines',
          title: 'Home & Gardening Magazine Subscriptions'
        },
        {
          key: 11,
          label: 'Sales Offers',
          url: '/discounted-magazine-sale-offers',
          title: 'Magazine Subscription Sale Offers'
        },
        {
          key: 20,
          label: 'a-z',
          url: '/magazines/view-all',
          title: 'Magazine Subscriptions A-Z'
        }
      ]
    }
  }
}
</script>
<style lang="scss">
.nav-header {
  content-visibility: auto;
  margin-top: 10px;
  width: 100%;
  .menu-top {
    display: table;
    width: 100%;
    list-style: none;
    padding: 0;
    margin: 0;
    &__item {
      display: table-cell;
      // border-right: 1px solid #ccc;
      border-bottom: none;
      vertical-align: middle;
      a {
        padding: 10px;
        font-size: 16px;
        display: block;
        font-weight: 500;
        text-transform: uppercase;
        text-align: center;
        text-decoration: none;
        color: #000;
      }
      &:last-child {
        border-right: none;
        overflow: hidden;
        white-space: nowrap;
        height: 100%;
      }
      &:hover {
        background: #ededed;
        text-decoration: none;
      }
    }
  }
}
@media (max-width: $screen-xs-max) {
  .nav-header {
    content-visibility: auto;
    display: none;
  }
}
@media (max-width: $screen-sm-min) {
  .nav-header {
    content-visibility: auto;
    .menu-top {
      &__item {
        a {
          padding: 5px;
          font-size: 13px;
        }
      }
      li:first-child {
        display: none;
      }
      li:nth-last-child(1) {
        border-right: none;
      }
    }
  }
}

/* ----------- iPad and iPad Mini  ----------- */
/* Landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
}
/* Portrait */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .nav-header .menu-top__item a {
    padding: 5px 10px;
  }
}
</style>
