<template>
  <div class="product">
    <client-only>
      <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="false"></loading>
    </client-only>
    <div class="product__image">
      <TheMagazineImage
        v-if="!isEGiftCard && !isGiftCard && !isBookazine && !isPocketmagsPlus"
        :href="`/${item.url}`"
        :item="item"
        :show-save-overlay="false"
        :show-free-gift-overlay="false"
      />
      <a v-else-if="isEGiftCard" :href="`/magazines/e-giftcards/${item.offer.productCartId}/${item.offer.giftcardId}`" :title="`Edit ${item.title}`">
        <img :src="`${$config.imgixURL}${item.imageUrl}`" :alt="item.title" blank-src="~/assets/images/image_placeholder.png" loading="lazy" />
      </a>
      <a v-else-if="isGiftCard" :href="`/magazines/gift-cards/${item.offer.productCartId}/${item.offer.giftcardId}`" :title="`Edit ${item.title}`">
        <img :src="`${$config.imgixURL}${item.imageUrl}`" :alt="item.title" blank-src="~/assets/images/image_placeholder.png" loading="lazy" />
      </a>
      <TheMagazineImage v-else-if="isBookazine" :item="item" :show-save-overlay="false" :show-free-gift-overlay="false" />
      <a v-else-if="isPocketmagsPlus" :href="`/magazines/pocketmagsplus/${item.offer.productCartId}/${item.offer.giftcardId}`" :title="item.title">
        <img :src="`${$config.imgixURL}${item.imageUrl}`" :alt="item.title" blank-src="~/assets/images/image_placeholder.png" loading="lazy" title="Edit Pocketmags Plus+" />
      </a>
    </div>
    <div class="product__info">
      <div class="product__magazine--top">
        <div v-if="!isPocketmagsPlus" class="product__title">{{ item.title }}</div>
        <div v-else class="product__title">{{ item.title }} Subscription</div>
        <div class="product__remove" title="Remove item" @click.stop="handleClickAction(item, 'delete')">
          <font-awesome-icon :icon="['fal', 'times']" class="fa fa-stack-1x removeButton" />
        </div>
      </div>

      <div v-if="item.offer.name && name && !isEGiftCard && !isGiftCard && !isPocketmagsPlus" class="product__subs">{{ name }}</div>
      <div v-if="item.offer.name && offerName && !isEGiftCard && !isGiftCard && !isPocketmagsPlus" class="product__subs">{{ offerName }}</div>

      <div v-if="isEGiftCard && item.offer.fromName">
        <div class="product__subs">£{{ item.offer.price }} E-Gift Card</div>
        <div v-if="item.offer.fromName" class="product__subs"><b>From:</b> {{ item.offer.fromName }}</div>
        <div v-if="item.offer.toEmail" class="product__subs"><b>Send to:</b> {{ item.offer.toName }} {{ item.offer.toEmail }}</div>
        <div v-if="item.offer.message" class="product__subs nooverflow">
          <b>Message:</b> {{ item.offer.message.length > 50 ? item.offer.message.substring(0, 50) + '...' : item.offer.message }}
        </div>
        <div v-if="item.offer.toDate" class="product__subs"><b>Delivery Date:</b> {{ item.offer.toDate }}</div>
      </div>
      <div v-else-if="isGiftCard">
        <div class="product__subs">£{{ item.offer.price }} Gift Card</div>
        <div v-if="item.offer.message" class="product__subs nooverflow">
          <b>Message:</b> {{ item.offer.message.length > 50 ? item.offer.message.substring(0, 50) + '...' : item.offer.message }}
        </div>
        <div v-if="item.offer.toDate" class="product__subs"><b>Delivery Date:</b> {{ item.offer.toDate }}</div>
      </div>
      <div v-else-if="isPocketmagsPlus">
        <div class="product__subs">{{ item.offer.label }} Saving {{ item.offer.save }}%</div>
        <div v-if="item.offer.fromName" class="product__subs"><b>From:</b> {{ item.offer.fromName }}</div>
        <div v-if="item.offer.toEmail" class="product__subs"><b>Send to:</b> {{ item.offer.toName }} {{ item.offer.toEmail }}</div>
        <div v-if="item.offer.message" class="product__subs nooverflow">
          <b>Message:</b> {{ item.offer.message.length > 50 ? item.offer.message.substring(0, 50) + '...' : item.offer.message }}
        </div>
        <div v-if="item.offer.toDate" class="product__subs"><b>Delivery Date:</b> {{ item.offer.toDate }}</div>
      </div>
      <div class="product__payment-type product__payment-type--basket">
        <PaymentImages
          :offer-payment-type="item.offer.paymentType"
          :is-pdp="true"
          :is-giftcard="item.offer.sku === 'gift-card' || item.offer.sku === 'e-giftcards'"
          :is-basket="true"
        />
      </div>
      <div class="product__price">
        Price <span class="product__price--red">{{ format(price) }}</span>
      </div>

      <div v-if="!isEGiftCard && !isGiftCard && !isPocketmagsPlus" class="product__buttons">
        <div class="product__buttons--desc">Qty</div>
        <div class="product__qty-group">
          <button type="button" class="minus" @click.stop="handleClickAction(item, 'remove')">-</button>

          <input :value="qty" name="Cart Quantity" size="4" title="Qty" maxlength="12" type="text" class="input-text qty" readonly="readonly" />

          <button type="button" class="plus add-to-cart" @click.stop="handleClickAction(item, 'add')">+</button>
        </div>
      </div>
      <div v-else-if="isEGiftCard" class="product__editAction">
        <a style="display: inline" :href="`/magazines/e-giftcards/${item.offer.productCartId}/${item.offer.giftcardId}`" title="Edit E-Gift Card">Edit Card</a>
      </div>
      <div v-else-if="isGiftCard" class="product__editAction">
        <a style="display: inline" :href="`/magazines/gift-cards/${item.offer.productCartId}/${item.offer.giftcardId}`" title="Edit Gift Card">Edit Card</a>
      </div>
      <div v-else-if="isPocketmagsPlus" class="product__editAction">
        <a style="display: inline" :href="`/magazines/pocketmagsplus/${item.offer.productCartId}/${item.offer.giftcardId}`" title="Edit Pocketmags Plus+">Edit</a>
      </div>
      <transition name="fade">
        <div v-if="showNotification" class="product__notification">You reach the MAX qty allowed</div>
      </transition>
    </div>
  </div>
</template>

<script>
import { formatCurrency, isMaxQty } from '~/lib/util/basket'
import { getGTMEcommerceActionPayload } from '~/lib/util/gtmUAEE'
import { getUniqueTrackingName, getGA4EcommerceNullPayload, getGA4EcommerceBasketActionPayload } from '~/lib/util/gtmGA4'

import { sendSSAPIAddToCart, createUniqueFBEventId } from '~/lib/util/fb'
import basketMixin from '~/mixins/basket.vue'

export default {
  components: {
    PaymentImages: () => import('~/components/common/product/PaymentImages.vue'),
    TheMagazineImage: () => import('~/components/common/TheMagazineImage')
  },
  mixins: [basketMixin],
  props: {
    item: {
      type: Object,
      required: true,
      default() {
        return {}
      }
    },
    save: {
      type: Number,
      requited: false,
      default() {
        return 0
      }
    },
    qty: {
      type: Number,
      requited: true,
      default() {
        return 0
      }
    },
    price: {
      // type: Number,
      default() {
        return 0
      },
      validator(value) {
        return Number(value).toFixed(2)
      }
    }
  },
  fetch() {
    if (this.item.offer.paymentType === 'CC') {
      this.name = this.item.offer.name
      this.offerName = getUniqueTrackingName({ offer: this.item.offer })
    }
    if (this.item.offer.paymentType === 'DD') {
      this.name = this.item.offer.name
      this.offerName = undefined
    }
  },
  data() {
    return {
      showNotification: false,
      maxQty: process.env.ITEM_MAX_QTY,
      isLoading: false,
      name: undefined,
      offerName: undefined
    }
  },
  computed: {
    format() {
      return price => formatCurrency(price)
    },
    isMaxQty() {
      return isMaxQty(this.item)
    },
    isGiftCard() {
      return this.item?.id === 'GIFT' || false
    },
    isEGiftCard() {
      return this.item?.id === 'EGFT' || false
    },
    isPocketmagsPlus() {
      return this.item?.id === 'PLUS' || false
    },
    isBookazine() {
      return this.item?.title?.toLowerCase().includes('bookazine') || false
    }
  },
  methods: {
    async handleClickAction(item, action) {
      console.log(`BASKET handleClickAction action=${action} basket.id=${this.$store.state.basket.id} offer.productId=${item.offer.productId} offer.sku=${item.offer.sku}`)
      if (isMaxQty(item) && action === 'add') {
        this.showNotification = true
        setTimeout(() => (this.showNotification = false), 3000)
        return
      }

      // Update the BE Basket data
      const ret = await this.updateBasket(item, action)

      if (ret.error) {
        // Error, show an overlay on the page
        this.$nuxt.$emit('SHOW_NOTIFICATION', { data: ret.error, show: true })
      } else {
        // Facebook Event Deduplication
        let eventId
        switch (action) {
          case 'add':
            eventId = createUniqueFBEventId('AddToCart')
            break
          case 'remove':
          case 'delete':
            eventId = createUniqueFBEventId('RemoveFromCart')
            break

          default:
            break
        }

        // Customer email address
        const email = this.$store.getters['s/email']

        // UAEES event for each Basket Button's Click
        this.$config.UAEESyntax &&
          getGTMEcommerceActionPayload({ action, item, eventId, email }) &&
          this.$gtm &&
          this.$gtm.push(getGTMEcommerceActionPayload({ action, item, eventId, email }))

        // GA4 events for each Basket Button's Click
        this.$config.GA4Syntax && this.$config.GA4FireEcommerceNull && this.$gtm && this.$gtm.push(getGA4EcommerceNullPayload())
        this.$config.GA4Syntax && this.$gtm && this.$gtm.push(getGA4EcommerceBasketActionPayload({ action, item, eventId, email }))

        if (action === 'add') {
          // FB v12.0
          const eventSourceUrl = `${this.$config.baseURL}${this.$route.fullPath}`

          // Facebook Server-side API AddToCart
          sendSSAPIAddToCart({ context: this, item, parameters: { eventId, eventSourceUrl } })
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.removeButton {
  display: inline-block;
  position: relative;
  left: 0px;
}
.product {
  position: relative;
  // margin: 10px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  align-items: center;
  align-content: space-around;
  border-bottom: 1px solid #ddd;
  &__remove {
    float: right;
    flex: 0 0 34px;
    align-self: flex-start;
    padding: 10px;
    cursor: pointer;
    &:hover {
      cursor: pointer;
    }
  }
  &__info {
    flex: 0 1 55%;
    // padding: 10px 5px;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-around;
  }
  &__info > div {
    text-align: left;
    color: $totalsLabelColor;
    img {
      padding: 10px 0;
    }
    margin-bottom: 10px;
  }
  &__magazine {
    display: flex;
    vertical-align: middle;
    font-size: 14px;
    height: auto;
    min-height: 20px;
    overflow-wrap: break-word;
    font-weight: 700;
  }
  &__magazine--top {
    display: flex;
    flex-flow: nowrap;
    justify-content: space-between;
    font-size: 14px;
    height: auto;
    min-height: 20px;
    overflow-wrap: break-word;
    font-weight: 700;
  }
  &__title {
    padding-top: 5px;
    align-self: center;
    flex: 0 1 80%;
  }
  &__subs {
    font-size: 14px;
  }
  &__price {
    font-weight: 700;
    font-size: 14px;
    &--red {
      margin: 0 5px;
      color: $totalsCurrencyColor;
    }
  }

  &__image {
    flex: 0 1 30%;
    max-width: 140px;
    min-height: 30px;
    img {
      max-width: 100%;
      display: block;
      margin: 0 auto;
      width: 100%;
      height: auto;
    }
  }
  &__buttons {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    &--desc {
      margin-right: 10px;
      font-weight: 700;
      font-size: 14px;
    }
  }
  &__notification {
    display: block !important;
    padding: 5px 0 !important;
    color: #ff0d00 !important;
  }
  &__editAction {
    margin-top: 10px;
    a {
      color: #333;
      text-decoration: underline;
      padding: 11px 11px 11px 0;
      font-size: 14px;
      font-weight: 700;
    }
  }
}

.product__qty-group {
  margin: 0;
  display: table;
  button,
  input[type='text'] {
    width: 21px;
    height: 26px;
    float: left;
    color: #7e7e7e;
  }
  button {
    min-width: 34px;
    min-height: 34px;
    border: 0;
    background: #eaeaea;
    &:hover {
      color: #fff;
      background: #ff0d00;
      cursor: pointer;
    }
  }
  input[type='text'] {
    border: 1px solid #eaeaea;
    border-left: 0;
    border-right: 0;
    border-radius: 0;
    text-align: center;
    font-size: 11px;
    min-width: 34px;
    min-height: 34px;
  }
  .plus {
    border-radius: 0 2px 2px 0;
  }
  .minus {
    border-radius: 2px 0 0 2px;
  }
}

.product:first-child {
  margin-top: 0px;
}
.product:last-child {
  margin-bottom: 0px;
}

@media (max-width: $screen-xs-max) {
  .product {
    &__remove {
      right: -10px;
    }
    &__image {
      flex: 0 0 30%;
      align-self: center;
      // padding: 0 10px;
    }
    &__info {
      flex: 0 1 55%;
    }
  }
}
</style>
