<template>
  <div class="content__main">ALL GOOD :)!!!!</div>
</template>

<script>
export default {
  layout: 'default',

  data() {
    return {
      dataUI: {
        title: 'magazine.co.uk TEST PAGE'
      }
    }
  },

  head() {
    return {
      title: this?.dataUI?.title,
      link: [{ rel: 'canonical', href: 'https://www.magazine.co.uk/jmagtest' }],
      meta: [
        {
          name: 'robots',
          content: 'NOINDEX,NOFOLLOW'
        }
      ]
    }
  }
}
</script>

<style lang="scss"></style>
