<template>
  <div class="category-products items-list search-list view-all__list">
    <Products v-for="(item, index) in infiniteLoadingValues" :key="`${index}::${item.productId}`" type="viewall" :item="item" class="search-list__item items-list__item" />
  </div>
</template>

<script>
export default {
  name: 'ViewAllProductsList',
  serverCacheKey() {
    return false
  },
  components: {
    Products: () => import('~/components/common/list/Product.vue')
  },
  props: {
    values: {
      type: Array,
      required: false,
      default() {
        return this.$store.getters['search/products']
      }
    }
  },
  data() {
    return {
      infiniteLoadingValues: [...this.values],
      localValues: [...this.values]
    }
  },
  methods: {
    infiniteHandler($state) {
      // we will add an inifnite scroll Logic  to minimise the HTML payload
      if (this.localValues.length) {
        const data = this.localValues.splice(0, 4)
        console.log(`ViewAllProductsList > infiniteHandler ADD data=`, data)
        console.log(`ViewAllProductsList > this.localValues.length=`, this.localValues.length)
        console.log(`ViewAllProductsList > this.infiniteLoadingValues data=`, this.infiniteLoadingValues)
        this.infiniteLoadingValues.push(...data)
        $state.loaded()
      } else {
        $state.complete()
      }
    }
  }
}
</script>

<style lang="scss"></style>
