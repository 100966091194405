<template>
  <div>
    <header>
      <div class="header-container">
        <div class="header-grid">
          <LazyHydrate when-idle>
            <Head :is-home-page="isHomePage" :is-from-tablet="isFromTablet" :timestamp="timestamp" />
          </LazyHydrate>
          <LazyHydrate when-idle>
            <Nav />
          </LazyHydrate>
        </div>
      </div>
      <MobileSearch />
    </header>
  </div>
</template>

<script>
import LazyHydrate from 'vue-lazy-hydration'

export default {
  name: 'Header',
  serverCacheKey: props => `Header-isHomePage${props.isHomePage}::isFromTablet${props.isFromTablet}::${props.timestamp}`,
  components: {
    LazyHydrate,
    Head: () => import('~/components/header/Head.vue'),
    Nav: () => import('~/components/header/Nav.vue'),
    MobileSearch: () => import('~/components/header/MobileSearch.vue')
  },
  props: {
    isHomePage: {
      type: Boolean,
      required: false,
      default() {
        return false
      }
    },
    isFromTablet: {
      type: Boolean,
      required: false,
      default() {
        return false
      }
    },
    timestamp: {
      type: Number,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.header-container {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
  align-content: center;
}
.header-grid {
  border-bottom: 1px solid $borderColor;
  flex: 0 0 100%;
  max-width: 1230px;
}
</style>
