<template>
  <span id="gc-preview-modal" :class="{ 'is-preview': isPreview, 'is-print': isPrint }">
    <div id="gc-live-preview-container" class="m-section gc-live-preview-container" :class="{ 'm-disabled-anchor': disabledAnchors }">
      <div class="m-section-no-margin gc-live-preview-inner">
        <div id="gc-live-preview-designs" class="m-section gc-requires-no-js gc-live-preview gc-email-live-preview gc-live-preview-current" style="display: block;">
          <div class="m-section gc-live-preview-inner">
            <div class="m-section gc-design-image-wrapper">
              <img id="gc-standard-design-image" alt="magazine.co.uk eCard design" :src="image" class="gc-design-img-preview" />
              <div id="gc-live-preview-inner-bottom-section" :src="image" class="gc-design-text-container">
                <div id="gc-live-preview-magazinecover-img">
                  <img id="gc-magazinecover-img" class="gc-magazinecover-img" alt="Magazine Cover" :src="magazineCover" />
                </div>
                <div id="gc-live-preview-inner-center-section" class="m-section">
                  <span class="gc-live-preview-message m-color-2">To: {{ toName }}</span>
                  <br />
                  <br />
                  <span class="gc-live-preview-message m-color-2 m-text-word-wrap">{{ message }}</span>
                  <br />
                  <br />
                  <span class="gc-live-preview-message m-color-2">From: {{ fromName }}</span>
                  <br />
                  <br />
                  <span class="gc-live-preview-message m-color-2">Your Gift: {{ magazineName }}</span>
                </div>
              </div>
            </div>
          </div>
          <img
            id="gc-logo-img"
            :class="{ 'gc-logo-img__isMobile': $ua.isFromIphone() || $ua.isFromAndroidMobile() }"
            alt=""
            src="https://magazine.imgix.net/wysiwyg/logo_transparent.png?auto=format&w=134&dpr=2"
          />
        </div>
      </div>
    </div>
  </span>
</template>

<script>
export default {
  name: 'ECardTemplate',
  serverCacheKey() {
    return false
  },
  props: {
    isPreview: {
      type: Boolean,
      required: false,
      default() {
        return false
      }
    },
    isPrint: {
      type: Boolean,
      required: false,
      default() {
        return false
      }
    },
    image: {
      type: String,
      required: false,
      default() {
        return 'https://magazine.imgix.net/ecard/tropical2.png?auto=format&w=540&dpr=3&rect=left,top,1506,375'
      }
    },
    message: {
      type: String,
      required: false,
      default() {
        return "You've been bought a magazine gift subscription from magazine.co.uk"
      }
    },
    magazineCover: {
      type: String,
      required: true,
      default() {
        return 'https://magazine.imgix.net/placeholder.jpg?auto=format&fit=fill&fill=solid&bg=0FFF&w=200&dpr=3'
      }
    },
    magazineName: {
      type: String,
      required: false,
      default() {
        return ''
      }
    },
    fromName: {
      type: String,
      required: false,
      default() {
        return ''
      }
    },
    fromEmail: {
      type: String,
      required: false,
      default() {
        return ''
      }
    },
    toName: {
      type: String,
      required: false,
      default() {
        return ''
      }
    },
    toEmail: {
      type: String,
      required: false,
      default() {
        return ''
      }
    },
    toDate: {
      type: Date,
      required: false,
      default() {
        return new Date()
      }
    },
    disabledAnchors: {
      type: Boolean,
      required: false,
      default() {
        return false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.m-disabled-anchor {
  cursor: auto !important;
}
#gc-live-preview-container {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  // border-collapse: collapse;
  border-radius: 6px;
  cursor: pointer;
  width: 95%;
  margin: 10px;
  max-width: 540px;
}
#gc-live-preview-inner-center-section {
  overflow: hidden;
  // height: 100%;
  // max-height: 202px;
  max-width: 56%;
}

.gc-live-preview-customizations {
  display: none;
}

.gc-design-image-wrapper img {
  width: 100%;
}

.gc-live-preview-inner .gc-design-img-preview {
  border-radius: 6px 6px 0 0;
  // border: 1px solid $gcBorderColorTemplate;
  // border-bottom-color: unset;
  // border-bottom-style: unset;
  // border-bottom-width: unset;
}

.gc-live-preview-inner .gc-design-text-container {
  border-radius: 0px 0px 6px 6px;
  // border: 1px solid $gcBorderColorTemplate;
  // border-top-color: unset;
  // border-top-style: unset;
  // border-top-width: unset;
  background: white;
  // min-height: 202px;
  width: 100%;
  #gc-magazinecover-img {
    width: 100%;
    height: auto;
    max-width: 200px;
    // max-height: 174px;
    // margin: 0 10%;
  }
}

#gc-logo-img {
  width: 100%;
  max-width: 154px;
  height: auto;
  float: right;
  margin-top: -45px;
  margin-right: 0px;
  padding: 10px;
}

.gc-live-preview-message-container {
  padding: 0px 25px 0px;
  height: auto;
}
.gc-live-preview-message-container {
  text-align: left;
  padding: 22px 22px 5px;
  word-wrap: break-word;
  overflow: hidden;
}
.gc-live-preview-message {
  white-space: break-spaces;
  font-size: 14px;
  line-height: 19px;
  color: #353535;
}
#gc-standard-design-image {
  width: 100%;
}
.m-text-center {
  text-align: center !important;
}
.m-text-word-wrap {
  word-wrap: break-word;
}

#gc-detail-live-preview-message {
  font-weight: 400;
  font-style: normal;
  color: $gcTextColor3;
  text-align: center;
  font-size: 14px;
}
#gc-live-preview-inner-bottom-section {
  // background-color: $gcBgColor;
  // border-radius: 0 0 6px 6px;

  display: flex;
  align-items: center;
  justify-content: flex-start;

  #gc-live-preview-magazinecover-img {
    margin: 5%;
    flex: 0 1 40%;
    min-width: 40%;
  }

  #gc-live-preview-inner-center-section {
    margin: 15px 10px 10% 0;
    text-align: left;
  }

  .gc-live-preview-logo-amount-row {
    display: flex;
    align-items: flex-end;
    flex-flow: row nowrap;
    justify-content: space-around;
  }
  .gc-live-preview-amount-container {
    margin: 2% 10px;
    flex: 1 1 60%;
    min-height: 1px;
    overflow: hidden;
  }
}

.gc-live-preview-inner-bottom-section {
  padding: 10px 20px;
}
.gc-live-preview-amount {
  font-weight: 400;
  font-style: normal;
  font-size: 21px;
  color: $gcTextColor1;
}
.gc-brand-text {
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
  margin-bottom: unset;
}

@media (min-width: $screen-xs-max) {
  .gc-live-preview-customizations {
    display: block;
  }
  .gc-live-preview-inner .gc-design-img-preview {
    // border-radius: 6px 6px 0 0;
  }

  .gc-design-image-wrapper img {
    width: 470px;
  }
}
@media (max-width: $screen-sm-min) {
  .gc-brand-text {
    line-height: 17px;
    font-weight: 400;
    font-style: normal;
    font-size: 11px;
    margin-bottom: unset;
  }

  #gc-logo-img {
    margin-top: -45px;
  }
}

.is-preview #gc-magazinecover-img {
  max-width: 160px !important;
}

.is-preview .gc-live-preview-message {
  font-size: 12px;
}
.is-preview #gc-logo-img {
  margin-top: -45px;
  max-width: 134px;
  padding: 8px;
}
.is-preview #gc-live-preview-container {
  max-width: 500px;
}
.is-preview .gc-logo-img__isMobile {
  margin-top: -40px !important;
}
.is-print .gc-logo-img__isMobile {
  margin-top: -35px !important;
}
#gc-live-preview-inner-bottom-section #gc-live-preview-magazinecover-img {
  min-width: 38%;
}

@media print {
  #gc-live-preview-container {
    border: 1px solid #d9d9d9 !important;
  }
}
</style>
