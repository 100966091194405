<template>
  <div class="error">
    <div class="gbg">
      <div class="oh">Uh oh!</div>
      <div v-if="error.statusCode === 404">
        <img src="~/assets/images/404page.png" alt="404" style="width: 100%; max-width: 800px; height: auto" blank-color="#ededed" width="400" height="277" loading="lazy" />
      </div>
      <div v-else-if="error.message" class="subheadoh">{{ error.message }}</div>
      <div v-else class="subheadoh">The link you clicked on may be broken or an internal error may have occurred</div>
    </div>
    <div class="bbg">
      <div class="head">This page is missing</div>
      <div class="subhead">Why not try one of these instead</div>
      <div class="btnhold">
        <div style="float: left"><a href="/" class="btn">HOME</a></div>
        <div style="float: right"><a href="/contact" class="btn">CONTACT</a></div>
        <div style="clear: both"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    error: {
      type: Object,
      required: true,
      default() {
        return {
          statusCode: 500
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.error {
  -webkit-box-flex: 0;
  flex: 0 0 100%;
  max-width: 1230px;
  margin: 0px auto 20px auto;
}
.gbg {
  background-color: #ededed;
  text-align: center;
  padding: 50px;
  box-sizing: border-box;
}
.bbg {
  background-color: black;
  text-align: center;
  padding: 50px;
  box-sizing: border-box;
  color: white;
}
.oh {
  font-size: 70px;
  line-height: 70px;
}
.subheadoh {
  font-size: 30px;
  line-height: 30px;
  margin-top: 20px;
  text-align: center;
  margin-left: 15%;
  margin-right: 15%;
}
.head {
  font-size: 50px;
  line-height: 50px;
  margin-bottom: 20px;
}
.subhead {
  font-size: 30px;
  line-height: 30px;
  margin-bottom: 30px;
}
.btn {
  width: 200px;
  height: 72px;
  background-color: #e20d0c;
  color: white !important;
  text-decoration: none !important;
  font-size: 26px;
  font-weight: 600;
  line-height: 72px;
  display: block;
}
.btnhold {
  width: 500px;
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (max-width: 768px) {
  .gbg {
    padding: 40px;
  }
  .bbg {
    padding: 40px;
  }
  .oh {
    font-size: 60px;
    line-height: 60px;
  }
  .head {
    font-size: 40px;
    line-height: 40px;
  }
  .subhead {
    font-size: 24px;
    line-height: 24px;
  }
  .btn {
    width: 180px;
    height: 62px;
    line-height: 62px;
    font-size: 23px;
  }
  .btnhold {
    width: 420px;
  }
}
@media only screen and (max-width: 767px) {
  .gbg {
    padding: 30px;
  }
  .bbg {
    padding: 30px;
  }
  .oh {
    font-size: 60px;
    line-height: 60px;
  }
  .head {
    font-size: 26px;
    line-height: 26px;
  }
  .subhead {
    font-size: 16px;
    line-height: 16px;
  }
  .btn {
    width: 120px;
    height: 42px;
    line-height: 42px;
    font-size: 18px;
  }
  .btnhold {
    width: 90%;
  }
}
</style>
