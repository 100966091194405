<template>
  <div class="product-best-sellers__info">
    <client-only>
      <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="false"></loading>
    </client-only>
    <a :href="`${item.url}`" :title="item.title" class="product-best-sellers__text" @click.prevent="handleViewOffer(item)">
      <div class="product-best-sellers__magazine">
        {{ item.title }}
      </div>
      <div class="product-best-sellers__offer">
        <p class="special-offer">{{ item.offer.name }}</p>
        <span v-if="item.offer.save > 0" class="savings">Saving {{ item.offer.save }}%</span>
      </div>
    </a>
    <div class="actions">
      <div class="add-to-basket-best-sellers" title="add to basket" @click.prevent="handleClickAction(item, 'add')">Add to Basket</div>
    </div>
  </div>
</template>

<script>
import basketMixin from '~/mixins/basket.vue'
import gtmProductPageMixin from '@/mixins/gtm/product-page'

export default {
  name: 'BestsellersProduct',
  serverCacheKey: props => `BestsellersProduct::${props.item.id}`,
  mixins: [gtmProductPageMixin, basketMixin],
  props: {
    item: {
      type: Object,
      required: true,
      default() {
        return {}
      }
    },
    type: {
      type: String,
      required: false,
      default() {
        return 'default'
      }
    }
  },

  data() {
    return {
      isLoading: false
    }
  },
  computed: {},
  methods: {
    async handleClickAction(item, action) {
      console.log('common/promotions/Bestseller handleClickAction item=', item)
      console.log('common/promotions/Bestseller handleClickAction action=', action)

      // Update the BE Basket data
      const ret = await this.updateBasket(item, action)

      // GTM & FB push events integration
      !ret.error && this.$config.UAEESyntax && this.$gtm && this.$gtm.push({ event: 'bestseller-right' })
      !ret.error && this.pushGTMAndFBAfterButtonClick({ action, item })

      // Show the basket overlay
      !ret.error && this.$nuxt.$emit('SHOW_BASKET', true)
    },
    handleViewOffer(item) {
      // Trigger GTM event
      if (item && item.url) {
        this.$nuxt.$emit('SHOW_BASKET', false)
        this.$config.UAEESyntax && this.$gtm && this.$gtm.push({ viewspecialofferurl: `${this.$config.baseURL}${item.url}` })
        this.$config.UAEESyntax && this.$gtm && this.$gtm.push({ event: 'viewspecialoffer-bestseller-right' })
        this.$store.commit('s/addtorecentlyviewed', this.item)
        // Redirct to the offer page
        window.location.href = this.getProductUrl(item)
      }
    }
  }
}
</script>

<style lang="scss">
.product-best-sellers {
  &__magazine {
    text-transform: uppercase;
  }
  &__info {
    position: relative;
    &:hover {
      background-color: #ededed;
    }
  }
  &__info {
    padding: 5px 0;
    border-bottom: 1px solid #dee2e6;
    &:last-child {
      padding-bottom: 0;
      // border-bottom: unset;
    }
  }
  &__magazine {
    padding: 0px 10px;
  }

  a {
    color: #333;
    font-size: 12px;
    font-weight: 700;
    div {
      padding-top: 10px;
    }
    &__magazine {
      vertical-align: middle;
      font-size: 12px;
      min-height: 34px;
      overflow: hidden;
      text-overflow: ellipsis;
      text-transform: uppercase;
    }
  }

  &__heading {
    background: #ff0d00;
    a {
      color: white;
      font-weight: 600;
      font-size: 18px;
    }
  }
}
.special-offer {
  color: #b30d00;
  font-size: 12px;
  line-height: 12px;
  margin: 0em;
}
.actions {
  display: inline-flex;
  div.add-to-basket-best-sellers {
    background-color: transparent;
    text-align: center;
    font-size: 12px !important;
    color: $buttonAddToCartColor;
    line-height: 12px;
    margin: 0;
    text-decoration: underline;
    min-width: 100px;
    padding: 15px;
    border: unset;
    font-weight: 700 !important;
    &:hover {
      color: $buttonAddToCartColor;
      text-decoration: none;
      cursor: pointer;
    }
  }
}
</style>
