<script>
import egiftcard from '~/mixins/egiftcard.vue'

export default {
  mixins: [egiftcard],

  asyncData({ store, route, params, query, $ua }) {
    const path = '/magazines/e-giftcards'
    console.log(`fetch E-Gift Card path=${path}`)

    return {
      isFromMobile: $ua.isFromIphone() || $ua.isFromAndroidMobile(),
      params,
      isUpdate: false
    }
  }
}
</script>
