<template>
  <div class="isholder mt-2">
    <div class="isbolt"><font-awesome-icon :icon="['fas', 'bolt']" class="fa-2x" /></div>
    <div class="istextholder">
      <div class="istxtheader">Instant Access when you subscribe</div>
      <div class="istxtbody">
        Get instant digital access to the latest issue of {{ magazine.title }} magazine to read immediately while you wait for the first print edition of your magazine subscription
        to arrive
      </div>
      <!-- <div v-else class="istxtbody">Enjoy instant digital access to the latest issue while you wait for your print subscription</div> -->
    </div>
    <div style="clear: both"></div>
  </div>
</template>

<script>
export default {
  props: {
    magazine: {
      type: Object,
      required: true,
      default() {
        return {}
      }
    }
  }
  // computed: {
  //   isMobile() {
  //     return this.$store.getters['UI/isMobile']
  //   }
  // }
}
</script>

<style lang="scss" scoped>
.isholder {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  border: 2px #0394ca solid;
  box-sizing: border-box;
  padding: 12px;
  display: inline-flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}
.isbolt {
  flex: 1 1 5%;
  width: 60px;
  align-self: center;
  color: $instant-access;
}
.istextholder {
  flex: 1 1 95%;
  padding-left: 10px;
  align-self: center;
}
.istxtheader {
  font-size: 14px;
  text-transform: uppercase;
  color: $instant-access;
  font-weight: 700;
}
.istxtbody {
  font-size: 13px;
  color: #8d8d8d;
}
// @media (max-width: $screen-sm-min) {
//   .istextholder {
//     flex: 1 1 80%;
//     padding:
//     align-self: center;
//   }
// }
</style>
