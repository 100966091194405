<template>
  <section class="content content-2col">
    <div class="content-2col__main">
      <div class="giftcards__banner">
        <img
          src="https://magazine.imgix.net/gift-card-images/giftcard_banner.png?auto=format&fit=fill&fill=solid&bg=0FFF&w=1015&h=280"
          alt="E-Gift Card"
          blank-color="#ededed"
          width="1015"
          height="auto"
        />
      </div>
      <div class="giftcards__head--top mt-2"><h1>Send a magazine.co.uk Gift Card</h1></div>
      <div class="giftcards__description">
        <p>
          Give the perfect gift with a magazine.co.uk Gift Card.
        </p>
        <p>
          E-Gift Cards offer a variety of designs and can be emailed instantly or on a specified date. E-Gift Cards can also be printed at home for you to give in person. The Gift
          Cards in Boxes are physical cards delivered Royal Mail 1st Class.
        </p>
      </div>

      <div class="giftcards__types-container">
        <a class="giftcards__type" href="/magazines/e-giftcards" alt="E-Gift Cards">
          <div class="giftcards__type-image">
            <BImageLazyImgix
              image-url="/gift-card-images/egiftcard.png"
              alt="E-Gift Card"
              query-string="&w=270"
              blank-color="#ededed"
              width="270"
              height="150"
              custom-class="giftcards__egiftcard"
              :fluid-grow="false"
            />
          </div>
          <div class="giftcards__text--h1">
            <p><span>E-Gift Cards</span></p>
          </div>
          <div class="giftcards__text--h2">
            <p><span>Email Delivery</span></p>
          </div>
          <div class="giftcards__text--button">
            <p><span>GET STARTED</span></p>
          </div>
        </a>
        <a class="giftcards__type" href="/magazines/gift-cards" alt="Boxed Gift Cards">
          <div class="giftcards__type-image">
            <BImageLazyImgix
              image-url="/gift-card-images/giftcard.png"
              alt="Gift Card"
              query-string="&w=230"
              blank-color="#ededed"
              width="230"
              height="127"
              custom-class="giftcards__giftcard"
              :fluid-grow="false"
            />
          </div>
          <div class="giftcards__text--h1">
            <p><span>Gift Cards</span></p>
          </div>
          <div class="giftcards__text--h2">
            <p><span>Postal Delivery</span></p>
          </div>
          <div class="giftcards__text--button">
            <p><span>GET STARTED</span></p>
          </div>
        </a>
      </div>

      <div class="giftcards__head--bottom mt-2"><h1>Already have a voucher?</h1></div>

      <div class="giftcards__description">
        <p>
          To redeem your gift card just follow these steps:
        </p>
        <p>
          Step 1: Select the magazine subscription you would like to purchase (excl. Direct Debit)<br />
          Step 2: When you have finished shopping, continue to checkout. <br />
          Step 3: When prompted on the payment page enter the Gift code
        </p>
        <p>
          Note: If your order exceeds the amount on the gift card, you must pay the outstanding balance with other accepted payment methods. If you don't spend all your gift card,
          your account will be in credit for your next purchase.
        </p>
      </div>
    </div>
  </section>
</template>

<script>
import { getBreadcrumbsMicrodataJSONLD } from '~/lib/util/microdata'

export default {
  name: 'GiftCardOptions',
  layout: '2ColumnsWithFixedLeftColumn',
  components: {
    BImageLazyImgix: () => import('~/components/common/BImageLazyImgix')
  },
  fetch() {
    // Breadcrumbs
    const magazine = undefined
    const breadcrumbs = [
      {
        first: true,
        value: 'magazine subscriptions',
        url: '/',
        last: false
      },
      {
        first: false,
        value: 'Gift Card Options',
        url: '/gift-card-options',
        last: true
      }
    ]
    this.$store.commit('ADD_BREADCRUMBS', { path: this.$route.path, magazine, breadcrumbs })

    if (this.$route.query.showBasket) {
      this.$nuxt.$emit('SHOW_BASKET', true)
    }
  },

  asyncData({ $ua }) {
    return {
      isFromMobile: $ua.isFromIphone() || $ua.isFromAndroidMobile()
    }
  },
  /* eslint max-len: ["error", { "code": 196 }] */
  data() {
    return {
      metaTitle: 'Giftcard Options',
      h1Title: 'Giftcard Options',
      canonicalURL: 'https://www.magazine.co.uk/magazines/e-giftcards',
      metaDescription: `A magazine subscription gift card is the perfect gift. Our E-cards can be emailed instantly to the lucky recipient or you can print it off yourself and give it in person.`,
      metaKeywords: 'Magazine.co.uk'
    }
  },
  // To check more options, link https://github.com/nuxt/vue-meta#script-object
  // To check how it is working https://nuxtjs.org/faq/duplicated-meta-tags/
  head() {
    return {
      title: this.metaTitle,
      link: [{ rel: 'canonical', href: this.canonicalURL }],
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.metaDescription
        },
        {
          name: 'keywords',
          content: this.metaKeywords || ''
        },
        {
          name: 'robots',
          content: this.$config.robots || 'INDEX,FOLLOW'
        },
        {
          hid: 'og:title',
          name: 'og:title',
          property: 'og:title',
          content: this.h1Title
        },
        {
          hid: 'og:description',
          name: 'og:description',
          property: 'og:description',
          content: this.metaDescription
        },
        {
          hid: 'og:site_name',
          name: 'og:site_name',
          property: 'og:site_name',
          content: 'magazine.co.uk'
        },
        {
          hid: 'og:image',
          name: 'og:image',
          property: 'og:image',
          content: 'https://www.magazine.co.uk/icon.png'
        }
      ]
    }
  },
  jsonld() {
    return [getBreadcrumbsMicrodataJSONLD({ breadcrumbs: this.$store.getters['UI/breadcrumbs'] })]
  }
}
</script>

<style lang="scss" scoped>
.content {
  margin: 0 auto;
  flex-flow: row nowrap;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.content-2col {
  &__left {
    flex: 0 0 175px;
    width: 175px;
    margin: 5px 0px;
    max-width: 175px;
  }
  &__main {
    flex: 0 1 100%;
    margin: 0 0px;
    padding: 10px 0;
    display: block;
  }
}
.giftcards {
  &__banner {
    img {
      max-width: 1055px;
      width: 100%;
    }
    margin-bottom: 0;
  }
  &__head {
    &--top {
      background: #333;
      h1 {
        text-align: center;
        font-size: 16px;
        color: #fff;
        font-weight: 500;
        text-transform: uppercase;
        margin: 0;
        padding: 9px 0;
      }
    }
    &--bottom {
      padding-left: 10px;
      h1 {
        font-size: 23px;
      }
    }
  }
  &__description {
    font-size: 14px;
    padding: 10px;
  }
  &__types-container {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
    margin: 5% 0px;
  }
  &__type {
    text-align: center;
    flex: 0 1 310px;
    min-height: 310px;
    background-color: $gcBgColor;
    border-radius: 10px;
    margin: 20px;
    &:hover {
      opacity: 0.75;
    }
    img {
      max-width: 270px;
      max-height: 150px;
      margin: 20px 10px;
    }
  }
  &__text {
    &--h1 {
      font-style: normal;
      font-size: 25px;
      color: $gcTextColor2;
      text-align: center;
      p {
        margin-top: -10px;
      }
    }
    &--h2 {
      font-style: normal;
      font-size: 20px;
      color: $gcTextColor3;
    }
    &--button {
      border-width: 0px;
      width: 151px;
      height: 39px;
      background: inherit;
      background-color: $gcButtonColor;
      box-sizing: border-box;
      border-width: 1px;
      border-style: solid;
      border-color: $gcButtonColor;
      border-radius: 6px;
      -moz-box-shadow: none;
      -webkit-box-shadow: none;
      box-shadow: none;
      font-weight: 700;
      font-style: normal;
      font-size: 14px;
      color: #ffffff;
      margin: 0 auto;
      p {
        margin: 10px;
      }
    }
  }
}
@media (min-width: $screen-xs-max) {
  .content-2col {
    margin: 0 20px;
    padding: 10px 0;
    &__main {
      padding: 0 20px;
    }
  }
  .giftcards {
    &__description {
      padding: 0px;
    }
    &__banner {
      margin-bottom: 1.5rem;
    }
    &__head {
      &--top {
        background: unset;
        h1 {
          text-align: left;
          font-size: 23px;
          color: #ff0d00;
          font-weight: 400;
          text-transform: unset;
          margin: 0;
          padding: 9px 0;
        }
      }
      &--bottom {
        padding: unset;
        h1 {
          font-size: 23px;
        }
      }
    }
  }
}
</style>
