import { render, staticRenderFns } from "./PromotionsSitewideDesktop.vue?vue&type=template&id=7b3d9fba&scoped=true"
import script from "./PromotionsSitewideDesktop.vue?vue&type=script&lang=js"
export * from "./PromotionsSitewideDesktop.vue?vue&type=script&lang=js"
import style0 from "./PromotionsSitewideDesktop.vue?vue&type=style&index=0&id=7b3d9fba&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7b3d9fba",
  null
  
)

export default component.exports