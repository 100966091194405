<template>
  <a class="product__image" :href="linkURL(item)" :title="`${item.title} Magazine Subscription`" @click.prevent="handleClick(item)">
    <div v-if="showFreeGiftOverlay" class="freegift-overlay ">
      <span class="freegift-overlay__txt1">FREE</span>
      <span class="freegift-overlay__txt2">GIFT</span>
    </div>
    <span v-else-if="showOverlay" class="saving-overlay ">
      <span class="saving-overlay__txt1">SAVE</span>
      <span class="saving-overlay__txt2">{{ item.offer.save }}%</span>
    </span>
    <img v-bind="imageProps" loading="lazy" :src="imageURL" :data-src="placeholder" class="shadow5" />
  </a>
</template>

<script>
export default {
  name: 'TheMagazineImage',
  // serverCacheKey: props => `TheMagazineImage::${props.item.sku}::SO_${props.showSaveOverlay}::FGO_${props.showFreeGiftOverlay}`,
  serverCacheKey() {
    return false
  },
  props: {
    item: {
      type: Object,
      required: true,
      default() {
        return {}
      }
    },
    showSaveOverlay: {
      type: Boolean,
      required: false,
      default() {
        return false
      }
    },
    showFreeGiftOverlay: {
      type: Boolean,
      required: false,
      default() {
        return false
      }
    }
  },
  data() {
    return {
      placeholder: '~/assets/images/image_placeholder.png',
      imageProps: {
        title: `${this.item.title} Magazine Subscription`,
        alt: `${this.item.title} Magazine Subscription`,
        width: 186,
        height: 255
      },
      isPocketmagsPlus: this.item.sku === 'Pocketmags Plus+',
      showOverlay: this.showSaveOverlay && this.item.offer.save > 0 && this.showSaveOverlay
    }
  },
  computed: {
    imageURL() {
      const imagePath = this.getFullImagePath(this.item)
      return `${this.$config.imgixURL}${imagePath}?auto=format&fit=fill&fill=solid&bg=0FFF&w=210`
    }
  },
  methods: {
    linkURL(item) {
      const url = item.url
      if (!url) {
        return this.$config.baseURL
      }
      return url.replace(/magazine-(\d{4,5})$/g, 'magazine')
    },
    getFullImagePath(item) {
      const imagePath = item?.imageUrl?.split('?').shift()

      if (item.sku === 'Pocketmags Plus+') {
        return `/pocketmagsplus/image.png`
      }

      // Custom image ONLY for a Pocketmags Plus product
      if (this.isPocketmagsPlus) {
        console.log('isPocketmagsPlus imagePath=', imagePath)
        return `${imagePath}`
      }

      // IMGIX CDN new Cover Update Logic
      if (imagePath.startsWith('/products/')) {
        return imagePath
      }

      // JMAG deafult M1 Image
      if (imagePath.startsWith('/catalog/product')) {
        return imagePath
      }

      return `/catalog/product${imagePath}`
    },
    handleClick(item) {
      // Trigger GTM event
      if (item?.title && item?.url) {
        this.$config.UAEESyntax && this.$gtm && this.$gtm.push({ magazinename: item.title })
        this.$config.UAEESyntax && this.$gtm && this.$gtm.push({ event: 'thumbnailclick' })
        this.$store.commit('s/addtorecentlyviewed', this.item)
        // Redirct to the offer page
        window.location.href = item.url?.replace(/magazine-(\d{4,5})$/g, 'magazine')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.product__image {
  img {
    border-radius: 7px;
    max-width: 100%;
    height: auto;
  }
}
.saving-overlay,
.freegift-overlay {
  bottom: -3px;
  height: 0;
  position: absolute;
  right: -3px;
  width: 60px;
  height: 60px;
  z-index: 0;
  background-color: #df0000;
  border-radius: 30px;
  border: 2px #fff solid;
}
.saving-overlay__txt1 {
  position: absolute;
  color: #fff;
  font-weight: 400;
  width: 60px;
  right: 0;
  top: 10px;
  font-size: 15px;
  text-align: center;
  right: -2px;
}

.saving-overlay__txt2 {
  position: absolute;
  color: #fff;
  font-weight: 700;
  width: 60px;
  right: 0;
  top: 25px;
  font-size: 18px;
  text-align: center;
  right: -2px;
}

.freegift-overlay__txt1 {
  position: absolute;
  color: #fff;
  font-weight: 700;
  width: 60px;
  right: -3px;
  top: 10px;
  font-size: 18px;
  text-align: center;
}

.freegift-overlay__txt2 {
  position: absolute;
  color: #fff;
  font-weight: 700;
  width: 60px;
  right: -3px;
  top: 25px;
  font-size: 18px;
  text-align: center;
}
</style>
