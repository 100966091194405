<template>
  <div id="giftcard" class="gc-container">
    <client-only v-if="$store.getters['UI/debug']">
      <ul class="debug__list">
        <li v-if="params" class="debug__item">
          <tree-view :data="params" :options="{ rootObjectKey: 'Params', maxDepth: 0, link: true }"> </tree-view>
        </li>
        <li class="debug__item">
          <tree-view :data="selection" :options="{ rootObjectKey: 'Selection', maxDepth: 0, link: true }"> </tree-view>
        </li>
        <!-- <li class="debug__item">
          <tree-view :data="$options.filters.objectParser(dataUI)" :options="{ rootObjectKey: 'Data UI', maxDepth: 0, link: true }"> </tree-view>
        </li> -->
      </ul>
    </client-only>
    <div id="gc-detail-page">
      <div id="gc-detail-page-left-column" class="m-section ">
        <div id="gc-main-image-container">
          <div id="gc-main-image-block">
            <div id="gc-main-preview" class="m-section">
              <div class="m-section gc-live-preview-outer-wrapper">
                <div class="m-section gc-live-preview-parent">
                  <div class="mobile magazine__sub-heading-bar"><h2>magazine.co.uk E-Gift Cards</h2></div>
                  <div class="m-section gc-live-preview-content" @click="togglePreviewEGiftCard = !togglePreviewEGiftCard">
                    <EGiftCardTemplate
                      :image="selection.design && createImgixURL(selection.design.image, designPreviewQueryString)"
                      :message="selection.message == '' ? undefined : selection.message"
                      :amount="selection.amount.value"
                    />
                    <div class="m-section m-spacing-top-medium m-text-center ">
                      <span class="m-color-3">
                        Click image to preview
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="gc-detail-page-right-column" class="m-section">
        <h1 class="gc-detail-page__title">
          <span>magazine.co.uk E-Gift Cards</span>
        </h1>
        <div class="gc-detail-page__description m-section m-color-1">
          <p>
            Give the gift of choice with a magazine.co.uk E-Gift Card! With hundreds of magazines to choose from, there’s something for everyone, so your recipient can pick the
            perfect subscription for them.
          </p>
          <p>
            Simply select the value of the gift card, pick a design, add your own personalised message and we’ll send it to your lucky recipient via email. Alternatively, you can
            always email the card to yourself to print out at home!
          </p>
        </div>
        <div class="gc-detail-page__steps-container m-section">
          <div class="gc-detail-page__step m-color-1">1. Choose E-Gift Card amount</div>
          <div id="gc-detail-amount" class="gc-detail-page__wrapper">
            <ul class="gc-detail-page__list">
              <li v-for="(value, index) in dataAmountValues" :key="`${value}-${index}`" class="gc-detail-page__list-item">
                <span
                  class="m-button m-button-toggle"
                  :class="{
                    'm-button-selected': selection.amount.value === value && selection.amount.id >= 0
                  }"
                >
                  <span class="m-button-inner">
                    <button class="m-button-text m-nofocus" type="button" :value="`${value}`" @click="updateSelection('amount', index, value)">£{{ value }}</button>
                  </span>
                </span>
              </li>
              <li class="gc-detail-page__list-other">
                <span
                  class="gc-detail-page__input-currency m-border-radius-left"
                  :class="{
                    'gc-detail-page__input-currency--selected': selection.amount.id === -1
                  }"
                  >£</span
                >
                <span
                  class="m-input m-input-toggle m-border-radius-right"
                  :class="[selection.amount.id === -1 && 'm-input-selected', $v.selection.amount.input.$invalid && 'm-input-error']"
                >
                  <input
                    v-model="selection.amount.input"
                    class="m-input-text m-input-text--medium m-nofocus m-border-radius-right"
                    type="text"
                    autocomplete="off"
                    placeholder="Other"
                    size="5"
                    aria-label="E-Gift Card amount"
                    @focus="selection.amount.input = undefined"
                    @blur="selection.amount.input && updateSelection('amount', -1, selection.amount.input)"
                  />
                </span>
              </li>
            </ul>
            <div v-if="$v.selection.amount.input.$invalid" class="m-section m-text-error" :class="{ 'm-color-error': $v.selection.amount.input.$invalid }">
              The E-Gift Card amount must be between £{{ $v.selection.amount.input.$params.between.min }} and £{{ $v.selection.amount.input.$params.between.max }}
            </div>
          </div>
        </div>
        <div class="gc-detail-page__steps-container m-section">
          <div class="gc-detail-page__step m-color-1">2. Choose your design</div>
          <div id="gc-detail-designs" class="gc-detail-page__wrapper">
            <ul class="gc-detail-page__list gc-detail-page__list--stretch">
              <li v-for="(template, index) in latestDesigns" :key="`${template.id}`" class="gc-detail-page__list-item">
                <span
                  class="m-button m-button-toggle"
                  :class="{
                    'm-button-selected': selection.design.id === template.id
                  }"
                >
                  <span class="m-button-inner">
                    <button class="m-button-text m-padding-2 m-nofocus" type="button" @click="updateSelection('design', index, template)">
                      <img :src="createImgixURL(template.image, designThumbnailQueryString)" class="m-button-thumbnail-small" :alt="template.title" />
                    </button>
                  </span>
                </span>
              </li>
              <li class="gc-detail-page__list-item">
                <button
                  class="m-button m-button-inner m-button-text m-nofocus gc-detail-page__button-more"
                  type="button"
                  @click="togglePreviewEGiftCardDesigns = !togglePreviewEGiftCardDesigns"
                >
                  More
                </button>
              </li>
            </ul>
          </div>
        </div>
        <div class="gc-detail-page__steps-container m-section">
          <div class="gc-detail-page__step m-color-1">3. Personalise your E-Gift Card<span id="gc-detail-page__required">* Required fields</span></div>
          <div id="gc-detail-form" class="gc-detail-page__wrapper">
            <div class="gc-detail-page__list gc-detail-page__list--form">
              <div class="gc-detail-page__list-item m-section">
                <div class="gc-detail-page__form-label">To* <span v-if="isFromMobile" id="gc-detail-page__required--mobile">* Required fields</span></div>
                <div class="gc-detail-page__form-field m-input m-input--noborder m-input-toggle m-border-radius">
                  <input
                    id="toName"
                    v-model.trim="selection.toName"
                    class="m-input-text m-input-text--medium m-nofocus m-border-radius"
                    :class="[errors.toName ? 'm-input-error' : 'm-input--border']"
                    type="text"
                    aria-label="Recipient Name"
                    name="toName"
                    placeholder="Recipient Name"
                    @blur="checkValidity('toName', $v.selection.toName)"
                  />
                  <div v-if="errors.toName" class="m-padding-2 m-text-error m-color-error">
                    Please insert a valid name
                  </div>
                </div>
              </div>
              <div class="gc-detail-page__list-item m-section">
                <div class="gc-detail-page__form-label"></div>
                <div class="gc-detail-page__form-field m-input m-input--noborder m-input-toggle m-border-radius">
                  <input
                    id="toEmail"
                    v-model.trim="selection.toEmail"
                    class="m-input-text m-input-text--medium m-nofocus m-border-radius"
                    :class="[errors.toEmail ? 'm-input-error' : 'm-input--border']"
                    type="text"
                    name="toEmail"
                    aria-label="Recipient Email"
                    placeholder="Recipient Email"
                    @blur="checkValidity('toEmail', $v.selection.toEmail)"
                  />
                  <div v-if="errors.toEmail" class="m-padding-2 m-text-error m-color-error">
                    Please insert a valid email address
                  </div>
                </div>
              </div>
              <div class="gc-detail-page__list-item m-section">
                <div class="gc-detail-page__form-label">From*</div>
                <div class="gc-detail-page__form-field m-input m-input--noborder m-input-toggle m-border-radius">
                  <input
                    id="fromName"
                    v-model.trim="selection.fromName"
                    class="m-input-text m-input-text--medium m-nofocus m-border-radius"
                    :class="[errors.fromName ? 'm-input-error' : 'm-input--border']"
                    type="text"
                    name="fromName"
                    aria-label="Your Name"
                    placeholder="Your Name"
                    @blur="checkValidity('fromName', $v.selection.fromName)"
                  />
                  <div v-if="errors.fromName" class="m-padding-2 m-text-error m-color-error">
                    Please insert a valid name
                  </div>
                </div>
              </div>
              <div v-if="fromEmailEnabled" class="gc-detail-page__list-item m-section">
                <div class="gc-detail-page__form-label"></div>
                <div class="gc-detail-page__form-field m-input m-input--noborder m-input-toggle m-border-radius">
                  <input
                    id="fromEmail"
                    v-model.trim="selection.fromEmail"
                    class="m-input-text m-input-text--medium m-nofocus m-border-radius"
                    :class="[errors.fromEmail ? 'm-input-error' : 'm-input--border']"
                    type="text"
                    name="fromEmail"
                    aria-label="Your Email"
                    placeholder="Your Email"
                    @blur="checkValidity('fromEmail', $v.selection.fromEmail)"
                  />
                  <div v-if="errors.fromEmail" class="m-padding-2 m-text-error m-color-error">
                    Please insert a valid email address
                  </div>
                </div>
              </div>
              <div class="gc-detail-page__list-item m-section">
                <div class="gc-detail-page__form-label">Message</div>
                <div class="gc-detail-page__form-field m-input m-input--noborder m-input-toggle m-border-radius">
                  <textarea
                    id="message"
                    v-model="selection.message"
                    class="m-textarea m-input-text--medium m-nofocus m-border-radius"
                    :class="[errors.message ? 'm-input-error' : 'm-input--border']"
                    name="message"
                    aria-label="Enjoy this magazine.co.uk Gift Card!"
                    placeholder="Enjoy this magazine.co.uk Gift Card!"
                    rows="4"
                    cols="45"
                    maxlength="120"
                    @blur="checkValidity('message', $v.selection.message)"
                  />
                  <div class="m-section m-text--chars-remaining m-color-1 m-padding-2">{{ 120 - selection.message.length }} characters remaining</div>
                  <div v-if="errors.message" class="m-padding-2 m-text-error m-color-error">
                    The E-Gift Card message must be between £{{ $v.selection.message.$params.between.min }} and £{{ $v.selection.message.$params.between.max }}
                  </div>
                </div>
              </div>
              <client-only>
                <div class="gc-detail-page__list-item m-section m-spacing-bottom-big">
                  <div class="gc-detail-page__form-label">E-Delivery Date*</div>
                  <div id="gc-detail-page__date-picker" class="gc-detail-page__form-field">
                    <div id="gc-detail-page__date-picker-wrapper" class="m-flex m-padding-10">
                      <div class="gc-detail-page__input-calendar m-border-radius-left">
                        <font-awesome-icon :icon="['fal', 'calendar-alt']" class="m-1" />
                      </div>
                      <date-picker
                        id="toDate"
                        v-model="selection.toDate"
                        input-class="m-input m-date-picker m-input-text--normal m-nofocus m-border-radius-right"
                        placeholder="DD/MM/YYYY"
                        :clear-button="false"
                        name="toDate"
                        format="dd/MM/yyyy"
                        :disabled-dates="selection.disabledDates"
                        @input="$v.selection.toDate && $v.selection.toDate.$touch && checkValidity('toDate', $v.selection.toDate)"
                      >
                      </date-picker>
                    </div>
                    <div v-if="errors.toDate" class="m-padding-2 m-text-error m-color-error">
                      Please insert a valid date in the future
                    </div>
                  </div>
                </div>
              </client-only>
              <div class="gc-detail-page__list-item m-section m-no-margin m-flex-justify-content-center">
                <div id="gc-buttons-wrapper">
                  <span id="gc-button-preview" :class="{ 'm-no-margin': isFromMobile }" class="m-button m-button__preview">
                    <span class="m-button-inner">
                      <button
                        id="gc-preview-button"
                        class="m-button-text m-button-text__preview m-text-center"
                        :class="{ 'm-button-text__addtobasket--mobile': isFromMobile }"
                        alt="Preview E-Gift Card"
                        @click="togglePreviewEGiftCard = !togglePreviewEGiftCard"
                      >
                        PREVIEW E-GIFT CARD
                      </button>
                    </span>
                  </span>
                  <span id="gc-button-addtobasket" :class="{ 'm-0 mb-3': isFromMobile }" class="m-button m-margin-left-20percent m-button__addtobasket">
                    <span class="m-button-inner">
                      <button
                        v-if="!isUpdate"
                        id="gc-add-to-basket-button"
                        :disabled="$v.selection.$invalid"
                        class="m-button-text m-button-text__addtobasket m-text-center "
                        :class="{ 'm-button-text__addtobasket--mobile': isFromMobile }"
                        alt="Add To Basket"
                        @click.prevent="!isClicked && handleUpdateSelection()"
                      >
                        ADD TO BASKET
                      </button>
                      <button
                        v-else
                        id="gc-add-to-basket-button"
                        :disabled="$v.selection.$invalid"
                        class="m-button-text m-button-text__addtobasket m-text-center"
                        :class="{ 'm-button-text__addtobasket--mobile': isFromMobile }"
                        alt="Add To Basket"
                        @click.prevent="!isClicked && handleUpdateSelection()"
                      >
                        Update E-Gift Card
                      </button>
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <EGiftCardPreview
        :toggle="togglePreviewEGiftCard === true"
        :image="createImgixURL(selection['design'].image, designPreviewQueryString)"
        :message="selection.message == '' ? undefined : selection.message"
        :amount="selection.amount.value"
        :from-name="selection.fromName"
        :from-email="selection.fromEmail"
        :to-name="selection.toName"
        :to-email="selection.toEmail"
        :to-date="selection.toDate"
      />
      <EGiftCardDesigns :toggle="togglePreviewEGiftCardDesigns === true" :designs="restOfDesigns" />
      <div v-show="togglePreviewEGiftCard || togglePreviewEGiftCardDesigns" class="m-popover-lightbox"></div>
    </div>
    <div>
      <div v-if="dataUI && dataUI.magazine.description" id="description" class="magazine__info">
        <b-tabs content-class="p-4" fill align="left">
          <!-- eslint-disable-next-line vue/no-v-html -->
          <b-tab title="Description"><div class="tab-text" v-html="$options.filters.sanitize(dataUI.magazine.description)"></div></b-tab>
          <b-tab disabled></b-tab>
        </b-tabs>
      </div>

      <LazyHydrate when-visible>
        <div v-if="dataUI && dataUI.upsell && dataUI.upsell.length > 0" class="product__upsell">
          <div class="product__panel">
            <p class="product__upsell-heading">Share this page</p>
          </div>

          <div class="basket__panel upsell-grid items-list">
            <Social :magazine="dataUI.magazine" />
          </div>
        </div>
      </LazyHydrate>

      <LazyHydrate when-visible>
        <div v-if="dataUI && dataUI.upsell && dataUI.upsell.length > 0" class="product__upsell">
          <div class="product__panel">
            <p class="product__upsell-heading">You might also like...</p>
          </div>

          <div class="basket__panel upsell-grid items-list">
            <UpsellProduct
              v-for="(item, index) in dataUI.upsell.slice(0, 4)"
              :key="`${index}-${item.productId}-upsell`"
              :type="'upsell'"
              :item="item"
              class="product__upsell-item items-list__item"
              imgix-query-string="&w=130&h=180"
            />
          </div>
        </div>
      </LazyHydrate>
    </div>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, between, email, minLength, maxLength, integer, minValue, requiredIf } from 'vuelidate/lib/validators'
import LazyHydrate from 'vue-lazy-hydration'
import product from '@/mixins/product'
import gtmProductPageMixin from '@/mixins/gtm/product-page'
import { createUniqueFBEventId } from '~/lib/util/fb'
import { getGTMViewItemPayload, getGTMRemarketingPayload, getGTMPageViewDetailsPayload, mapGTMGiftCardsOffers } from '~/lib/util/gtmUAEE'
import { getGA4EcommerceNullPayload, getGA4ViewItemPayload } from '~/lib/util/gtmGA4'
import { getBreadcrumbsMicrodataJSONLD } from '~/lib/util/microdata'

import { getStatusCodeFromPath, callAWSFrontendAPI } from '~/lib/util/aws'

export default {
  name: 'EGiftCard',
  fetchOnServer: true,
  components: {
    LazyHydrate,
    EGiftCardTemplate: () => import('~/components/giftcard/EGiftCardTemplate.vue'),
    EGiftCardPreview: () => import('~/components/giftcard/EGiftCardPreview.vue'),
    EGiftCardDesigns: () => import('~/components/giftcard/EGiftCardDesigns.vue')
  },
  mixins: [gtmProductPageMixin, product, validationMixin],
  validations: {
    selection: {
      amount: {
        input: {
          integer,
          between: between(5, 200)
        }
      },
      fromName: { required, minLength: minLength(1), maxLength: maxLength(50) },
      fromEmail: {
        required: requiredIf(function() {
          return this.fromEmailEnabled
        }),
        email
      },
      toName: { required, minLength: minLength(1), maxLength: maxLength(50) },
      toEmail: { required, email },
      toDate: {
        required,
        minValue: minValue((d => new Date(d.setDate(d.getDate() - 1)))(new Date()))
      },
      message: { minLength: minLength(1), maxLength: maxLength(120) }
    }
  },
  layout: '2Columns',

  async fetch() {
    // Skip the refresh client side that will trigger a CORS error
    if (this.isUpdate) {
      return
    }

    const status = getStatusCodeFromPath({ context: this })
    if (status !== 200) {
      if (process.server) {
        this.$nuxt.context.res.statusCode = status
      }
      this.$store.commit('UI/statusCode', status, { root: true })
      return
    }

    // Look for an available Query String and retrieve the URL path
    const path = '/magazines/e-giftcards'
    const query = this.$nuxt.context.query
    const useCache = this.$store.getters['UI/useCache']
    console.log('page=E-GiftCard')
    console.log('path=', path)
    console.log('query=', query)
    console.log('QS useCache=', useCache)
    console.log('AWSAPIGatewayURL=', this.$nuxt.$config.AWSAPIGatewayURL)

    this.dataUI = {
      ...(await callAWSFrontendAPI({ context: this, path, query, useCache, type: 'PRODUCTPAGE' }))
    }

    // Breadcrumbs
    const breadcrumbs = [
      {
        first: true,
        value: 'magazine subscriptions',
        url: '/',
        last: false
      },
      {
        first: false,
        value: 'Gift Card Options',
        url: '/gift-card-options',
        last: false
      },
      {
        first: false,
        value: 'E-Gift Cards',
        url: '/magazines/e-giftcards',
        last: true
      }
    ]
    this.$store.commit('ADD_BREADCRUMBS', { path: '/magazines/e-giftcard', breadcrumbs })

    if (this.params?.id && this.params?.uuid) {
      // Quote Item Info by API -> M1 backend
      console.log('E-Gift Card action=edit params=', this.params)
      this.$axios.setHeader('Content-Type', 'application/x-www-form-urlencoded', ['post'])
      this.$config.backenApiSecret && this.$axios.setHeader('emailSender-sls-backend-apiKey', this.$config.backenApiSecret)

      const data = {
        type: 'e-gift-card',
        id: this.$options.filters.sanitize(this.params.id),
        uuid: this.$options.filters.sanitize(this.params.uuid)
        // mock: true
      }

      const url = `${process.env.BACKEND_URL}/mcheckout/giftcard/index`
      const response = await this.$axios
        .$post(url, data)
        .then(function(response) {
          console.log('RESPONSE=', response)
          return response
        })
        .catch(function(error) {
          console.log('ERROR=', error)
          return error
        })

      this.isUpdate = false
      if (response?.amount) {
        this.isUpdate = true
        this.selection = { ...response }
        if (this.selection.toDate) {
          this.selection.toDate = new Date(this.selection.toDate)
        }
        this.selection.disabledDates = {
          to: new Date(Date.now() - 86400000), // Disable all dates up to specific date (YESTERDAY)
          from: new Date(Date.now() + 86400000 * 180) // Disable all dates after specific date (IN 180 days)
        }
        this.$v.$touch()
      } else {
        this.$router.push({ path: '/magazines/e-giftcards' })
      }
    }
  },

  data() {
    return {
      togglePreviewEGiftCard: false,
      togglePreviewEGiftCardDesigns: false,
      isUpdate: false,
      isClicked: false,
      fromEmailEnabled: false,
      moreDesignSelected: false,
      designPreviewQueryString: '?auto=format&w=470&dpr=2',
      designThumbnailQueryString: '?auto=format&fit=fill&fill=solid&bg=0FFF&w=99&h64&dpr=2',
      errors: {
        amount: false,
        fromName: false,
        fromEmail: false,
        toName: false,
        toEmail: false,
        toDate: false,
        message: false
      },
      selection: {
        amount: {
          value: 20,
          id: 0,
          input: undefined
        },
        design: {
          index: 0,
          id: 'default',
          image: '/gift-card-images/JMAG_Giftcards.png',
          title: 'magazine.co.uk'
        },
        fromName: '',
        fromEmail: '',
        toName: '',
        toEmail: '',
        toDate: new Date(),
        message: '',
        disabledDates: {
          to: new Date(Date.now() - 86400000), // Disable all dates up to specific date (YESTERDAY)
          from: new Date(Date.now() + 86400000 * 180) // Disable all dates after specific date (IN 180 days)
        }
      },
      designs: [
        {
          title: 'magazine.co.uk',
          image: '/gift-card-images/Default.png',
          id: 'default'
        },
        {
          title: 'Happy Birthday',
          image: '/gift-card-images/HappyBirthday.png',
          id: 'bday-0'
        },
        {
          title: 'Thank You',
          image: '/gift-card-images/ThankYou.png',
          id: 'thank-you-0'
        },
        {
          title: 'Thank You',
          image: '/gift-card-images/ThankYou-1.png',
          id: 'thank-you-1'
        },
        {
          title: 'Happy Birthday',
          image: '/gift-card-images/HappyBirthday-1.png',
          id: 'bday-1'
        },
        {
          title: 'From All Of Us',
          image: '/gift-card-images/FromAllOfUs.png',
          id: 'from-all-of-us-0'
        },
        {
          title: 'Valentines',
          image: '/gift-card-images/Valentines.png',
          id: 'valentines-0'
        },
        {
          title: 'Valentines',
          image: '/gift-card-images/Valentines-1.png',
          id: 'valentines-2'
        },
        {
          title: "Mother's Day",
          image: '/gift-card-images/Mothers.png',
          id: 'mothers-0'
        },
        {
          title: 'Happy Easter',
          image: '/gift-card-images/Easter.png',
          id: 'easter-0'
        },
        {
          title: "Father's Day",
          image: '/gift-card-images/FathersDay.png',
          id: 'fathers-0'
        },
        {
          title: 'Merry Christmas',
          image: '/gift-card-images/Xmas.png',
          id: 'xmas-0'
        },
        {
          title: 'Merry Christmas',
          image: '/gift-card-images/Xmas-1.png',
          id: 'xmas-1'
        },
        {
          title: 'Merry Christmas',
          image: '/gift-card-images/Xmas-2.png',
          id: 'xmas-2'
        }
      ],
      image: undefined,
      metaTitleDefault: 'E-Gift Cards',
      dataAmountValuesDefault: [20, 30, 40, 50],
      trackingItemsForUAEE: [
        {
          name: 'E-Gift Card - £20.00',
          id: 'e-giftcards',
          price: '0.00',
          brand: 'E-Gift Card',
          category: '',
          sub_category: '',
          product_price: '20.00',
          product_rrp: '0.00',
          product_issues: 0,
          product_type: 'EGIFTCARD',
          payment_type: 'CC',
          productId: '6880',
          sku: 'e-giftcards'
        },
        {
          name: 'E-Gift Card - £30.00',
          id: 'e-giftcards',
          price: '0.00',
          brand: 'E-Gift Card',
          category: '',
          sub_category: '',
          product_price: '30.00',
          product_rrp: '0.00',
          product_issues: 0,
          product_type: 'EGIFTCARD',
          payment_type: 'CC',
          productId: '6880',
          sku: 'e-giftcards'
        },
        {
          name: 'E-Gift Card - £40.00',
          id: 'e-giftcards',
          price: '0.00',
          brand: 'E-Gift Card',
          category: '',
          sub_category: '',
          product_price: '40.00',
          product_rrp: '0.00',
          product_issues: 0,
          product_type: 'EGIFTCARD',
          payment_type: 'CC',
          productId: '6880',
          sku: 'e-giftcards'
        },
        {
          name: 'E-Gift Card - £50.00',
          id: 'e-giftcards',
          price: '0.00',
          brand: 'E-Gift Card',
          category: '',
          sub_category: '',
          product_price: '50.00',
          product_rrp: '0.00',
          product_issues: 0,
          product_type: 'EGIFTCARD',
          payment_type: 'CC',
          productId: '6880',
          sku: 'e-giftcards'
        }
      ],
      trackingItemsForGA4: [
        {
          item_name: 'E-Gift Card - £20.00',
          item_id: 'e-giftcards',
          price: '0.00',
          currency: 'GBP',
          discount: '0',
          item_brand: 'E-Gift Card',
          item_category: '',
          item_category2: '',
          product_price: '20.00',
          product_rrp: '0.00',
          product_issues: 0,
          product_type: 'EGIFTCARD',
          payment_type: 'CC',
          productId: '6880',
          sku: 'e-giftcards'
        },
        {
          item_name: 'E-Gift Card - £30.00',
          item_id: 'e-giftcards',
          price: '0.00',
          currency: 'GBP',
          discount: '0',
          item_brand: 'E-Gift Card',
          item_category: '',
          item_category2: '',
          product_price: '30.00',
          product_rrp: '0.00',
          product_issues: 0,
          product_type: 'EGIFTCARD',
          payment_type: 'CC',
          productId: '6880',
          sku: 'e-giftcards'
        },
        {
          item_name: 'E-Gift Card - £40.00',
          item_id: 'e-giftcards',
          price: '0.00',
          currency: 'GBP',
          discount: '0',
          item_brand: 'E-Gift Card',
          item_category: '',
          item_category2: '',
          product_price: '40.00',
          product_rrp: '0.00',
          product_issues: 0,
          product_type: 'EGIFTCARD',
          payment_type: 'CC',
          productId: '6880',
          sku: 'e-giftcards'
        },
        {
          item_name: 'E-Gift Card - £50.00',
          item_id: 'e-giftcards',
          price: '0.00',
          currency: 'GBP',
          discount: '0',
          item_brand: 'E-Gift Card',
          item_category: '',
          item_category2: '',
          product_price: '50.00',
          product_rrp: '0.00',
          product_issues: 0,
          product_type: 'EGIFTCARD',
          payment_type: 'CC',
          productId: '6880',
          sku: 'e-giftcards'
        }
      ],
      h1TitleDefault: 'E-Gift Card',
      canonicalURLDefault: 'https://www.magazine.co.uk/magazines/e-giftcards',
      metaDescriptionDefault: `A magazine subscription gift card is the perfect gift. Our E-cards can be emailed instantly to the lucky recipient or you can print it off yourself and give it in person.`,
      metaKeywordsDefault: 'Magazine.co.uk',
      isFromMobile: this.$ua.isFromIphone() || this.$ua.isFromAndroidMobile()
    }
  },

  computed: {
    dataAmountValues() {
      if (this.isFromMobile) {
        return this.dataAmountValuesDefault.slice(0, 3)
      }
      return this.dataAmountValuesDefault
    },
    latestDesigns() {
      // console.log('isFromIphone=', this.$ua.isFromIphone())
      // console.log('isFromAndroidMobile=', this.$ua.isFromAndroidMobile())
      // console.log('isFromMobile=', this.isFromMobile)
      if (this.isFromMobile) {
        if (this.selection.design.index >= 3) {
          return [this.selection.design, ...this.designs.slice(0, 2)]
        }
        if (this.moreDesignSelected) {
          return [this.moreDesignSelected, ...this.designs.slice(0, 2)]
        }
        return this.designs.slice(0, 3)
      }

      if (this.selection.design.index >= 4) {
        // Add a design as first element if it has been selected from the more choice
        return [this.selection.design, ...this.designs.slice(0, 3)]
      }

      if (this.moreDesignSelected) {
        return [this.moreDesignSelected, ...this.designs.slice(0, 3)]
      }

      return this.designs.slice(0, 4)
    },
    restOfDesigns() {
      if (this.isFromMobile) {
        if (this.selection.design.index >= 3) {
          // Show one design more if we have already selected one from the more choice
          return this.designs.slice(2)
        }
        if (this.moreDesignSelected) {
          return this.designs.slice(2)
        }

        return this.designs.slice(3)
      }

      if (this.selection.design.index >= 4) {
        // Show one design more if we have already selected one from the more choice
        return this.designs.slice(3)
      }

      if (this.moreDesignSelected) {
        return this.designs.slice(3)
      }

      return this.designs.slice(4)
    }
  },
  mounted() {
    const defaultOffer = {
      price: '20.00',
      sku: 'e-giftcards'
    }

    // Facebook Event Deduplication
    const eventId = createUniqueFBEventId('PageView')
    const eventIdViewContent = createUniqueFBEventId('ViewContent')
    const eventIdProductDetail = createUniqueFBEventId('productDetail')

    // Customer email address
    const email = this.$store.getters['s/email']

    // UAEE tracking PageView
    this.$config.UAEESyntax &&
      this.$gtm &&
      this.$gtm.push(
        getGTMPageViewDetailsPayload({
          pageUrl: '/magazines/e-giftcard',
          pageTitle: 'E-Gift Cards - Magazine Subscription E-Gift Cards',
          routeName: this.$route.name,
          eventId,
          email
        })
      )

    // GTM productView with the cheapest offer
    let data = getGTMViewItemPayload(defaultOffer)
    const products = mapGTMGiftCardsOffers({ offers: this.trackingItemsForUAEE })
    this.$config.UAEESyntax &&
      data &&
      this.$gtm &&
      this.$gtm.push({
        event: 'productView',
        ...data,
        ...products
      })

    // GA4 Fire view_item event
    this.$config.GA4Syntax && this.$config.GA4FireEcommerceNull && this.$gtm && this.$gtm.push(getGA4EcommerceNullPayload())
    this.$config.GA4Syntax && this.$gtm && this.$gtm.push(getGA4ViewItemPayload({ rawItems: this.trackingItemsForGA4 }))

    // GTM Measuring Views of Product Details
    // link https://developers.google.com/tag-manager/enhanced-ecommerce#details
    this.$config.UAEESyntax &&
      products &&
      this.$gtm &&
      this.$gtm.push({
        event: 'productDetail',
        ecommerce: {
          detail: {
            ...products
          }
        },
        eventID: eventIdProductDetail,
        eventID4ViewContent: eventIdViewContent
      })

    // GTM fire remaketing tag with the cheapest offer
    // Based on https://magento.stackexchange.com/questions/82965/how-to-put-the-code-google-remarketing
    data = getGTMRemarketingPayload(defaultOffer)
    data &&
      this.$gtm &&
      this.$gtm.push({
        event: 'fireRemarketingTag',
        ...data
      })
  },
  created() {
    this.$nuxt.$on('SHOW_GIFTCARD_PREVIEW', value => {
      this.togglePreviewEGiftCard = value
    })
    this.$nuxt.$on('SHOW_GIFTCARD_DESIGNS', value => {
      this.togglePreviewEGiftCardDesigns = value
    })
    this.$nuxt.$on('UPDATE_DESIGN_SELECTION', ({ action, index, data }) => {
      this.updateSelection(action, index, data)
      this.moreDesignSelected = data
    })
  },
  methods: {
    createImgixURL(image, qs) {
      return `${this.$config.imgixURL}${image}${qs}`
    },

    checkValidity(field, el) {
      // console.log('checkValidity field=', field)
      // console.log('checkValidity el=', el)

      if (field === 'toDate') {
        // console.log('checkValidity el.$invalid=', el.$invalid)
        return (this.errors[field] = el.$invalid)
      }

      // console.log('checkValidity el.required=', el.required)
      // console.log('checkValidity el.$invalid=', el.$invalid)
      return (this.errors[field] = el.$invalid)
    },

    updateSelection(action, index, data) {
      console.log('updateSelection action=', action)
      console.log('updateSelection index=', index)
      console.log('updateSelection data=', data)

      switch (action) {
        case 'amount':
          this.selection.amount.id = index
          this.selection.amount.value = Number(data)
          if (index > 0) {
            this.selection.amount.input = undefined
          }
          break
        case 'design':
          this.selection.design = {
            index,
            ...data
          }
          break
        default:
          break
      }
    },
    formatInput(value) {
      // console.log('formatInput value=', value)
      let numericValue = parseInt(value.replace('£', ''))
      if (isNaN(numericValue)) {
        numericValue = undefined
        this.selection.amount.input = undefined
        this.updateSelection('amount', -1, numericValue)
      }
      // console.log('formatInput numericValue=', numericValue)
      if (numericValue > 0) {
        this.updateSelection('amount', -1, numericValue)
        this.selection.amount.input = `${numericValue}`
      }
      return undefined
    },
    focusFirstStatus(component = this) {
      if (component.status) {
        component.$el.focus()
        return true
      }

      let focused = false

      component.$children.some(childComponent => {
        focused = this.focusFirstStatus(childComponent)
        return focused
      })

      return focused
    },
    validate() {
      this.$v.$touch()
      this.$nextTick(() => this.focusFirstStatus())
    },

    async handleUpdateSelection() {
      this.isClicked = true

      const item = {
        title: 'E-Gift Card',
        productId: 6880,
        id: 'EGFT',
        sku: 'e-giftcards',
        offer: {
          productId: 6880,
          price: Number(this.selection.amount.value).toFixed(2),
          cpa: '0.00',
          ...this.selection,
          sku: 'e-giftcards',
          rrp: '0.00',
          numOfIssues: 0,
          type: 'EGIFTCARD',
          paymentType: 'CC'
        },
        category: '',
        subCategory: ''
      }
      // console.log('handleUpdateSelection item=', item)

      // Default message
      if (!item.offer.message) {
        item.offer.message = 'Enjoy this magazine.co.uk Gift Card!'
      }

      const action = (this.isUpdate && 'update') || 'add'

      console.log('e-giftcard handleClickAction item=', item)
      console.log('e-giftcard handleClickAction action=', action)

      // Update the BE Basket data
      const ret = await this.updateBasket(item, action)

      if (!ret.error && !this.isUpdate) {
        this.selection = {
          amount: {
            value: 20,
            id: 0,
            input: undefined
          },
          design: {
            index: 0,
            id: 'default',
            image: '/gift-card-images/JMAG_Giftcards.png',
            title: 'magazine.co.uk'
          },
          fromName: '',
          fromEmail: '',
          toName: '',
          toEmail: '',
          toDate: new Date(),
          message: ''
        }
      }

      // GTM & FB push events integration
      !ret.error && this.pushGTMAndFBAfterButtonClick({ action, item })

      // Show the basket overlay or an error notification
      if (ret.error) {
        console.log('ERROR', ret.error)
        this.$nuxt.$emit('SHOW_NOTIFICATION', { data: ret.error, show: true })
      } else {
        this.$router.push({ path: '/gift-card-options?showBasket=1' })
      }

      this.isClicked = false
    }
  },

  // To check more options, link https://github.com/nuxt/vue-meta#script-object
  // To chcek how it is working https://nuxtjs.org/faq/duplicated-meta-tags/
  head() {
    return {
      title: this.dataUI?.title || '',
      link: [{ rel: 'canonical', href: this.dataUI?.magazine?.canonicalURL }],
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.dataUI?.magazine?.metaDescription || ''
        },
        {
          name: 'keywords',
          content: this.dataUI.metaKeywords || ''
        },
        {
          name: 'robots',
          content: this.$config.robots || 'INDEX,FOLLOW'
        },
        {
          hid: 'og:title',
          name: 'og:title',
          property: 'og:title',
          content: this.dataUI?.title || ''
        },
        {
          hid: 'og:description',
          name: 'og:description',
          property: 'og:description',
          content: this.dataUI?.magazine?.metaDescription || ''
        },
        {
          hid: 'og:site_name',
          name: 'og:site_name',
          property: 'og:site_name',
          content: 'magazine.co.uk'
        },
        {
          hid: 'og:image',
          name: 'og:image',
          property: 'og:image',
          content: 'https://magazine.imgix.net/gift-card-images/JMAG_Giftcards.png?auto=format'
        }
      ]
    }
  },

  jsonld() {
    return [getBreadcrumbsMicrodataJSONLD({ breadcrumbs: this.$store.getters['UI/breadcrumbs'] })]
  }
}
</script>
<style lang="scss">
.m-popover-lightbox {
  z-index: 1008;
  opacity: 0.5;
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: #0f1111;
}
.m-section:last-child {
  margin-bottom: 0px;
}
.m-section {
  margin-bottom: 10px;
}
.m-section-no-margin {
  margin-bottom: 0;
}
.m-text-center {
  text-align: center !important;
}
.m-spacing-bottom-big {
  margin-bottom: 30px;
}
.m-spacing-top-medium {
  margin-top: 16px !important;
}
.m-spacing-top-big {
  margin-top: 30px !important;
}
.m-color-1 {
  color: $gcTextColor1 !important;
}
.m-color-2 {
  color: $gcTextColor2 !important;
}
.m-color-3 {
  color: $gcTextColor3 !important;
}
.m-text-error {
  color: $gcTextColorError !important;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
}
.m-text {
  &--chars-remaining {
    text-align: right;
    font-size: 12px;
    font-weight: 400;
  }
}
.m-button-close {
  float: right;
  top: 5px;
  position: absolute;
  right: 5px;
  width: 24px;
  padding: 23px;
  cursor: pointer;
}
.m-text-bold {
  font-weight: 700;
}
.m-no-margin {
  margin: 0 !important;
}
.m-margin-left-20percent {
  margin-left: 20%;
}
.m-button {
  background-color: white;
  border-radius: 6px;
  border: 1px solid $gcBorderColor;
  cursor: pointer;
  display: inline-block;
  padding: 0;
  text-align: center;
  text-decoration: none !important;
  vertical-align: middle;
  &__addtobasket {
    border: unset;
    text-align: center;
    padding: 0px 0;
    &:hover {
      opacity: 0.7;
      filter: alpha(opacity=70);
    }
  }
  &__preview {
    border: 1px solid transparent;
    text-align: center;
    padding: 0px 0;
    &:hover {
      opacity: 0.7;
      filter: alpha(opacity=70);
    }
  }
}
.m-button-selected {
  outline: 0;
  overflow: visible;
  border: 2px solid $gcItemSelectBorderColor;
  background-color: $gcItemSelectBgColor;
}
.m-button-inner {
  background-color: transparent;
  background-image: none;
}
.m-button-text {
  padding: 10px;
  background-color: transparent;
  border: 0;
  display: block;
  line-height: 19px;
  margin: 0;
  outline: 0;
  text-align: center;
  white-space: nowrap;
  &__addtobasket {
    text-transform: uppercase;
    text-align: center;
    font-size: 12px;
    font-weight: 600;
    background: $gcButtonColor;
    color: white;
    line-height: 19px;
    margin: 0;
    text-decoration: none;
    min-width: 150px;
    padding: 10px 15px;
    &--mobile {
      width: 100%;
      margin: 0;
    }
  }
  &__preview {
    text-transform: uppercase;
    text-align: center;
    font-size: 12px;
    font-weight: 600;
    line-height: 19px;
    margin: 0;
    text-decoration: none;
    min-width: 150px;
    padding: 10px 15px;
    background-color: $gcBgColorDisabled;
    color: $gcTextColor1;
    &--mobile {
      width: 100%;
      margin: 0;
    }
  }
}
.m-input {
  background-color: white;
  border: 1px solid $gcBorderColor;
  cursor: pointer;
  display: inline-block;
  padding: 0;
  text-align: center;
  text-decoration: none !important;
  vertical-align: middle;
  &--noborder {
    border: unset;
  }
  &--border {
    border: 1px solid $gcBorderColor !important;
  }
}
.m-input-text {
  &--big {
    padding: 6px;
    font-size: 20px;
  }
  &--medium {
    padding: 9px;
    font-size: 16px;
  }
  &--normal {
    padding: 10px;
    font-size: 16px;
  }
  background-color: transparent;
  border: 0;
  display: block;
  line-height: 20px;
  margin: 0;
  outline: 0;
  text-align: left;
  white-space: nowrap;
}
.m-textarea {
  background-color: transparent;
  border: 0;
  display: block;
  line-height: 20px;
  margin: 0;
  outline: 0;
  text-align: left;
  width: 100%;
  resize: vertical;
  word-break: break-all;
  overflow: auto;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
}
.m-input-selected {
  outline: 0;
  overflow: visible;
  border: 2px solid $gcItemSelectBorderColor;
  background-color: $gcItemSelectBgColor;
}
.m-input-error {
  outline: 0;
  overflow: visible;
  border: 2px solid $gcTextColorError;
  background-color: $gcTextColorBgColor;
  .m-nofocus {
    &:focus {
      outline: 0;
      box-shadow: 0 0 3px 2px $gcTextColorError;
    }
  }
}
.m-nofocus {
  &:focus {
    outline: 0;
    box-shadow: 0 0 3px 2px $gcItemSelectBorderColor;
  }
}
.m-button-thumbnail-small {
  width: 100%;
  max-width: 75px;
  border-radius: 4px;
}
.m-button-thumbnail-large {
  width: 100%;
  border-radius: 4px;
}
.m-padding-2 {
  padding: 2px;
}
.m-padding-10 {
  padding: 0 10px;
}
.m-flex {
  display: flex;
}
.m-flex-stretch {
  align-items: stretch;
}
.m-flex-justify-content-center {
  justify-content: center;
}
.m-border-radius-right {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}
.m-border-radius-left {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}
.m-border-radius {
  border-radius: 6px;
}
.m-date-picker {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: auto;
  background-color: transparent;
  display: block;
  line-height: 22px;
  margin: 0;
  outline: 0;
  text-align: left;
  white-space: nowrap;
  max-width: 110px;
}

button:disabled,
button[disabled] {
  // border: 1px solid #ff0d00;
  // background-color: #f3d4d2;
  color: white;
  opacity: 0.7;
}

#giftcard {
  width: 100%;
}
#gc-buttons-wrapper {
  display: flex;
  flex-flow: column-reverse nowrap;
  justify-content: flex-start;
  align-content: center;
  // margin-bottom: 200px;
}
#gc-detail-page__required {
  display: none;
  &--mobile {
    display: block;
    float: right;
    position: relative;
    top: 0;
    right: 0;
    font-size: 12px;
    margin-right: 20px;
    color: $gcTextColor1;
    font-weight: 400;
  }
}
.vdp-datepicker__calendar {
  left: -50px;
}
#gc-detail-page-right-column {
  padding: 10px;
  ul.gc-detail-page__list {
    margin-left: 20px;
    padding: 0;
  }
  .gc-detail-page {
    &__title {
      display: none;
      // font-weight: 400;
      font-style: normal;
      font-size: 23px;
      color: $headingColor;
    }
    &__description {
      display: none;
      // font-weight: 400;
      font-style: normal;
      font-size: 14px;
    }
    &__step {
      font-weight: 600;
      font-style: normal;
      font-size: 16px;
      max-width: 595px;
    }
    &__wrapper {
      margin-top: 10px;
    }
    &__list {
      list-style-type: none;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-flow: row nowrap;
      &--stretch {
        align-items: stretch;
      }
    }
    &__list--form {
      display: block;
    }
    &__list-other {
      // display: inline-table;
      margin-right: 10px;
      display: flex;
      flex-flow: row nowrap;
    }
    &__list-item {
      display: flex;
      flex-flow: column nowrap;
      margin-right: 15px;
      font-size: 20px;
      button {
        border-radius: 6px;
      }
    }
    &__button-more {
      width: 100%;
      padding: 10px;
      height: 100%;
      border-radius: 6px;
      border: 1px solid $gcBorderColor;
    }
    &__input-currency {
      background-color: #f6f6f6;
      padding: 8px 6px;
      white-space: nowrap;
      vertical-align: middle;
      font-size: 20px;
      margin: 0px;
      line-height: 21px;
      border-color: $gcBorderColor;
      border-style: solid;
      border-width: 1px 0 1px 1px;
    }
    &__input-calendar {
      background-color: #f6f6f6;
      padding: 6px 7px;
      line-height: 1;
      width: 42px;
      white-space: nowrap;
      vertical-align: middle;
      font-size: 20px;
      margin: 0px;
      border-color: $gcBorderColor;
      border-style: solid;
      border-width: 1px 0 1px 1px;
    }
    &__form-label {
      float: left;
      text-align: left;
      width: 100%;
      padding: 10px;
      font-size: 16px;
      margin-left: 20px;
      color: $gcTextColor1;
    }
    &__form-field {
      margin-left: 20px;
      width: 90%;
      max-width: 700px;
      input {
        width: 100%;
      }
    }
  }
}

@media (max-width: 320px) {
  .m-input-text--medium {
    padding: 9px;
    font-size: 15px;
  }
  #gc-detail-page-right-column {
    .gc-detail-page {
      &__list-item {
        &:nth-child(3) {
          display: none;
        }
      }
    }
  }
}
@media (min-width: $screen-sm-min) {
  .m-input-text--medium {
    padding: 9px;
    font-size: 15px;
  }
  .m-button-thumbnail-small {
    width: 80%;
    max-width: 75px;
    border-radius: 4px;
  }
  .gc-container {
    max-width: 1230px !important;
    display: flex;
    flex-flow: column nowrap;
    align-content: center;
  }
  #giftcard {
    width: 100%;
    #gc-detail-page {
      margin: 5px 0px;
      max-width: 1230px !important;
      display: flex;
      flex-flow: row nowrap;
      #gc-detail-page-left-column {
        padding: 5px 10px;
        flex: 1 1 40%;
        max-width: 500px;
      }
      #gc-detail-page-right-column {
        flex: 1 1 60%;
        padding: 5px 10px;
      }
      #gc-buttons-wrapper {
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-content: center;
        margin-bottom: 20px;
      }
      #gc-detail-page__required {
        display: block;
        float: right;
        position: relative;
        top: 0;
        right: 0;
        font-size: 12px;
        margin-right: 25px;
        color: $gcTextColor1;
        font-weight: 400;
      }
      #gc-detail-page__date-picker {
        display: flex;
        flex-flow: column nowrap;
        align-content: center;
        .vdp-datepicker div {
          .vdp-datepicker__clear-button span i span {
            padding: 10px;
            float: right;
            top: -42px;
            bottom: 0px;
            position: relative;
            font-style: normal;
            font-size: 25px;
            font-weight: 400;
            color: black;
          }
        }
        .vdp-datepicker__calendar {
          left: 0px;
        }
      }
      #gc-detail-page__date-picker-wrapper {
        max-height: 44px;
      }
    }

    #gc-detail-page-right-column {
      #gc-detail-designs {
        max-width: 70%;
      }
      .gc-detail-page {
        &__title {
          display: block;
          // font-weight: 400;
          font-style: normal;
          font-size: 23px;
          color: $headingColor;
        }
        &__description {
          display: block;
          // font-weight: 400;
          font-style: normal;
          font-size: 14px;
        }
        &__step {
          font-weight: 600;
          font-style: normal;
          font-size: 16px;
          max-width: 595px;
        }
        &__wrapper {
          margin-top: 15px;
        }
        &__list {
          list-style-type: none;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          flex-flow: row nowrap;
          &--stretch {
            align-items: stretch;
          }
        }
        &__list--form {
          display: block;
        }
        &__list-other {
          display: inline-table;
          margin-right: 10px;
        }
        &__list-item {
          display: flex;
          flex-flow: row nowrap;
          margin-right: 15px;
          font-size: 15px;
          button {
            border-radius: 6px;
          }
        }
        &__button-more {
          width: 100%;
          padding: 10px;
          height: 100%;
          border-radius: 6px;
          border: 1px solid $gcBorderColor;
        }
        &__input-currency {
          background-color: #f6f6f6;
          padding: 8px 7px 10px 7px;
          line-height: 1;
          width: 1%;
          white-space: nowrap;
          vertical-align: middle;
          font-size: 15px;
          margin: 0px;
          border-color: $gcBorderColor;
          border-style: solid;
          border-width: 1px 0 1px 1px;
        }
        &__input-calendar {
          background-color: #f6f6f6;
          padding: 6px 7px;
          line-height: 1;
          width: 42px;
          white-space: nowrap;
          vertical-align: middle;
          font-size: 20px;
          margin: 0px;
          border-color: $gcBorderColor;
          border-style: solid;
          border-width: 1px 0 1px 1px;
        }
        &__form-label {
          float: left;
          text-align: left;
          width: 150px;
          padding: 10px;
          font-size: 15px;
          margin-left: 30px;
          color: $gcTextColor1;
        }
        &__form-field {
          margin-left: 10px;
          width: 55%;
          input {
            width: 100%;
          }
        }
      }
    }

    .magazine {
      position: relative;
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-start;

      align-items: flex-start;

      align-content: space-between;
      margin-bottom: 20px;

      &__info {
        margin-bottom: 20px;
        .tab-content ul {
          display: block;
          list-style-type: disc;
          margin-block-start: 1em;
          margin-block-end: 1em;
          margin-inline-start: 0px;
          margin-inline-end: 0px;
          padding-left: 40px;
        }

        .nav-item {
          a,
          a::before,
          a::after {
            box-sizing: unset !important;
            font-size: 14px;
          }
        }

        .nav-link {
          color: #212529;

          &:hover {
            border-color: $borderColor $borderColor white !important;
            color: #212529 !important;
          }
        }
        .nav-tabs {
          background: #ededed;
          border-bottom: unset;
        }
        .nav {
          flex-wrap: nowrap;
        }
        .tab-content {
          border-radius: 0 0 0.25rem 0.25rem;
          border: 1px solid #dee2e6;
        }
        .tab-text {
          font-size: 14px;
          p:last-child {
            margin-bottom: 0px;
          }
        }
      }
    }
    .product {
      &__recentlyViewed {
        .product__offer {
          display: none;
        }
        .actions {
          display: none;
        }
      }
      &__upsell {
        flex: 1 1 100%;
        padding-top: 20px;
        z-index: 100;
        transform: none;
        // width: 400px;
        height: auto;

        position: relative;
        clear: both;
        background-color: #fff;
        text-align: center;
      }
      &__upsell-heading {
        margin: 0 0 10px;
        letter-spacing: 0.1em;
        font-weight: 700;
        padding: 10px;
      }
    }
    .upsell-grid {
      .items-list {
        &__item {
          padding: 0px 20px;
          flex: 1 0 20%;
          max-width: 170px;
          min-width: 100px;
          .actions button {
            background-color: transparent;
            text-align: center;
            font-size: 12px;
            color: $buttonAddToCartColor;
            line-height: 12px;
            margin: 0em;
            text-decoration: underline;
            min-width: 100px;
            padding: 15px 0px;
            border: unset;

            &:hover {
              text-decoration: none;
            }
            .spinner {
              top: 40%;
            }
          }
          .view-btn {
            display: none;
          }
          .btn-divider {
            display: none;
          }
        }
      }
    }
  }
}
</style>
