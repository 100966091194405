<template>
  <div>
    <img v-bind="mainProps" :src="imgixUrl" :srcset="`${imgixUrl} 1x, ${imgixUrl}&dpr=2&q=30 2x, ${imgixUrl}&dpr=2&q=30 3x`" loading="lazy" />
  </div>
</template>

<script>
export default {
  props: {
    imageUrl: {
      type: String,
      required: true,
      default() {
        return '/dist/assets/images/image_placeholder.png'
      }
    },
    queryString: {
      type: String,
      required: false,
      default() {
        return '&w=193&h=265'
      }
    },
    alt: {
      type: String,
      required: true,
      default() {
        return 'Image'
      }
    },
    width: {
      type: String,
      required: false,
      default() {
        return '186'
      }
    },
    height: {
      type: String,
      required: false,
      default() {
        return '255'
      }
    },
    blankColor: {
      type: String,
      required: false,
      default() {
        return undefined
      }
    },
    customClass: {
      type: String,
      required: false,
      default() {
        return ''
      }
    },
    isPocketmagsPlus: {
      type: Boolean,
      required: false,
      default() {
        return false
      }
    },
    fluidGrow: {
      type: Boolean,
      required: false,
      default() {
        return true
      }
    }
  },
  data() {
    return {
      imagePlaceholder: '~/assets/images/image_placeholder.png',
      imgixBaseQueryString: '?auto=format&fit=fill&fill=solid&bg=0FFF',
      imgixPocketmagsPlusQueryString: '?auto=format&fit=fill&fill=solid&bg=0FFF&w=178&border=1,070707',
      mainProps: {
        alt: this.alt,
        class: this.customClass,
        fluidGrow: this.fluidGrow,
        blankSrc: this.blankColor ? undefined : 'https://magazine.imgix.net/placeholder.png',
        blankColor: this.blankColor,
        width: parseInt(this.width),
        height: parseInt(this.height)
      },
      isFromMobile: this.$ua.isFromIphone() || this.$ua.isFromAndroidMobile()
    }
  },
  computed: {
    imgixUrl() {
      const originalUrl = this.imageUrl.includes('?') ? this.imageUrl.split('?').shift() : this.imageUrl
      if (this.isPocketmagsPlus) {
        return `${this.$config.imgixURL}${originalUrl}${this.imgixPocketmagsPlusQueryString}`
      }
      return `${this.$config.imgixURL}${originalUrl}${this.imgixBaseQueryString}${this.queryString}`
    }
  }
}
</script>

<style>
.giftcards__egiftcard {
  width: auto !important;
  margin: 14px;
}
.giftcards__giftcard {
  width: auto !important;
  margin: 25px;
}
</style>
