<template>
  <div v-if="!$store.isMobile">
    <SidebarGiftCards creative-slot="right_nav" location-id="sitewide_giftcard" />

    <PromotionsSitewideDesktop v-if="sitewide && sitewide.enabled === true" creative-slot="right_nav" location-id="sitewide_offer_promotion" :sitewide="sitewide" />

    <div v-if="bestSellersValues && bestSellersValues?.length > 0" class="product-best-sellers">
      <div class="product-best-sellers__panel">
        <div class="product-best-sellers__heading"><a href="/best-selling-magazine-subscriptions" title="View all Best Sellers"> Best Sellers</a></div>
      </div>

      <div class="product-best-sellers__panel">
        <BestsellersProduct v-for="(item, index) in bestSellersValues" :key="`bestseller-${item.productId}-${index}`" :item="item" />
      </div>
    </div>
  </div>
</template>

<script>
import { getGA4EcommerceNullPayload, getGA4SelectPromotionPayload } from '~/lib/util/gtmGA4'

export default {
  name: 'Right',
  serverCacheKey() {
    return false
  },

  components: {
    BestsellersProduct: () => import('~/components/common/promotions/BestsellersProduct.vue'),
    PromotionsSitewideDesktop: () => import('~/components/common/promotions/PromotionsSitewideDesktop.vue'),
    SidebarGiftCards: () => import('~/components/giftcard/SidebarGiftCards')
  },

  data() {
    return {
      sitewide: this.$store.getters['utility/sitewide'] || { enabled: false },
      bestSellersValues: this.$store.getters['utility/bestsellers'] || []
    }
  },

  methods: {
    handleClick() {
      // GA4 events for every  Banner Image Click
      const banner = { id: '0', alt: 'SITEWIDE - Offer Promotion', key: this.creativeSlot }
      this.$config.GA4Syntax && this.$config.GA4FireEcommerceNull && this.$gtm && this.$gtm.push(getGA4EcommerceNullPayload())
      this.$config.GA4Syntax && this.$gtm && this.$gtm.push(getGA4SelectPromotionPayload({ banner, locationId: 'sitewide_offer_promotion' }))
      this.$config.GA4Syntax && this.$gtm && this.$gtm.push({ event: 'giftCardBannerClick' })
    }
  }
}
</script>

<style lang="scss">
.block {
  margin-bottom: 20px;
}
.product-best-sellers {
  width: 175px;
  background: #ededed;
  // width: 100%;
  margin-bottom: 20px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__panel {
    flex: 0 1 100%;
    text-align: center;
  }
  &__heading {
    margin: 0;
    font-weight: 700;
    padding: 10px 0;
    border-bottom: 1px solid #dee2e6;
    text-transform: uppercase;
  }
}
</style>
