var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"content content-2col"},[_c('div',{staticClass:"content-2col__main"},[_vm._m(0),_vm._v(" "),_vm._m(1),_vm._v(" "),_vm._m(2),_vm._v(" "),_c('div',{staticClass:"giftcards__types-container"},[_c('a',{staticClass:"giftcards__type",attrs:{"href":"/magazines/e-giftcards","alt":"E-Gift Cards"}},[_c('div',{staticClass:"giftcards__type-image"},[_c('BImageLazyImgix',{attrs:{"image-url":"/gift-card-images/egiftcard.png","alt":"E-Gift Card","query-string":"&w=270","blank-color":"#ededed","width":"270","height":"150","custom-class":"giftcards__egiftcard","fluid-grow":false}})],1),_vm._v(" "),_vm._m(3),_vm._v(" "),_vm._m(4),_vm._v(" "),_vm._m(5)]),_vm._v(" "),_c('a',{staticClass:"giftcards__type",attrs:{"href":"/magazines/gift-cards","alt":"Boxed Gift Cards"}},[_c('div',{staticClass:"giftcards__type-image"},[_c('BImageLazyImgix',{attrs:{"image-url":"/gift-card-images/giftcard.png","alt":"Gift Card","query-string":"&w=230","blank-color":"#ededed","width":"230","height":"127","custom-class":"giftcards__giftcard","fluid-grow":false}})],1),_vm._v(" "),_vm._m(6),_vm._v(" "),_vm._m(7),_vm._v(" "),_vm._m(8)])]),_vm._v(" "),_vm._m(9),_vm._v(" "),_vm._m(10)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"giftcards__banner"},[_c('img',{attrs:{"src":"https://magazine.imgix.net/gift-card-images/giftcard_banner.png?auto=format&fit=fill&fill=solid&bg=0FFF&w=1015&h=280","alt":"E-Gift Card","blank-color":"#ededed","width":"1015","height":"auto"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"giftcards__head--top mt-2"},[_c('h1',[_vm._v("Send a magazine.co.uk Gift Card")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"giftcards__description"},[_c('p',[_vm._v("\n        Give the perfect gift with a magazine.co.uk Gift Card.\n      ")]),_vm._v(" "),_c('p',[_vm._v("\n        E-Gift Cards offer a variety of designs and can be emailed instantly or on a specified date. E-Gift Cards can also be printed at home for you to give in person. The Gift\n        Cards in Boxes are physical cards delivered Royal Mail 1st Class.\n      ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"giftcards__text--h1"},[_c('p',[_c('span',[_vm._v("E-Gift Cards")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"giftcards__text--h2"},[_c('p',[_c('span',[_vm._v("Email Delivery")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"giftcards__text--button"},[_c('p',[_c('span',[_vm._v("GET STARTED")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"giftcards__text--h1"},[_c('p',[_c('span',[_vm._v("Gift Cards")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"giftcards__text--h2"},[_c('p',[_c('span',[_vm._v("Postal Delivery")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"giftcards__text--button"},[_c('p',[_c('span',[_vm._v("GET STARTED")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"giftcards__head--bottom mt-2"},[_c('h1',[_vm._v("Already have a voucher?")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"giftcards__description"},[_c('p',[_vm._v("\n        To redeem your gift card just follow these steps:\n      ")]),_vm._v(" "),_c('p',[_vm._v("\n        Step 1: Select the magazine subscription you would like to purchase (excl. Direct Debit)"),_c('br'),_vm._v("\n        Step 2: When you have finished shopping, continue to checkout. "),_c('br'),_vm._v("\n        Step 3: When prompted on the payment page enter the Gift code\n      ")]),_vm._v(" "),_c('p',[_vm._v("\n        Note: If your order exceeds the amount on the gift card, you must pay the outstanding balance with other accepted payment methods. If you don't spend all your gift card,\n        your account will be in credit for your next purchase.\n      ")])])
}]

export { render, staticRenderFns }