<template>
  <div v-if="values" class="clp-top-ten" itemscope itemtype="http://schema.org/ItemList" style="content-visibility: auto;">
    <div class="clp-top-ten--container" itemprop="numberOfItems" content="12">
      <h2 class="box category-heading">
        <a disabled="" class="text ten mdl-button" content="Top 12 Magazines" itemprop="name">{{ label }}</a>
      </h2>

      <div class="items-list offer-container block items-list--topx">
        <Product
          v-for="(item, index) in infiniteLoadingValues"
          :key="`${index}::${item.productId}`"
          :show-save-overlay="showSaveOverlay"
          :item="item"
          :index="index + 1"
          :is-digital-magazines-cats="isDigitalMagazinesCats"
        />
      </div>
    </div>
    <!-- <client-only>
      <infinite-loading @infinite="infiniteHandler"></infinite-loading>
    </client-only> -->
  </div>
</template>

<script>
export default {
  name: 'CLPTopxList',
  serverCacheKey: props => `CLPTopxList::${props.id}`,
  components: {
    Product: () => import('~/components/common/topx/Product.vue')
  },
  props: {
    values: {
      type: Array,
      required: true,
      default() {
        return []
      }
    },
    label: {
      type: String,
      required: false,
      default() {
        return ''
      }
    },
    showSaveOverlay: {
      type: Boolean,
      required: false,
      default() {
        return false
      }
    },
    isDigitalMagazinesCats: {
      type: Boolean,
      required: false,
      default() {
        return false
      }
    },
    id: {
      type: String,
      required: false,
      default() {
        return 0
      }
    }
  },
  data() {
    return {
      // infiniteLoadingValues: process.server ? [...this.values] : [],
      infiniteLoadingValues: [...this.values],
      localValues: [...this.values]
    }
  },
  methods: {
    infiniteHandler($state) {
      // we will add an inifnite scroll Logic to minimise the HTML payload
      if (this.localValues.length) {
        const data = this.localValues.splice(0, 4)
        console.log(`CLPTopxList > infiniteHandler ADD data=`, data)
        console.log(`CLPTopxList > this.localValues.length=`, this.localValues.length)
        console.log(`CLPTopxList > this.infiniteLoadingValues data=`, this.infiniteLoadingValues)
        this.infiniteLoadingValues.push(...data)
        $state.loaded()
      } else {
        $state.complete()
      }
    }
  }
}
</script>

<style lang="scss"></style>
