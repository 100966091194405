<template>
  <div
    class="content-clp content-clp__main"
    :class="{
      'content--desktop': !isFromMobile
    }"
  >
    <client-only>
      <CLPFilter v-if="products" :show-filter="showFilter" :count="count" :facets="facets" />
    </client-only>
    <!-- <client-only v-if="$store.getters['UI/debug']">
      <ul class="debug__list">
        <li class="debug__item">
          <tree-view :data="query" :options="{ rootObjectKey: 'QueryString', maxDepth: 0, link: true }"> </tree-view>
        </li>
        <li class="debug__item">
          <tree-view :data="$store.getters['category/settings']" :options="{ rootObjectKey: 'Vuex State', maxDepth: 0, link: true }"> </tree-view>
        </li>
        <li class="debug__item">
          <tree-view :data="$options.filters.objectParser(dataUI)" :options="{ rootObjectKey: 'Data UI', maxDepth: 0, link: true }"> </tree-view>
        </li>
      </ul>
    </client-only> -->
    <LazyHydrate when-visible>
      <Carousel2Images
        v-if="dataUI.banners && dataUI.banners.length && dataUI.banners.length === 2"
        :id="`clp::${categoryId}::${dataUI.expiresAt}`"
        :banners="dataUI.banners || []"
        location-id="category_hero_banner"
        :timestamp="timestamp"
      />

      <Carousel3Images v-else :id="`clp::${categoryId}::${dataUI.expiresAt}`" :banners="dataUI.banners || []" location-id="category_hero_banner" :timestamp="timestamp" />
    </LazyHydrate>
    <div class="clp-head mt-2">
      <h1 v-if="dataUI.h1Title">{{ dataUI.h1Title }}</h1>
    </div>

    <div v-if="dataUI.description" role="tablist" class="clp-accordion">
      <b-card no-body class="mb-0">
        <b-card-header header-tag="header" class="p-0 clp-accordion--header clp-accordion--header__first" role="tab">
          <b-button v-b-toggle.accordion-1 block href="#" variant="info">
            Description
            <font-awesome-icon :icon="['fal', 'plus']" class="fa right minus" />
            <font-awesome-icon :icon="['fal', 'minus']" class="fa right plus" />
          </b-button>
        </b-card-header>
        <b-collapse id="accordion-1" class="p-0 clp-accordion--description" accordion="clp-accordion" role="tabpanel">
          <!-- eslint-disable-next-line vue/no-v-html -->
          <b-card-body> <div v-html="$options.filters.parseHTML(dataUI.description)" /> </b-card-body>
        </b-collapse>
      </b-card>

      <b-card v-if="dataUI.childrenCategories && dataUI.childrenCategories.length > 0" no-body class="mb-0">
        <b-card-header header-tag="header" class="p-0 clp-accordion--header" role="tab">
          <b-button v-b-toggle.accordion-2 block href="#" variant="info">
            Sub Categories
            <font-awesome-icon :icon="['fal', 'plus']" class="fa right minus" />
            <font-awesome-icon :icon="['fal', 'minus']" class="fa right plus" />
          </b-button>
        </b-card-header>
        <b-collapse id="accordion-2" class="p-0 clp-accordion--categories" accordion="clp-accordion" role="tabpanel">
          <b-card-body v-for="(item, index) in dataUI.childrenCategories" :key="`i${index}-${item.categoryId}`" class="clp-categories__item clp-categories__item--clp">
            <a :href="item.urlPath">
              {{ item.name }}
            </a>
          </b-card-body>
        </b-collapse>
      </b-card>
    </div>

    <!-- eslint-disable-next-line vue/no-v-html -->
    <div class="clp-description" v-html="$options.filters.parseHTML(dataUI.description)"></div>
    <div class="clp-categories clp-categories--clp">
      <div
        v-for="(item, index) in dataUI.childrenCategories"
        :key="`i${index}-${item.categoryId}`"
        class="clp-categories__item clp-categories__item--clp"
        :class="[item.active && item.active === 1 ? 'clp-categories__item--clp-active' : '']"
      >
        <a :href="item.urlPath">
          {{ item.name }}
          <font-awesome-icon :icon="['fal', 'chevron-right']" class="fa fa-stack-1x rightArrow" />
        </a>
      </div>

      <div class="clp-categories__item clp-categories__item--no-border"></div>
      <div class="clp-categories__item clp-categories__item--no-border"></div>
    </div>
    <div class="clp-top">
      <div class="clp-filter m-md-2">
        <div class="clp-filter" @click.stop="handleFilterClick">Filter&nbsp;&nbsp;<font-awesome-icon :icon="['fal', 'filter']" class="fa" /></div>
        <!-- <div class="clp-filter__count">{{ count }} Titles</div> -->
      </div>
      <div class="clp-sort">
        <b-dropdown right variant="link" class="m-md-2" toggle-class="text-decoration-none" no-caret>
          <template #button-content> Sort: {{ sortLabel }}&nbsp;&nbsp;<font-awesome-icon :icon="['fal', 'chevron-down']" class="fa" /> </template>
          <b-dropdown-item :active="sortLabel == 'Popularity'" aria-describedby="Popularity" @click.stop="clickSortCallback({ type: 'position', value: 'asc' })">
            Popularity
          </b-dropdown-item>
          <b-dropdown-item :active="sortLabel == 'Name (A to Z)'" aria-describedby="Name (A to Z)" @click.stop="clickSortCallback({ type: 'name', value: 'asc' })">
            Name (A to Z)
          </b-dropdown-item>
          <b-dropdown-item :active="sortLabel == 'Name (Z to A)'" aria-describedby="Name (Z to A)" @click.stop="clickSortCallback({ type: 'name', value: 'desc' })">
            Name (Z to A)
          </b-dropdown-item>
          <b-dropdown-item :active="sortLabel == 'Price (Low to High)'" aria-describedby="Price (Low to High)" @click.stop="clickSortCallback({ type: 'price', value: 'asc' })">
            Price (Low to High)
          </b-dropdown-item>
          <b-dropdown-item :active="sortLabel == 'Price (High to Low)'" aria-describedby="Price (High to Low)" @click.stop="clickSortCallback({ type: 'price', value: 'desc' })">
            Price (High to Low)
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </div>
    <CLPProductsList
      v-if="products && products.length"
      :category-id="categoryId"
      :page-count="pageCount"
      :show-save-overlay="dataUI.showSaveOverlay"
      :show-free-gift-overlay="showFreeGiftOverlay"
      :is-digital-magazines-cats="isDigitalMagazinesCats"
      :is-magazine-subscriptions-for-schools="isMagazineSubscriptionsForSchools"
    />
    <div v-if="pageCount > 1" class="pagination-container" aria-label="Page navigation">
      <client-only>
        <paginate
          v-model="currentPage"
          :page-count="pageCount"
          :page-range="3"
          :margin-pages="0"
          :prev-text="'Prev'"
          :next-text="'Next'"
          :container-class="'pagination justify-content-center py-4'"
          :page-class="'page-item'"
          :page-link-class="'page-link'"
          :prev-class="'page-item'"
          :prev-link-class="'page-link'"
          :next-class="'page-item'"
          :next-link-class="'page-link'"
          :click-handler="clickPageCallback"
        >
        </paginate>
        <a class="btn btn-outline-primary my-4 ml-4 pagination__viewall" href="?limit=all">View All</a>
      </client-only>
    </div>
    <LazyHydrate never>
      <!-- eslint-disable-next-line vue/no-v-html -->
      <div class="clp-bottom-text pt-4" v-html="dataUI.bottomText" />
    </LazyHydrate>
    <!-- <LazyHydrate when-visible> -->
    <CLPTopxList
      v-if="dataUI.topXDisabled === false && dataUI.topx.items.length"
      :id="`clp::${categoryId}::${dataUI.expiresAt}`"
      :values="dataUI.topx.items"
      :label="dataUI.topx.label"
      :show-save-overlay="dataUI.showSaveOverlay"
      :is-digital-magazines-cats="isDigitalMagazinesCats"
    />
    <!-- </LazyHydrate> -->
    <PromotionsSitewideMobile
      v-if="sitewidemobile && sitewidemobile.enabled === true"
      style="margin-bottom: 30px;"
      creative-slot="mobile"
      location-id="sitewide_offer_promotion"
      :sitewide="sitewidemobile"
    />
  </div>
</template>

<script>
import category from '@/mixins/category'
export default {
  mixins: [category]
}
</script>
