<template>
  <div style="ec-conteiner">
    <div v-if="ecard && ecard.type === 'SEND_NOW'" class="ec ec__text-align-center">
      <span class="ec__text-header">
        <b>Your ecard has been sent to {{ ecard.to_name }} ({{ ecard.to_email }})</b>
      </span>
      <br />
      <a class="ec__text-sendanotherone" href="/ecard" title="ECard Creator">Send another one?</a>
    </div>
    <div v-if="ecard && ecard.type === 'SEND_CONFIRMATION_FUTURE_DELIVERY_TOBUYER'" class="ec ec__text-align-center">
      <span class="ec__text-header">
        <b>Your ecard will be sent to {{ ecard.to_name }} ({{ ecard.to_email }}) on {{ ecard.to_date_longformat }}</b>
      </span>
      <br />
      <a class="ec__text-sendanotherone" href="/ecard" title="ECard Creator">Send another one?</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ECardNotification',
  serverCacheKey() {
    return false
  },
  props: {
    ecard: {
      type: Object,
      required: true,
      default() {
        return undefined
      }
    }
  }
}
</script>

<style scoped lang="scss">
.ec-conteiner {
  padding: 10px;
}
.ec {
  margin: 0px;
  // border: 1px solid black;
  // border-radius: 6px;
  padding: 10px 10px 0 10px;
  line-height: 40px;
  &__text-align-center {
    text-align: center;
  }
  &__text-header {
    font-size: 16px;
  }
}
</style>
