<template>
  <div v-show="toggle" class="m-modal-scroller" style="padding-bottom: 1px; visibility: visible;">
    <div class="a-popover m-popover-modal">
      <div class="m-popover-wrapper">
        <header class="m-popover-header">
          <h4 class="m-popover-header-content">Select a design for your E-Gift Card</h4>
          <div class="m-button-close " @click.stop.prevent="handleClick">
            <font-awesome-icon :icon="['fal', 'times']" class="fa fa-stack-1x right" />
          </div>
        </header>
        <div class="gc-popover-inner" style="height: auto; overflow-y: auto;">
          <div id="gc-designs-modal-wrapper">
            <div class="gc-designs__inner-wrapper">
              <ul class="gc-designs__list">
                <li v-for="(template, index) in designs" :key="`${template.id}`" class="gc-designs__list-item">
                  <span class="m-button m-button-toggle">
                    <span class="m-button-inner">
                      <button class="m-button-text m-padding-2 m-nofocus" type="button" @click="updateSelection('design', index + 4, template)">
                        <img :src="createImgixURL(template.image, designThumbnailQueryString)" class="m-button-thumbnail-large" :alt="template.title" />
                      </button>
                    </span>
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    toggle: {
      type: Boolean,
      required: true,
      default() {
        return false
      }
    },
    designs: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      designPreviewQueryString: '?auto=format&w=470&dpr=2',
      designThumbnailQueryString: '?auto=format&fit=fill&fill=solid&bg=0FFF&w=198&h124&dpr=2'
    }
  },
  methods: {
    createImgixURL(image, qs) {
      return `${this.$config.imgixURL}${image}${qs}`
    },
    handleClick() {
      this.$nuxt.$emit('SHOW_GIFTCARD_DESIGNS', false)
    },
    updateSelection(action, index, data) {
      this.$nuxt.$emit('UPDATE_DESIGN_SELECTION', { action, index, data })
      setTimeout(function() {
        this.$nuxt.$emit('SHOW_GIFTCARD_DESIGNS', false)
      }, 150)
    }
  }
}
</script>

<style lang="scss" scoped>
.m-modal-scroller {
  height: 100%;
  width: 100%;
  right: 0;
  top: 0;
  overflow: auto;
  position: fixed;
  visibility: hidden;
  z-index: 10009;
}
.m-popover-modal {
  width: 95%;
  max-height: none;
  max-width: 95%;
  visibility: visible;
  position: relative;
  top: 0px;
  left: 0px;
  opacity: 1;
  left: 50%;
  top: 25%;
  margin-bottom: 10%;
  /* Use this for centering if unknown width/height */
  transform: translate(-50%, -20%);
}
.m-popover-modal {
  .m-popover-wrapper {
    position: relative;
    border-radius: 8px;
    border: 1px solid;
    border-color: $gcBorderColorTemplate;
    background: white;
    box-shadow: 0 0 14px 0 rgba(15, 17, 17, 0.5);
    height: 100%;
  }
  .m-popover-header {
    border-bottom: 1px solid $gcBorderColorTemplate;
    background-color: #f0f2f2;
    border-radius: 8px 8px 0 0;
    padding: 0 24px;

    .m-popover-header-content {
      margin: unset;
      padding: 16px 0;
      min-height: 56px;
      margin-right: 28px;
      line-height: 24px;
      font-weight: 700;
      font-size: 16px;
      color: black;
    }
  }
}

.gc-designs {
  &__section {
    text-align: left;
    margin-top: 10px;
  }
  &__inner-wrapper {
    padding: 20px 5% 0 5%;
  }
  &__list {
    padding: 10px;
    list-style-type: none;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-flow: row wrap;
    &--stretch {
      align-items: stretch;
    }
  }
  &__list-item {
    flex: 0 1 40%;
    margin: 5% 1%;
  }
}
@media (min-width: $screen-sm-min) {
  .m-popover-modal {
    width: 740px;
    top: 30%;
  }
  .gc-designs {
    &__list {
      justify-content: flex-start;
    }
    &__list-item {
      flex: 0 1 30%;
    }
  }
}
</style>
