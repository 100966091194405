<template>
  <div class="full-size layout-column layout-align-center-vertical bootstrap snippets">
    <div class="size-7 horizontal-align">
      <div class="panel panel-default">
        <div class="panel-body text-center">
          <div class="logo-box logo-box-primary padding-top-4">
            <div class="logo">
              <img src="https://magazine.imgix.net/wysiwyg/logo_home_desktop.png?auto=format&fit=fill&fill=solid&bg=0FFF&w=160&dpr=2&q=30" />
            </div>
          </div>
          <h2>Under Maintenance</h2>
          <p>We will be down for a short time<br />Please check back later</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  layout: 'maintenance'
}
</script>
<style lang="scss" scoped>
body {
  overflow: hidden;
  background-size: cover;
  font-size: 15px;
  margin: 0;
  padding: 0;
}
.layout-align-center-vertical {
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  max-height: 100%;
}
.horizontal-align {
  position: relative;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

.width-7,
.size-7 {
  width: 392px !important;
  margin-top: 20%;
}
.height-7,
.size-7 {
  height: 392px !important;
}
.logo-box-primary .logo {
  color: #fff;
  background-color: #fff !important;
}
.logo-box,
.logo-box .logo {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-align-content: center;
  -moz-align-content: center;
  -ms-align-content: center;
  align-content: center;
}
.logo-box .logo {
  width: 96px;
  height: 96px;
  font-size: 72px;
  font-weight: 500;
  color: #fff;
  background-color: #777e7d;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  align-content: center;
}
</style>
