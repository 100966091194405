<template>
  <div class="footer-container">
    <Social />
    <Links />
  </div>
</template>
<script>
export default {
  name: 'Footer',
  serverCacheKey() {
    return false
  },
  components: {
    Social: () => import('~/components/footer/Social.vue'),
    Links: () => import('~/components/footer/Links.vue')
  }
}
</script>
<style lang="scss">
.footer-container {
  content-visibility: auto;
  margin: 0;
}
@media (max-width: $screen-xs-max) {
  .footer-container {
    content-visibility: auto;
    margin-bottom: 50px;
  }
}
</style>
