<template>
  <div v-show="toggle" class="m-modal-scroller" style="padding-bottom: 1px; visibility: visible;">
    <div class="a-popover m-popover-modal">
      <div class="m-popover-wrapper">
        <header class="m-popover-header" @click.stop.prevent="handleClick">
          <h4 class="m-popover-header-content">Preview</h4>
          <div class="m-button-close">
            <font-awesome-icon :icon="['fal', 'times']" class="fa fa-stack-1x right" />
          </div>
        </header>
        <div class="gc-popover-inner" style="height: auto; overflow-y: auto;">
          <div id="gc-preview-modal-wrapper">
            <div class="gc-preview__email-wrapper">
              <div v-if="toName" class="gc-preview__section">
                <span id="gc-preview__text-label" class="m-text-bold">E-Gift card will be emailed to</span>
                <br />
                <span id="gc-preview__text-value">{{ toName }} {{ toEmail }}</span>
              </div>
              <div v-if="toDate" class="gc-preview__section">
                <span id="gc-preview__text-label" class="m-text-bold">On</span>
                <br />
                <span id="gc-preview__text-value">{{ toDate.toLocaleDateString('en-GB') }}</span>
              </div>
              <div v-if="fromName" class="gc-preview__section">
                <span id="gc-preview__subject-title" class="m-text-bold">From</span>
                <br />
                <span id="gc-preview__subject-value">{{ fromName }} {{ fromEmail }}</span>
              </div>
              <div class="gc-preview__section">
                <span id="gc-preview__subject-title" class="m-text-bold">Subject</span>
                <br />
                <span id="gc-preview__subject-value">{{ fromName }} {{ havePresentTense }} bought you a gift subscription to {{ magazineName }}</span>
              </div>
              <div v-if="isFromMobile && message" class="gc-preview__section">
                <span id="gc-preview__text-label" class="m-text-bold">Message:</span>
                <br />
                <span id="gc-preview__text-value">{{ message }}</span>
              </div>
            </div>

            <div class="gc-preview__preview-wrapper">
              <ECardTemplate
                :image="image"
                :message="message"
                :magazine-cover="magazineCover"
                :magazine-name="magazineName"
                :from-name="fromName"
                :from-email="fromEmail"
                :to-name="toName"
                :to-email="toEmail"
                :to-date="toDate"
                :is-preview="true"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ECardPreview',
  serverCacheKey() {
    return false
  },
  components: {
    ECardTemplate: () => import('~/components/ecard/ECardTemplate.vue')
  },
  props: {
    toggle: {
      type: Boolean,
      required: true,
      default() {
        return false
      }
    },
    image: {
      type: String,
      required: false,
      default() {
        return 'https://magazine.imgix.net/gift-card-images/JMAG_Giftcards.png?auto=format&w=470&dpr=2'
      }
    },
    message: {
      type: String,
      required: false,
      default() {
        return "You've been bought a magazine gift subscription from magazine.co.uk"
      }
    },
    magazineCover: {
      type: String,
      required: false,
      default() {
        return 'https://magazine.imgix.net/placeholder.jpg?auto=format&fit=fill&fill=solid&bg=0FFF&w=133&h=174&dpr=3'
      }
    },
    magazineName: {
      type: String,
      required: false,
      default() {
        return ''
      }
    },
    fromName: {
      type: String,
      required: true,
      default() {
        return 'John Doe'
      }
    },
    fromEmail: {
      type: String,
      required: true,
      default() {
        return 'john.doe@example.com'
      }
    },
    toName: {
      type: String,
      required: false,
      default() {
        return 'Joe Bloggs'
      }
    },
    toEmail: {
      type: String,
      required: false,
      default() {
        return 'joe.bloggs@example.com'
      }
    },
    toDate: {
      type: Date,
      required: false,
      default() {
        return undefined
      }
    }
  },
  data() {
    return {
      isFromMobile: this.$ua.isFromIphone() || this.$ua.isFromAndroidMobile()
    }
  },
  computed: {
    havePresentTense() {
      // Add have/has preent tense depens on the first_name value
      let value = 'has'
      const regexInsensitive = /( and | or |&|\+)/i
      if (regexInsensitive.test(this.fromName)) {
        value = 'have'
      }
      return value
    }
  },
  methods: {
    handleClick() {
      this.$nuxt.$emit('SHOW_PREVIEW', false)
    }
  }
}
</script>

<style lang="scss" scoped>
.m-modal-scroller {
  height: 100%;
  width: 100%;
  right: 0;
  top: 0;
  overflow: auto;
  position: fixed;
  visibility: hidden;
  z-index: 10009;
}
.m-popover-modal {
  width: 95%;
  max-height: none;
  max-width: 95%;
  visibility: visible;
  position: relative;
  top: 0px;
  left: 0px;
  opacity: 1;
  left: 50%;
  top: 25%;
  margin-bottom: 10%;
  /* Use this for centering if unknown width/height */
  transform: translate(-50%, -15%);
}
.m-popover-modal {
  .m-popover-wrapper {
    position: relative;
    border-radius: 8px;
    border: 1px solid;
    border-color: $gcBorderColorTemplate;
    background: white;
    box-shadow: 0 0 14px 0 rgba(15, 17, 17, 0.5);
    height: 100%;
  }
  .m-popover-header {
    border-bottom: 1px solid $gcBorderColorTemplate;
    background-color: #f0f2f2;
    border-radius: 8px 8px 0 0;
    padding: 0 24px;

    .m-popover-header-content {
      margin: unset;
      padding: 16px 0;
      min-height: 56px;
      margin-right: 28px;
      line-height: 24px;
      font-weight: 700;
      font-size: 16px;
      color: black;
    }
  }
}

.gc-preview {
  &__preview-wrapper {
    padding: 5% 20%;
  }
  &__template-code-wrapper {
    // background-color: $gcBgColorDark;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0px 0px 6px 6px;
  }
  &__template-code-text {
    font-size: 14px;
    color: $gcTextColor2;
    padding: 0px;
    text-align: center;
    width: 300px;
    margin: 0px 10px 20px 10px;
    padding: 10px;
  }
  &__section {
    text-align: left;
    margin-top: 10px;
  }
  &__email-wrapper {
    padding: 20px 10% 0 10%;
  }
}

@media (min-width: $screen-sm-min) {
  .m-popover-modal {
    width: 740px;
    top: 30%;
  }
}
@media (max-width: $screen-xs-max) {
  .gc-preview {
    &__email-wrapper {
      padding: unset;
      margin: 15px;
    }
    &__preview-wrapper {
      padding: unset;
      margin: 25px 5px;
    }
  }
}
</style>
