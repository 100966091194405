<script>
import { getGTMPromoClickPayload } from '~/lib/util/gtmUAEE'
import { getGA4EcommerceNullPayload, getGA4SelectPromotionPayload } from '~/lib/util/gtmGA4'

export default {
  props: {
    banners: {
      type: Array,
      required: true,
      default() {
        return []
      }
    },
    id: {
      type: String,
      required: false,
      default() {
        return ''
      }
    },
    locationId: {
      type: String,
      required: false,
      default() {
        return ''
      }
    },
    isHomepage: {
      type: Boolean,
      required: false,
      default() {
        return false
      }
    },
    timestamp: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      imgixBaseQueryString: '?auto=format',
      activeImages: this.banners || [],
      isFromMobile: this.$ua.isFromIphone() || this.$ua.isFromAndroidMobile(),
      jsLoading: true
    }
  },
  computed: {
    getWidth() {
      return this.isFromMobile ? '414px' : '1025px'
    },
    getHeight() {
      return this.isFromMobile ? '100px' : '283px'
    }
  },
  mounted() {
    setTimeout(() => {
      console.log('jsLoading Delayed for 5 second')
      this.jsLoading = false
      console.log('jsLoading=', this.jsLoading)
    }, '5000')
  },
  methods: {
    imgixUrl(url) {
      const originalUrl = url.includes('?') ? url.split('?').shift() : url
      return `${originalUrl}${this.imgixBaseQueryString}`
    },
    removeImgixWidthQS(url) {
      return url.replace(/&w=\d*/g, '')
    },
    handleClick(banner) {
      if (banner) {
        // UAEES event for every Banner Image Click
        this.$config.UAEESyntax && this.$gtm && this.$gtm.push(getGTMPromoClickPayload({ banner }))

        // GA4 events for every  Banner Image Click
        this.$config.GA4Syntax && this.$config.GA4FireEcommerceNull && this.$gtm && this.$gtm.push(getGA4EcommerceNullPayload())
        this.$config.GA4Syntax && this.$gtm && this.$gtm.push(getGA4SelectPromotionPayload({ banner, locationId: this.locationId }))
        this.$config.GA4Syntax && this.$config.GA4FireEcommerceNull && this.$gtm && this.$gtm.push(getGA4EcommerceNullPayload())
        this.isHomepage && this.$config.GA4Syntax && this.$gtm && this.$gtm.push({ event: 'homepageBannerClick' })

        // Redirct to the offer page
        window.location.href = `${this.$config.baseURL}/${banner.link}`.replace(/([^:]\/)\/+/g, '$1')
      }
    }
  }
}
</script>
