<template>
  <div class="category-products items-list clp-list">
    <Products v-for="(item, index) in values" :key="`${index}::${item.productId}`" :type="'clp'" :item="item" class="clp-list__item items-list__item" />
  </div>
</template>

<script>
export default {
  name: 'GiftFinderProductsList',
  serverCacheKey() {
    return false
  },
  components: {
    Products: () => import('~/components/common/list/Product.vue')
  },
  props: {
    values: {
      type: Array,
      required: false,
      default() {
        return this.$store.getters['category/products']
      }
    }
  }
}
</script>

<style lang="scss">
@media (max-width: $screen-xs-max) {
  .clp-list .items-list__item {
    text-align: center;
    flex: 0 0 45%;
  }
  .clp-list .items-list__item .actions {
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .clp-list .items-list__item .actions a.view-btn {
    color: #333;
    transition: all 0.2s;
    font-size: 12px;
    line-height: 12px;
    margin: 0 2px;
    text-decoration: underline;
    border: unset;
    min-width: 50px;
    padding: 15px 0;
    text-align: center;
  }

  .items-list__item .actions div.add-to-basket-btn {
    display: inline-block;
    vertical-align: middle;
    border-radius: 3px;
    border: none;
    background: #00933e;
    font-family: Open Sans, sans-serif;
    font-weight: 600;
    font-size: 12px;
    text-transform: uppercase;
    text-decoration: none;
    text-align: center;
    color: #fff;
    width: 100%;
    padding: 10px !important;
    margin-top: 10px;
    transition: all 0.2s;
    line-height: 12px;
    margin: 0;
    min-width: 100px;
  }
}
@media (min-width: $screen-xs-max) {
  .clp-list .items-list__item {
    flex: 0 0 24.5%;
  }
  .clp-list .items-list__item .actions {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
  }
  .clp-list .items-list__item .actions a {
    transition: all 0s;
    font-size: 12px;
    color: #00933e;
    line-height: 12px;
    margin: 0 2px;
    text-decoration: underline;
    border: unset;
    min-width: 50px;
    padding: 15px 0;
    text-align: center;
  }
}
@media (width: $screen-sm-min) {
  .clp-list .items-list__item .actions a {
    transition: all 0s;
    font-size: 12px;
    color: #00933e;
    line-height: 12px;
    margin: 0 2px;
    text-decoration: underline;
    border: unset;
    min-width: 50px;
    padding: 15px 0;
    text-align: center;
    background-color: white;
  }
}
</style>
