<template>
  <div class="product-faq">
    <div class="product-faq__head"><h2>Frequently Asked Questions</h2></div>
    <div v-for="faq in faqs.slice(0, 4)" :key="faq.id" class="product-faq__item">
      <div class="product-faq__accordion" @click="toggleFAQIsOpen(faq)">
        <font-awesome-icon :icon="['fal', 'chevron-left']" class="product-faq__arrow product-faq__arrow--left fa" :class="{ active: !faq.isOpen, hidden: faq.isOpen }" />
        <font-awesome-icon :icon="['fal', 'chevron-down']" class="product-faq__arrow product-faq__arrow--down fa" :class="{ active: faq.isOpen, hidden: !faq.isOpen }" />
        <!-- eslint-disable-next-line vue/no-v-html -->
        <button class="product-faq__accordion-button" v-html="faq.question"></button>
      </div>
      <div class="product-faq__panel" :class="{ active: faq.isOpen, hidden: !faq.isOpen }">
        <!-- eslint-disable-next-line vue/no-v-html -->
        <p v-html="faq.answer"></p>
      </div>
    </div>
    <div v-for="faq in faqs.slice(4)" :key="faq.id" class="product-faq__item" :class="{ active: !isViewMoreActive, hidden: isViewMoreActive }">
      <div class="product-faq__accordion" @click="toggleFAQIsOpen(faq)">
        <font-awesome-icon :icon="['fal', 'chevron-left']" class="product-faq__arrow product-faq__arrow--left fa" :class="{ active: !faq.isOpen, hidden: faq.isOpen }" />
        <font-awesome-icon :icon="['fal', 'chevron-down']" class="product-faq__arrow product-faq__arrow--down fa" :class="{ active: faq.isOpen, hidden: !faq.isOpen }" />
        <!-- eslint-disable-next-line vue/no-v-html -->
        <button class="product-faq__accordion-button" v-html="faq.question"></button>
      </div>
      <div class="product-faq__panel" :class="{ active: faq.isOpen, hidden: !faq.isOpen }">
        <!-- eslint-disable-next-line vue/no-v-html -->
        <p v-html="faq.answer"></p>
      </div>
    </div>
    <div :class="{ active: isViewMoreActive, hidden: !isViewMoreActive }" class="product-faq__item product-faq__view-more" @click="toggleIsViewMoreActive">View More</div>
    <div :class="{ active: !isViewMoreActive, hidden: isViewMoreActive }" class="product-faq__item product-faq__view-less" @click="toggleIsViewMoreActive">View Less</div>
  </div>
</template>
<script>
export default {
  name: 'PDPFAQs',
  serverCacheKey: props => `PDPFAQs::${props?.payload?.magazine?.sku}::${props.isXMAS}`,
  props: {
    payload: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      faqs: JSON.parse(JSON.stringify(this.$store.getters['UI/faqs'])),
      isViewMoreActive: true
    }
  },
  methods: {
    toggleIsViewMoreActive() {
      this.isViewMoreActive = !this.isViewMoreActive
    },
    toggleFAQIsOpen(faq) {
      faq.isOpen = !faq.isOpen
    }
  }
}
</script>
<style lang="scss" scoped>
/* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
.active {
  display: block !important;
}
.hidden {
  display: none !important;
}
.product-faq {
  border-radius: unset;
  border: unset;
  padding-left: unset;
  padding-right: unset;
  padding-top: unset;
  border: 1px solid #ededed;
  color: #000 !important;

  &__item {
    border-style: solid;
    border-color: #ededed;
    border-left-width: 0px;
    border-bottom-width: 1px;
    border-top-width: 0px;
    border-right-width: 0px;
  }

  &__accordion {
    width: 100%;
    display: inline-flex;
    justify-content: left;
    align-items: center;
    color: #000 !important;
  }

  &__arrow {
    position: relative;
    height: 50px !important;
    width: 36px !important;
    background: whit;
    transform: matrix(-1, 0, 0, 1, 0, 0);
    &--left {
      padding: 0px 14px;
    }
    &--down {
      padding: 0px 11px;
    }
  }

  /* Style the buttons that are used to open and close the accordion panel */
  &__accordion-button {
    background-color: white;
    cursor: pointer;
    padding: 15px 15px 15px 0;
    min-height: 50px;
    width: 100%;
    text-align: left;
    border: none;
    outline: none;
    transition: 0.4s;
    font-size: 14px;
    color: #000 !important;
  }

  /* Style the accordion panel. Note: hidden by default */
  &__panel {
    padding: 0 15px 0 36px;
    background-color: white;
    display: none;
    overflow: hidden;
    p {
      font-size: 14px;
    }
  }

  &__head {
    padding: 15px;
    h2 {
      font-size: 20px;
      line-height: 20px;
      margin: 0px !important;
    }
  }
  &__view-more,
  &__view-less {
    padding: 15px;
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
    text-align: center;
    text-decoration: underline;
    cursor: pointer;
  }
}

.product-faq > .product-faq__item {
  border-top-width: 1px;
  border-bottom-width: 0px;
}
.product-faq > .product-faq__item ~ .product-faq__item {
  border-bottom-width: 0px;
}

@media (min-width: $screen-xs-max) {
  .product-faq {
    border-radius: 0.25rem;

    &__accordion-button {
      font-size: 16px;
    }

    &__panel {
      p {
        font-size: 16px;
      }
    }

    .head {
      h2 {
        font-size: 21px;
      }
    }
  }
}
</style>
